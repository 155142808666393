import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles'; 
import {Grid, FormGroup, FormControlLabel, Checkbox} from '@material-ui/core';
import CreateTaxPresetDrawer from './CreateTaxPresetDrawer';
import {connect} from 'react-redux';
import { consoleToLog } from '../util/AppUtil';
import { updateInvoiceDisplaySettingsApi } from '../services/authService';
import { useSnackbar } from 'notistack';
import { setSelectedInvoiceAccount, setInvoiceAccountsList, setClearInvoiceAccounts } from '../actions/invoiceAccount';
import { useIsMount } from './useIsMount';
import { logout } from '../actions/invoiceAuth';
import { clearSelectedInvoiceFilters } from '../actions/invoiceFilters';
import { setSelectedDrawerState } from '../actions/drawer';
import { clearSelectedTransactionFilters } from "../actions/transactionFilters";
import {trackGAEvent} from '../util/AppUtil';

const useStyles = makeStyles((theme) => ({
    mainContent : {
        width: (showDrawer) => {
            return showDrawer ? "83%" : "100%"; 
        },
        backgroundColor: theme.palette.primary.light, 
        transition: ".5s", 
        flexGrow: 1,
        //overflow:'hidden',
        height:'calc(100vh - 48px)',
        overflow:'hidden',
        overflowX:'hidden', 
    },
    container: {
        marginTop: "8px"
    },
    settingsContainer: {
        width:'100%',
        background:'#fff',
        borderRadius:'4px',
        padding:'8px 16px',
        marginBottom:'32px'
    }
}))

const InvoiceDisplaySettings = (props) => {
    const classes = useStyles();
    const {show_payment_stub, show_payment_terms, show_client_notes} = props.selectedAccount.display_settings;
    const [paymentStub, setPaymentStub] = useState(show_payment_stub);
    const [paymentTerms, setPaymentTerms] = useState(show_payment_terms);
    const [clientTerms, setClientTerms] = useState(show_client_notes);
    const {enqueueSnackbar} = useSnackbar();
    const isMount = useIsMount();

    useEffect(() => {
        if(!isMount) {
            setPaymentStub(show_payment_stub);
            setPaymentTerms(show_payment_terms);
            setClientTerms(show_client_notes);
        }
        }, [props.selectedAccount?.id])

    const updateInvoiceDisplaySettings = (paramPaymentStub, paramPaymentTerms, paramClientTerms) => {
        const invoice_account_id = props.selectedAccount?.id;

        updateInvoiceDisplaySettingsApi(invoice_account_id, paramPaymentStub, paramPaymentTerms, paramClientTerms)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response updateInvoiceDisplaySettingsApi: ', res);
                let account = {
                    ...props.selectedAccount,
                    display_settings: res.display_settings
                };

                let accountList = props.invoiceAccountsList.map((ia)=>{
                    if(ia.id === account.id) return account;
                    else return ia;
                });

                props.setInvoiceAccountsList(accountList);
                props.setSelectedInvoiceAccount(account)
                enqueueSnackbar("Setting updated", {variant: 'success'});

                trackGAEvent(props.selectedAccount.name, 'Invoice display settings updated', `${props.user.firstname} ${props.user.lastname}`);
            })
            .catch((e) => {
                consoleToLog('Error updateInvoiceDisplaySettingsApi: ', e.response);
                if(e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {variant: 'error'});
                    return;
                }
            })
    }

    return (
        <Grid item xs={12} className={classes.settingsContainer}>
            <FormGroup>
                <FormControlLabel control={
                    <Checkbox  
                        onChange = {(e)=>{
                            setPaymentStub(e.target.checked);
                            updateInvoiceDisplaySettings(e.target.checked, paymentTerms, clientTerms);
                        }}
                        checked={paymentStub}
                    />
                    } 
                    label="Show Payment stub" />
                <FormControlLabel control={
                    <Checkbox   
                        onChange = {(e)=>{
                            setPaymentTerms(e.target.checked);
                            updateInvoiceDisplaySettings(paymentStub, e.target.checked, clientTerms );
                        }}
                        checked={paymentTerms}/>
                    } 
                    label="Show Payment Terms" />
                <FormControlLabel control={
                    <Checkbox   
                        onChange = {(e)=>{
                            setClientTerms(e.target.checked);
                            updateInvoiceDisplaySettings(paymentStub, paymentTerms, e.target.checked);
                        }}
                        checked={clientTerms}/>
                    } 
                    label="Show Client Notes" />
            </FormGroup>
        </Grid>
    );
}

const mapStateToProps = (state) => ({
    accessToken: state.invoiceAuth,
    selectedAccount: state.invoiceAccounts.selectedAccount,
    invoiceAccountsList: state.invoiceAccounts.invoiceAccountsList,
    user: state.invoiceAccounts.user
})

const mapDispatchToProps = (dispatch) => ({
    setSelectedInvoiceAccount: (account) => dispatch(setSelectedInvoiceAccount(account)),
    setInvoiceAccountsList: (list) => dispatch(setInvoiceAccountsList(list)),
    logout: () => dispatch(logout()),
    clearSelectedInvoiceFilters: () => dispatch(clearSelectedInvoiceFilters()),
    setClearInvoiceAccounts: () => dispatch(setClearInvoiceAccounts()),
    setSelectedDrawerState: (drawerState) => dispatch(setSelectedDrawerState(drawerState)),
    clearSelectedTransactionFilters: () => dispatch(clearSelectedTransactionFilters())
})

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceDisplaySettings);