import React from 'react';
import { makeStyles } from '@material-ui/core';
import {Grid, Typography, Dialog, Slide, Tooltip, Button} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import moment from 'moment';
import MoreHorizOutlinedIcon from '@material-ui/icons/MoreHorizOutlined';
import { useWindowSize } from '../useWindowSize';
import {statusColor, currencyFormatter, displayInvoiceTypeLabel} from '../../util/AppUtil'

const useStyles = makeStyles((theme) => ({
    dialogTitle : {
        borderBottom:'1px solid #ccc', 
        padding:'16px 24px', 
        display:'flex',  
        justifyContent:'space-between', 
        alignItems:'center',
        fontSize:'16px',
        fontWeight:'600'
    },
    MuiDialogpaper: {
        scrollbarWidth: 'none',
        overflow:'visible',
        paddingBottom:'5px',
        "&::-webkit-scrollbar": {
            display: "none"
        }   
    },
    textHeading: {
        fontWeight:'500'
    },
    headingContainer: {
        background:'#fbfbfc',
        borderBottom:'2px solid rgba(0,0,0,0.1)',
        padding:'12px 16px',
        color:'#5a5d60'
    },
    listContainer: {
        borderBottom:'1px solid rgba(0,0,0,0.1)',
        padding:'12px 16px',
        color:'#5a5d60',
        '&:last-child': {
            border:'none'
        },
        '& .action__icon' : {
            padding:'0px 2px 0px 2px',
            border:'1px solid white',
            borderRadius:'4px',
            cursor:'pointer'
        },
        '&:hover .action__icon' : {
           border:'1px solid #ccc'
        },
    },
    textEllipsis: {
        maxWidth: '45ch !important',
        whiteSpace:  'nowrap !important',
        overflow: 'hidden !important',
        textOverflow: 'ellipsis !important',
        [theme.breakpoints.down(1400)] : {
            maxWidth: '40ch !important',
        },
        [theme.breakpoints.down(1300)] : {
            maxWidth: '37ch !important',
        }
    },
    customColumn1:{
        flexBasis:'10%',
        maxWidth:'10%'
    },
    customColumn2: {
        flexBasis:'6%',
        maxWidth:'6%'
    },
    customColumn3: {
        flexBasis:'10%',
        maxWidth:'10%'
    },
    customColumn4: {
        flexBasis:'32%',
        maxWidth:'32%'
    },
    dialogStyle: {
        position: 'relative !important', 
        zIndex: '1300', 
        inset: '0px', 
        width:'200% !important'
    },
    invoiceTypeStyle: {
        fontSize:'10px',
        borderRadius: '4px !important',
        backgroundColor: '#f9f9f9 !important',
        border: '1px solid #ececec !important',
        padding:'3px',
        marginRight:'3px'
    }
}))

const OtherInvoicesModal = (props) => {
    const {width} = useWindowSize();
    const classes = useStyles(width);

    const onMoreActionClick = (e, invoice) => {
        e.stopPropagation();
        props.setIsActive(!props.isActive);
        props.setMenuId(invoice.id);
        props.setFromInvoiceModal(!props.fromInvoiceModal)
    }

    return (
        <div>
            <Dialog
                    open={props.showAllInvoicesModal}
                    //TransitionComponent={Transition}
                    classes={{paper: classes.MuiDialogpaper}}
                    maxWidth={width < 700 ? 'xl' :"md"}
                    className={width < 700 && classes.dialogStyle}
                    fullWidth>
                    <Typography
                        className={classes.dialogTitle}>
                        <span>Other invoices by {props.invoiceObj?.client.name}</span>

                        <ClearIcon fontSize='xs' onClick={props.closeOtherInvoicesModal}
                         style={{cursor:'pointer', verticalAlign:'inherit'}}/>
                    </Typography>
                        
                    <Grid item container direction="column">   
                        <Grid item container className={classes.headingContainer}>
                            <Grid item sm={1}>
                                <Typography variant="body1" className={classes.textHeading}>
                                    #
                                </Typography>
                            </Grid>
                            <Grid item sm={1} className={classes.customColumn1}>
                                <Typography variant="body1" className={classes.textHeading}>
                                    Status
                                </Typography>
                            </Grid>
                            <Grid item sm={1} className={classes.customColumn3}>
                                <Typography variant="body1" className={classes.textHeading}>
                                    Issued
                                </Typography>
                            </Grid>
                            <Grid item sm={4} className={classes.customColumn4}>
                                <Typography variant="body1" className={classes.textHeading}>
                                    Job Description
                                </Typography>
                            </Grid>
                            <Grid item sm={2}>
                                <Typography variant="body1" className={classes.textHeading}>
                                    Total
                                </Typography>
                            </Grid>
                            <Grid item sm={2}>
                                <Typography variant="body1" className={classes.textHeading}>
                                    Amount due
                                </Typography>
                            </Grid>

                        </Grid>

                        <Grid item style={{borderBottom:'1px solid rgba(0,0,0,0.1)'}}>
                            {
                                props.otherInvoices && props.otherInvoices.length > 0 &&
                                props.otherInvoices.map((invoice) => {
                                    return <Grid item container key={invoice.id}
                                                alignItems="center"
                                                className={classes.listContainer}>

                                                <Grid item sm={1}>
                                                    <Typography variant="body2">
                                                        {<span className={classes.invoiceTypeStyle}>
                                                            {displayInvoiceTypeLabel(invoice.invoice_type)}
                                                        </span>} 
                                                        <span style={{fontSize:'13px', position:'relative', top:'1px'}}>
                                                            {invoice.inv_number}
                                                        </span>
                                                    </Typography>
                                                </Grid>

                                                <Grid item sm={1} className={classes.customColumn1}>
                                                    <div style={{display:'flex', alignItems:'center'}}>
                                                        <div 
                                                            style={{width: '4px', 
                                                                    height: '20px', 
                                                                    marginRight: '4px',
                                                                    borderTopLeftRadius: '2px',
                                                                    borderBottomLeftRadius: '2px',
                                                                    background: statusColor(invoice.status.toLowerCase())}}>
                                                        </div>
                                                        <Typography variant="subtitle1" 
                                                            style={{color:statusColor(invoice.status.toLowerCase()), fontSize: "12px"}}>
                                                            {'Downloaded'}
                                                        </Typography>
                                                    </div>
                                                </Grid>

                                                <Grid item sm={1} className={classes.customColumn3}>
                                                    <Typography variant="body2">
                                                        {moment(invoice.issue_date).format("DD/MM/YYYY")}
                                                    </Typography>
                                                </Grid>
                                                
                                                <Grid item sm={4} className={classes.customColumn4}>
                                                    <Tooltip title={invoice.invoice_description}>
                                                        <Typography variant="body2" className={classes.textEllipsis}>
                                                            {invoice.invoice_description}
                                                        </Typography>
                                                    </Tooltip>
                                                </Grid>
                                                
                                                <Grid item sm={2}>
                                                    <Typography variant="body2">
                                                        {/* {invoice.total.toFixed(2)}₹ */}
                                                        {currencyFormatter.format(invoice.total)}
                                                    </Typography>
                                                </Grid>   
                                                
                                                <Grid item sm={2}>
                                                    <Typography variant="body2">
                                                        {/* {invoice.due.toFixed(2)}₹ */}
                                                        {currencyFormatter.format(invoice.due)}
                                                    </Typography>
                                                </Grid>
                                                
                                                <Grid item sm={1} className={classes.customColumn2}
                                                    style={{position:'relative'}}
                                                >
                                                    <Tooltip title="More Actions" arrow>  
                                                        <MoreHorizOutlinedIcon 
                                                        onClick={(e) => onMoreActionClick(e, invoice)}
                                                        fontSize='small'
                                                        className='action__icon'/>
                                                    </Tooltip>
                                                    {props.showDropDown(invoice)}
                                                </Grid>
                                            </Grid>
                                })

                            }
                            
                        </Grid>
                    </Grid>
                    <Grid item sm={12} 
                        style={{display:'flex', 
                                justifyContent:'flex-end',
                                padding:'18px 32px 16px 0px'
                            }}>
                        <Button variant="outlined" color="secondary"
                            onClick={props.closeOtherInvoicesModal}>
                            Close
                        </Button>
                    </Grid> 
                </Dialog>
            </div>
    );
}

export default OtherInvoicesModal;