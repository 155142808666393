import React, {useState ,useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles'; 
import {Grid, Box, Divider, Typography, Button} from '@material-ui/core';
import CreateTaxPresetDrawer from './CreateTaxPresetDrawer';
import {connect} from 'react-redux';
import {trackGAEvent, AccessLevel} from '../util/AppUtil';
import { useIsMount } from './useIsMount';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TaxRates from './TaxRates';
import DefaultTaxPreference from './DefaultTaxPreference';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

const useStyles = makeStyles((theme) => ({
    mainContent : {
        width: (showDrawer) => {
            return showDrawer ? "83%" : "100%"; 
        },
        backgroundColor: theme.palette.primary.light, 
        transition: ".5s", 
        flexGrow: 1,
        //overflow:'hidden',
        height:'100%',
        overflow:'hidden',
        overflowX:'hidden', 
    },
    container: {
        marginTop: "8px"
    },
    settingsContainer: {
        width:'100%',
        background:'#fff',
        borderRadius:'4px',
        marginBottom:'32px'
    },
    heading: {
        fontSize: "20px",
        fontWeight: "600",
    },
    btn: {
        background: "#383838",
        // width: "107px",
        // padding: "7px 0",
        marginTop: "-4px",
        color: "white",
        padding:"4px 8px",
        maxWidth:"8rem"
    },
    tabs: {
        borderRight: `1px solid rgba(0, 0, 0, 0.1)`,
    },
    addButton: {
        color: "white",
        backgroundColor: "#3d3d3d",
        marginLeft: "auto",
        height: "30px",
        marginTop: "9px",
        "&:hover": {
            //you want this to be the same as the backgroundColor above
            backgroundColor: "#3d3d3d"
        },
        marginRight: "8px"
    },
}))

const TaxPresets = (props) => {
    const classes = useStyles();
    const isMount = useIsMount();

    // const [taxPresets, setTaxPresets] = useState([
    //     {
    //         id:Math.random(),
    //         name: 'CGST',
    //         value: `${parseFloat(9.000)}%`
    //     },
    //     {
    //         id:Math.random(),
    //         name: 'SGST',
    //         value: `${parseFloat(9.000)}%`
    //     },
    //     {
    //         id:Math.random(),
    //         name: 'IGST',
    //         value: `${parseFloat(9.000)}%`
    //     },
    //     {
    //         id:Math.random(),
    //         name: 'Service Tax',
    //         value: `${parseFloat(18.000)}%`
    //     },
    // ]);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    const { access_level } = props.selectedAccount || {};
    const accessLevelViewer = (AccessLevel.getUserAccessLevelValue(access_level) === AccessLevel.VIEWER) ? true : false;

    const taxPresets = props.selectedAccount.tax_presets;

    useEffect(() => {
        if(!isMount) {
            if(props.openTaxPresetDrawerFromParent) {
                handleDrawerOpen();
            }
        }
    }, [props.openTaxPresetDrawerFromParent])
    
    const handleDrawerOpen = () => {
        trackGAEvent(props.selectedAccount.name, 'Tax Presets - Add Tax Preset button clicked');
        setOpenDrawer(true)
    }

    const handleDrawerClose = () => {
        setOpenDrawer(false)
        props.handleTaxPresetDrawerCloseFromParent();
    }

    const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    
    return (
        <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
        >
        {value === index && (
            <div style={{padding:'0px 0px 0px 16px'}}>
                {children}
            </div>
        )}
        </div>
    );
    }

    const a11yProps = (index) => {
        return {
            id: `vertical-tab-${index}`,
            'aria-controls': `vertical-tabpanel-${index}`,
        };
    }

    return (
        <Grid item xs={12} className={classes.settingsContainer}
            style={{ padding: '8px 16px'}}>
                {props.fromOnboardingComponent &&
                <Grid item container justifyContent='space-between' alignItems='center'>
                    <Grid item>
                        <Typography className='outboardingLabel'>
                            Tax Presets
                        </Typography>
                    </Grid>

                    <Grid item>
                        <Button
                            onClick={handleDrawerOpen}
                            className={'addButton'}
                            disabled={accessLevelViewer}
                        >
                        Add Tax Preset
                        <AddCircleOutlineIcon fontSize='small' 
                            style={{fontSize:'15px', marginLeft:'4px'}}
                            className={'buttonPlusIcon'}/>
                        </Button>
                    </Grid>
                </Grid>}
                <Grid item container 
                    justifyContent='space-between'
                    style={{flexGrow:1, marginTop: '16px'}}>
                    <Grid item md={5}>
                        <Typography style={{fontSize:'15px', fontWeight:500}}>
                            Tax Rates
                        </Typography>
                        <TaxRates taxArrayList={taxPresets}
                            fromTaxPresetComponent={true}/>
                    </Grid>
                    <Grid item>
                        <Divider orientation='vertical'/>
                    </Grid>
                    <Grid item md={6}>
                        <Typography style={{fontSize:'15px', fontWeight:500}}>
                            Default Tax Rate
                        </Typography>
                        <DefaultTaxPreference
                            callApi={props.callApi}
                            setCallApi={props.setCallApi}
                            setTabValue={props.setTabValue}/>
                    </Grid>
                </Grid>

                <CreateTaxPresetDrawer openDrawer={openDrawer} 
                    title="New Tax Preset"
                    handleDrawerClose={handleDrawerClose}
                />
        </Grid>
    );
}

const mapStateToProps = (state) => ({
    selectedAccount: state.invoiceAccounts.selectedAccount,
})
export default connect(mapStateToProps)(TaxPresets);