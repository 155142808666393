import React, {useState, useEffect} from 'react';
import Modal from '@material-ui/core/Modal';
import { Grid, Typography, Button, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import {updateEntityNextInvoiceNumberApi} from '../../services/authService';
import {connect} from 'react-redux';
import { consoleToLog } from '../../util/AppUtil';
import { useSnackbar } from 'notistack';
import { setEditInvoiceObj, setEntityObj, setSelectedInvoiceAccount, setInvoiceAccountsList } from '../../actions/invoiceAccount';
import {getEntityNextInvoiceEstimateNumberApi} from '../../services/authService';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        position: 'absolute',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        width:'530px',
        outline:'none'
    },
    modalHeading: {
        fontSize:'16px',
        fontWeight:500,
        color:"#212121"
    },
    inputTextHeading: {
        fontSize:'15px',
        color:'#212121',
        fontWeight:500
    },
    headingContainer: {
        background:'#f7f7f7',
        padding:'8px 16px',
        border:'1px solid rgba(0,0,0,0.1)'
    },
    contentContainer: {
        padding:'16px',
    },
    MuiTextFieldroot: {
        background:'#fff',
        '& .MuiOutlinedInput-input': {
            paddingTop: '7px',
            paddingBottom: '7px'
        }
    },
    footer: {
        borderTop:'1px solid rgba(0,0,0,0.1)',
        padding:'16px'
    }
}));


const UpdateNumberModal = (props) => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();

    let entityObj = props.entityObj !== -1 ? props.entityObj : props.invoiceObj.entity;
    let selectedEntityObj = props.selectedAccount?.entities.find((entity) => entity.id === entityObj.id);
    let next_invoice_number = selectedEntityObj?.next_invoice_number;

    const [invoiceNumber, setInvoiceNumber] = useState(0);
    

    useEffect(() => {
        getEntityNextInvoiceOrEstimateNumber();
    }, [])


    const getEntityNextInvoiceOrEstimateNumber = () => {
        const invoice_account_id = props.selectedAccount?.id;
        const entity_id = selectedEntityObj?.id;
        const apiStr = props.showEstimateScreen ? `entity/${entity_id}/estimate/entity_next_estimate_number` : `${entity_id}/entity_next_invoice_number`;

        getEntityNextInvoiceEstimateNumberApi(invoice_account_id, apiStr)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response getEntityNextInvoiceEstimateNumberApi: ', res);
                let next_number = props.showEstimateScreen ? res.next_estimate_number : res.next_invoice_number;
                setInvoiceNumber(next_number);
                props.setEntityNextInvoiceNumber(next_number);

            })
            .catch((e) => {
                consoleToLog('Error getEntityNextInvoiceEstimateNumberApi', e.response);
                if(e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {variant: 'error'});
                    return;
                }
        });
    }

    function addLeadingZeros(number) {
        // Check the magnitude of the number
        if (number < 10) {
            return '00' + number;
        } else if (number < 100) {
            return '0' + number;
        } else {
            return number.toString(); // No need to add leading zeros
        }
    }

    const updateInvoiceNextNumber = () => {
        const invoice_account_id = props.selectedAccount.id;
        const entity_id = selectedEntityObj?.id;
        const update_next_invoice_number =  !props.showEstimateScreen ? invoiceNumber : undefined;
        const update_next_estimate_number = props.showEstimateScreen ?  invoiceNumber : undefined;
        const apiStr = props.showEstimateScreen ? `entity/${entity_id}/estimate/update_next_estimate_number` : `entity/${entity_id}/update_next_invoice_number`;

        updateEntityNextInvoiceNumberApi(invoice_account_id, update_next_invoice_number, update_next_estimate_number, apiStr)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response updateEntityNextInvoiceNumberApi:", res);

                enqueueSnackbar('Next invoice number updated successfully', {variant:'success'});

                let account = {
                    ...props.selectedAccount,
                    entities: props.selectedAccount?.entities.map((item) => {
                        if(item.id === selectedEntityObj.id) {
                            item = res;
                        }
                        return item
                    })
                };

                let accountList = props.invoiceAccountsList.map((ia) => {
                if (ia.id === account.id) return account;
                    else return ia;
                });
                props.setDontCallApi(true);
                props.setInvoiceAccountsList(accountList);
                props.setSelectedInvoiceAccount(account);

                if(props.showCreateInvoice) {
                    props.setEntityObj(res);
                    props.setInvoiceNumber(addLeadingZeros(props.showEstimateScreen ? res.next_estimate_number : res.next_invoice_number));
                } else {
                    const obj = {
                        ...props.invoiceObj,
                        entity: res
                    };
                    props.setEditInvoiceObj(obj);
                }
                props.handleUpdateNumberDialogClose();

            })
            .catch((e) => {
                consoleToLog('Error updateEntityNextInvoiceNumberApi: ', e);
                if(e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {variant:'error'});
                    return;
                }
            })
    }

    return (
        <Modal
            open={props.openUpdateNumberDialog}
            onClose={props.handleUpdateNumberDialogClose}
            aria-labelledby="Update Number Modal"
            className={classes.modal}
        >
            <div className={classes.paper}>
                <Grid item sm={12}>
                    <Grid item container justifyContent='space-between' alignItems='center' 
                        className={classes.headingContainer}>
                        <Grid item>
                            <Typography variant='body1' className={classes.modalHeading}>
                                {`Configure ${props.showEstimateScreen ? 'Estimate' : 'Invoice'} Number Preferences`}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Button 
                                onClick={props.handleUpdateNumberDialogClose}
                                style={{minWidth:"20px"}}>
                                <ClearIcon style={{color:"#212121"}}/>
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item sm={12} className={classes.contentContainer}>
                        <Grid item sm={12}>
                            <Typography className={classes.inputTextHeading}>
                                {`Continue auto-generating ${props.showEstimateScreen ? 'estimate' : 'invoice'} numbers`}
                            </Typography>
                        </Grid>
                        <Grid item container alignItems='center'
                            style={{margin:'12px 0px 8px 0px'}}>
                            <Grid item sm={6}>
                                <Typography variant='subtitle1'>
                                    Prefix
                                </Typography>
                                <TextField 
                                    fullWidth
                                    variant='outlined'
                                    type='text'
                                    value={props.invoiceNumberString}
                                    classes={{
                                        root: classes.MuiTextFieldroot
                                    }}
                                    style={{width:'90%'}}
                                />
                            </Grid>
                            <Grid item sm={4}>
                                <Typography variant='subtitle1'>
                                    Next Number
                                </Typography>
                                <TextField 
                                    fullWidth
                                    variant='outlined'
                                    type='text'
                                    value={invoiceNumber}
                                    classes={{
                                        root: classes.MuiTextFieldroot
                                    }}
                                    onChange={(e) => setInvoiceNumber(e.target.value)}
                                />
                            </Grid>
                        </Grid>
                                
                    </Grid>
                    <Grid item container alignItems='center'
                        className={classes.footer}>
                        <Grid item>
                            <Button variant='contained' 
                                onClick={updateInvoiceNextNumber}
                                color='primary'>
                                Save
                            </Button>
                        </Grid>

                        <Grid item>
                            <Button variant='outlined' 
                                style={{marginLeft:'16px'}}
                                onClick={props.handleUpdateNumberDialogClose}
                                color='primary'>
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </Modal>
    )
}

const mapStateToProps = (state) => ({
    accessToken: state.invoiceAuth,
    selectedAccount: state.invoiceAccounts.selectedAccount,
    showCreateInvoice: state.invoiceAccounts.showCreateInvoice,
    entityObj: state.invoiceAccounts.entityObj,
    invoiceAccountsList: state.invoiceAccounts.invoiceAccountsList,
    showEstimateScreen: state.invoiceAccounts.showEstimateScreen
});

const mapDispatchToProps = (dispatch) => ({
    setEditInvoiceObj: (obj) => dispatch(setEditInvoiceObj(obj)),
    setEntityObj: (entity) => dispatch(setEntityObj(entity)),
    setSelectedInvoiceAccount: (account) => dispatch(setSelectedInvoiceAccount(account)),
    setInvoiceAccountsList: (list) => dispatch(setInvoiceAccountsList(list))
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateNumberModal);