import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Dialog, Grid, Typography, Button, IconButton, TextField, FormControlLabel, Checkbox, CircularProgress} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import {updateEstimateSettingsApi} from '../../services/authService';
import { consoleToLog } from '../../util/AppUtil';

const useStyles = makeStyles((theme) => ({
    MuiDialogpaper: {
        scrollbarWidth: 'none',
        "&::-webkit-scrollbar": {
            display: "none"
        }   
    },
    dialogTitle : {
        borderBottom:'1px solid #ccc', 
        padding:'8px 16px', 
        fontSize:'16px',
        fontWeight:'600',
    },
    dialogBody: {
        padding: theme.spacing(2),
    },
    customMarginTop: {
        marginTop:'16px'
    },
    MuiFormControlLabelroot: {
        fontSize:'10px'
    }
}));

const EstimateSettingsModal = ({openEstimatesSettingsModal, handleEstimatesSettingsModalClose, selectedAccount, invoiceAccountsList, setInvoiceAccountsList, setSelectedInvoiceAccount}) => {
    const classes = useStyles();

    const [isLoading, setIsLoading] = useState(false);
    const [labelValue, setLabelValue] = useState(selectedAccount.estimate_terminology);
    const [estimateApprovalRequired, setEstimateApprovalRequired] = useState(selectedAccount.estimate_approval_required);

    const saveEstimateSettings = async () => {
        setIsLoading(true);
        try {   
            const response = await updateEstimateSettingsApi(selectedAccount.id, estimateApprovalRequired, labelValue);
            const res = response.data;
            consoleToLog("updateEstimateSettingsApi: ", res);
            setIsLoading(false);

            const obj = {
                ...selectedAccount,
                estimate_terminology: res.estimate_terminology,
                estimate_approval_required: res.estimate_approval_required
            }

            let accountList = invoiceAccountsList.map((ia) => {
                if (ia.id === obj.id) return obj;
                    else return ia;
            });

            setSelectedInvoiceAccount(obj);
            setInvoiceAccountsList(accountList);

            handleEstimatesSettingsModalClose();
        } catch (error) {
            consoleToLog("updateEstimateSettingsApi: ", error);
            setIsLoading(false);
        }
    }
    return (
        <div>
            <Dialog
                open={openEstimatesSettingsModal}
                //TransitionComponent={Transition}
                scroll="paper"
                classes={{
                    paper: classes.MuiDialogpaper}}
                maxWidth="sm"
                fullWidth
                style={{scrollbarWidth:'none !important'}}
                onClose={handleEstimatesSettingsModalClose}
                >
                        <Grid item container   
                            justifyContent='space-between'
                            alignItems='center'className={classes.dialogTitle}>

                            <Grid item>        
                                <Typography>
                                    Estimate Settings
                                </Typography>
                            </Grid>

                            <Grid item>
                                <IconButton onClick={handleEstimatesSettingsModalClose}>
                                    <ClearIcon fontSize='medium' />
                                </IconButton>
                            </Grid>

                        </Grid>

                        <Grid item container flexDirection='column' className={classes.dialogBody}>

                            <Grid item md={12}>
                                <Typography variant='subtitle1' style={{marginBottom:'-8px'}}>
                                    Terminology
                                </Typography>

                                <TextField
                                    fullWidth
                                    variant='outlined'
                                    color='secondary'
                                    placeholder='Terminology'
                                    margin='dense'
                                    value={labelValue}
                                    onChange={(e)=>{
                                        setLabelValue(e.target.value)
                                    }}
                                    style={{marginBottom:'-8px'}}
                                />
                            </Grid>

                            <Grid item sm={12} className={classes.customMarginTop}>
                                <FormControlLabel 
                                    classes={{
                                        root: classes.MuiFormControlLabelroot
                                    }}
                                    control={
                                        <Checkbox  
                                            onChange = {(e)=>{
                                                setEstimateApprovalRequired(e.target.checked)
                                            
                                            }}
                                            checked={estimateApprovalRequired}
                                        />
                                } 
                                style={{fontSize:'14px'}}
                                label="Estimate Approval Required" />
                        </Grid>
                        
                            <Grid item md={12} className={classes.customMarginTop}>
                                <Grid item container spacing={1}>
                                    <Grid item>
                                        <Button variant='contained' color='primary'
                                            onClick={saveEstimateSettings}
                                        >
                                            {isLoading && <CircularProgress size={18} style={{marginRight:'8px', color:'white'}}/>}
                                            Save
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button variant='outlined' color='secondary'
                                            onClick={handleEstimatesSettingsModalClose}
                                        >
                                            Cancel
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                    </Grid>
                </Dialog>
        </div>
    );
}

export default EstimateSettingsModal;