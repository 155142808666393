import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Typography, Button, CircularProgress, Avatar, Tooltip, TextField, InputAdornment, ClickAwayListener} from '@material-ui/core';
import {connect} from 'react-redux';
import {onDeleteCustomFieldApi} from '../services/clientService';
import { useIsMount } from './useIsMount';
import { consoleToLog} from '../util/AppUtil';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import DescriptionIcon from '@material-ui/icons/Description';
import { useSnackbar } from 'notistack';
import TransactionsFilterDrawer from './TransactionsFilterDrawer';
import CustomFieldDrawer from './CustomFieldDrawer';
import { setSelectedInvoiceAccount, setInvoiceAccountsList } from '../actions/invoiceAccount';
import { trackGAEvent } from '../util/AppUtil';


const useStyles = makeStyles((theme) => ({
    loading: {
        position:'relative',
        left:'50%',
        right:'50%',
        marginTop:'1%'
    },
    headings: {
        fontWeight:'500',
        color:'#202020',
        [theme.breakpoints.down(1400)]: {
            fontSize:'14px'
        },
    },
    itemTitleList: {
        background:'#f9fafb',
        borderBottom: '1px solid #edf2f7',
    },
    itemListContainer : {
        background:'white', 
        minWidth:"100%", 
        padding:'10px 16px', 
        color:'#202020',
        position:'relative',
        borderBottom: '1px solid #edf2f7',
        cursor:'pointer',
        '& .action__icon' : {
            padding:'2px 4px 0px 4px',
            border:'1px solid white',
            borderRadius:'4px',
            cursor:'pointer'
        },
        '&:hover .action__icon' : {
           border:'1px solid #ccc'
        },
        '&:hover':{
          backgroundColor:'#f7fafc',
        },
        '& .more_actionButton' : {
            visibility:'hidden',
            position:'absolute',
            right:'90px',
            width:'160px',
            background:'white',
            border:'1px solid #ccc',
            borderRadius:'5px',
            display:'flex',
            justifyContent:'center',
            aligItems:'center',
            fontSize:'14px',
            padding:'4px 0px 4px 0px',
            [theme.breakpoints.down(1500)]: {
                right:'80px',
                padding:'3px 0px 3px 0px',
            },

        },
        '&:hover .more_actionButton' : {
            visibility:'visible',
        },
    },
    avatarStyle: {
        width:"38px !important",
        height:"38px !important",
        fontSize:'16px !important',
        fontWeight:'600 !important',
        background:'#fff',
        color:'#202020',
        border:'1px solid #4a5568'
    },
    clientName: {
        fontSize:'14px', 
        whiteSpace: "nowrap", 
        maxWidth: "320px", 
        overflow: "hidden", 
        textOverflow: "ellipsis",
    },
    emailEllipsis : {
        whiteSpace: "nowrap", 
        maxWidth: "83% !important", 
        overflow: "hidden", 
        textOverflow: "ellipsis",
        cursor: 'pointer'
    },
    addressHeading: {
        display:'flex', 
        justifyContent: 'center', 
        marginLeft:'-72px'
    },
    addressText : {
        display:'flex', 
        justifyContent: 'center'
    },
    customColumn1 : {
        flexWidth: '40%',
        maxWidth: '40%'
    },
    customColumn2 : {
        flexWidth: '18%',
        maxWidth: '18%'
    },
    actionsIcons: {
        color: '#b5bbc2',
        fontSize: '18px',
    },
}))

const ClientCustomFieldComponent = (props) => {
    const classes = useStyles();
    const [isActive, setIsActive] = useState(false);
    const [customFieldId, setCustomFieldId] = useState(undefined);

    //const [customFieldList, setCustomFieldList] = useState([]);
    const [loading, setLoading] = useState(false);
    const {enqueueSnackbar} = useSnackbar();
    const [openDrawer, setOpenDrawer] = useState(props.openDrawer1);
    const [selectedCustomField, setSelectedCustomField] = useState(undefined)
    const [customFieldList, setCustomFieldList] = useState(props.selectedAccount?.custom_fields);
    const [searchCustomField, setSearchCustomField] = useState('');
    const [id, setId] = useState(props.selectedAccount?.id);

    const isMount = useIsMount();

    useEffect(() => {
        setId(props.selectedAccount?.id)
    }, [props.selectedAccount?.id]);

    useEffect(() => {
        if(!isMount) {
            if(id !== props.selectedAccount?.id) {
                setCustomFieldList(props.selectedAccount?.custom_fields);
            }
        }
    }, [props.selectedAccount?.id]);

    useEffect(() => {
        if(!isMount){
            setOpenDrawer(props.openDrawer1);
        }
    }, [props.openDrawer1]);

    useEffect(() => {
        if(isMount) {
            //consoleToLog('first Render');
        } else {
            if(searchCustomField === '') {
                setCustomFieldList(props.selectedAccount?.custom_fields)
            }    
        }
    }, [searchCustomField])

    const closeMoreOptionsMenu = () => {
        setIsActive(false);
        setCustomFieldId('');
    }

    const onEditCustomFieldClick = (e, customField) => {
        e.stopPropagation();
        setOpenDrawer(true);
        setSelectedCustomField(customField);
        closeMoreOptionsMenu();
    }

    const onDeleteCustomField =(e, customField) => {
        
        if(window.confirm("Do you really want to delete this custom field?")){
            onDeleteCustomFieldApi(customField.id)
                .then((response) => {
                    const res = response.data;
                    consoleToLog('Response onDeleteCustomFieldApi: ', res);
                    enqueueSnackbar(res.message, {
                        variant:'success'
                    });

                    //remove deleted item from list
                    let updatedCustomFieldArr = customFieldList.filter((cfo) => cfo.id !== customField.id);
                    //console.log('custom field arr', updatedCustomFieldArr);
                    let account = {
                        ...props.selectedAccount,
                        custom_fields: updatedCustomFieldArr
                    }

                    let accountList = props.invoiceAccountsList.map((ia)=>{
                        if(ia.id === account.id) return account;
                        else return ia;
                    });
                    trackGAEvent(props.selectedAccount.name, 'Custom Field Deleted', `${props.user.firstname} ${props.user.lastname}`);

                    setCustomFieldList(updatedCustomFieldArr);
                    props.setInvoiceAccountsList(accountList);
                    props.setSelectedInvoiceAccount(account);

                    closeDrawer();
                })
                .catch((e) => {
                    setLoading(false);
                    consoleToLog('Error onDeleteCustomFieldApi: ', e.response);
                    if(e.response.data && e.response.data.message) {
                        enqueueSnackbar(e.response.data.message, {variant: 'error'});
                        return;
                    }
                });
        }
    }

    const closeDrawer = () => {
        setOpenDrawer(false);
        setSelectedCustomField(undefined);
        props.handleCloseFilterDrawer(false);
    }

    const onSearchFolder = (e) => {
        const name = e.target.value.toLowerCase();
        setSearchCustomField(name);
        let searchList = customFieldList.filter(cf => cf.name.toLowerCase().includes(name));
        //console.log(test)
        setCustomFieldList(searchList)
    }

    return (
        <div style={{width:'100%'}}>
            <Grid item sm={4} style={{margin:'16px 0px 0px auto', paddingRight:"16px"}}>
                <TextField 
                    fullWidth
                    variant='outlined'
                    margin='dense'
                    placeholder='Search custom field'
                    InputLabelProps={{style: {fontSize: 14, color: "#ccc"}}}
                    onChange={onSearchFolder}
                    InputProps={{
                        startAdornment: (
                        <InputAdornment position="start">
                            {/* <SearchIcon className={classes.searchicon}/> */}
                            <i className="flaticon-search"></i>
                        </InputAdornment>
                        )
                    }}
                />
            </Grid>
            <Grid item xs={12} style={{marginTop:'24px'}}>
                    <Grid item sm={12}>
                        <Grid item sm={12} className={classes.itemTitleList}>
                            <Grid item container style={{padding:'10px 16px'}}>
                                <Grid item sm={2}  className={classes.customColumn1}>
                                    <Typography variant="body1" className={classes.headings}>
                                        #
                                    </Typography>
                                </Grid>

                                <Grid item sm={9}  className={classes.customColumn2}>
                                    <Typography variant="body1" className={classes.headings}>
                                        Field Name
                                    </Typography>
                                </Grid>

                            </Grid>
                        </Grid>

                    {loading ?
                    <CircularProgress size={30} className={classes.loading}/> 
                    :
                    <Grid item xs={12}>
                    {
                        customFieldList && customFieldList.length > 0  &&
                        customFieldList.map((cf, index) => {
                            return  <Grid item container 
                                            key={cf.id}
                                            justifyContent="flex-start"
                                            className={classes.itemListContainer}
                                            alignItems="center">
                                        
                                        <Grid item md={2} lg={2} className={classes.customColumn1}>
                                            {index+1}
                                        </Grid>

                                        <Grid item md={9} lg={9} /* className={classes.customColumn2} */>
                                            <Grid item container alignItems='center'>
                                                <Grid item >
                                                    <Tooltip title={cf.name} arrow>
                                                        <Typography variant='body1' className={classes.clientName}>
                                                            {cf.name}
                                                        </Typography>
                                                    </Tooltip>
                                                </Grid>        
                                        </Grid>
                                        </Grid>
                                        
                                        {!props.accessLevelViewer &&
                                        <Grid item md={1} lg={1}
                                            style={{display:'flex', aligItems:'center', justifyContent:"end", flexBasis:'6%', maxWidth:'6%'}}>
                                            <Tooltip title="More Actions" arrow>  
                                                <i className="flaticon-more action__icon"  
                                                onClick={(e) => {
                                                        e.stopPropagation();
                                                        setIsActive(!isActive);
                                                        setCustomFieldId(cf.id);
                                                    }}
                                                style={{fontSize:'1.1rem'}}></i>
                                            </Tooltip>
                                        <>    
                                            {cf.id === customFieldId &&
                                            <ClickAwayListener onClickAway={() => {
                                                    setIsActive(false);
                                                    setCustomFieldId('');
                                            }}>
                                            <Grid item
                                                style={{marginRight:'5%', marginTop:'4px'}}
                                                className={`menu ${isActive ? `active ${classes.marginDropdown}` : "inactive"}`}>
                                                <ul>
                                                    
                                                    <li onClick={(e) => onEditCustomFieldClick(e, cf)}>
                                                        <a style={{display:'flex', alignItems:'center'}}>
                                                            <EditOutlinedIcon fontSize='small'  
                                                            className={`more_actionsIcons ${classes.actionsIcons}`}/>
                                                            Edit
                                                        </a>
                                                    </li>

                                                    <li onClick={(e) => onDeleteCustomField(e, cf)}>
                                                        <a href="#" style={{display:'flex', alignItems:'center'}}>
                                                            <DeleteOutlinedIcon fontSize='small' 
                                                            className={`more_actionsIcons ${classes.actionsIcons}`}/>
                                                            Delete
                                                        </a>
                                                    </li>
                                                </ul>
                                            </Grid>
                                            </ClickAwayListener>
                                            }
                                        </>
                                        </Grid> 
                                        }
                                    </Grid>

                        })}
                        {customFieldList && customFieldList.length === 0 &&
                            <Grid tem container
                                className={classes.invoiceListContainer}
                                alignItems="center" justifyContent="center">
                                <Grid item>
                                    <Typography variant="body1" style={{padding:"16px"}}>
                                            No custom fields found.
                                    </Typography>
                                </Grid>     
                            </Grid>
                        }    
                        </Grid>
                    }
                    </Grid>
                </Grid> 
                <CustomFieldDrawer openFilterDrawer={openDrawer}
                    handleCloseFilterDrawer={() => closeDrawer()}
                    selectedCustomField={selectedCustomField}
                />
                
            </div>
    );
}

const mapStateToProps = (state) => ({
    selectedAccount: state.invoiceAccounts.selectedAccount,
    invoiceAccountsList: state.invoiceAccounts.invoiceAccountsList,
    user: state.invoiceAccounts.user
});

const mapDispatchToProps = (dispatch) => ({
    setSelectedInvoiceAccount: (account) => dispatch(setSelectedInvoiceAccount(account)),
    setInvoiceAccountsList: (list) => dispatch(setInvoiceAccountsList(list)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ClientCustomFieldComponent);