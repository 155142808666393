import React, { createContext, useContext } from 'react';

const PropsContext = createContext();

export const usePropsContext = () => {
    return useContext(PropsContext);
};

export const PropsProvider = ({ children, value }) => {
    return (
        <PropsContext.Provider value={value}>
            {children}
        </PropsContext.Provider>
    );
};