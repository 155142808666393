import React, {useState, useEffect} from 'react';
import {Grid, Typography, Button, TextField, InputAdornment, Select, MenuItem} from '@material-ui/core';
import {connect} from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { showAllCountries, consoleToLog, capitalizeFirstLetterOfEachWord, trackGAEvent} from '../../util/AppUtil';
import { orgNameValidator } from '../../util/validator';
import {invoiceAccountUpdateApi} from '../../services/authService'
import {Autocomplete} from '@material-ui/lab';
import countries from '../../data/countries.json';
import Flags from "country-flag-icons/react/3x2";
import { useSnackbar } from 'notistack';
import { useIsMount } from '../useIsMount';
import {setInvoiceAccountsList} from '../../actions/invoiceAccount';
import { setSelectedInvoiceAccount } from '../../actions/invoiceAccount';
import {getCurrencyObjApi} from '../../services/authService';
import {extractFlagCode} from '../../util/AppUtil';
import country from "../../data/country.json";
import { getCountryObj } from '../../util/AppUtil';

const useStyles = makeStyles((theme) => ({
    customMarginTop : {
        margin:'16px 0px 0px 0px'
    },
    enableStyle:{
        width:"100%",
        height:"2.5rem",
        padding:"2px",
    },
    disableStyle:{
        width:"100%",
        height:"2.5rem",
        padding:"2px",
        color:"#bababa"
    },
    MuiAutocompleteinputRoot : {
        '& .MuiOutlinedInput-root' : {
        padding:'0px 3px',
        borderRadius: '4px',
        background:'#fff',
        '& .MuiAutocomplete-input' : {
            padding:'7.5px 4px'
        }
        }
    },
    customTextField: {
        "& input::placeholder": {
            paddingLeft: "8px",
        }
    }
}))

const UpdateInvoiceAccount = (props) => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();
    const {isMount} = useIsMount();

    const [invoiceAccountName, setInvoiceAccountName] = useState('');
    const [selectedCountry, setSelectedCountry] = useState({});
    const [currencies, setCurrencies] = useState([]);
    const [selectedCurrency, setSelectedCurrency] = useState({});
    const [inputText, setInputText] = useState('');
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const accountObj = props.accountObj ? props.accountObj : props.selectedAccount;
    let invoice_account_id = accountObj?.id;
    let currenciesObj = accountObj?.currencies;
    const {countries} = country;

    useEffect(() => {
        if(accountObj) {
            setInvoiceAccountName(accountObj?.name);
            let countryObj = getCountryObj(countries, props.selectedAccount?.country);
            setSelectedCountry(countryObj ? countryObj : {});
        }
    }, [accountObj]);

    useEffect(() => {
        if(currenciesObj) {
            getCurrencies(currenciesObj?.name, true);
        }
    }, [currenciesObj])

    useEffect(() => {
        if(!isMount) {
            if(props.callApi) {
                props.setCallApi(false);
                onSaveClick();
            }

        }
    }, [props.callApi]);

    const getCurrencies = (query, setCurrencyObj) => {
        getCurrencyObjApi(invoice_account_id, query)
        .then((respose) => {
            const res = respose.data;
            consoleToLog('Response getCurrencyObjApi: ', res);

            setCurrencies(res);
            setCurrencyObj && setSelectedCurrency(currenciesObj);
        })
        .catch((e) => {
            consoleToLog('Error getCurrencyObjApi', e);
            if(e.response.data && e.response.data.message) {
                enqueueSnackbar(e.response.data.message, {variant: 'error'});
                return;
            }
        })

    }

    const handleInputChange = (e, newValue) => {
        const value = e?.target.value;
        value && getCurrencies(newValue, false);

        setInputText(newValue);
            if (!newValue) {
                setOpen(false);
            }
    }

    const onSelectedCurrencyChange = (e, newValue) => {
        setSelectedCurrency(newValue);
    }

    const onCountryNameChange = (e, newValue) => {
        setSelectedCountry(newValue ? newValue : {});
    }

    const renderCurrencyOptions = (option) => {
        const flagCode = extractFlagCode(option.flag)
        const Flag = Flags[flagCode];
        return (
            <Grid item md={12} className={classes.itemOptionStyle}>
                <Grid item container alignItems='center'> 
                    <Grid item md={1}>
                        {Flag &&
                        <div style={{maxWidth:'10%', position:'relative', top:'1px'}}>
                            <Flag width='25px' height='22px'/> 
                        </div>}
                    </Grid>
                    <Grid item md={8}>
                        <Typography variant='body1' style={{marginLeft:'8px'}}>
                            {option.name}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            );
    }

    const showFlagStartAdornment = () => {
        const flagCode = extractFlagCode(selectedCurrency?.flag)
        const Flag = Flags[flagCode];

        if (!Flag) {
            console.error(`Flag component for code ${flagCode} not found.`);
            return null; // Return null if the Flag is undefined
        }

        return ( 
            <InputAdornment position="start">
                <Grid item container alignItems='center'>
                    <Grid item style={{paddingLeft:'6px', position:"relative", top:'1px'}}>
                        <Flag width='25px'/>
                    </Grid>
                </Grid>
            </InputAdornment>
        )
    }

    const onSaveClick = () => {

        const invoice_account_name = invoiceAccountName;
        if (!invoice_account_name) {
            enqueueSnackbar('Invoice account name can not be empty!', {
                variant:'error'
            });
            return;
        }

        const isInvoiceAccountNameValid = orgNameValidator(invoice_account_name);
        if (!isInvoiceAccountNameValid) {
            enqueueSnackbar('Invoice account name is not valid! Please enter Alphabets only', {
                variant:'error'
            });
            return;
        }

        if(Object.keys(selectedCountry).length === 0) {
            enqueueSnackbar('Please Select the Country', {
                variant: 'error'
            });
            return;
        }
        const country = selectedCountry?.code;

        // const currency = selectedCurrency;
        // if(currency === 'none' || currency === '' || currency === undefined) {
        //     enqueueSnackbar('Please Select the Currency', {
        //         variant: 'error'
        //     });
        //     return;
        // }

        const address = accountObj?.address;
        const selectedState = accountObj?.state;
        const email = accountObj.email ? accountObj.email : '';
        const mobile = accountObj.mobile ? accountObj.mobile : '';
        const tax_id = accountObj.tax_id ? accountObj.tax_id : '';
        const default_client_notes = accountObj.default_client_notes ? accountObj.default_client_notes : '';
        const default_payment_terms = accountObj.default_payment_terms ? accountObj.default_payment_terms : '';
        const image = '';
        const prefix = accountObj.prefix;
        const sequence_format = accountObj.sequence_format;
        const financial_year_start_month = accountObj.financial_year_start_month;
        const currency = selectedCurrency?.id;


        const invoice_account_id = accountObj?.id;
        const onboarding = props.fromOnboardingComponent ? true : false;
        
        setLoading(true);
        invoiceAccountUpdateApi(invoice_account_id, capitalizeFirstLetterOfEachWord(invoice_account_name), address, country, 
            selectedState, email, mobile, tax_id, default_client_notes, default_payment_terms, image, prefix, sequence_format, financial_year_start_month, currency, onboarding)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response invoiceAccountUpdateApi', res);
                setLoading(false);

                enqueueSnackbar('Invoice Account updated successfully', {variant:'success'});

                let account = {
                    ...accountObj,
                    name: res.name,
                    country: res.country,
                    currencies: res.currencies
                }

                let accountList = props.invoiceAccountsList.map((ia)=>{
                    if(ia.id === account.id) return account;
                    else return ia;
                });

                props.setInvoiceAccountsList(accountList);
                props.setSelectedInvoiceAccount(account);
                !props.fromOnboardingComponent && props.handleDrawerClose();
                props.fromOnboardingComponent && props.onSkipClick();
            
                trackGAEvent(accountObj.name, 'Invoice Account Updated Successfully', `${props.user.firstname} ${props.user.lastname}`);


            })
            .catch((e) => {
                consoleToLog('Error invoiceAccountUpdateApi', e.response);
                setLoading(false);
                if(e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {variant:'error'});
                    return;
                }
            
            })

        
        
    }

    return (
        <div>
            <Grid item container style={{padding:'0px 16px'}}>
                <Grid item md={12} lg={12}>
                    <Typography variant="subtitle1" style={{marginBottom:'-8px'}}>
                        Business Name
                    </Typography>

                    <TextField 
                        fullWidth
                        variant="outlined"
                        margin="dense"
                        type="text"
                        value={invoiceAccountName}
                        placeholder="Enter Invoice Account Name"
                        InputLabelProps={{style: {fontSize: 13, marginTop:'3px'}}}
                        onChange={(e) => setInvoiceAccountName(e.target.value)}
                    />
                </Grid>

                <Grid item md={12} lg={12} className={classes.customMarginTop}>
                    <Typography variant="subtitle1">
                        Country
                    </Typography>

                    <Autocomplete
                        id="combo-box-demo"
                        options={countries}
                        classes={{
                            root: classes.MuiAutocompleteinputRoot
                        }}
                        value={selectedCountry}
                        getOptionLabel={(option) => option.name}
                        onChange={(e, newValue) => {
                            onCountryNameChange(e, newValue)
                        }}
                        renderInput={params => (
                            <TextField {...params} variant="outlined" 
                            placeholder='Search Country' 
                            fullWidth
                            classes={{
                                root: classes.customTextField
                            }}
                                />
                        )}
                        disabled={props.fromOnboardingComponent ? false : true}
                    />

                    {/* <Select 
                        className={selectedCountry === 'none' ? classes.disableStyle : classes.enableStyle}
                        value={selectedCountry}
                        onChange={onSelectedCountryChange}
                        variant="outlined"
                        placeholder='Select Country'
                        autoWidth
                        disabled={true}
                        >
                        {
                            selectedCountry === 'none' && <MenuItem value='none'>Select Country</MenuItem>
                        } 
                        {showAllCountries()}
                    </Select> */}
                </Grid>

                <Grid item md={12} lg={12} className={classes.customMarginTop}>
                    <Typography variant="subtitle1">
                        Currency
                    </Typography>   

                    <Autocomplete
                        id="combo-box-demo"
                        options={currencies}
                        classes={{
                            root: classes.MuiAutocompleteinputRoot
                        }}
                        open={open}
                        onOpen={() =>  {
                            if(inputText) {
                                setOpen(true)
                            }
                        }}
                        onClose={() => setOpen(false)}
                        value={selectedCurrency}
                        getOptionLabel={(option) => option.name}
                        renderOption={(option) => renderCurrencyOptions(option)}
                        onChange={(e, newValue) => onSelectedCurrencyChange(e, newValue)}
                        onInputChange={(e, newValue) => handleInputChange(e, newValue)}
                        renderInput={params => (
                            <TextField {...params} variant="outlined" 
                            placeholder='Search Currency' 
                            fullWidth
                            classes={{
                                root: classes.customTextField
                            }}
                            InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                    <>
                                    {showFlagStartAdornment()}
                                    {params.InputProps.startAdornment}
                                    </>
                                ),
                            }}
                                />
                        )}
                        
                    />
                </Grid>
            </Grid>
        </div>
    );
}

const mapStateToProps = (state) => ({
    accessToken: state.invoiceAuth,
    selectedAccount: state.invoiceAccounts.selectedAccount,
    invoiceAccountsList: state.invoiceAccounts.invoiceAccountsList,
    user: state.invoiceAccounts.user
});

const mapDispatchToProps = (dispatch) => ({
    setInvoiceAccountsList: (accountList) => dispatch(setInvoiceAccountsList(accountList)),
    setSelectedInvoiceAccount: (account) => dispatch(setSelectedInvoiceAccount(account))
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateInvoiceAccount);