import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core';
import {Grid, Typography, Dialog, Avatar, Button} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import {connect} from 'react-redux';
import { showDate, showStatusImages, displayInitials, displayInvoiceType, trackGAEvent, DiscountTypes } from '../../util/AppUtil';
import moment from 'moment';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { setShowPreviewInvoiceScreen, setInvoiceItemObj, setEditInvoiceObj } from '../../actions/invoiceAccount';
import {SelectedDrawerState, setSelectedDrawerState} from '../../actions/drawer';
import {parseString, currencyFormatter} from '../../util/AppUtil';
import {getImageDownloadUrlApi} from '../../services/authService';
import { consoleToLog } from '../../util/AppUtil';

const useStyles = makeStyles((theme) => ({
    MuiDialogpaper: {
        scrollbarWidth: 'none',
        "&::-webkit-scrollbar": {
            display: "none"
        }   
    },
    dialogTitle : {
        borderBottom:'1px solid #ccc', 
        padding:'14px 16px', 
        fontSize:'16px',
        fontWeight:'600',
    },
    firstRow: {
        borderBottom:'1px solid rgba(0,0,0, .1)',
        padding:'8px 22px',
    },
    backIconContainer : {
        [theme.breakpoints.down(1450)] : {
            flexBasis:'50%',
            maxWidth:'50%'
        },
    },
    backIcon: {
        padding:'6px',
        border:'1px solid rgba(0,0,0, .1)',
        borderRadius:'5px',
        cursor:'pointer',
    },
    secondRow: {
        padding:'8px 16px 8px 24px' 
    },
    avatarStyle: {
        width:"80px",
        height:"80px",
        fontSize:'35px',
        fontWeight:'600',
        marginRight: "4px"
    },
    thirdRow: {
        padding:'16px 16px 16px 24px' 
    },
    invoiceNumberStyle: {
        fontSize:'14px !important'
    },
    customColumn3: {
        flexBasis:'12%',
        maxWidth:'12%'
    },
    customColumn4: {
        flexBasis:'16%',
        maxWidth:'16%'
    },
    customColumn5: {
        flexBasis:'22%',
        maxWidth:'22%'
    },
    iconStyles: {
        marginTop:'-1px',
        marginRight:'6px', 
        fontSize:"16px"
    }
}));

const InvoiceDetailsModal = (props) => {
    const classes = useStyles();
    const client_name = props.invoiceObj?.client.name;
    let over_due = (moment(props.invoiceObj.due_date).toDate().getTime() < Date.now())
    const invoiceType = props.invoiceObj?.invoice_type;
    const entity = props.invoiceObj?.entity;

    const [eSignUrl, setESignUrl] = useState(undefined);

    useEffect(() => {
        getDownloadSignedUrl();
    }, [])

    const onEditInvoiceClick = () => {
        trackGAEvent(props.selectedAccount.name, 'Invoice Detail - Edit button clicked');
        (props.isFromTransactionComponent || props.isFromClientInvoiceComponent) && props.setSelectedDrawerState(SelectedDrawerState.SHOW_INVOICES);
        props.setEditInvoiceObj(props.invoiceObj); 
        props.handleClickDetailsDialogClose();
    }

    const onPreviewInvoiceClick = () => {
        trackGAEvent(props.selectedAccount.name, 'Invoice Detail - Preview button clicked');
        (props.isFromTransactionComponent || props.isFromClientInvoiceComponent) && props.setSelectedDrawerState(SelectedDrawerState.SHOW_INVOICES);
        props.setInvoiceItemObj(props.invoiceObj);
        props.setShowPreviewInvoiceScreen(true);
        props.handleClickDetailsDialogClose();
    }

    const getDownloadSignedUrl = () => {
        const invoice_account_id = props.selectedAccount?.id;

        getImageDownloadUrlApi(invoice_account_id, entity?.signature, 'signature')
            .then((response) => {
                const res =  response.data;
                consoleToLog('Response getImageDownloadUrlApi: ', res);

                setESignUrl(res.download_signed_url);
            })
            .catch((e) => {
                consoleToLog("invoiceAccountUpdateApi error", e.response);
                if (e.response.data && e.response.data.message) {
                    return false;
                }
            });
    }

    return (
        <div>
            <Dialog
                open={props.openDetailsDialog}
                //TransitionComponent={Transition}
                scroll="paper"
                classes={{
                    paper: classes.MuiDialogpaper}}
                maxWidth="md"
                fullWidth
                style={{scrollbarWidth:'none !important'}}
                onClose={props.handleClickDetailsDialogClose}>
                    <Grid item container   
                        justifyContent='space-between'
                        alignItems='center'className={classes.dialogTitle}>

                    <Grid item>        
                        <Typography>
                            <span style={{marginLeft:'9px'}}>Invoice Details</span>
                        </Typography>
                    </Grid>

                    <Grid item>
                        <Grid item container spacing={1}
                            alignItems='center' 
                            justifyContent='center'>
                                <Grid item>
                                    <Button color="secondary" variant="outlined"
                                        onClick={onEditInvoiceClick}
                                        disabled={props.accessLevelViewer}
                                        >
                                        <EditOutlinedIcon fontSize='small' 
                                            className={classes.iconStyles} />
                                        Edit
                                    </Button>
                                </Grid>
                                
                                <Grid item>
                                    <Button color="secondary" variant="outlined"
                                        onClick={onPreviewInvoiceClick}
                                        disabled={props.accessLevelViewer}
                                        >
                                        <VisibilityOutlinedIcon fontSize='small'  
                                            className={classes.iconStyles}/>
                                        Preview
                                    </Button>
                                </Grid>
                                
                                <Grid item>
                                    <ClearIcon fontSize='small' onClick={props.handleClickDetailsDialogClose}
                                    style={{cursor:'pointer', verticalAlign:'inherit'}}/>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item container alignItems='center'
                        justifyContent='space-between' 
                        className={classes.firstRow}>

                        <Grid item sm={5} className={classes.backIconContainer}>
                            <Grid item container >
                                <Grid item>
                                    <ArrowLeftIcon className={classes.backIcon}
                                        onClick={props.handleClickDetailsDialogClose}
                                    />
                                </Grid>

                                <Grid item style={{display:'flex', alignItems:'center', marginLeft:'16px'}}>
                                    <Typography className={classes.invoiceNumberStyle}>
                                        Invoice # {props.invoiceObj?.invoice_sequence_str}
                                    </Typography>

                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item sm={6}>
                            <Grid item container spacing={2} justifyContent="flex-end">
                                <Grid item>
                                    <Typography className='preview_invoice_text_style'>
                                        <b>Issued: </b>  
                                        {showDate(props.invoiceObj.issue_date)}
                                    </Typography>
                                </Grid>

                                <Grid item>
                                    <Typography className='preview_invoice_text_style'>
                                        <b>Due: </b> 
                                        {showDate(props.invoiceObj.due_date)}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid> 

                    <Grid item container alignItems='center'
                        justifyContent='space-between' 
                        className={classes.secondRow} style={{padding:"16px 0px 16px 16px"}}>
                            <Grid item>
                                <Avatar className={classes.avatarStyle} 
                                    style={{background:'white', color:'#202020', border:'1px solid #202020'}}>
                                    {displayInitials(client_name)}
                                </Avatar>
                            </Grid>

                            {over_due && props.invoiceObj?.status !== 'paid' ?
                            <Grid item>
                                <img src="/images/eprocessify_pastdue.png" width="180px" height="67.5px" alt='status image'/>
                            </Grid>
                            :
                            <Grid item>
                                <img src={showStatusImages(props.invoiceObj?.status)} width="180px" height="67.5px" alt='status image'/>
                            </Grid>}

                            <Grid item style={{textAlign:'right'}}>
                                <Typography style={{fontSize:'32px', color:"#4a5568", marginRight:'24px'}}>
                                    {displayInvoiceType(invoiceType, props.invoiceObj?.total_tax)} Invoice
                                </Typography>
                                <Typography className='preview_invoice_desp_style' 
                                    style={{width:"200px", wordWrap:'break-word', marginRight:'24px'}}>
                                    {props.invoiceObj?.invoice_description}
                                </Typography>
                            </Grid>
                    </Grid> 

                    <div className='modalBorderBottom'></div>

                    <Grid item container 
                        justifyContent='space-between' 
                        className={classes.thirdRow}>
                            <Grid item sm={3}>
                                <Typography className='bill_label'>
                                    Bill To
                                </Typography>

                                <Typography className="company_heading">
                                    {props.invoiceObj?.client.name}
                                </Typography>

                                <Typography className='clientOwner_address'>
                                    <div> {props.invoiceObj?.client.address}</div>
                                    <div>{props.invoiceObj?.client.state}, {props.invoiceObj?.client.country}</div>
                                    {/* {props.invoiceObj?.client?.tax_id &&
                                        <div>Tax Id: {props.invoiceObj?.client?.tax_id}</div>
                                    } */}
                                    {
                                        props.invoiceObj?.client.contacts && props.invoiceObj?.client.contacts.length > 0 &&
                                        props.invoiceObj?.client.contacts.slice(0, 1).map((contact) => {
                                            return <div key={contact.id}>
                                                    {
                                                        contact.email && <div> Email - {contact.email} </div>
                                                    }
                                                    {
                                                        contact.phone_number && <div> Phone - {contact.phone_number} </div>
                                                    }
                                                    </div>
                                        })
                                    }
                                    {
                                            props.invoiceObj.client.user_defined_field && 
                                            props.invoiceObj.client.user_defined_field.length > 0 && 
                                            props.invoiceObj.client.user_defined_field.map(udf => {
                                                return (udf.value !== '' && udf.show_invoice) ?
                                                <div key={udf.value}>{udf.name}: {udf.value}</div>
                                                :
                                                <div></div>
                                            })
                                    }
                                </Typography>
                               
                            </Grid>

                            <Grid item style={{textAlign:'right', marginRight: "8px"}}>
                                    <Typography className='bill_label'>
                                        Bill From
                                    </Typography>
                                    <Typography className="company_heading"> 
                                        {entity?.name}
                                    </Typography> 
                                    <Typography className='clientOwner_address'>
                                        <div dangerouslySetInnerHTML={{__html: entity?.address}}>
                                        </div>
                                        <div>{entity?.country}</div>
                                        <div>Tel: {entity?.mobile}</div>
                                        <div>Email: {entity?.email}</div> 
                                        {entity?.tax_id &&
                                            <div>Tax Id: {entity?.tax_id}</div>
                                        }
                                    </Typography>
                            </Grid>
                    </Grid> 

                    <div className='modalBorderBottom'></div>

                    <Grid item container alignItems='center'
                        className={classes.thirdRow} style={{paddingRight: "26px"}}>
                            <Grid item sm={12}>
                                <Grid item container
                                    alignItems='center' 
                                    className="table_container">
                                    <Grid item sm={4}>
                                        <Typography variant="subtitle1" 
                                            className='bill_label'>
                                            Item
                                        </Typography>
                                    </Grid>

                                    <Grid item sm={2}>
                                        <Typography variant="subtitle1"
                                            className='bill_label'>
                                            Cost
                                        </Typography>
                                    </Grid>

                                    <Grid item sm={2} className={classes.customColumn3}>
                                        <Typography variant="subtitle1"
                                            className='bill_label'>
                                            QTY
                                        </Typography>
                                    </Grid>

                                    <Grid item sm={2} className={classes.customColumn4}>
                                        <Typography variant="subtitle1"
                                            className='bill_label'>
                                            HSN/SAC code
                                        </Typography>
                                    </Grid>

                                    <Grid item sm={2} className={classes.customColumn5}
                                        style={{textAlign:'right'}}>
                                        <Typography variant="subtitle1"
                                            className='bill_label'>
                                            Price
                                        </Typography>
                                    </Grid>

                                </Grid>
                            </Grid>

                            {
                                props.invoiceObj?.invoice_items.map((invoice) => {
                                    return  <Grid item sm={12} key={invoice.invoice_id}>
                                                <Grid item container style={{padding:'8px 4px'}}>
                                                    <Grid item sm={4}>
                                                        <Typography className='preview_list_name_style'>
                                                            {invoice.item_name}
                                                        </Typography>
                
                                                        <Typography className="preview_list_style">
                                                            {invoice.item_description} 
                                                        </Typography>
                                                    </Grid>
                
                                                    <Grid item sm={2}>
                                                        <Typography className="preview_list_style">
                                                            {/* {`${invoice.cost.toFixed(2)}₹`} */}
                                                            {currencyFormatter.format(invoice.cost)}
                                                        </Typography>
                                                    </Grid>
                
                                                    <Grid item sm={2} className={classes.customColumn3}>
                                                        <Typography className="preview_list_style">
                                                            {invoice.quantity.toFixed(2)}
                                                        </Typography>
                                                    </Grid>

                                                    <Grid item sm={2} className={classes.customColumn4}>
                                                        <Typography className="preview_list_style">
                                                            {invoice.hsn_code}
                                                        </Typography>
                                                    </Grid>
                
                                                    <Grid item sm={2} className={classes.customColumn5} 
                                                        style={{textAlign:'right'}}>
                                                        <Typography className="preview_list_style">
                                                            {/* {invoice.price.toFixed(2)} */}
                                                            {currencyFormatter.format(invoice.price)}
                                                        </Typography>
                                                        {
                                                            invoice.tax && invoice.tax.length > 0 && invoice.tax.map((taxObj) => {
                                                                return  <Typography style={{fontSize:'12px', marginTop:'4px', fontWeight:'500', color:'#4a5568'}} key={taxObj.tax_preset_id}>
                                                                            {taxObj.name + ' ' + taxObj.rate.toFixed(3) +  "%" +  '   ' + `(${taxObj.amount ? currencyFormatter.format(taxObj.amount) : 0})`}
                                                                        </Typography>
                                                            })
                                                        }

                                                        {
                                                            invoice.discount && invoice.discount.length > 0 && invoice.discount.map((discount) => {
                                                                return  <Grid item xs={12}>
                                                                            {discount.type === DiscountTypes.PERCENT &&
                                                                                <Typography style={{fontSize:'12px', marginTop:'4px', fontWeight:'500', color:'#4a5568'}} key={discount.value}>
                                                                                    Discounted: {`${discount.value}%`}
                                                                                </Typography>
                                                                            }
                                                                            <Typography style={{fontSize:'12px', marginTop:'4px', fontWeight:'500', color:'#4a5568'}} key={discount.value}>
                                                                                Discounted:{` ${currencyFormatter.format(discount.amount)}`}
                                                                            </Typography>
                                                                        </Grid>
                                                            })
                                                        }
                                                    
                                                    </Grid>
                                                </Grid>
                                                {/* {<div style={{borderBottom:'1px solid rgba(0,0,0,.1)'}}></div>} */}
                                            </Grid>    

                                })
                            }
                    </Grid>

                    {entity?.enable_esign ? <div className="border_bottom_two"></div> : <div className="dottedborderBottom"></div>}

                    {entity?.enable_esign &&
                        <Grid item sm={12} style={{marginTop:'18px', padding: '0px 32px'}}>
                            <Grid item container direction='column' alignItems='flex-end'>
                                
                                {eSignUrl &&
                                    <Grid item style={{position:'relative', top:'3px'}}>
                                        <div className={'eSign_style'}>
                                            <img src={eSignUrl} width='40%' />
                                        </div>
                                        <Typography style={{fontWeight:400, marginTop:'4px'}}>
                                            Authorized Signatory
                                        </Typography>
                                    </Grid>
                                }

                            </Grid>
                            <div className="dashed_border"></div>
                        </Grid>}

                    <Grid item sm={12} style={{marginTop:'-16px'}}>
                        <Grid item container justifyContent="space-between" 
                            className={classes.thirdRow}
                            style={{paddingRight: "26px"}}>
                            <Grid item sm={5} className={classes.paymentClientStyles}>
                                {props.invoiceObj?.show_payment_terms &&
                                <Grid item>
                                    <Typography className='font_styles'>
                                        Payment Terms
                                    </Typography>

                                    <Typography className="payment_terms"
                                        dangerouslySetInnerHTML={{__html: props.invoiceObj?.payment_terms}}>
                                    </Typography>
                                </Grid>}
                                
                                {props.invoiceObj?.show_client_notes &&
                                <Grid item style={{marginTop:props.invoiceObj?.show_payment_terms && '24px', width:'40ch'}}>
                                    <Typography className='font_styles'> 
                                        Client Notes
                                    </Typography>

                                    <Typography className='clientOwner_address' 
                                        dangerouslySetInnerHTML={{__html: props.invoiceObj?.client_notes}}>
                                    </Typography>
                                </Grid>}
                            </Grid>

                            <Grid item sm={5} style={{marginRight:'4px'}}>
                                <Grid item container justifyContent='space-between'> 
                                    <Typography className='list_item_styles'>
                                        Subtotal
                                    </Typography>

                                    <Typography className='list_item_styles'>
                                        {/* {`${props.invoiceObj?.subtotal.toFixed(2)}₹`} */}
                                        {currencyFormatter.format(props.invoiceObj?.subtotal)}
                                    </Typography>
                                </Grid>

                                {
                                    props.invoiceObj?.discount.map((discount) => {
                                        return <Grid item container justifyContent='space-between'
                                                    key={discount.value}
                                                    className="item_marginTop"> 
                                                    <Typography className='list_item_styles'>
                                                        Discount
                                                    </Typography>
                
                                                    <Typography className='list_item_styles'>
                                                        {`${currencyFormatter.format(discount.amount)}`}
                                                    </Typography>
                                                </Grid>
                                    })
                                }

                                {
                                    props.invoiceObj?.total_tax.map((taxObj) => {
                                        return <Grid item container justifyContent='space-between'
                                                    key={taxObj.tax_preset_id}
                                                    className="item_marginTop"> 
                                                    <Typography className='list_item_styles'>
                                                        {taxObj.name}
                                                    </Typography>
                
                                                    <Typography className='list_item_styles'>
                                                        {`${taxObj.amount ? currencyFormatter.format(taxObj.amount): 0}`}
                                                    </Typography>
                                                </Grid>
                                    })
                                }

                                

                                <div className="bill_invoice"></div>

                                <Grid item container justifyContent='space-between'
                                    className="item_marginTop"> 
                                    <Typography className='list_item_styles'>
                                        Total
                                    </Typography>

                                    <Typography className='list_item_styles'>
                                        {/* {`${props.invoiceObj?.total.toFixed(2)}₹`} */}
                                        {currencyFormatter.format(props.invoiceObj?.total)}
                                    </Typography>
                                </Grid>

                                <Grid item container justifyContent='space-between'
                                    className="item_marginTop"> 
                                    <Typography className='list_item_styles'>
                                        Paid to date
                                    </Typography>

                                    <Typography className='list_item_styles'>
                                    {/* {`${props.invoiceObj?.paid.toFixed(2)}₹`} */}
                                    {currencyFormatter.format(props.invoiceObj?.paid)}
                                    </Typography>
                                </Grid>

                                <Grid item container justifyContent='space-between'
                                    className="item_marginTop"> 
                                    <Typography className='list_item_styles'>
                                        Amount Due (INR)
                                    </Typography>

                                    <Typography className='list_item_styles'>
                                    {/* {`${props.invoiceObj?.due.toFixed(2)}₹`} */}
                                    {currencyFormatter.format(props.invoiceObj?.due)}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <div className='dottedborderBottom'></div>

                    <Grid item sm={12} className={classes.thirdRow} style={{paddingRight: "26px"}}>
                        {props.invoiceObj?.show_payment_stub &&
                        <Grid item container justifyContent="space-between">
                                <Grid item sm={5} className="payment_stubContainer" 
                                    style={{flexBasis:'55%', maxWidth:'55%'}}>
                                    <Typography className="company_heading"> 
                                        {entity?.name}
                                    </Typography> 
                                    <Typography className='clientOwner_address'> 
                                        <div dangerouslySetInnerHTML={{__html: entity?.address}}>
                                        </div> 
                                        <div>{entity?.country}</div>
                                        <div>Tel: {entity?.mobile}</div>
                                        <div>Email: {entity?.email}</div>
                                        {entity?.tax_id &&
                                            <div>Tax Id: {entity?.tax_id}</div>
                                        }
                                    </Typography>   
                                </Grid>

                            <Grid item sm={5} style={{marginRight:'4px'}}>
                                <Grid item container justifyContent='space-between'> 
                                    <Typography className='list_item_styles'>
                                        Client
                                    </Typography>

                                    <Typography className='list_item_styles'>
                                    {props.invoiceObj?.client?.name}
                                    </Typography>
                                </Grid>

                                <Grid item container justifyContent='space-between'
                                    className="item_marginTop"> 
                                    <Typography className='list_item_styles'>
                                        Invoice
                                    </Typography>

                                    <Typography className='list_item_styles'>
                                        #{props.invoiceObj?.invoice_number}
                                    </Typography>
                                </Grid>

                                {
                                    props.invoiceObj?.total_tax.map((taxObj) => {
                                        return <Grid item container justifyContent='space-between'
                                                    key={taxObj.tax_preset_id}
                                                    className="item_marginTop"> 
                                                    <Typography className='list_item_styles'>
                                                        {taxObj.name}
                                                    </Typography>
                
                                                    <Typography className='list_item_styles'>
                                                        {`${taxObj.amount ? currencyFormatter.format(taxObj.amount) : 0}`}
                                                    </Typography>
                                                </Grid>
                                    })
                                }

                                <div className="bill_invoice"></div>


                                <Grid item container justifyContent='space-between'
                                    className="item_marginTop"> 
                                    <Typography className='list_item_styles'>
                                        Amount Due (INR)
                                    </Typography>

                                    <Typography className='list_item_styles'>
                                        {/* {`${props.invoiceObj?.due.toFixed(2)}₹`} */}
                                        {currencyFormatter.format(props.invoiceObj?.due)}
                                    </Typography>
                                </Grid>

                                <Grid item container justifyContent='space-between'
                                    className="item_marginTop"> 
                                    <Typography className='list_item_styles'>
                                        Amount Enclosed
                                    </Typography>

                                    <Typography className='list_item_styles'>
                                        {/* {`${props.invoiceObj?.paid.toFixed(2)}₹`} */}
                                        {currencyFormatter.format(props.invoiceObj?.paid)}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>}
                    </Grid>


            </Dialog>
        </div>
    );
}

const mapStateToProps = (state) => ({
    accessToken: state.invoiceAuth,
    selectedAccount: state.invoiceAccounts.selectedAccount
});

const mapDispatchToProps = (dispatch) => ({
    setShowPreviewInvoiceScreen: (previewScreen) => dispatch(setShowPreviewInvoiceScreen(previewScreen)),
    setInvoiceItemObj: (invoice) => dispatch(setInvoiceItemObj(invoice)),
    setEditInvoiceObj: (obj) => dispatch(setEditInvoiceObj(obj)),
    setSelectedDrawerState: (drawerState) => dispatch(setSelectedDrawerState(drawerState))
})

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceDetailsModal);