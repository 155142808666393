import React, {useState, useEffect} from 'react';
import {Container, Grid, Typography, Button, TextField, MenuItem, CircularProgress, Paper, Tabs, Tab, Avatar} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {connect} from 'react-redux'; 
import { consoleToLog, capitalize } from '../util/AppUtil';
import { useSnackbar } from 'notistack';
import {addMemberToInvoiceAcountApi, accountMembersListApi, changeMemberRoleApi, 
    deleteAccountMemberApi, getPendingInvitationsListApi, resendInvitationApi,
    pendingInvitationExistApi, createPendingInvitationApi, removeScopeApi} from '../services/authService';
import { emailValidator } from '../util/AppUtil';
import { logout } from '../actions/invoiceAuth';
import { clearSelectedInvoiceFilters } from '../actions/invoiceFilters';
import { setSelectedDrawerState } from '../actions/drawer';
import { clearSelectedTransactionFilters } from "../actions/transactionFilters";
import { setClearInvoiceAccounts } from '../actions/invoiceAccount';
import { useIsMount } from './useIsMount';
import {AccessLevel, trackGAEvent} from '../util/AppUtil';
import {setHeightForComponent} from '../util/AppUtil';
import { usePropsContext } from './context';

const useStyles = makeStyles((theme) => ({
    mainContent : {
        backgroundColor: theme.palette.primary.light, 
        transition: ".5s", 
        flexGrow: 1,
        //overflow:'hidden',
        overflow:'auto',
        overflowX:'hidden'
    },
    container: {
        marginTop: "8px",
        marginBottom:'24px'
    },
    circularProgress: {
        marginLeft: 0,
        marginRight: theme.spacing(2),
        color: 'white'
    },
    tab: {
        flexGrow: 1,
        borderRadius:0,
        borderBottom: "1px solid #c5b9b9",
    },
    memberContainer: {
        flexGrow: 1,
        background: 'white',
        padding:'15px',
        //height:'100vh',
        marginBottom:'25px'
    },
    memberColumn : {
        background: '#F9F9F9',
        borderRadius: '5px',
        marginBottom: '8px',
        padding:'8px 8px',
        '&:last-child' : {
            marginBottom: '0px'
        },
        "& .deleteIcon" : {
            visibility: 'hidden'
        },
        "&:hover .deleteIcon" : {
            visibility: 'visible'
        }
    },
    avatarStyle: {
        width:"38px !important",
        height:"38px !important",
        fontSize:'16px !important',
        fontWeight:'600 !important',
        transform: 'translateY(3px)'
    },
    memberText: {
        transform: 'translateY(1.5px)'
    },
    pendingLoading: {
        position:'relative',
        left:'50%',
        right:'50%',
        top:'5%'
    },
    pendingMemberColumn : {
        background: '#F9F9F9',
        borderRadius: '5px',
        marginBottom: '8px',
        padding:'8px',
        '&:last-child' : {
            marginBottom: '0px'
        },
        "& .refreshIcon" : {
            visibility: 'hidden'
        },
        "&:hover .refreshIcon" : {
            visibility: 'visible'
        }

    }
    }
));

const AccountMembers = (props) => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();
    const isMount = useIsMount();

    const { fullName, is_entity_email_unverified, isInvoiceAccountPlanFree, entityLimitExceeded } = usePropsContext();

    const is_email_verified = props.selectedAccount?.is_email_verified;
    const roles = ["owner", "editor", "viewer"];

    const [email, setEmail] = useState('');
    const [selectedRowId, setSelectedRowId] = useState(undefined);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [accountMembersList, setAccountMembersList] = useState([]);

    const [pendingMembersList, setPendingMembersList] = useState([]);
    const [pendingMembersLoading, setPendingMembersLoading] = useState(false);
    const [tabValue, setTabValue] = useState(0);

    const { access_level } = props.selectedAccount || {};
    const accessLevelOwner = (AccessLevel.getUserAccessLevelValue(access_level) === AccessLevel.OWNER) ? true : false;
    const loggedInUserAccessLevel = AccessLevel.getUserAccessLevelValue(access_level);
    const accessLevelViewer = (AccessLevel.getUserAccessLevelValue(access_level) === AccessLevel.VIEWER) ? true : false;

    const onChangeSelectedRoleType = (e) => {
        const value = e.target.value;
        setSelectedRowId(value);
    }

    useEffect(() => {
        document.title = `Members - ${fullName}`;
    }, []);

    useEffect(() => {
        getAccountMemberList();
    }, []);

    useEffect(() => {
        if(!isMount) {
            getAccountMemberList();
        }
    }, [props.selectedAccount?.id])

    useEffect(() => {
        if(isMount) {

        } else {
            if(tabValue) {
                getPendingInvitations();
            }
        }

    }, [tabValue])

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    }

    const checkDisabled = (isCurrentlyLoggedIn) => {
        if(isCurrentlyLoggedIn) {
            return true;
        } else {
            return false;
        }

    }

    const getAccountMemberList = () => {
        const invoice_account_id = props.selectedAccount.id;
        //get members list api on first render
        accountMembersListApi(invoice_account_id)
        .then((response) => {
            const res = response.data;
            consoleToLog("Response accountMembersListApi: ", res);

            setAccountMembersList(res);
        })
        .catch((e) => {
            consoleToLog("Error accountMembersListApi: ", e.response);
            if(e.response.data && e.response.data.message) {
                enqueueSnackbar(e.response.data.message, {variant: 'error'});
                return;
            }
        });
    }

    const getPendingInvitations = () => {
        const invoice_account_id = props.selectedAccount.id;
        setPendingMembersLoading(true);

        getPendingInvitationsListApi(invoice_account_id)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response getPendingInvitationsListApi: ", res);
                setPendingMembersLoading(false);
                setPendingMembersList(res);
            })
            .catch((e) => {
                setPendingMembersLoading(false);
                if (e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {
                        variant:'error'
                    });
                    return false;
                }
            });
    }

    const onMemberTypeChanged = (e, invoice_account_user_id) => {
        const changeTypeToRoleId = e.target.value;
        const invoice_account_id = props.selectedAccount?.id;

        changeMemberRoleApi(invoice_account_id, invoice_account_user_id, changeTypeToRoleId)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response changeMemberRoleApi: ", res);
                enqueueSnackbar('Member role changed successfully', {
                    variant: 'success'
                });

                const updatedArr = accountMembersList.map((member) => {
                    if(member.user.id === res.user.id) {
                        member = res;
                    }
                    return member;
                });
                setAccountMembersList(updatedArr);
                trackGAEvent(props.selectedAccount.name, 'Member Role Changed', `${props.user.firstname} ${props.user.lastname}`);
            })
            .catch((e) => {
                consoleToLog("Response changeMemberRoleApi: ", e.response);
                if(e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {variant: 'error'});
                    return;
                }
            });
    }

    const deleteMemberFromOrg = (invoice_account_member_id, member_user_id, fullName) => {
        //dont allow if owner deleting itself
        if (props.user.id === member_user_id) {
            enqueueSnackbar('You can not delete yourself!', {
                variant:'error'
            });
            return;
        }

        if(window.confirm('Are you sure you want to remove member?')) {
            const invoice_account_id = props.selectedAccount?.id;

            deleteAccountMemberApi(invoice_account_id, invoice_account_member_id) 
                .then((response) => {
                    const res = response.data;
                    consoleToLog("Response removeOrgMemberApi: ", res);
                    enqueueSnackbar('Member deleted successfully', {
                        variant: 'success'
                    });
                            
                    const updatedArr = accountMembersList.filter((member) => member.id !== invoice_account_member_id);
                    setAccountMembersList(updatedArr);

                    if(res.orphaned) {
                        removeRegisterScope(member_user_id)
                    }
                    trackGAEvent(props.selectedAccount.name, 'Member Deleted', `${props.user.firstname} ${props.user.lastname}`);
                    //remove from members-redux 
                    //props.removeOrgMember(organization_member_id);
                })
                .catch((e) => {
                    consoleToLog("Response removeOrgMemberApi: ", e.response);
                    if(e.response.data && e.response.data.message) {
                        enqueueSnackbar(e.response.data.message, {variant: 'error'});
                        return;
                    }
                });    
            }
    }

    const removeRegisterScope = (member_user_id) => {
        const service = 'invoices';
        const user_id = member_user_id

        removeScopeApi(service, user_id)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response removeScopeApi: ', res);
            })
            .catch((e) => {
                consoleToLog("Response removeScopeApi: ", e.response);
                if(e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {variant: 'error'});
                    return;
                }
            });   
    }

    const showMembersList = () => {
        return (
            <Grid item container>
                <Paper className={classes.memberContainer}  elevation={0}>
                    <Grid item container direction="column">
                        {accountMembersList && accountMembersList.length > 0 ? 
                        accountMembersList.map((member) => {
                            let full_name = member.user.firstname + " " + member.user.lastname;
                            const isDisabled = checkDisabled(props.user.id === member.user.id);
                            const memberAccessLevel = AccessLevel.getUserAccessLevelValue(member.access_level);
                            return <Grid item container 
                                        key={member.user.id} 
                                        className={classes.memberColumn}>

                                        <Grid item md={8} lg={8}>
                                            <Grid item container alignItems="center">
                                                <Grid item>
                                                    <Avatar className={classes.avatarStyle}>
                                                    {member.user.firstname.charAt(0).toUpperCase() +
                                                        member.user.lastname.charAt(0).toUpperCase()}    
                                                    </Avatar>
                                                </Grid>
                                                <Grid item className={classes.memberText}>
                                                    <div style={{marginLeft: "16px"}}>
                                                        <Typography style={{fontSize:'16px', fontWeight:'600'}}>
                                                            {full_name}
                                                        </Typography>
                                                        <Typography variant="subtitle1" style={{color:'#555555'}}>
                                                            {member.user.email}
                                                        </Typography>
                                                    </div>
                                                </Grid>
                                            </Grid>                                                
                                        </Grid>

                                        <Grid item lg={2}>
                                                <TextField fullWidth
                                                    margin="dense"
                                                    style={{transform:'translateY(-1.5px)'}}
                                                    variant="outlined"
                                                    select
                                                    disabled={isDisabled || !accessLevelOwner}
                                                    value={member.access_level}
                                                    onChange={(e) => onMemberTypeChanged(e, member.id)}
                                                >
                                                    {
                                                        roles && roles.map((role) => {
                                                        return  <MenuItem key={role} value={role}>
                                                                    {capitalize(role)}
                                                                </MenuItem>
                                                        })
                                                    }
                                                </TextField>               
                                        </Grid> 

                                        {accessLevelOwner &&
                                        <div style={{display:'flex', alignItems:'center', marginLeft:'auto'}}>
                                            <Grid item lg={2} className="deleteIcon"> 
                                                <Button  onClick={() => deleteMemberFromOrg(member.id, member.user.id, full_name)} 
                                                    style={{
                                                        fontSize: "18px",
                                                        minWidth: '20px'
                                                    }}
                                                    title="Delete member">
                                                    <i className="flaticon-delete-1" style={{height:'25px'}}></i>
                                                </Button> 
                                            </Grid>              
                                        </div> }

                                    </Grid>
                            }) : 
                            <Grid item container justifyContent='center'>
                                <Typography>
                                    No Members Found
                                </Typography>
                            </Grid>
                        }   
                    </Grid>
                </Paper>          
            </Grid>
        )
    }

    const resendInvitation = (member) => {
        const service = 'invoices';
        const email = member.email;
        const role = member.role
        const entity_id = props.selectedAccount.id;
        const entity_name = props.selectedAccount?.name;
        const entity_email = props.selectedAccount?.email

        resendInvitationApi(service, email, role, entity_id, entity_name, entity_email)
            .then((response) => {
                const res = response.data;
                enqueueSnackbar(res.message, {
                    variant: 'success'
                });
                consoleToLog("Response resendInvitationApi: ", res);
            })
            .catch((e) => {
                consoleToLog("Error resendInvitationApi error", e.response);
                if (e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {
                        variant:'error'
                    });
                    return false;
                }
            });

    }

    const showPendingMemberList = () => {
        return (
            <Grid item container >
                    <Paper className={classes.memberContainer}  elevation={0}>
                    { pendingMembersLoading ? <CircularProgress size={35} className={classes.pendingLoading}/> : 
                    <Grid item container direction="column">
                    {pendingMembersList && pendingMembersList.length > 0 ? pendingMembersList.map((member) => {
                            return <Grid item container 
                                        alignItems='center'
                                        key={member.id} 
                                        className={classes.pendingMemberColumn}>

                                        <Grid item md={8} lg={8}>
                                            <Grid item container alignItems="center">
                                                {member.email &&
                                                <Grid item>
                                                    <Avatar className={classes.avatarStyle} style={{transform:'translateY(0px)'}}>
                                                    {member.email.charAt(0).toUpperCase()}    
                                                    </Avatar>
                                                </Grid>}
                                                <Grid item> 
                                                    <div style={{marginLeft: "16px", marginTop:"-4px"}}>
                                                        <Typography style={{fontSize:"15px", fontWeight:"600"}}>
                                                            {member.email}
                                                        </Typography>
                                                    </div>
                                                </Grid>
                                            </Grid>                                                
                                        </Grid>

                                        <Grid item lg={2}>
                                                <TextField fullWidth
                                                style={{transform:'translateY(-1.5px)'}}
                                                margin="dense"
                                                variant="outlined"
                                                select
                                                disabled={true}
                                                value={member.role}
                                                onChange={(e) => onMemberTypeChanged(e, member.id)}>
                                                    {
                                                    roles && roles.map((role) => {
                                                    return  <MenuItem key={role} value={role}>
                                                                {capitalize(role)}
                                                            </MenuItem>
                                                    })
                                                    }
                                                </TextField>               
                                        </Grid> 

                                        <div style={{display:'flex', alignItems:'center', marginLeft:'auto'}}>
                                            <Grid item lg={2} className="refreshIcon">
                                                <Button  onClick={() => resendInvitation(member)}
                                                    style={{
                                                    fontSize: "18px",
                                                    minWidth: '20px'
                                                }}>
                                                    <i className="flaticon-reload" style={{height:'25px'}}></i>
                                                </Button> 
                                            </Grid>              
                                        </div>       

                                    </Grid>
                            }) : <Grid item container justifyContent="center">
                                    <Typography variant="body1">No Pending Invitations</Typography>
                                </Grid>
                        }   
                    </Grid>}
                </Paper>          
            </Grid>
        )
    }

    const onInviteMemberClick = () => {
        if (!email) {
            enqueueSnackbar('Email cannot be empty', {
                variant: 'error'
            });
            return;
        }
        const isEmailValid = emailValidator(email);
            if (!isEmailValid) {
            enqueueSnackbar('Email is not valid', {
                variant: 'error'
            });
                return;
        }

        const role_id = selectedRowId;
        consoleToLog("*************role_id", role_id);
        if(!role_id) {
            enqueueSnackbar('Please Select a Type', {
                variant: 'error'
            });
            return;
        }

        const invoice_account_id = props.selectedAccount.id;
        const service = 'invoices';
        const entity_name = props.selectedAccount?.name;
        const entity_email = props.selectedAccount?.email

        
        setButtonLoading(true);
        pendingInvitationExistApi(invoice_account_id, email)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response pendingInvitationExistApi: ', res);

                if(res.message === 'No record in pending inviation') {
                    addMemberToInvoiceAccount(service, email, role_id, invoice_account_id, entity_name)
                }
            })
            .catch((e) => {
                consoleToLog("pendingInvitationExistApi error", e.response);
                setButtonLoading(false);
                if(e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {variant: 'error'});
                    return;
                }
            });

    }

    const addMemberToInvoiceAccount = (service, email, role_id, invoice_account_id, entity_name) => {

        addMemberToInvoiceAcountApi(service, email, role_id, invoice_account_id, entity_name)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response addMemberToInvoiceAcountApi', res);
                setButtonLoading(false);
                enqueueSnackbar(res.message, {
                    variant: 'success'
                });

                setEmail('');
                setSelectedRowId(undefined);
                createPendingInvitation(invoice_account_id, role_id, email);
                trackGAEvent(props.selectedAccount.name, 'Member Added', `${props.user.firstname} ${props.user.lastname}`);

            })
            .catch((e) => {
                consoleToLog("addMemberToInvoiceAcountApi error", e.response);
                setButtonLoading(false);
                if(e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {variant: 'error'});
                    return;
                }
            });
    }

    const createPendingInvitation = (invoice_account_id, role, email) => {
            
            createPendingInvitationApi(invoice_account_id, role, email)
                .then((response) => {
                        const res = response.data;
                        consoleToLog('Response createPendingInvitationApi', res);

                })
                .catch((e) => {
                    consoleToLog("createPendingInvitationApi error", e.response);
                    setButtonLoading(false);
                    if(e.response.data && e.response.data.message) {
                        enqueueSnackbar(e.response.data.message, {variant: 'error'});
                        return;
                    }
                });
    }

    return (
        <div className={classes.mainContent}
            //style={{height: setHeightForComponent(is_entity_email_unverified, accessLevelViewer, isInvoiceAccountPlanFree, entityLimitExceeded)}}
        >
            <Container className={classes.container} maxWidth="xl">
                <Grid item sm={12}>
                    <Typography style={{fontSize:'20px', fontWeight:'600'}} color="primary">
                        Invite Members
                    </Typography>
                    <Grid item container spacing={1} alignItems="center">
                        <Grid item lg={4}>
                            <TextField fullWidth
                                margin="dense"
                                variant="outlined"
                                value={email}
                                onFocus={email}
                                label="Enter Email Address"
                                type="text"
                                style={{background: '#fff'}}
                                InputLabelProps={{style: {fontSize: 14, paddingRight:'31px !important'}}}
                                onChange={e => setEmail(e.target.value)}
                            />
                        </Grid>
                        <Grid item lg={3}>
                            <TextField fullWidth
                                margin="dense"
                                variant="outlined"
                                select
                                label= "Select Type"
                                value={selectedRowId ? selectedRowId : ''}
                                style={{background: '#fff'}}
                                InputLabelProps={{style: {fontSize: 14, paddingRight:'31px !important'}}}
                                onChange={onChangeSelectedRoleType}
                            >
                                    {
                                        roles && roles.map((role) => {
                                        return  <MenuItem key={role} value={role}>
                                                    {capitalize(role)}
                                                </MenuItem>
                                        })
                                    }
                            </TextField>    
                        </Grid>
                        <Grid item lg={1}>
                            <Button  variant="contained" 
                                style={{background: "#383838",
                                        width: "107px",
                                        padding: "7px 0",
                                        marginTop: "4px",
                                        color: "white"
                                    }}
                                onClick={onInviteMemberClick}   
                                disabled={!accessLevelOwner}    
                            >
                                {buttonLoading && <CircularProgress size={24} className={classes.circularProgress}/>}
                                Invite
                            </Button>
                        </Grid>          
                    </Grid>
                </Grid>

                <Grid item container direction="column" style={{marginTop:'30px'}}>
                    <Grid item container>
                        <Paper className={classes.tab} elevation={0}>
                            <Tabs
                                value={tabValue}
                                onChange={handleTabChange}
                                indicatorColor="primary"
                                textColor="primary"
                            >
                                <Tab label="Members" />
                                <Tab label="Pending Invitation" />
                            </Tabs>
                        </Paper>
                    </Grid>

                    {!tabValue ? showMembersList() : showPendingMemberList()} 
                </Grid>

            </Container>
        </div>
    );
}

const mapStateToProps = (state) => ({
    selectedAccount: state.invoiceAccounts.selectedAccount,
    user: state.invoiceAccounts.user
})

const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(logout()),
    clearSelectedInvoiceFilters: () => dispatch(clearSelectedInvoiceFilters()),
    setClearInvoiceAccounts: () => dispatch(setClearInvoiceAccounts()),
    setSelectedDrawerState: (drawerState) => dispatch(setSelectedDrawerState(drawerState)),
    clearSelectedTransactionFilters: () => dispatch(clearSelectedTransactionFilters())
})

export default connect(mapStateToProps, mapDispatchToProps)(AccountMembers);