import React, {useState, Fragment} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {TextField, Grid, Avatar, Chip} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ClearIcon from '@material-ui/icons/Clear';
import {addSelectedMethodToTransactionFilter, removeSelectedMethodFromTransactionFilter} from '../../actions/transactionFilters';
import { trackGAEvent } from '../../util/AppUtil';

const useStyles = makeStyles((theme) => ({
    chip : {
        borderRadius: '4px !important',
        backgroundColor: '#f9f9f9 !important',
        border: '1px solid #ececec !important',
        },
        
       
    autoStyle: {
        "&::-webkit-scrollbar": {
            display: "none"
        }
            
    }
    }
));

const PaymentMethodFilterComponent = (props) => {
    const classes = useStyles();
    const [selectedMethod, setSelectedMethod] = useState([]);


    const paymentMethodList = [
        {name: "Bank", value: "bank"},
        {name: "Cash", value: "cash"}, 
        {name: "Cheque", value: "cheque"},
        {name: "UPI", value: "upi"},
        {name: "Credit Card", value: "credit_card"},
        {name: "External", value: "external"}
    ];

    const onMethodClick = (option) => {
        props.addSelectedMethodToTransactionFilter(option)
    }

    const onRemoveMethodClick = (option) => {
        props.removeSelectedMethodFromTransactionFilter(option)
    }

    const renderOptions = (option) => {
        return (
            <div style={{display: "flex", width:"100%"}} 
                 className={classes.autoStyle}
                onClick={() => {
                    onMethodClick(option);
                    props.pageReset();
                }}     
            >
                {option.name}
            </div>
        );
    }

    const renderTags = (options) => {
        return (
            options.map(option =>
                <Fragment key={option.name}>
                <Chip
                    classes={{
                        root: classes.chip
                    }}
                    label={option.name}
                    onDelete={() => {
                        onRemoveMethodClick(option)
                        props.pageReset();
                    }}
                    variant="outlined"
                    style={{margin: "2px 2px"}}
                    deleteIcon={<ClearIcon  style={{width: "18px"}}/>}
                />
                </Fragment>))
    }

    return (
        <div style={{marginTop:'16px'}}>
            <span style={{fontSize:'14px'}}>
                Select Payment Method            
            </span>
            <Grid container md={12} lg={12} style={{marginTop:'8px'}}>
                <Grid item lg={12}>
                <Autocomplete
                    multiple={true}
                    style={{width: "100%"}}
                    id="size-small-outlined-multi"
                    size="small"
                    options={paymentMethodList}
                    //getOptionSelected={(option, value) => option.id === value.id}
                    getOptionLabel={(option) => option.name}
                    disableClearable
                    renderOption={(option) => renderOptions(option)}
                    value={props.selectedMethodTransactionFilter}
                    onChange={(e, newValue) => {
                        //props.isFromReport &&  props.onUpdateClearFilterValue();
                        trackGAEvent(props.selectedAccount.name, 'Transaction filter - Payemnt method filter changed');
                        setSelectedMethod(newValue)
                    }}
                    renderTags={(options) => renderTags(options)}
                    renderInput={(params) => (
                        <TextField {...params} variant="outlined" placeholder="Payment Methods" />
                    )}
                />
                </Grid>
            </Grid>        
        </div>
    );
}

const mapStateToProps = (state) => ({
    selectedMethodTransactionFilter: state.transactionFilters.selectedMethodTransactionFilter,
    selectedAccount: state.invoiceAccounts.selectedAccount
});

const mapDispatchToProps = (dispatch) => ({
    addSelectedMethodToTransactionFilter: (method) => dispatch(addSelectedMethodToTransactionFilter(method)),
    removeSelectedMethodFromTransactionFilter: (method) => dispatch(removeSelectedMethodFromTransactionFilter(method))
})

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethodFilterComponent);