import React, {useState} from 'react';
import { connect } from 'react-redux';
import {Grid, Typography, TextField, Button, CircularProgress, Paper, Avatar, InputAdornment, Tooltip, IconButton, Link} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import CheckSharpIcon from '@material-ui/icons/CheckSharp';
import ClearIcon from '@material-ui/icons/Clear';
import {useSnackbar} from 'notistack';
import { nameValidator, emailValidator } from '../util/validator';
import {consoleToLog, capitalizeFirstLetterOfEachWord, trackGAEvent} from '../util/AppUtil';
import {updateUserProfileApi, sendUserVerificationMailApi} from '../services/authService';
import { setUserObj } from '../actions/invoiceAccount';

const useStyles = makeStyles((theme) => ({
    tabPanelContainer: {
        flexGrow: 1,
        padding:'15px',
        background: 'white',
        //height:'100vh',
    },      
    imgContainer :{
        marginTop:"16px",
        padding:'8px',
        width:'110px',
        height:'110px',
        borderRadius:'50%',
        border: '1px solid rgba(0, 113, 255, 0.19)',
        boxSizing:'border-box'
    },
    customColumn : {
        flexBasis:'23%',
        maxWidth:'23%'
    },
    customColumn2: {
        flexBasis:'70.5%',
        maxWidth:'70.5%'
    },
    uploadImg: {
        position:'relative',
        left:'-30px',
        top:'90px',
        minWidth:'25px', 
        height:'25px', 
        borderRadius:'50%',
        background:'#3d3d3d', 
        color:'white',
        cursor:'pointer'
    },
    uploadImg1: {
        position:'absolute',
        top:'80px',
        left:'75px',
        minWidth:'25px', 
        height:'25px', 
        borderRadius:'50%',
        background:'#3d3d3d', 
        color:'white',
        cursor:'pointer',
    },  
    avatarStyle: {
        position:'relative',
        marginTop: '24px',
        marginLeft: '12rem',
        width: '110px !important',
        height: '110px !important',
        fontSize: '32px !important',
        fontWeight: '600 !important',
        background: 'rgba(51, 51, 51, 0.1) !important',
        border: '1px solid rgba(0, 113, 255, 0.19) !important',
        boxSizing:'border-box',
        overflow:'visible'
    },
    circularProgress: {
        marginLeft: 0,
        marginRight: theme.spacing(2),
        color: 'white'
    },
    enableStyle:{
        width:"100%",
        height:"2.5rem",
        padding:"2px",
    },
    disableStyle:{
        width:"100%",
        height:"2.5rem",
        padding:"2px",
        color:"#bababa"
    }, 

}))

const BasicInfo = (props) => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();

    let first_name = props.user?.firstname;
    let last_name = props.user?.lastname;
    let user_email = props.user?.email;

    const [attachment, setAttachment] = useState(undefined);
    const [userFirstName, setUserFirstName] = useState(first_name);
    const [userLastName, setUserLastName] = useState(last_name);
    const [userEmail, setUserEmail] = useState(user_email);
    const [loading, setLoading] = useState(false);

    const isEmailVerified = props.user.is_email_verified;
    const {image}  = props.user || {}

    const setImageSrc = () => {

        if(attachment){
            return URL.createObjectURL(attachment);
        }
        else if(image){
            return image;
        }
        else return undefined;
    }
    
    const onProfilePhotoSelected = (e) => {
            const { target: { files } } = e;
            //var fileName = files[0].name;
            setAttachment(e.target.files[0]);
            var oFReader = new FileReader();
            oFReader.readAsDataURL(e.target.files[0]);
            oFReader.onload = function (oFREvent) {
                document.getElementById("userProfilePhoto").src = oFREvent.target.result;
            };
        }  

    const endAdornmentIcon = () => {
        return (
            <InputAdornment position="end">
                <Tooltip title={isEmailVerified ? "Email Verified" : "Verify Email"} arrow>
                    <IconButton disableRipple style={{color: isEmailVerified ? 'green' : 'red', padding:'0', background:"none"}}>
                        {isEmailVerified ? 
                            <CheckSharpIcon fontSize="medium" /> 
                            : 
                            <ClearIcon fontSize="small" style={{marginRight:'4px', fontWeight:'bold'}}/>
                        }
                    </IconButton>
                </Tooltip>
                
            </InputAdornment>
        )
    }    


    const onCancelProfileChanges = () => {
        //set firstname,lastname from redux to fields
        setUserFirstName(first_name);
        setUserLastName(last_name);
        setUserEmail(user_email);
    }

    const onUpdateClick = (e) => {
        e.preventDefault();

        const firstName = userFirstName;
        const lastName = userLastName;
        const email = userEmail;

        if (!firstName) {//check empty
            enqueueSnackbar('First name can not be empty!', {
                variant: 'error'
            });
            return;
        }
        const isFirstNameValid = nameValidator(firstName);
        if (!isFirstNameValid) {
            enqueueSnackbar('First name can not be empty!', {
                variant: 'error'
            });
            return;
        }

        const isLastNameValid = nameValidator(lastName);
        if (!lastName) {
            enqueueSnackbar('First name can not be empty!', {
                variant: 'error'
            });
            return;
        }
        if (!isLastNameValid) {
            enqueueSnackbar('Last name is not valid! Please enter only Alphabets', {
                variant: 'error'
            });
            return;
        }

        const isEmailValid = emailValidator(email);
        if (!isEmailValid) {
            return;
        }
        if (!email) {
            enqueueSnackbar('Email can not be empty!', {
                variant: 'error'
            });
            return;
        }


        //get image
        const image = attachment;
        console.log('image********************', image);

        setLoading(true);
        //update profile api call
        updateUserProfileApi(capitalizeFirstLetterOfEachWord(firstName),
            capitalizeFirstLetterOfEachWord(lastName), image)
                .then((response) => {
                    const res = response.data;
                    consoleToLog('Response updateUserProfileApi: ', res);
                    setAttachment(undefined);

                    setLoading(false);
                    enqueueSnackbar('User Profile Updated', {variant:'success'});
                    props.setUserObj(res);
                    trackGAEvent(props.selectedAccount.name, 'User Profile Updated', `${props.user.firstname} ${props.user.lastname}`);

                })
                .catch((e) => {
                    consoleToLog('Error updateUserProfileApi: ', e.response);
                    setLoading(false);
                    if(e.response.data && e.response.data.message) {
                        enqueueSnackbar(e.response.data.message, {variant:'error'});
                        return;
                    }
                })
    }  
    

        return (
            <Grid item container>
                    <Paper className={classes.tabPanelContainer}  elevation={0}>
                        <Grid item container direction="column">
                            <Grid item md={5}>
                            {image || attachment ?
                                    <div style={{display:'flex', justifyContent:'center', position:'relative'}}>
                                        <img className={classes.imgContainer}
                                            src={image ? image : "/images/eprocessify-icon.png"}
                                            id="userProfilePhoto"
                                            alt="userProfilePic"
                                        />
                                        <label className={classes.uploadImg}>
                                            <Typography  style={{marginLeft:'4px', marginTop:'4px'}}>
                                                <EditRoundedIcon fontSize="small" style={{fontSize:'18px'}}/>
                                            </Typography>
                                                <input style={{display:'none'}}
                                                    type="file"
                                                    id="userProfilePhoto"
                                                    accept="image/x-png,image/gif,image/jpeg"
                                                    onChange={onProfilePhotoSelected}
                                                    //disabled={disabled}
                                                />
                                        </label>
                                    </div>
                                    :
                                    <Avatar className={classes.avatarStyle}
                                        id="profilePhoto"
                                        alt="Profile Photo"
                                        src={setImageSrc()}
                                    >
                                    {props.user?.firstname.charAt(0).toUpperCase() +
                                    props.user?.lastname.charAt(0).toUpperCase()}
                                        <label className={classes.uploadImg1}>
                                            <Typography  style={{marginLeft:'4px', marginTop:'4px'}}>
                                                <EditRoundedIcon fontSize="small" style={{fontSize:'18px'}}/>
                                            </Typography>
                                                <input style={{display:'none'}}
                                                    type="file"
                                                    id="userProfilePhoto"
                                                    accept="image/x-png,image/gif,image/jpeg"
                                                    onChange={onProfilePhotoSelected}
                                                    //disabled={disabled}
                                                />
                                        </label>
                                </Avatar>
                                }
                            </Grid>  

                            <Grid item container direction="column" spacing={2} style={{marginTop:'4px'}}>
                                <Grid item md={5} lg={5}>
                                    <Typography component={'span'} variant="body2">
                                        First Name
                                    </Typography>

                                    <TextField 
                                        fullWidth
                                        variant="outlined"
                                        margin="dense"
                                        value={userFirstName}
                                        placeholder="Enter First Name"
                                        InputLabelProps={{style: {fontSize: 13, marginTop:'3px'}}}
                                        onChange={(e) => setUserFirstName(e.target.value)}
                                    />
                                </Grid> 

                                <Grid item md={5} lg={5}>
                                    <Typography component={'span'} variant="body2">
                                        Last Name
                                    </Typography>

                                    <TextField 
                                        fullWidth
                                        variant="outlined"
                                        margin="dense"
                                        value={userLastName}
                                        placeholder="Enter Last Name"
                                        InputLabelProps={{style: {fontSize: 13, marginTop:'3px'}}}
                                        onChange={(e) => setUserLastName(e.target.value)}
                                    />
                                </Grid>  

                                <Grid item md={5} lg={5}>
                                    <Typography component={'span'} variant="body2">
                                        Email
                                    </Typography>
                                    <TextField 
                                        fullWidth
                                        variant="outlined"
                                        margin="dense"
                                        value={userEmail}
                                        placeholder="Enter Email Address"
                                        InputLabelProps={{style: {fontSize: 13, marginTop:'3px'}}}
                                        onChange={(e) => setUserEmail(e.target.value)}
                                        disabled
                                    />
                                </Grid>  

                                <Grid item md={5} lg={5} style={{marginTop:'8px'}}>
                                    <Grid item container>
                                        <Button variant="contained" 
                                            color="primary" 
                                            style={{minWidth:'107px'}}
                                            onClick={onUpdateClick}
                                            >
                                            {loading && <CircularProgress size={24} className={classes.circularProgress}/>} 
                                            Save
                                        </Button>    

                                        <Button variant="outlined" 
                                            onClick={onCancelProfileChanges}
                                            color="primary" 
                                            style={{marginLeft:'8px', minWidth:'107px'}}>
                                            Reset
                                        </Button>
                                    </Grid>   
                                </Grid>
                            </Grid>    
                        </Grid>
                    </Paper>          
                </Grid>
    );
}

const mapStateToProps = (state) => ({
    accessToken: state.invoiceAuth,
    drawerState: state.drawerState.setDrawerState,
    selectedAccount: state.invoiceAccounts.selectedAccount,
    user: state.invoiceAccounts.user
});

const mapDispatchToProps = (dispatch) => ({
    setUserObj: (user_obj) => dispatch(setUserObj(user_obj))
});

export default connect(mapStateToProps, mapDispatchToProps)(BasicInfo);