import React, {memo} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Grid, Typography, Button, Box, Divider} from '@material-ui/core';
import IssueDateRangeFilterComponent from './filters/IssueDateRangeFilterComponent';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    entityBox: {
        padding: theme.spacing(2),
        borderRadius: '8px',
        backgroundColor: theme.palette.background.default,
        cursor: 'pointer',
        border: `1px solid ${theme.palette.divider}`,
        overflow: 'hidden',
        '&:hover': {
            backgroundColor: theme.palette.action.hover,
        },
        '&.selected': {
            backgroundColor: theme.palette.action.selected,
            border: `2px solid green`,
        },
        position:'relative',
    },
    selectedEntity: {
        backgroundColor: theme.palette.action.selected,
    },
    boldText: {
        fontWeight: 600,
        paddingTop: '24px'
    },
    imageStyles: {
        position:'absolute',
        right:'16px',
        bottom:'16px',
        width:'25px'
    }
}))


const LabelAndEntityComponent = memo(({heading, setDateRange, dateRange, entities, selectedEntities, handleEntityClick}) => {
    const classes = useStyles();

    return (
        <div>
            <Grid item container alignItems='center' justifyContent='space-between'>
                <Grid item md={5}>
                    <Typography className={'invoice_text_color'} variant="h5" gutterBottom>
                        {heading}
                    </Typography>
                </Grid>

                <Grid item md={5}>
                    <div style={{textAlign:'right', position:'relative', top:'-4px'}}>
                        <IssueDateRangeFilterComponent fromReportsComponent={true}
                            setDateRange={setDateRange}
                            dateRange={dateRange}/>
                    </div>
                </Grid>
            </Grid>

            <Box mt={2}>

                <Grid container spacing={2}>
                    {entities?.map((entity, index) => (
                    <Grid item xs={6} key={index}>
                        <Box
                            className={clsx(classes.entityBox, { selected: selectedEntities.includes(entity) })}
                            onClick={() => handleEntityClick(entity)}
                            style={{position:'relative'}}
                        >
                            <Typography className='bill_label'>
                                Bill from:
                            </Typography>
                            <Typography className='client_owner_name'>{entity.name}</Typography>
                            <Typography variant="body2">{entity.address}</Typography>
                            <Typography variant="body2">{entity.country}, {entity.state}</Typography>
                            <Typography variant="body2">{entity.email}</Typography>
                            <Typography variant="body2">{entity.phone_number}</Typography>

                            {selectedEntities.includes(entity) && 
                                <div className="checkmark">
                                    <span style={{position:'relative', top:'-6px', right:'0px', fontSize:'15px'}}>
                                        ✔
                                    </span>
                                </div>}
                        </Box>
                    </Grid>
                    ))}
                </Grid>
            </Box>
            <Divider variant='dark' style={{margin:'24px 0px 16px 0px'}}/>
        </div>
    );
});

export default memo(LabelAndEntityComponent);