import React, {useState, createRef, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import InvoiceListComponent from './InvoiceListComponent';
import CreateUpdateInvoiceComponent from './CreateUpdateInvoiceComponent';
import {connect} from 'react-redux';
import PreviewAndSendInvoiceComponent from './PreviewAndSendInvoiceComponent';
import { useIsMount } from './useIsMount';
import {SendEmailType} from '../actions/invoiceAccount';
import {AccessLevel} from '../util/AppUtil';

const useStyles = makeStyles((theme) => ({
    mainContent : {
        width: (showDrawer) => {
            return showDrawer ? "83%" : "100%"; 
        },
        backgroundColor: theme.palette.primary.light, 
        transition: ".5s", 
        flexGrow: 1,
        //overflow:'hidden',
        overflow:'auto',
        overflowX:'hidden'
        },
    }
));    


const InvoicesComponent = (props) => {
    const {showDrawer} = props;
    const classes = useStyles(showDrawer);
    const isMount = useIsMount();

    const myDivToFocus = createRef();
    
    const [createInvoice, setCreateInvoice] = useState(false);
    const [customerObj, setCustomerObj] = useState(undefined);
    const [apiResponse, setApiResponse] = useState(undefined);
    const [showPreviewAndSend, setShowPreviewAndSend] = useState(undefined);
    const is_email_verified = props.selectedAccount?.is_email_verified;

    const { access_level } = props.selectedAccount || {};
    const accessLevelViewer = (AccessLevel.getUserAccessLevelValue(access_level) === AccessLevel.VIEWER) ? true : false;

    const onCustomerItemClicked = (item) => {
        setCreateInvoice(true);
        setCustomerObj(item);
    }

    useEffect(() => {
        document.title = `Invoices - ${props.fullName}`;
    }, []);

    useEffect(() => {
            if(myDivToFocus && myDivToFocus.current) {
                myDivToFocus.current.scrollTo(0,0)
                window.scrollTo(0, 0);
            
            }
    }, [myDivToFocus, props.invoiceObj]);

    return (
        <div className={classes.mainContent} ref={myDivToFocus}
            style={{height: props.is_entity_email_unverified && !accessLevelViewer ? 'calc(100vh - 105px)' : 'calc(100vh - 48px)'}}
        >
            {props.showCreateInvoice || Object.keys(props.editInvoiceObj).length !== 0 ||  Object.keys(props.invoiceObj).length !== 0 ?
                Object.keys(props.invoiceObj).length !== 0 || (props.invoiceObj && props.showPreviewInvoiceScreen) || 
                (props.invoiceObj && props.showSendInvoiceScreenType !== SendEmailType.NONE) 
                ? 
                <PreviewAndSendInvoiceComponent apiResponse={apiResponse}
                    setApiResponse={setApiResponse}/> 
                :
                <CreateUpdateInvoiceComponent setApiResponse={setApiResponse}
                    setShowPreviewAndSend={setShowPreviewAndSend}
                />
                :
                <InvoiceListComponent setApiResponse={setApiResponse}
                    handleOpenAddPaymentDrawer={props.handleOpenAddPaymentDrawer}
                    paymentInvoiceObj={props.paymentInvoiceObj}
                    setPaymentInvoiceObj={props.setPaymentInvoiceObj}/>
            }
        </div>
    
    );
}

const mapStateToProps = (state) => ({
    showCreateInvoice: state.invoiceAccounts.showCreateInvoice,
    invoiceObj: state.invoiceAccounts.invoiceObj,
    showPreviewInvoiceScreen: state.invoiceAccounts.showPreviewInvoiceScreen,
    showSendInvoiceScreenType: state.invoiceAccounts.showSendInvoiceScreenType,
    editInvoiceObj: state.invoiceAccounts.editInvoiceObj,
    selectedAccount: state.invoiceAccounts.selectedAccount
})

export default connect(mapStateToProps)(InvoicesComponent);