import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import { Typography, Grid, TextField, Button, CircularProgress } from '@material-ui/core';
import {addTaxPresetApi} from '../services/authService';
import {connect} from 'react-redux';
import {setSelectedInvoiceAccount, setClearInvoiceAccounts} from '../actions/invoiceAccount';
import { consoleToLog } from '../util/AppUtil';
import { useSnackbar } from 'notistack';
import RightToLeftSideDrawer from './RightToLeftSideDrawer';
import { logout } from '../actions/invoiceAuth';
import { clearSelectedInvoiceFilters } from '../actions/invoiceFilters';
import { setSelectedDrawerState } from '../actions/drawer';
import { clearSelectedTransactionFilters } from "../actions/transactionFilters";
import {trackGAEvent} from '../util/AppUtil';
import { setInvoiceAccountsList } from '../actions/invoiceAccount';
 
const useStyles = makeStyles((theme) => ({
    drawer: {
    //    width: drawerWidth,
        flexShrink: 0
    },
    drawerPaper: {
        width: '33%',
        //height: 'calc(100vh - 51px)',
        flexGrow:1,
        top:'51px',
    },
    appBar: {
        background:'#F9F9F9',
        height:'45px',
        color:'#202020'
    },
    customMarginTop : {
        marginTop:"16px"
    },
    customMarginBottom : {
        marginBottom:"-8px"
    }
}));

const CreateTaxPresetDrawer = (props) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

        const [taxPresetName, setTaxPresetName] = useState('');
        const [taxPresetRate, setTaxPresetRate] = useState(0);
        const [errorNo, setErrorNo] = useState(undefined);
        const [errorMsg, setErrorMsg] = useState('');
        const [loading, setLoading] = useState(false);

        const onCreateTaxPreset = () => {
            const name = taxPresetName;
            if(name === '') {
                setErrorNo(0);
                setErrorMsg('* Please enter tax preset name');
                return;
            }

            const rate = Number(taxPresetRate);
            if(rate === 0) {
                setErrorNo(1);
                setErrorMsg('* Please enter tax preset rate');
                return;
            }

            const invoice_account_id = props.selectedAccount?.id;

            setLoading(true);
            addTaxPresetApi(invoice_account_id, name, rate)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response addTaxPresetApi: ', res);
                setLoading(false);
                enqueueSnackbar('Tax Preset added successfully', { variant: 'success' });
                setErrorMsg('');
                setErrorNo(undefined);

                let account = {
                    ...props.selectedAccount,
                    tax_presets: [res, ...props.selectedAccount?.tax_presets]
                };

                let accountList = props.invoiceAccountsList.map((ia)=>{
                    if(ia.id === account.id) return account;
                    else return ia;
                });

                props.setInvoiceAccountsList(accountList);
                props.setSelectedInvoiceAccount(account);

                // let object = props.selectedAccount;
                // if(object.tax_presets && object.tax_presets.length > 0) {
                //     object.tax_presets = [res, ...object.tax_presets];
                // } else {
                //     object.tax_presets = [];
                //     object.tax_presets?.push(res);
                // }
                
                //props.setSelectedInvoiceAccount(object);
                props.setTaxPresetObj && props.setTaxPresetObj(res);
                onCancelClick();

                trackGAEvent(props.selectedAccount.name, 'Tax Preset created successfully', `${props.user.firstname} ${props.user.lastname}`)

            })
            .catch((e) => {
                consoleToLog('Error addTaxPresetApi: ', e);
                setLoading(false);
                if(e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {variant: 'error'});
                    return;
                }
            });
        }

        const onCancelClick = () => {
            setTaxPresetName('');
            setTaxPresetRate(0);
            setErrorNo(undefined);
            setErrorMsg('');
            props.handleDrawerClose();
        }

        return(
            <RightToLeftSideDrawer title="New Tax Preset" 
                openDrawer={props.openDrawer}
                closeDrawer={onCancelClick}
                fromOnboardingComponent={props.fromOnboardingComponent}>

                <Grid item container style={{padding:'16px'}}>
                    <Grid item md={12} lg={12}>
                        <Typography variant="subtitle1" 
                            className={classes.customMarginBottom}>
                            Tax Preset Name
                        </Typography>

                        <TextField fullWidth 
                            id="tax_name" 
                            variant="outlined"
                            type="text"
                            margin="dense"
                            value={taxPresetName}
                            autoComplete="off"
                            placeholder="Enter tax preset name"
                            InputLabelProps={{style: {fontSize: 13, color: "#ccc"}}}
                            onChange={(e) => {
                                setTaxPresetName(e.target.value)
                                setErrorNo(undefined);
                                setErrorMsg('');
                            }}
                            error={errorNo === 0}
                            helperText={errorNo === 0 ? errorMsg : ''}
                        />
                    </Grid>    


                    <Grid item md={12} lg={12} 
                        className={classes.customMarginTop}>
                        <Typography variant="subtitle1" 
                            className={classes.customMarginBottom}>
                            Tax Preset rate
                        </Typography>   

                        <TextField fullWidth 
                            id="tax-rate" 
                            variant="outlined"
                            type="number"
                            margin="dense"
                            autoComplete="off"
                            value={taxPresetRate}
                            //placeholder="Mobile Number"
                            InputLabelProps={{style: {fontSize: 13, color: "#ccc"}}}
                            onChange={(e) => {
                                let input=e.target.value;
                                if (input === "" || /^\d+(\.\d{0,2})?$/.test(input)) {
                                    setTaxPresetRate(input);
                                    setErrorNo(undefined);
                                    setErrorMsg('');
                                }
                            }}
                            error={errorNo === 1}
                            helperText={errorNo === 1 ? errorMsg : ''}
                        />
                    </Grid> 

                    <div className="modal__footer">
                        <Button variant="contained" color="primary" 
                            onClick={onCreateTaxPreset}
                            disabled={loading}
                            className="modal__button">
                            {loading && <CircularProgress size={15} className='loading__style'/>}
                            Save    
                        </Button>
                        <Button variant="outlined" style={{
                            minWidth: '130px',
                        }} onClick={onCancelClick}>
                            Cancel
                        </Button>
                    </div> 
                </Grid>

            </RightToLeftSideDrawer>
            );
    }

const mapStateToProps = (state) => ({
    accessToken: state.invoiceAuth,
    selectedAccount: state.invoiceAccounts.selectedAccount,
    user: state.invoiceAccounts.user,
    invoiceAccountsList: state.invoiceAccounts.invoiceAccountsList
});

const mapDispatchToProps = (dispatch) => ({
    setSelectedInvoiceAccount: (account) => dispatch(setSelectedInvoiceAccount(account)),
    logout: () => dispatch(logout()),
    clearSelectedInvoiceFilters: () => dispatch(clearSelectedInvoiceFilters()),
    setClearInvoiceAccounts: () => dispatch(setClearInvoiceAccounts()),
    setSelectedDrawerState: (drawerState) => dispatch(setSelectedDrawerState(drawerState)),
    clearSelectedTransactionFilters: () => dispatch(clearSelectedTransactionFilters()),
    setInvoiceAccountsList: (accountList) => dispatch(setInvoiceAccountsList(accountList))
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateTaxPresetDrawer);