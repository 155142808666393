import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles'; 
import {Grid, TextField, Typography, Button, CircularProgress, MenuItem, Select, Divider, Tooltip, InputAdornment} from '@material-ui/core';
import {connect} from 'react-redux';
import { setSelectedInvoiceAccount, setInvoiceAccountsList, setClearInvoiceAccounts } from '../actions/invoiceAccount';
import { setSelectedDrawerState } from '../actions/drawer';
import { consoleToLog, invoicePrefixOptions, startMonthOptions, showPrefixExampleValue, InvoiceEntityApi, trackGAEvent } from '../util/AppUtil';
import {getInvoiceAccountsEntityListApi} from '../services/authService';
import { useSnackbar } from 'notistack';
import {invoiceAccountUpdateApi, updateEntitiesDetailsApi} from '../services/authService';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

const useStyles = makeStyles((theme) => ({
    containerStyles: {
        width:'100%',
        padding:"16px"
    },
    customMarginButton: {
        marginBottom:'-8px'
    },
    entityListStyle: {
        padding:'8px 16px',
        background:'#f7f7f7',
        borderRadius:'5px',
        marginBottom:'8px',
        '& .entity_name' : {
            fontSize:'15px',
            fontWeight: 500
        },
        '& .entity_email' : {
            fontSize:'13px',
            color:'#888'
        },

    },
    textFieldStyle: {
        background:'#fff'
    },
    labelStyle: {
        marginBottom: '8px',
    },
    infoIcon: {
        position:'relative',
        top:'3px',
        marginLeft:'8px', 
        fontSize:'16px',
        cursor:'pointer'
    }
})) 

const OnboardingNumberingSetup = (props) => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();

    const [formatValue, setFormatValue] = useState(props.selectedAccount?.sequence_format);
    const [exampleValue, setExampleValue] = useState(props.selectedAccount?.sequence_format);
    const [monthValue, setMonthValue] = useState(props.selectedAccount?.financial_year_start_month);
    const [loading, setLoading] = useState(false);
    const [apiLoading, setApiLoading] = useState(false);
    const [entityList, setEntityList] = useState([]);

    useEffect(() => {
        getEntityList();
    }, [])

    const getEntityList = () => {
        const invoice_account_id = props.selectedAccount?.id;
        const apiPath = InvoiceEntityApi.ACTIVE

        setLoading(true);
        getInvoiceAccountsEntityListApi(invoice_account_id, apiPath)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response getInvoiceAccountsEntityListApi: ', res);
                setLoading(false);

                setEntityList(res);
                let account = {
                    ...props.selectedAccount,
                    entities: res
                };

                let accountList = props.invoiceAccountsList.map((ia)=>{
                    if(ia.id === account.id) return account;
                    else return ia;
                });
                props.setInvoiceAccountsList(accountList);
                props.setSelectedInvoiceAccount(account);

            })
            .catch((e) => {
                consoleToLog("getInvoiceAccountsEntityListApi error", e.response);
                setLoading(false);
                if (e.response.data && e.response.data.message) {
                    return false;
                }
            });
    }

    const handleFormatChangeValue = (e) => {
        let value = e.target.value;
        setFormatValue(value);
        setExampleValue(value);
    }

    const handleMonthChange = (e) => {
        setMonthValue(e.target.value);
    }

    const updateEntityPrefix = (e, index) => {
        const updatedEntityList = entityList;

        updatedEntityList[index]['prefix'] = e.target.value;
        setEntityList([...updatedEntityList]);
    }

    const updateEntityNextInvoiceNumber = (e, index) => {
        const updatedEntityList = entityList;

        updatedEntityList[index]['next_invoice_number'] = e.target.value;
        setEntityList([...updatedEntityList]);
    }

    const compareAndShowValues = () => {
        if(formatValue === `{{prefix}}-{{MMM-YY}}-{{number}}`) {
            return `{{prefix}}-{{MMM_YY}}-{{number}}`
        } else {
            return formatValue;
        }
    }

    const onSaveClick = () => {
        const name = props.selectedAccount?.name;
        const address = props.selectedAccount?.address;
        const selectedCountry = props.selectedAccount?.country;
        const selectedState = props.selectedAccount?.state;
        const email = props.selectedAccount.email ? props.selectedAccount.email : '';
        const mobile = props.selectedAccount.mobile ? props.selectedAccount.mobile : '';
        const tax_id = props.selectedAccount.tax_id ? props.selectedAccount.tax_id : '';
        const default_client_notes = props.selectedAccount.default_client_notes ? props.selectedAccount.default_client_notes : '';
        const default_payment_terms = props.selectedAccount.default_payment_terms ? props.selectedAccount.default_payment_terms : '';
        const image = '';
        const prefix = props.selectedAccount?.prefix;

        const format = compareAndShowValues();
        if(format === '-1') {
            enqueueSnackbar('Please select format', {variant:'error'});
            return
        }

        const financial_year_start_month = monthValue;
        if(financial_year_start_month === -1) {
            enqueueSnackbar('Please select ffinancial year start month', {variant:'error'});
            return
        }

        const invoice_account_id = props.selectedAccount?.id;
        const currency = props.selectedAccount.currencies?.id;
        const onboarding = false;

        const apiStr = 'entities/update_details';

        const entities = entityList.map((entityItem) => {
            return {
                entity_id: entityItem.id,
                next_invoice_number: Number(entityItem.next_invoice_number),
                prefix: entityItem.prefix
            }
        })

        
        setApiLoading(true);
        callInvoiceAccountUpdateApi(invoice_account_id, name, address, selectedCountry, 
            selectedState, email, mobile, tax_id, default_client_notes, default_payment_terms, image, prefix, format, 
            financial_year_start_month, currency, onboarding, entities, apiStr);

    }

    const updateListAndSelectedAccount = (account) => {
        
        let accountList = props.invoiceAccountsList.map((ia)=>{
            if(ia.id === account.id) return account;
            else return ia;
        });
        props.setSelectedInvoiceAccount(account);
        props.setInvoiceAccountsList(accountList);
    }

    const callInvoiceAccountUpdateApi = async(invoice_account_id, name, address, selectedCountry, 
        selectedState, email, mobile, tax_id, default_client_notes, default_payment_terms, image, prefix, format, 
        financial_year_start_month, currency, onboarding, entities, apiStr) => {


            try {

                const response = await invoiceAccountUpdateApi(invoice_account_id, name, address, selectedCountry, 
                    selectedState, email, mobile, tax_id, default_client_notes, default_payment_terms, image, prefix, format, 
                    financial_year_start_month, currency, onboarding);
                    const res = response.data;
                    consoleToLog("Response invoiceAccountUpdateApi: ", res);
                    
                    callUpdateEntityDetailsApi(invoice_account_id, entities, apiStr, res);
                    
                    //enqueueSnackbar("Invoice numbering settings updated", {variant: 'success'});
                    trackGAEvent(props.selectedAccount.name, 'Invoice Account Setting Updated', `${props.user.firstname} ${props.user.lastname}`);

            } catch(e) {
                consoleToLog("invoiceAccountUpdateApi error", e);
                    callUpdateEntityDetailsApi(invoice_account_id, entities, apiStr, props.selectedAccount);
                    if (e.response.data && e.response.data.message) {
                        enqueueSnackbar(e.response.data.message, {
                            variant:'error'
                        });
                        return false;
                    }
            }
    }

    const callUpdateEntityDetailsApi = async(invoice_account_id, entities, apiStr, accountObj) => {

        try {

            const response = await updateEntitiesDetailsApi(invoice_account_id, entities, apiStr);
            const res = response.data;
            consoleToLog('Response updateInvoicePrintSettingsApi: ', res);
            
            setApiLoading(false);
            if(res.entities && res.entities.length === 0) {
                //enqueueSnackbar("Entity Details updated", {variant: 'success'});
            } else {
                if(res.error_list && res.error_list.length > 0) {
                    res.error_list.map((error) => {
                        enqueueSnackbar(error, {variant: 'error'});
                    })
                }
            }
            const updatedEntityList = entityList.map((entityItem) => {
                res.entities.map((entityObj) => {
                    if(entityItem.id === entityObj.id) {
                            return {
                                ...entityItem,
                                prefix: entityObj.prefix,
                                next_invoice_number:  Number(entityObj.next_invoice_number),
                            }
                        }
                    return entityItem;
                });
                return entityItem;
            });
            setEntityList(updatedEntityList);
            
                let account = {
                    ...props.selectedAccount,
                    prefix: accountObj.prefix,
                    sequence_format: accountObj.sequence_format,
                    financial_year_start_month: accountObj.financial_year_start_month,
                    entities: updatedEntityList
                };
                updateListAndSelectedAccount(account);
            
            trackGAEvent(props.selectedAccount.name, 'Invoice Entities details updated', `${props.user.firstname} ${props.user.lastname}`);

        } catch(e) {
            consoleToLog('Error updateInvoicePrintSettingsApi: ', e.response);
            setApiLoading(false);
            if(e.response.data && e.response.data.message) {
                enqueueSnackbar(e.response.data.message, {variant: 'error'});
                return;
            }
        }
    }

    return (
        <div className={classes.containerStyles}>
            <Grid item md={12}>

                    <Grid item md={6} >
                    <Typography className={classes.labelStyle}>
                        Invoice Prefix And Number
                    </Typography>
                        {
                            loading ?
                            <CircularProgress size={18}  style={{margin:'16px 0px 0px 32px'}}/>
                            :
                            entityList && entityList.length > 0 ?
                            entityList.map((entityItem, index) => {
                                return <Grid item container direction='column'  
                                            key={entityItem.id}
                                            className={classes.entityListStyle}>
        
                                            <Grid item md={12}>
                                                <Typography className='entity_name'>
                                                    {entityItem.name}
                                                </Typography>
                                            </Grid>
        
                                            <Grid item md={12}>
                                                <Grid item container alignItems='center' spacing={2}>
                                                    <Grid item md={6}>
                                                        <TextField variant='outlined'
                                                            fullWidth
                                                            type='text'
                                                            margin='dense'
                                                            placeholder='Enter Prefix'
                                                            value={entityItem.prefix}
                                                            classes={{
                                                                root: classes.textFieldStyle
                                                            }}
                                                            onChange={(e) => updateEntityPrefix(e, index)}
        
                                                        />
                                                    </Grid>
                                                    <Grid item md={6}>
                                                        <TextField variant='outlined'
                                                            fullWidth
                                                            type='text'
                                                            margin='dense'
                                                            placeholder='Enter Next Invoice Number'
                                                            classes={{
                                                                root: classes.textFieldStyle
                                                            }}
                                                            value={entityItem.next_invoice_number}
                                                            onChange={(e) => updateEntityNextInvoiceNumber(e, index)}

                                                            InputProps={{
                                                
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <Tooltip title={`You next invoice number starts from here.`} arrow>
                                                                        <HelpOutlineIcon
                                                                            style={{
                                                                            cursor: "pointer",
                                                                            fontSize: "16px",
                                                                        }}
                                                                        />
                                                                    </Tooltip>
                                                                </InputAdornment>
                                                            ),
                                                            }}
                                                            
                                                        />  
                                                    </Grid>
                                                </Grid>
                                            </Grid>
        
                                        </Grid>
                            }) 
                            :
                            <Grid item container justifyContent='center'>
                                <Typography variant='body1'>
                                    No Entities Found
                                </Typography>
                            </Grid>
                        }
                    </Grid>

                    <Grid item md={6}>
                        <Divider style={{margin:"16px 0px"}}/>
                    </Grid>
                    
                    <Grid item md={6}>
                        <Typography className={classes.labelStyle}>
                            Invoice Numbering Format
                        </Typography>

                        <Grid item sm={12}>
                            <Typography variant='subtitle1'>
                                Format
                            </Typography>
                            <Select variant='outlined'
                                fullWidth
                                margin='dense'
                                value={formatValue}
                                onChange={handleFormatChangeValue}
                                renderValue={(value) => (invoicePrefixOptions.includes(value) ? value : `${value}`)}
                            >
                                <MenuItem value='-1'>Please Select Format</MenuItem>

                                {invoicePrefixOptions.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </Select>
                            {exampleValue &&
                            <Typography variant='body1'>
                                {showPrefixExampleValue(exampleValue, props.selectedAccount.prefix)}
                            </Typography>
                            }
                        </Grid>
                        
                        <Grid item sm={12} style={{marginTop:'16px'}}>
                            <Typography variant='subtitle1'
                                className={classes.customMarginButton}>
                                Financial Year Start Month

                                <Tooltip title={`Every year on start of this month, your invoice number will reset and start from 1`} arrow>
                                    <HelpOutlineIcon fontSize='small' className={classes.infoIcon}/>
                                </Tooltip>
                            </Typography>
                            <TextField fullWidth
                                placeholder='prefix'
                                variant='outlined'
                                margin='dense'
                                inputProps={{maxLength :3}}
                                value={monthValue}
                                onChange={handleMonthChange}
                                select
                            >
                                {
                                    <MenuItem value={-1}>Please Select Start Month</MenuItem>
                                }
                                {
                                    startMonthOptions.map((val) => {
                                        return <MenuItem key={val.name} value={val.value}>
                                                    {val.name}
                                                </MenuItem> 
                                    })
                                }
                                
                            </TextField>
                        </Grid>
                    </Grid>

                    <Grid item md={2} style={{marginTop:'16px'}}>
                        <Button 
                            onClick={onSaveClick}
                            variant='contained' color='primary'>
                            Save
                            {apiLoading && <CircularProgress size={18} style={{marginLeft:'8px', color:'#fff'}}/>}
                        </Button>
                    </Grid>

            </Grid>
        </div>
    );
}

const mapStateToProps = (state) => ({
    selectedAccount: state.invoiceAccounts.selectedAccount,
    invoiceAccountsList: state.invoiceAccounts.invoiceAccountsList,
    user: state.invoiceAccounts.user
});

const mapDispatchToProps = (dispatch) => ({
    setSelectedInvoiceAccount: (account) => dispatch(setSelectedInvoiceAccount(account)),
    setInvoiceAccountsList: (list) => dispatch(setInvoiceAccountsList(list)),
    setSelectedDrawerState: (drawerState) => dispatch(setSelectedDrawerState(drawerState)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingNumberingSetup);