import moment from 'moment';



const defaultState = {
    paymentStartDateFilter: getDefaultStartDate(),
    paymentEndDateFilter: getDefaultEndDate(),

    selectedMethodTransactionFilter: [],
    transactionClientsFilter: [],

}

function getDefaultStartDate() {
    return (moment().month() < 3) ? 
        moment().subtract(1, 'year').month('April').startOf('month').format('YYYY-MM-DD')
        : 
        moment().month('April').startOf('month').format('YYYY-MM-DD'); 
}

function getDefaultEndDate() {
   return (moment().month() < 3) ? 
        moment().month('March').endOf('month').format('YYYY-MM-DD')
        : 
        moment().add(1, 'year').month('March').endOf('month').format('YYYY-MM-DD');
}


export default (state = defaultState, action) => {
    switch(action.type) {
        
        case 'SET_PAYMENT_START_DATE_FILTER' :
            return {
                ...state,
                paymentStartDateFilter : action.start_date
            }
        
        case 'SET_PAYMENT_END_DATE_FILTER' :
            return {
                ...state,
                paymentEndDateFilter : action.end_date
            }  
            
        case 'ADD_SELECTED_METHOD_TO_TRANSACTION_FILTER':
            return {
                ...state,
                selectedMethodTransactionFilter: [
                    ...state.selectedMethodTransactionFilter,
                    action.method
                ]
            }
        
        case 'REMOVE_SELECTED_METHOD_FROM_TRANSACTION_FILTER':
            return {
                ...state,
                selectedMethodTransactionFilter: state.selectedMethodTransactionFilter.filter((m) => m !== action.method)
            }   

        //company filter
        case 'ADD_SELECTED_CLIENT_TO_TRANSACTION_FILTER':
            return {
                ...state,
                transactionClientsFilter: [
                    ...state.transactionClientsFilter,
                    action.client
                ]
            }

        case 'REMOVE_SELECTED_CLIENT_FROM_TRANSACTION_FILTER':
            return {
                ...state,
                transactionClientsFilter: state.transactionClientsFilter.filter((client) => client.id !== action.client.id)
            }    

        //clear filters
        case 'CLEAR_SELECTED_TRANSACTION_FILTERS' :
            return defaultState     
        
        default:
            return state;
    }
}