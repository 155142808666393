import React, {useState, useEffect} from 'react';
import RightToLeftSideDrawer from './RightToLeftSideDrawer';
import {Grid, Typography, Button, TextField, CircularProgress, Select, MenuItem} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { showAllCountries, showAllStates, consoleToLog, capitalizeFirstLetterOfEachWord, trackGAEvent} from '../util/AppUtil';
import { orgNameValidator, emailValidator } from '../util/validator';
import {invoiceAccountUpdateApi} from '../services/authService'
import {setInvoiceAccountsList} from '../actions/invoiceAccount';
import {connect} from 'react-redux';
import { useSnackbar } from 'notistack';
import { setSelectedInvoiceAccount } from '../actions/invoiceAccount';
import UpdateInvoiceAccount from './onboarding/UpdateInvoiceAccount';

const useStyles = makeStyles((theme) => ({
    customMarginTop : {
        margin:'16px 0px 0px 0px'
    },
    enableStyle:{
        width:"100%",
        height:"2.5rem",
        padding:"2px",
    },
    disableStyle:{
        width:"100%",
        height:"2.5rem",
        padding:"2px",
        color:"#bababa"
    },
}))

const CreateInvoiceAccountDrawer = (props) => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();

    const [loading, setLoading] = useState(false);
    const [invoiceAccountName, setInvoiceAccountName] = useState('');
    const [invoiceAccountEmail, setInvoiceAccountEmail] = useState('');
    const [selectedCountry, setSelectedCountry] = useState('IN');
    const [selectedState, setSelectedState] = useState('none');
    const [callApi, setCallApi] = useState(false);


    const onClientCountryChange = (e) => {
        setSelectedCountry(e.target.value);
    }

    const onClientStateChange = (e) => {
        setSelectedState(e.target.value);
    }

    const onSaveClick = () => {
        setCallApi(true);
        // const invoice_account_name = invoiceAccountName;
        // if (!invoice_account_name) {
        //     enqueueSnackbar('Invoice account name can not be empty!', {
        //         variant:'error'
        //     });
        //     return;
        // }

        // const isInvoiceAccountNameValid = orgNameValidator(invoice_account_name);
        // if (!isInvoiceAccountNameValid) {
        //     enqueueSnackbar('Invoice account name is not valid! Please enter Alphabets only', {
        //         variant:'error'
        //     });
        //     return;
        // }
        // const address = props.selectedAccount?.address;
        // const selectedCountry = props.selectedAccount?.country;
        // const selectedState = props.selectedAccount?.state;
        // const email = props.selectedAccount.email ? props.selectedAccount.email : '';
        // const mobile = props.selectedAccount.mobile ? props.selectedAccount.mobile : '';
        // const tax_id = props.selectedAccount.tax_id ? props.selectedAccount.tax_id : '';
        // const default_client_notes = props.selectedAccount.default_client_notes ? props.selectedAccount.default_client_notes : '';
        // const default_payment_terms = props.selectedAccount.default_payment_terms ? props.selectedAccount.default_payment_terms : '';
        // const image = '';
        // const prefix = props.selectedAccount.prefix;
        // const sequence_format = props.selectedAccount.sequence_format;
        // const financial_year_start_month = props.selectedAccount.financial_year_start_month;

        // const invoice_account_email = invoiceAccountEmail;
        // if (!invoice_account_email) {
        //     enqueueSnackbar('Invoice account email can not be empty!', {
        //         variant:'error'
        //     });
        //     return;
        // }

        // const isInvoiceAccountEmailValid = emailValidator(invoice_account_email);
        // if (!isInvoiceAccountEmailValid) {
        //     enqueueSnackbar('Email is not valid!', {
        //         variant:'error'
        //     });
        //     return;
        // }

        // if(selectedCountry === 'none' || selectedCountry === '' || selectedCountry === undefined) {
        //     enqueueSnackbar('Please Select the Country', {
        //         variant: 'error'
        //     });
        //     return;
        // }

        // if(selectedCountry !== 'IN') {
        //     enqueueSnackbar('Please Select India as Country', {
        //         variant: 'error'
        //     });
        //     return;
        // }
    
        // if(selectedState === 'none' || selectedState === '' || selectedState === undefined) {
        //     enqueueSnackbar('Please Select the State', {
        //         variant: 'error'
        //     });
        //     return;
        // }


        // const invoice_account_id = props.accountObj?.id;
        
        // setLoading(true);
        // invoiceAccountUpdateApi(invoice_account_id, capitalizeFirstLetterOfEachWord(invoice_account_name), address, selectedCountry, 
        //     selectedState, email, mobile, tax_id, default_client_notes, default_payment_terms, image, prefix, sequence_format, financial_year_start_month)
        //     .then((response) => {
        //         const res = response.data;
        //         consoleToLog('Response invoiceAccountUpdateApi', res);
        //         setLoading(false);

        //         enqueueSnackbar('Invoice Account updated successfully', {variant:'success'});

        //         let account = {
        //             ...props.selectedAccount,
        //             name: res.name
        //         }

        //         let accountList = props.invoiceAccountsList.map((ia)=>{
        //             if(ia.id === account.id) return account;
        //             else return ia;
        //         });

        //         props.setInvoiceAccountsList(accountList);
        //         props.setSelectedInvoiceAccount(account)
        //         props.handleDrawerClose();
        //         trackGAEvent(props.selectedAccount.name, 'Invoice Account Updated Successfully', `${props.user.firstname} ${props.user.lastname}`);


        //     })
        //     .catch((e) => {
        //         consoleToLog('Error invoiceAccountUpdateApi', e.response);
        //         setLoading(false);
        //         if(e.response.data && e.response.data.message) {
        //             enqueueSnackbar(e.response.data.message, {variant:'error'});
        //             return;
        //         }
            
        //     })

        
        
    }

    return (
        <RightToLeftSideDrawer title='Update Account'
            openDrawer={props.openDrawer}
            closeDrawer={props.handleDrawerClose}>

                <div style={{marginTop:"16px"}}>
                    <UpdateInvoiceAccount fromCreateInvoice={true}
                        accountObj={props.accountObj}
                        callApi={callApi}
                        setCallApi={setCallApi}
                        handleDrawerClose={props.handleDrawerClose}
                    />
                </div>
            {/* <Grid item container style={{padding:'16px'}}>
                <Grid item md={12} lg={12}>
                    <Typography variant="subtitle1" style={{marginBottom:'-8px'}}>
                        Invoice Account Name
                    </Typography>

                    <TextField 
                        fullWidth
                        variant="outlined"
                        margin="dense"
                        type="text"
                        value={invoiceAccountName}
                        placeholder="Enter Invoice Account Name"
                        InputLabelProps={{style: {fontSize: 13, marginTop:'3px'}}}
                        onChange={(e) => setInvoiceAccountName(e.target.value)}
                    />
                </Grid> 

                <Grid item md={12} lg={12} 
                    className={classes.customMarginTop}>
                    <Typography  variant="subtitle1" style={{marginBottom:'-8px'}}>
                        Enter Invoice Account Email
                    </Typography>

                    <TextField 
                        fullWidth
                        variant="outlined"
                        margin="dense"
                        type="text"
                        value={invoiceAccountEmail}
                        placeholder="Enter Invoice Account Email"
                        InputLabelProps={{style: {fontSize: 13, marginTop:'3px'}}}
                        onChange={(e) => setInvoiceAccountEmail(e.target.value)}  
                    />
                </Grid>

                <Grid item md={12} lg={12} 
                    className={classes.customMarginTop}>
                    <Typography variant="subtitle1">
                        Country
                    </Typography>   

                    <Select 
                        className={selectedCountry === 'none' ? classes.disableStyle : classes.enableStyle}
                        value={selectedCountry}
                        onChange={onClientCountryChange}
                        variant="outlined"
                        placeholder='Select Country'
                        autoWidth
                        //disabled={disabled}
                        >
                        {
                            selectedCountry === 'none' && <MenuItem value='none'>Select Country</MenuItem>
                        } 
                        {showAllCountries()}
                    </Select>
                </Grid>

                <Grid item md={12} lg={12} 
                    className={classes.customMarginTop}>
                    <Typography variant="subtitle1">
                        State
                    </Typography>   

                    <Select 
                        className={selectedState === 'none' ? classes.disableStyle : classes.enableStyle}
                        value={selectedState}
                        onChange={onClientStateChange}
                        variant="outlined"
                        placeholder='Select State'
                        autoWidth
                        disabled={selectedCountry === 'none' || selectedCountry !== 'IN'}
                    >
                    {
                        selectedState === 'none' && <MenuItem value='none'>Select State</MenuItem>
                    } 
                    {showAllStates()}
                    </Select>
                </Grid> 
            </Grid> */}
        

            <div className="modal__footer">
                <Button variant="contained" color="primary" 
                    onClick={onSaveClick}
                    className="modal__button">
                    {loading && <CircularProgress size={15} className='loading__style'/>}
                    Update
                </Button>
                <Button variant="outlined" style={{
                    minWidth: '130px',
                }} onClick={props.handleDrawerClose}>
                    Cancel
                </Button>
            </div>
        </RightToLeftSideDrawer>
    );
}

const mapStateToProps = (state) => ({
    accessToken: state.invoiceAuth,
    selectedAccount: state.invoiceAccounts.selectedAccount,
    invoiceAccountsList: state.invoiceAccounts.invoiceAccountsList,
    user: state.invoiceAccounts.user
})

const mapDispatchToProps = (dispatch) => ({
    setInvoiceAccountsList: (accountList) => dispatch(setInvoiceAccountsList(accountList)),
    setSelectedInvoiceAccount: (account) => dispatch(setSelectedInvoiceAccount(account))
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateInvoiceAccountDrawer);