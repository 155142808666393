import {SendEmailType} from '../actions/invoiceAccount';

const defaultState = {
    invoiceAccountsList: [],
    selectedAccount: {},
    showCreateInvoice: false,
    customerObj: {},
    editInvoiceObj: {},
    invoiceObj: {},
    showPreviewInvoiceScreen: false,
    showSendInvoiceScreenType: SendEmailType.NONE,
    user: {},
    tdsTypeList:[],
    entityObj: -1,
    goToBilling: false,
    showEstimateScreen: false,
    estimateObjForCreateInvoice: {}
}

export default (state = defaultState, action) => {
    switch(action.type) {

        case 'SET_INVOICE_ACCOUNTS_LIST' :
            return {
                ...state,
                invoiceAccountsList : action.accountList
        }

        case 'SET_SELECTED_INVOICE_ACCOUNT' :
            return {
                ...state,
                selectedAccount : action.account
        }
        
        case 'SET_SHOW_CREATE_INVOICE' :
            return {
                ...state,
                showCreateInvoice : action.createInvoice
            }

        case 'SET_CUSTOMER_OBJECT' :
            return {
                ...state,
                customerObj : action.customerObj
            } 

        case 'SET_EDIT_INVOICE_OBJECT' :
            return {
                ...state,
                editInvoiceObj : action.obj
            }    
        
        case 'SET_INVOICE_OBJECT' :
            return {
                ...state,
                invoiceObj : action.invoice
            }    

        case 'SET_SHOW_PREVIEW_INVOICE_SCREEN' :
            return {
                ...state,
                showPreviewInvoiceScreen : action.previewInvoiceScreen
            }
            
        case 'SET_SHOW_SEND_INVOICE_SCREEN_TYPE' :
            return {
                ...state,
                showSendInvoiceScreenType : action.sendInvoiceScreenType
            }    

        case 'SET_USER_OBJ' :
            return {
                ...state,
                user : action.user_obj
            }

        case 'SET_TDS_TYPE_LIST' :
            return {
                ...state,
                tdsTypeList : action.tdsTypeList
        }

        case 'SET_ENTITY_OBJ' :
            return {
                ...state,
                entityObj : action.entity
        }

        case 'SET_GO_TO_BILLING' :
            return {
                ...state,
                goToBilling: action.goToBilling

            }

        case 'SET_SHOW_ESTIMATE_SCREEN' :
            return {
                ...state,
                showEstimateScreen: action.showEstimateScreen
            }

        case 'SET_ESTIMATE_OBJECT_FOR_CREATE_INVOICE' :
            return {
                ...state,
                estimateObjForCreateInvoice : action.estimate
        }
            
        case 'SET_CLEAR_INVOICE_ACCOUNTS' :    
            return defaultState
        
        default:
            return state;
    }
}