import React, {useEffect} from 'react';
import {Grid, CircularProgress} from '@material-ui/core';
import {connect} from 'react-redux';
import {logout} from '../actions/invoiceAuth';
import {setClearInvoiceAccounts, setSelectedInvoiceAccount} from '../actions/invoiceAccount';
import {setSelectedDrawerState, SelectedDrawerState} from '../actions/drawer';
import { consoleToLog, trackGAEvent } from '../util/AppUtil';
import { clearSelectedInvoiceFilters } from '../actions/invoiceFilters';
import {clearSelectedTransactionFilters} from '../actions/transactionFilters';
import {logoutApi} from '../services/authService';
import {useLocation} from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { history } from '../routers/AppRouter';

const HomePage = (props) => {
    const location = useLocation();
    const {enqueueSnackbar} = useSnackbar();

    useEffect(() => {
        if(location.state?.logOut) {
            callLogoutApi();
        } else {
            if(props.accessToken) {
                history.push('/dashboard');
            } else {
                window.open(`${process.env.REACT_APP_AUTH_APP_DOMAIN_NAME}/login?service=invoices`, '_self');
            }
        } 
    }, []);

    const clearRedux = () => {
        props.logout();
        props.setClearInvoiceAccounts();
        props.setSelectedDrawerState(SelectedDrawerState.SHOW_DASHBOARD);
        props.clearSelectedInvoiceFilters();
        props.clearSelectedTransactionFilters();
        window.open(`${process.env.REACT_APP_AUTH_APP_DOMAIN_NAME}/logout?service=invoices`, '_self');
    }

    const callLogoutApi = () => {
        logoutApi()
            .then((response) => {
                const res = response.data;
                consoleToLog('Response logoutApi: ', res);

                clearRedux();
            })
            .catch((e) => {
                consoleToLog('Error logoutApi: ', e);
                if(e.response && e.response.status === 401 && e.response.data && e.response.data.detail) {
                    enqueueSnackbar(e.response.data.detail, { variant: 'error' });
                    clearRedux();
                }
                if(e.response.data && e.response.data.message) {
                    return;
                }
            })
    }

    return (
        <div style={{width:"100%", height:'100vh'}}>
            <Grid item container justifyContent='center' style={{marginTop:'32px'}}>
                <CircularProgress size={28}/>
            </Grid>
        </div>
    );
}

const mapStateToProps = (state) => ({
    accessToken: state.invoiceAuth,
    invoiceAccountsList: state.invoiceAccounts.invoiceAccountsList,
    selectedAccount: state.invoiceAccounts.selectedAccount,
    drawerState: state.drawerState.setDrawerState
});

const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(logout()),
    setClearInvoiceAccounts: () => dispatch(setClearInvoiceAccounts()),
    setSelectedDrawerState: (drawerState) => dispatch(setSelectedDrawerState(drawerState)),
    setSelectedInvoiceAccount: (account) => dispatch(setSelectedInvoiceAccount(account)),
    clearSelectedInvoiceFilters: () => dispatch(clearSelectedInvoiceFilters()),
    clearSelectedTransactionFilters: () => dispatch(clearSelectedTransactionFilters())
});

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);