export const currencySymbols = {
    'AED': 'د.إ',
    'AFN': '؋',
    'ALL': 'L',
    'AMD': '֏',
    'ANG': 'ƒ',
    'AOA': 'Kz',
    'ARS': '$',
    'AUD': '$',
    'AWG': 'ƒ',
    'AZN': '₼',
    'BAM': 'KM',
    'BBD': '$',
    'BDT': '৳',
    'BGN': 'лв',
    'BHD': 'ب.د',
    'BIF': 'FBu',
    'BMD': '$',
    'BND': '$',
    'BOB': 'Bs.',
    'BRL': 'R$',
    'BSD': '$',
    'BTN': 'Nu.',
    'BWP': 'P',
    'BYN': 'Br',
    'BZD': 'BZ$',
    'CAD': '$',
    'CDF': 'FC',
    'CHF': 'CHF',
    'CLP': '$',
    'CNY': '¥',
    'COP': '$',
    'CRC': '₡',
    'CUP': '$',
    'CVE': 'Esc',
    'CZK': 'Kč',
    'DJF': 'Fdj',
    'DKK': 'kr.',
    'DOP': 'RD$',
    'DZD': 'دج',
    'EGP': 'ج.م',
    'ERN': 'Nfk',
    'ETB': 'Br',
    'EUR': '€',
    'FJD': '$',
    'FKP': '£',
    'FOK': 'kr',
    'GBP': '£',
    'GEL': '₾',
    'GGP': '£',
    'GHS': '₵',
    'GIP': '£',
    'GMD': 'D',
    'GNF': 'FG',
    'GTQ': 'Q',
    'GYD': '$',
    'HKD': '$',
    'HNL': 'L',
    'HRK': 'kn',
    'HTG': 'G',
    'HUF': 'Ft',
    'IDR': 'Rp',
    'ILS': '₪',
    'IMP': '£',
    'INR': '₹',
    'IQD': 'ع.د',
    'IRR': '﷼',
    'ISK': 'kr',
    'JEP': '£',
    'JMD': '$',
    'JOD': 'د.ا',
    'JPY': '¥',
    'KES': 'KSh',
    'KGS': 'с',
    'KHR': '៛',
    'KID': '$',
    'KMF': 'CF',
    'KRW': '₩',
    'KWD': 'د.ك',
    'KYD': '$',
    'KZT': '₸',
    'LAK': '₭',
    'LBP': 'ل.ل',
    'LKR': 'Rs',
    'LRD': '$',
    'LSL': 'L',
    'LYD': 'ل.د',
    'MAD': 'د.م.',
    'MDL': 'L',
    'MGA': 'Ar',
    'MKD': 'ден',
    'MMK': 'K',
    'MNT': '₮',
    'MOP': 'P',
    'MRU': 'UM',
    'MUR': '₨',
    'MVR': 'ރ.',
    'MWK': 'MK',
    'MXN': '$',
    'MYR': 'RM',
    'MZN': 'MT',
    'NAD': '$',
    'NGN': '₦',
    'NIO': 'C$',
    'NOK': 'kr',
    'NPR': '₨',
    'NZD': '$',
    'OMR': 'ر.ع.',
    'PAB': 'B/.',
    'PEN': 'S/',
    'PGK': 'K',
    'PHP': '₱',
    'PKR': '₨',
    'PLN': 'zł',
    'PYG': '₲',
    'QAR': 'ر.ق',
    'RON': 'lei',
    'RSD': 'дин',
    'RUB': '₽',
    'RWF': 'FRw',
    'SAR': '﷼',
    'SBD': '$',
    'SCR': '₨',
    'SDG': '£',
    'SEK': 'kr',
    'SGD': '$',
    'SHP': '£',
    'SLL': 'Le',
    'SOS': 'Sh',
    'SRD': '$',
    'SSP': '£',
    'STN': 'Db',
    'SYP': '£',
    'SZL': 'E',
    'THB': '฿',
    'TJS': 'ЅМ',
    'TMT': 'T',
    'TND': 'د.ت',
    'TOP': 'T$',
    'TRY': '₺',
    'TTD': '$',
    'TVD': '$',
    'TWD': 'NT$',
    'TZS': 'Sh',
    'UAH': '₴',
    'UGX': 'Sh',
    'USD': '$',
    'UYU': '$U',
    'UZS': 'сўм',
    'VES': 'Bs.',
    'VND': '₫',
    'VUV': 'VT',
    'WST': 'T',
    'XAF': 'FCFA',
    'XCD': '$',
    'XOF': 'CFA',
    'XPF': '₣',
    'YER': '﷼',
    'ZAR': 'R',
    'ZMW': 'ZK',
    'ZWL': '$',
    'SVC': '$',
    'KPW': '₩',
    'VEF': 'Bs.'
};

export const currencyFormatterForAmount = (locale, currency, amount) => {
    let code = currency ? currency : 'INR';
    const formatter = new Intl.NumberFormat(locale ? locale : 'en-IN', {
        style: 'currency',
        currency: code,
        currencyDisplay: 'symbol',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });

    let formattedAmount = formatter.format(amount);

    // Replace the default symbol with the custom symbol if available
    if (currencySymbols[code]) {
        const defaultSymbol = formatter.formatToParts(amount).find(part => part.type === 'currency').value;
        formattedAmount = formattedAmount.replace(defaultSymbol, currencySymbols[code]);
    }

    return formattedAmount;
}

export const formatAmount = (amount, config) => {

    const {
        thousand_separator,
        decimal_separator,
        symbol_on_left,
        space_in_amount_and_symbol,
        symbol,
        lakh_view
    } = config || {};

    let invoice_amount = Number(amount);
    
    // Split the amount into integer and fractional parts
    if(config && thousand_separator && decimal_separator) {
    let [integerPart, fractionalPart] = invoice_amount?.toFixed(2).split('.');
    //console.log("***integralPart", integerPart);
    //console.log("***fractionalPart", fractionalPart);

    // Insert thousand separators based on the locale
    if (lakh_view) {
        // Custom format for Indian numbering system
        const lastThree = integerPart.slice(-3);
        //console.log("***lastThree", lastThree);
    
        const otherNumbers = integerPart.slice(0, -3);
        //console.log("***otherNumbers", otherNumbers);
        
        integerPart = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, thousand_separator) + (otherNumbers ? thousand_separator : '') + lastThree;
    // console.log("###integerPartIndia", integerPart);
    } else {
        // General format for other countries
        integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, thousand_separator);
        //console.log("###integerPartOther", integerPart);
    }

    // Construct the formatted amount
    let formattedAmount = integerPart + decimal_separator + fractionalPart;
    //console.log("###formattedAmount", integerPart);

    // Handle the placement of the currency symbol
    if (symbol_on_left) {
        formattedAmount = symbol + (space_in_amount_and_symbol ? ' ' : '') + formattedAmount;
    } else {
        formattedAmount = formattedAmount + (space_in_amount_and_symbol ? ' ' : '') + symbol;
    }

    return formattedAmount;
    } else return invoice_amount?.toFixed(2);
}