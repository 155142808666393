import React, {Fragment, useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/styles';
import {Grid, Typography, Button, Chip, TextField, Box, Divider, CircularProgress} from '@material-ui/core';
import {connect} from 'react-redux'; 
import { Autocomplete } from '@material-ui/lab';
import ClearIcon from '@material-ui/icons/Clear';
import AutoCompleteTaxRates from './AutocompleteTaxRates';
import {saveDefaultTaxPreferenceApi} from '../services/authService';
import {consoleToLog, otherTaxesTypes, trackGAEvent} from '../util/AppUtil';
import { useSnackbar } from 'notistack';
import { setSelectedInvoiceAccount, setInvoiceAccountsList, setClearInvoiceAccounts } from '../actions/invoiceAccount';
import { logout } from '../actions/invoiceAuth';
import { clearSelectedInvoiceFilters } from '../actions/invoiceFilters';
import { setSelectedDrawerState } from '../actions/drawer';
import { clearSelectedTransactionFilters } from "../actions/transactionFilters";
import {intraStateTaxesArr, interStateTaxesArr} from '../util/AppUtil';
import { useIsMount } from './useIsMount';
import { history } from '../routers/AppRouter';
import { SelectedDrawerState } from '../actions/drawer';
import { isInvoiceAccountCountryIndia, modifyTaxObj } from '../util/AppUtil';


const useStyles = makeStyles((theme) => ({
    taxPrfLabel: {
        fontSize:'15px',
        color:"#ff5b5b",
        fontWeight:500
    },
    taxTypeLabel: {
        fontSize:'14px',
        color:"#888"
    },
    saveBtn: {
        background:'#007bfb',
        color:'#fff',
        width:'80px',
        '&:hover': {
            background:'#0066d1'
        }
    },
    customColumn1: {
        maxWidth:'22%',
        flexBasis:'22%'
    },
    customColumn2: {
        maxWidth:'65%',
        flexBasis:'65%'
    }
}))

const DefaultTaxPreference = (props) => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();
    const isMount = useIsMount();

    let inter_state_taxes = interStateTaxesArr(props.selectedAccount.tax_presets.map(({id, name, rate}) => modifyTaxObj(id, name, rate)));
    let intra_state_taxes = intraStateTaxesArr(props.selectedAccount.tax_presets.map(({id, name, rate}) => modifyTaxObj(id, name, rate)));
    let other_country_taxes = props.selectedAccount.tax_presets.map(({id, name, rate}) => modifyTaxObj(id, name, rate));

    const [interStateTaxes, setInterStateTaxes] = useState(inter_state_taxes);
    const [intraStateTaxes, setIntraStateTaxes] = useState(intra_state_taxes);
    const [selectedInterStateTaxes, setSelectedInterStateTaxes] = useState([]);
    const [selectedIntraStateTaxes, setSelectedIntraStateTaxes] = useState([]);

    const [otherCountryTaxes, setOtherCountryTaxes] = useState(other_country_taxes);
    const [selectedOtherCountryTaxes, setSelectedOtherCountryTaxes] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if(props.selectedAccount.tax_preference) {
            let taxPreference = props.selectedAccount.tax_preference;
            if(isInvoiceAccountCountryIndia(props.selectedAccount?.country)) {
                    setSelectedInterStateTaxes(taxPreference?.inter_state);
                    setSelectedIntraStateTaxes(taxPreference?.intra_state);
            } else {
                setSelectedOtherCountryTaxes(taxPreference?.states && taxPreference?.states.length > 0 ? taxPreference?.states : []);
            }
        }

    }, []);

    useEffect(() => {
        if(!isMount) {
            if(isInvoiceAccountCountryIndia(props.selectedAccount?.country)) {
                setInterStateTaxes(inter_state_taxes);
                setIntraStateTaxes(intra_state_taxes);
            } else {
                setOtherCountryTaxes(other_country_taxes);
            }
        }

    }, [props.selectedAccount.id, props.selectedAccount.tax_presets])

    useEffect(() => {
        if(!isMount) {
            if(props.callApi && props.fromOnboardingComponent) {
                onSaveTaxPreferenceClick()
            }
        }

    }, [props.callApi]);

    const invoiceAccountCountry = props.selectedAccount?.country;


    const onSaveTaxPreferenceClick = () => {
        const invoice_account_id = props.selectedAccount?.id;
        let tax_preference = {};

        if(isInvoiceAccountCountryIndia(invoiceAccountCountry)) {
            tax_preference = {
                inter_state: selectedInterStateTaxes,
                intra_state: selectedIntraStateTaxes
            }
        } else {
            tax_preference = {
                states : selectedOtherCountryTaxes
            }
        }

        setLoading(true);
        saveDefaultTaxPreferenceApi(invoice_account_id, tax_preference) 
            .then((response) => {
                const res = response.data;
                consoleToLog('Response saveDefaultTaxPreferenceApi: ', res);
                setLoading(false);

                enqueueSnackbar('Tax Preference updated successfully!', {variant:'success'});

                let account = {
                    ...props.selectedAccount,
                    tax_preference: res.tax_preference
                };

                let accountList = props.invoiceAccountsList.map((ia)=>{
                    if(ia.id === account.id) return account;
                    else return ia;
                });

                props.setInvoiceAccountsList(accountList);
                props.setSelectedInvoiceAccount(account);

                if(props.fromOnboardingComponent) {
                    props.setCallApi(false);
                    props.setSelectedDrawerState(SelectedDrawerState.SHOW_ONBOARDING);
                    history.push('/dashboard');
                }

                trackGAEvent(props.selectedAccount.name, 'Tax Preference updated', `${props.user.firstname} ${props.user.lastname}`);
            })
            .catch((e) => {
                consoleToLog('Error saveDefaultTaxPreferenceApi: ', e.response);
                setLoading(false);
                if(e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {variant: 'error'});
                    return;
                }
            })
    }

    return (
        <div style={{marginTop:'16px'}}>
            {isInvoiceAccountCountryIndia(invoiceAccountCountry) ?
            <Box display='flex' flexDirection='column' style={{gap:props.fromOnboardingComponent ? '28px' : '32px'}}>
                <Grid item md={12}>
                    <Grid item container alignItems='center'>
                        <Grid item md={props.fromOnboardingComponent ? 5 : 3}
                            className={props.fromOnboardingComponent && classes.customColumn1}>
                            <Typography className={classes.taxPrfLabel}>
                                Intra State Tax Rate*
                            </Typography>
                            <Typography className={classes.taxTypeLabel}>
                                (Within your state)
                            </Typography>
                        </Grid>

                        <Grid item  md={props.fromOnboardingComponent ? 7 : 6}
                            className={props.fromOnboardingComponent && classes.customColumn2}>
                            <AutoCompleteTaxRates fromInterState={false}
                                intraStateTaxes={intraStateTaxes}
                                selectedIntraStateTaxes={selectedIntraStateTaxes}
                                setSelectedIntraStateTaxes={setSelectedIntraStateTaxes}/>
                        </Grid>

                    </Grid>
                </Grid>

                <Grid item md={12}>
                    <Grid item container alignItems='center'>
                        <Grid item  md={props.fromOnboardingComponent ? 5 : 3}
                            className={props.fromOnboardingComponent && classes.customColumn1}>
                            <Typography className={classes.taxPrfLabel}>
                                Inter State Tax Rate*
                            </Typography>
                            <Typography className={classes.taxTypeLabel}>
                                (Outside your state)
                            </Typography>
                        </Grid>

                        <Grid item md={props.fromOnboardingComponent ? 7 : 6}
                            className={props.fromOnboardingComponent && classes.customColumn2}>
                            <AutoCompleteTaxRates fromInterState={true}
                                interStateTaxes={interStateTaxes}
                                selectedInterStateTaxes={selectedInterStateTaxes}
                                setSelectedInterStateTaxes={setSelectedInterStateTaxes}/>
                        </Grid>

                    </Grid>
                </Grid>
            </Box>
            :
            <Grid item md={12}>
                <Grid item container alignItems='center'>

                    <Grid item md={8}
                        className={props.fromOnboardingComponent && classes.customColumn2}>
                        <AutoCompleteTaxRates fromOtherCountry={true}
                            otherCountryTaxes={otherCountryTaxes}
                            selectedOtherCountryTaxes={selectedOtherCountryTaxes}
                            setSelectedOtherCountryTaxes={setSelectedOtherCountryTaxes}/>
                    </Grid>

                </Grid>
            </Grid>    
            }
            {!props.fromOnboardingComponent && 
            <Grid item md={12} style={{margin:"24px 0px"}}>
                <Divider />
            </Grid>}

            {!props.fromOnboardingComponent &&
            <Grid item>
                <Button className={classes.saveBtn}
                    onClick={onSaveTaxPreferenceClick}>
                    {loading && <CircularProgress size={18} style={{marginRight:'8px', color:'#fff'}}/>}
                    Save
                </Button>
            </Grid>}
        </div>
    );
}

export const mapStateToProps = (state) => ({
    selectedAccount: state.invoiceAccounts.selectedAccount,
    invoiceAccountsList: state.invoiceAccounts.invoiceAccountsList,
    user: state.invoiceAccounts.user
});

const mapDispatchToProps = (dispatch) => ({
    setSelectedInvoiceAccount: (account) => dispatch(setSelectedInvoiceAccount(account)),
    setInvoiceAccountsList: (list) => dispatch(setInvoiceAccountsList(list)),
    logout: () => dispatch(logout()),
    clearSelectedInvoiceFilters: () => dispatch(clearSelectedInvoiceFilters()),
    setClearInvoiceAccounts: () => dispatch(setClearInvoiceAccounts()),
    setSelectedDrawerState: (drawerState) => dispatch(setSelectedDrawerState(drawerState)),
    clearSelectedTransactionFilters: () => dispatch(clearSelectedTransactionFilters())
});

export default connect(mapStateToProps, mapDispatchToProps)(DefaultTaxPreference);