/**Transaction Filters (start_date, end_date, method, clients) */
export const setPaymentStartDateFilter = (start_date) => ({
    type: 'SET_PAYMENT_START_DATE_FILTER',
    start_date
});

export const setPaymentEndDateFilter = (end_date) => ({
    type: 'SET_PAYMENT_END_DATE_FILTER',
    end_date
});

//status
export const addSelectedMethodToTransactionFilter = (method) => ({
    type: 'ADD_SELECTED_METHOD_TO_TRANSACTION_FILTER',
    method
});

export const removeSelectedMethodFromTransactionFilter = (method) => ({
    type: 'REMOVE_SELECTED_METHOD_FROM_TRANSACTION_FILTER',
    method
});

export const addSelectedClientToTransactionFilter = (client) => ({
    type: 'ADD_SELECTED_CLIENT_TO_TRANSACTION_FILTER',
    client
});

export const removeSelectedClientFromTransactionFilter = (client) => ({
    type: 'REMOVE_SELECTED_CLIENT_FROM_TRANSACTION_FILTER',
    client
});

export const clearSelectedTransactionFilters = () => ({
    type: 'CLEAR_SELECTED_TRANSACTION_FILTERS'
});