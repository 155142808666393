import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Typography, Button, CircularProgress, Divider, Tooltip} from '@material-ui/core';
import {useIsMount} from '../useIsMount';
import {connect} from 'react-redux';
import { consoleToLog } from '../../util/AppUtil';
import { setInvoiceItemObj } from '../../actions/invoiceAccount';
import InvoiceListComponent from '../InvoiceListComponent'

const useStyles = makeStyles((theme) => ({
    detailsContainer: {
    },
    heading: {
        fontSize: "20px",
        fontWeight: "600",
    },
    invoiceListContainer: {
        background:'#f9fafb',
        borderBottom: '1px solid #edf2f7',
    },
    invoicesStyle: {
        minWidth:"100%", 
        borderRadius:'4px', 
        color:'#2B2B2B',
    },
    invoiceListItemStyle: {
        padding:'16px 12px',
        position:'relative',
        '&:hover' : {
            backgroundColor:'#f7fafc',
            cursor:'pointer'
        }
    },
    loading: {
        position:'relative',
        left:'50%',
        right:'50%',
        marginTop:'2%'
    },
}))

const ClientInvoices = (props) => {
    const classes = useStyles();
    const isMount = useIsMount();

    const [clientInvoiceList, setClientInvoiceList] = useState([]);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [isMoreLoading, setIsMoreLoading] = useState(false);
    const [id, setId] = useState(props.selectedAccount?.id);
    const [items, setItems] = useState([]);
    const [openDetailsDialog, setOpenDetailsDialog] = useState(false);
    const [invoiceObj, setInvoiceObj] = useState(undefined);

    const enable_tds = props.selectedAccount?.enable_tds;

    // useEffect(() => {
    //     getClientInvoices();
    // }, [])

    // useEffect(() => {
    //     setId(props.selectedAccount?.id);
    // }, [props.selectedAccount?.id]);

    // useEffect(() => {
    //     if(!isMount) {
    //         getClientInvoices();
    //     }
    // }, [id]);

    // useEffect(() => {
    //     if(!isMount) {
    //         if(isMoreLoading) {
    //             getClientInvoices();
    //         }
    //     }
    // }, [isMoreLoading]);

    // useEffect(() => {
    //     if(!isMount) {
    //         if(props.clientInvoiceObj) {
    //             updateInvoice();
    //         }
    //     }
    // }, [props.clientInvoiceObj]);


    const updateInvoice = () => {
        const updatedInvoicesArr = clientInvoiceList.map((invoice) => {
            if(invoice.id === props.clientInvoiceObj?.id) {
                invoice = props.clientInvoiceObj
            }
            return invoice;
        });

        setClientInvoiceList(updatedInvoicesArr);
        props.setClientInvoiceObj(undefined);
    }

    return (
        <Grid item xs={12} className={classes.detailsContainer}>
                <InvoiceListComponent clientInfo={props.clientInfo}
                    handleInvoiceDialogOpen={props.handleInvoiceDialogOpen}
                    updatedInvoiceObj={props.updatedInvoiceObj}
                    setUpdatedInvoiceObj={props.setUpdatedInvoiceObj}
                    is_entity_email_unverified={props.is_entity_email_unverified}
                    fromClientDetails={true}
                    clientComponentObj={props.clientComponentObj}
                    tabValue={props.tabValue}
                    showClientInfo={props.showClientInfo}
                    scrollContainer={props.scrollContainer}
                />
        </Grid>
    );
}

const mapStateToProps = (state) => ({
    accessToken: state.invoiceAuth,
    selectedAccount: state.invoiceAccounts.selectedAccount
});

const mapDispatchToProps = (dispatch) => ({
    setInvoiceItemObj: (invoice) => dispatch(setInvoiceItemObj(invoice))
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientInvoices);