import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles'; 
import {Grid, Typography,Button, CircularProgress, ClickAwayListener, Tooltip} from '@material-ui/core';
import { connect } from 'react-redux';
import {setInvoiceAccountsList, setSelectedInvoiceAccount, setClearInvoiceAccounts} from '../actions/invoiceAccount';
import { useIsMount } from './useIsMount';
import AddEditInvoiceItemsDrawer from './AddEditInvoiceItemsDrawer';
import { CSSTransition, TransitionGroup} from 'react-transition-group';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import {getItemListApi, deleteItemApi} from '../services/authService';
import { consoleToLog, AccessLevel } from '../util/AppUtil';
import { logout } from '../actions/invoiceAuth';
import { clearSelectedInvoiceFilters } from '../actions/invoiceFilters';
import { setSelectedDrawerState } from '../actions/drawer';
import { clearSelectedTransactionFilters } from "../actions/transactionFilters";
import {trackGAEvent} from '../util/AppUtil';
import { useSnackbar } from 'notistack';
import CreateTaxPresetDrawer from './CreateTaxPresetDrawer';
import AutoCompleteTaxPreset from './AutoCompleteTaxPreset';
import { invoiceAccountCountryObj } from '../util/AppUtil';
import { currencyFormatterForAmount } from '../util/currencyUtil';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { formatAmount } from '../util/currencyUtil';
import { isInvoiceAccountCountryIndia } from '../util/AppUtil';

const useStyles = makeStyles((theme) => ({
    settingsContainer: {
        width:'100%',
        background:'#fff',
        borderRadius:'4px',
        marginBottom:'32px'
    },
    heading: {
        fontSize: "20px",
        fontWeight: "600",
    },
    btn: {
        background: "#383838",
        width: "135px",
        paddingLeft: "4px",
        paddingRight: "4px",
        color: "white",
    },
    loading: {
        position:'relative',
        left:'50%',
        right:'50%',
        marginTop:'3%'
    },
    headings: {
        fontWeight: '600',
        color:'#6B7280',
        [theme.breakpoints.down(1400)]: {
            fontSize:'14px'
        },
    },
    itemTitleList: {
        background:'#f9fafb',
        borderBottom: '1px solid #edf2f7',
    },
    itemListContainer : {
        background:'white', 
        minWidth:"100%", 
        padding:'10px 16px', 
        color:'#6b7280',
        position:'relative',
        borderBottom: '1px solid #edf2f7',
        '& .action__icon' : {
            padding:'2px 4px 0px 4px',
            border:'1px solid white',
            borderRadius:'4px',
            cursor:'pointer'
        },
        '&:hover .action__icon' : {
           border:'1px solid #ccc'
        },
        '&:hover':{
          backgroundColor:'#f7fafc',
        },
        '& .more_actionButton' : {
            visibility:'hidden',
            position:'absolute',
            right:'90px',
            width:'160px',
            background:'white',
            border:'1px solid #ccc',
            borderRadius:'5px',
            display:'flex',
            justifyContent:'center',
            aligItems:'center',
            fontSize:'14px',
            padding:'4px 0px 4px 0px',
            [theme.breakpoints.down(1500)]: {
                right:'80px',
                padding:'3px 0px 3px 0px',
            },

        },
        '&:hover .more_actionButton' : {
            visibility:'visible',
        },
    },
    actionsIcons: {
        color: '#b5bbc2',
        fontSize: '18px',
        marginRight: '8px' 
    },
}
));

const InvoiceItemsComponent = (props) => {
    const classes = useStyles();
    const isMount = useIsMount();
    const {enqueueSnackbar} = useSnackbar();

    const [openDrawer, setOpenDrawer] = useState(false);
    const [itemList, setItemList] = useState([]);
    const [isActive, setIsActive] = useState(false);
    const [itemId, setItemId] = useState('');
    const [loading, setLoading] = useState(false);
    const [animationActive, setAnimationActive] = useState(false);
    const [itemObj, setItemObj] = useState({});
    const [openTaxPresetDrawer, setOpenTaxPresetDrawer] = useState(false);
    const [taxPresetObj,setTaxPresetObj]=useState(undefined);

    useEffect(() => {
        getItemList();
    }, []);

    useEffect(() => {
        if(!isMount) {
            getItemList();
        }
    }, [props.selectedAccount?.id]);

    useEffect(() => {
        if(!isMount) {
            if(props.openInvoiceItemsdrawerFromParent) {
                handleDrawerOpen();
            }
        }
    }, [props.openInvoiceItemsdrawerFromParent]);

    const { access_level } = props.selectedAccount || {};
    const accessLevelViewer = (AccessLevel.getUserAccessLevelValue(access_level) === AccessLevel.VIEWER) ? true : false;

    const currenciesObj = props.selectedAccount?.currencies;
    const invoiceAccountCountry = props.selectedAccount?.country;

    const getItemList = () => {
        const invoice_account_id = props.selectedAccount?.id;

        setLoading(true);
        getItemListApi(invoice_account_id)
            .then((respose) => {
                const res = respose.data;
                consoleToLog('Response getItemListApi: ', res);
                setLoading(false);

                setItemList(res);
            })
            .catch((e) => {
                consoleToLog('Error getItemListApi', e.response);
                setLoading(false);
                if(e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {variant: 'error'});
                    return;
                }
            })
    }

    const handleDrawerOpen = () => {
        trackGAEvent(props.selectedAccount.name, 'Invoice Items Settings - Add Invoice items clicked', `${props.user.firstname} ${props.user.lastname}`);
        setOpenDrawer(true)
    }

    const handleDrawerClose = () => {
        setOpenDrawer(false);
        itemObj && setItemObj({});
        !props.fromOnboardingComponent && props.handleInvoiceItemsDrawerCloseFromParent();
    }

    const handleTaxPresetDrawerOpen = () => {
       setOpenTaxPresetDrawer(true)
    }

    const handleTaxPresetDrawerClose = () => {
        setOpenTaxPresetDrawer(false)
    }

    const closeMoreOptionsMenu = () => {
        setIsActive(false);
        setItemId('');
    }

    const onEditItemClick = (e, item) => {
        trackGAEvent(props.selectedAccount.name, 'Invoice Items Settings - Edit Item button clicked', `${props.user.firstname} ${props.user.lastname}`);
        e.stopPropagation();
        setItemObj(item);
        handleDrawerOpen();
    }

    const onUpdateLocalList = (obj) => {
        let updatedArray = itemList.map((item) => {
            if(item.id === obj.id) {
                item = obj;
            }
            return item;
        });

        setItemList(updatedArray);
        setItemObj({});
    }

    const onDeleteItemClick = (e, item) => {
        e.stopPropagation();
        closeMoreOptionsMenu();

        setAnimationActive(true);
        if(window.confirm('Are you sure you want to delete this item ?')) {
            const invoice_account_id = props.selectedAccount?.id;
            const item_id = item.id;

            deleteItemApi(invoice_account_id, item_id)
                .then((response) => {
                    const res = response.data;
                    consoleToLog('Response deleteItemApi: ', res);

                    const updated_array = itemList.filter((item) => item.id !== item_id);
                    setItemList(updated_array);
                    props.fromOnboardingComponent && props.setOnboardingItemList(updated_array);
                    setAnimationActive(false);

                    trackGAEvent(props.selectedAccount.name, 'Invoice Items Settings - Invoice item deleted', `${props.user.firstname} ${props.user.lastname}`);
                })
                .catch((e) => {
                    setAnimationActive(false);
                    consoleToLog('Error deleteItemApi', e.response);
                    if(e.response.data && e.response.data.message) {
                        enqueueSnackbar(e.response.data.message, {variant: 'error'});
                        return;
                    }
                })
        }
    }

    const onAddItemsClick = (obj) => {
        setItemList(oldItems => [...oldItems, obj]);
        props.fromOnboardingComponent && props.setOnboardingItemList(oldItems => [obj, ...oldItems]);
    }

    return (
        <Grid item xs={12} className={classes.settingsContainer}>
                {props.fromOnboardingComponent &&
                        <Grid item container justifyContent='space-between' 
                            style={{padding:"16px 16px 16px 16px"}}
                            alignItems='center'>
                            <Grid item>
                                <Typography className='outboardingLabel'>
                                    Invoice Items
                                </Typography>
                            </Grid>

                        <Grid item>
                            <Button
                                onClick={handleDrawerOpen}
                                className={'addButton'}
                            >
                            Add Items
                            <AddCircleOutlineIcon fontSize='small' 
                                style={{fontSize:'15px', marginLeft:'4px'}}
                                className={'buttonPlusIcon'}/>
                            </Button>
                        </Grid>
                    </Grid>}   

                <Grid item xs={12} style={{marginTop:props.fromOnboardingComponent ? 0 :'24px'}}>
                    <Grid item sm={12}>
                        <Grid item sm={12} className={classes.itemTitleList}>
                            <Grid item container style={{padding:'10px 16px'}}>
                                <Grid item sm={1}>
                                    <Typography variant="body1" className={classes.headings}>
                                        #
                                    </Typography>
                                </Grid>

                                <Grid item sm={isInvoiceAccountCountryIndia(invoiceAccountCountry) ? 4 : 5}>
                                    <Typography variant="body1" className={classes.headings}>
                                        Item Name
                                    </Typography>
                                </Grid>

                                {isInvoiceAccountCountryIndia(invoiceAccountCountry) &&
                                <Grid item sm={3}>
                                    <Typography variant="body1" className={classes.headings}>
                                        HSN/SAC code
                                    </Typography>
                                </Grid>}

                                {/* <Grid item sm={3}>
                                    <Typography variant="body1" className={classes.headings}>
                                        Taxes
                                    </Typography>
                                </Grid> */}

                                <Grid item sm={isInvoiceAccountCountryIndia(invoiceAccountCountry) ? 3 : 5}>
                                    <Typography variant="body1" className={classes.headings}>
                                        Price/Rate
                                    </Typography>
                                </Grid>

                            </Grid>
                        </Grid>

                    {loading ?
                    <CircularProgress size={30} className={classes.loading}/> 
                    :
                    <Grid item xs={12}>
                    <TransitionGroup mode="out-in">
                    {
                        itemList && itemList.length > 0  &&
                        itemList.map((item) => {
                            return  <CSSTransition
                                        key={item.id}
                                        timeout={1000}
                                        exit={animationActive}
                                        classNames="item">
                                        <Grid item container 
                                            justifyContent="flex-start"
                                            className={classes.itemListContainer}
                                            alignItems="center">
                                        
                                            <Grid item md={1} lg={1}>
                                                <Typography variant="body1">
                                                    {item.id}
                                                </Typography>
                                            </Grid> 

                                            <Grid item md={isInvoiceAccountCountryIndia(invoiceAccountCountry) ? 4 : 5}>
                                                <Typography variant="body1">
                                                    {item.title}
                                                </Typography>
                                            </Grid>

                                            {isInvoiceAccountCountryIndia(invoiceAccountCountry) &&
                                            <Grid item md={3} lg={3}>
                                                <Typography variant="body1">
                                                    {item.hsn_code}
                                                </Typography>
                                            </Grid>}

                                            {/* <Grid item md={3} lg={3} style={{marginLeft:'2px'}}
                                                className={classes.customColumn6}>
                                                    {/* <Typography variant="body1" className={classes.textEllipsis}>
                                                        {item.tax.map(tax => tax.name).join(', ')}
                                                    </Typography>}
                                            </Grid> */}

                                            <Grid item md={isInvoiceAccountCountryIndia(invoiceAccountCountry) ? 3 : 5}>
                                                <Typography variant="body1" >
                                                    {formatAmount(item.cost, currenciesObj)}
                                                </Typography>
                                            </Grid>

                                            {!accessLevelViewer &&
                                            <Grid item md={1} lg={1}
                                                    style={{display:'flex', aligItems:'center', justifyContent:"end"}}>
                                                    <Tooltip title="More Actions" arrow>  
                                                        <i className="flaticon-more action__icon"  
                                                        onClick={(e) => {
                                                                e.stopPropagation();
                                                                setIsActive(!isActive);
                                                                setItemId(item.id);
                                                            }}
                                                        style={{fontSize:'1.1rem'}}></i>
                                                </Tooltip>
                                                <>    
                                                {item.id === itemId &&
                                                <ClickAwayListener onClickAway={closeMoreOptionsMenu}>
                                                <Grid item style={{color:'#6b7280'}}
                                                    className={`menu menu1_margin ${isActive ? "active" : "inactive"}`}>
                                                    <ul>
                                                        <li onClick={(e) => onEditItemClick(e, item)}>
                                                            <a  style={{display:'flex', alignItems:'center'}}>
                                                                <EditOutlinedIcon fontSize='small'  
                                                                style={{marginTop:'4px'}}
                                                                className={classes.actionsIcons}/>
                                                                Edit
                                                            </a>
                                                        </li>

                                                        <li onClick={(e) => onDeleteItemClick(e, item)}>
                                                            <a style={{display:'flex', alignItems:'center'}}>
                                                                <DeleteOutlinedIcon fontSize='small'  className={classes.actionsIcons}/>
                                                                Delete
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </Grid>
                                                </ClickAwayListener>
                                                }
                                                </>
                                            </Grid> }   
                                        </Grid>
                                    </CSSTransition>     
                        })}
                        </TransitionGroup>
                        {itemList && itemList.length === 0 &&
                            <Grid tem container
                                style={{marginTop:'16px'}}
                                alignItems="center" justifyContent="center">
                                <Grid item>
                                    <Typography variant="body1">
                                            No Items Found
                                    </Typography>
                                </Grid>     
                            </Grid>
                        }    
                        {/* {loadMoreLogic()} */}
                        </Grid>}
                    </Grid>
                </Grid> 


                <AddEditInvoiceItemsDrawer 
                    openDrawer={openDrawer} 
                    handleDrawerClose={handleDrawerClose}
                    onAddItemsClick={onAddItemsClick}
                    itemObj={itemObj}
                    setItemObj={setItemObj}
                    onUpdateLocalList={onUpdateLocalList}
                    handleTaxPresetDrawerOpen={handleTaxPresetDrawerOpen}
                    taxPresetObj={taxPresetObj}
                    setTaxPresetObj={setTaxPresetObj}
                />
                <CreateTaxPresetDrawer
                    openDrawer={openTaxPresetDrawer}
                    handleDrawerClose={handleTaxPresetDrawerClose}
                    setTaxPresetObj={setTaxPresetObj}
                />

        </Grid>
    );
}

const mapStateToProps = (state) => ({
    selectedAccount: state.invoiceAccounts.selectedAccount,
    invoiceAccountsList: state.invoiceAccounts.invoiceAccountsList,
    user: state.invoiceAccounts.user
});

const mapDispatchToProps = (dispatch) => ({
    setInvoiceAccountsList: (accountList) => (dispatch(setInvoiceAccountsList(accountList))),
    setSelectedInvoiceAccount: (account) => (dispatch(setSelectedInvoiceAccount(account))),
    logout: () => dispatch(logout()),
    clearSelectedInvoiceFilters: () => dispatch(clearSelectedInvoiceFilters()),
    setClearInvoiceAccounts: () => dispatch(setClearInvoiceAccounts()),
    setSelectedDrawerState: (drawerState) => dispatch(setSelectedDrawerState(drawerState)),
    clearSelectedTransactionFilters: () => dispatch(clearSelectedTransactionFilters())
})

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceItemsComponent);