import React, {useState, useEffect, createRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Grid, Typography, Button, Avatar, Tooltip, Link, IconButton, TextField, MenuItem, CircularProgress, Paper} from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { useIsMount } from "../useIsMount";
import {udfTypes, consoleToLog, isValidUserDefinedFields, showDefaultClientValues, show401ErrorAndClearRedux} from '../../util/AppUtil';
import ClearIcon from '@material-ui/icons/Clear';
import {connect} from 'react-redux';
import {Autocomplete } from '@material-ui/lab';
import {editClientApi} from '../../services/clientService';
import { useSnackbar } from 'notistack';
import S3FileUpload from "react-s3";
import {getDownloadURLForClientAttachmentApi, getUploadURLApi} from '../../services/clientService';
import CustomFieldDrawer from '../CustomFieldDrawer';
import axios from 'axios';
import moment from 'moment';
import {getClientUploadURLApi, uploadCustomFieldSuccessApi, deleteCustomFieldAttachmentApi, downloadAttachmentApi} from '../../services/authService';
window.Buffer = window.Buffer || require("buffer").Buffer;

const useStyles = makeStyles((theme) => ({
    clientName: {
        fontSize:'16px',
        fontWeight:'500'
    },
    labelHeading: {
        fontWeight:'600 !important',
        fontSize:'14px !important'
    },
    companyInfoStyle: {
        marginBottom:'8px'
    },
    addressStyle: {
        width:'250px'
    },
    MuiAutocompleteinputRoot : {
        '& .MuiOutlinedInput-root' : {
        padding:'0.5px 1px 0.5px 1px',
        borderRadius: '4px',
        background:'#fff',
        marginTop:'4px'
        }
    },
    customTextField: {
        "& input::placeholder": {
            paddingLeft: "8px",
        }
    },
    itemOptionStyle: {
        width: "100%", 
        height:'100%',
        color:'#202020',
        margin:'5px 0px'
    },
    downloadLink: {
        color: "#20a8e0",
        fontSize: "15px",
        fontWeight: "600",
        marginLeft: "2px",
        cursor: "pointer",
    },
    addCustomFieldStyle: {
        margin:'16px auto 4px 6px', 
        width:'95%', 
        color:'#2196f3', 
        borderColor:'#d4d8de'
    },
    addItemIcon: {
        margin:'0px 6px 0px 0px', 
        fontSize:'16px'
    },
}))

const ClientCustomFields = (props) => {
    const classes = useStyles();
    const isMount = useIsMount();
    const user_defined_field = props.userDefinedFieldsFromParent ? props.userDefinedFieldsFromParent : []

    const [userDefinedFields, setUserDefinedFields] = useState(user_defined_field);
    const [loading, setLoading] = useState(false);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [currentIndex, setCurrentIndexIndex] = useState(undefined);
    const {enqueueSnackbar} = useSnackbar();
    const [switchFocus, setSwitchFocus] = useState(false);
    const focusTextInput = createRef();

    const customFieldsList = props.selectedAccount?.custom_fields;
    useEffect(() => {
        if (!isMount) {
            setUserDefinedFields(user_defined_field);
        }
    }, [props.clientInfo]);

    useEffect(() => {
        if (!isMount) {
            if(props.addCustomField) {
                onUserDefinedFieldAdded();
                props.setAddCustomField(false);
            }
        }
    }, [props.addCustomField]);

    useEffect(() => {
        if(!isMount) {
            if(switchFocus) {
                focusTextInput.current?.focus();
            }
        }

    }, [focusTextInput]);


    // useEffect(() => {
    //     if (!isMount) {
    //         if(focusTextInput.current) {
    //             focusTextInput.current.focus();
    //         }
    //     }
    // }, [focusTextInput]);

    const onUserDefinedFieldAdded = () => {
        //allow repeatation give warning about error
        var newUDFObj = {
            name: undefined,
            type: undefined,
            value: undefined,
            show_invoice: false,
        };
        setUserDefinedFields([...userDefinedFields, newUDFObj]);
    };

    const onRemoveUDFObj = (removeUDFObj, index) => {
        consoleToLog("removeUDFObj", removeUDFObj);
    
        const remainingObjs = userDefinedFields.filter((udf, i) => udf.name !== removeUDFObj.name);
        if (removeUDFObj.type === udfTypes.ImageOrDocument.value && removeUDFObj.value && typeof(removeUDFObj.value) !== 'object') {
            //setDeletedFiles([...deletedFiles, removeUDFObj]);
            callRemoveAttachmentApi(removeUDFObj);
        }

        if(remainingObjs && remainingObjs.length === 0 && removeUDFObj.value) {
            callEditClientApi([]);
            setUserDefinedFields(remainingObjs);
        } else {
        //setUserDefinedArrayInState(remainingObjs);
        setUserDefinedFields(remainingObjs);
        }
    };

    const callRemoveAttachmentApi = (removeUDFObj) => {
        const client_id = props.clientInfo?.id;
        
        deleteCustomFieldAttachmentApi(client_id, removeUDFObj.value) 
            .then((response) => {
                const res = response.data;
                consoleToLog('Response deleteCustomFieldAttachmentApi: ', res);
            })
            .catch((e) => {
                consoleToLog('Error deleteCustomFieldAttachmentApi', e.response);
            })
    }

    const onRemoveCustomFieldClick = (udf, index) => {
        const isFromParentCustomField = props.userDefinedFieldsFromParent?.includes(udf);
        console.log('is from parent', isFromParentCustomField);

        if(isFromParentCustomField) {
            if(window.confirm('Are you sure you want to delete this Custom Field?')) {
                onRemoveUDFObj(udf, index);
            }
        } else {
            onRemoveUDFObj(udf, index);
        }

    }

    const  getSelectedItem = (udf, index) => {
        const itemObj = customFieldsList.find((cf) => {
            if(cf.name === userDefinedFields[index].name) {
                return cf || userDefinedFields[index]
            } 
        });
        //console.log('**********item', itemObj);

        return itemObj || {};
    }

    const handleOpenCustomFieldDrawer = () => {
        setOpenDrawer(true);
    }

    const handleCloseCustomFieldDrawer = () => {
        setOpenDrawer(false);
    }

    const filterCustomFeilds = () => {
        const customFieldArr = customFieldsList.filter((cf) => userDefinedFields.find(filter => filter.name === cf.name) === undefined)
        return customFieldArr;
    }

    const AddCustomFieldButton = ({ children, ...other }) => (
        <Paper {...other} 
            style={{marginTop:'6px',
                    boxShadow: 'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px' 
                    }}>
            <Button fullWidth
                className={classes.addCustomFieldStyle}
                variant="outlined"
                color="primary"
                onMouseDown={handleOpenCustomFieldDrawer}
            >
            <AddCircleOutlineIcon fontSize='small' className={classes.addItemIcon}/>  
                Add Custom Field
            </Button>
            {children}
        </Paper>
        );

    const onUDFNameChanged = (e, udf, index) => {
        const updatedArray = userDefinedFields;
        updatedArray[index].name = udf.name;
        setUserDefinedFields([...updatedArray]);
    }

    // const showDefaultClientValues = (option) => {
    //     const itemObj = option.default_value;
    //     console.log('*******************', itemObj)
    //     switch (itemObj) {
    //         case '{{client.tax_id}}':
    //             return props.clientInfo?.tax_id
        
    //         default:
    //             break;
    //     }
    // }

    const onItemClick = (option, index) => {
        const updatedArray = userDefinedFields;
        
        updatedArray[index].name = option.name;
        updatedArray[index].type = option.type;
        updatedArray[index].value = showDefaultClientValues(option, props.clientInfo);
        updatedArray[index].show_invoice = option.show_invoice;
        focusTextInput.current?.focus();
        setUserDefinedFields([...updatedArray]);
        if(switchFocus) setSwitchFocus(false);
    }

    const renderOptions = (option, index) => {
        return (
            <div className={classes.itemOptionStyle}>
                <Grid item container 
                    onClick={() => onItemClick(option, index)}
                    justifyContent="space-between">
                    <Typography variant='body2'>
                        {option.name}
                    </Typography> 
                </Grid>    

            </div>
            );
    }

    const onUpdateUDFValue = (e, index, type) => {
        var newValue = undefined;
        if (type === udfTypes.ImageOrDocument.value) {
          //file
            newValue = e.target.files[0];
            console.log('newFile', newValue);
          //uploadFileIfExists(newValue, index)
        } else {
          //text password
            newValue = e.target.value;
        }
        const allObjs = userDefinedFields;
        allObjs[index].value = newValue;
        setUserDefinedFields([...allObjs]);
    };

    const downloadAttachmentUrl = (value) => {
        const invoice_account_id = props.selectedAccount?.id;
    
        //get downloadable url from backend using url
        downloadAttachmentApi(invoice_account_id, value)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response downloadAttachmentApi: ", res);

                const downloadableURL = res.document_signed_url;

                //initiate download
                document.getElementById("udfFileDownloader").href = downloadableURL;
                document.getElementById("udfFileDownloader").click();
            })
            .catch((e) => {
                consoleToLog("downloadAttachmentApi error", e.response);
                show401ErrorAndClearRedux(props, e, enqueueSnackbar);
            });
    }

    const customNameField = (udf) => {
        const udfName = props.userDefinedFieldsFromParent?.find((udfField) => udfField.name === udf.name);
        //console.log('udf name*****************************', udfName);
        return udfName?.name ? true : false;
    }

    const showUserDefinedFields = () => {
        return (
            userDefinedFields && userDefinedFields.length > 0 
            ? userDefinedFields.map((udf, index) => {
            const typeOfValueInputField =
            udf.type === udfTypes.Text.value
                ? "text"
                : udf.type === udfTypes.Password.value
                ? "password"
                : "";
            return (
            <Grid item container spacing={1}
                key={index}
                onClick={() => setCurrentIndexIndex(index)}
                alignItems="center">

                <Grid item sm={3}>
                    <Autocomplete
                        id="combo-box-demo"
                        //options={customFieldsList}
                        options={filterCustomFeilds()}
                        classes={{
                            root: classes.MuiAutocompleteinputRoot
                        }}
                        //value={getSelectedItem(udf, index)}
                        value={userDefinedFields[index]}
                        disabled={customNameField(udf)}
                        getOptionLabel={(option) => option.name}
                        //inputValue={invoiceItem.item_name}
                        //onInputChange={(e, newInputvalue) => onInvoiceItemNameChanged(e, newInputvalue, index)}
                        renderOption={(option) => renderOptions(option, index)}
                        onChange={(e, newValue) => {
                            onUDFNameChanged(e, newValue, index);
                            onItemClick(newValue, index)
                        }}
                        renderInput={params => (
                            <TextField {...params} variant="outlined" 
                            placeholder='Search Custom Field' 
                            fullWidth
                            classes={{
                                root: classes.customTextField
                            }}
                                />
                        )}
                        PaperComponent={AddCustomFieldButton}
                        //closeIcon={<CloseIcon fontSize='small' onClick={() => onItemClearClick(index)}/>}
                    />
                </Grid>
    
                <Grid item sm={3}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        margin="dense"
                        disabled
                        value={udf.type ? udf.type : -1}
                        select
                        classes={{root: classes.MuiFormControlroot,}}>
                            {Object.values(udfTypes).map((typeObj) => {
                            return (
                                <MenuItem key={typeObj.value} value={typeObj.value}>
                                {typeObj.displayName}
                                </MenuItem>
                            );
                            })}
                    </TextField>
                </Grid>

                <Grid item sm={3}>
                    {udf.type === udfTypes.ImageOrDocument.value ? (
                    <Grid item container sm={12}>
                        {!udf.value  && (
                        <label>
                            <input
                                style={{ display: "none" }}
                                type="file"
                                onChange={(e) => onUpdateUDFValue(e, index, udf.type)}
                            />
    
                            <Typography variant="body2"
                                style={{
                                    marginTop: "2px",
                                    marginBottom: "none",
                                    padding: "8px 38px 8px 32px",
                                    border: "1px dashed #555",
                                    cursor: "pointer",
                                    color: "#555",
                            }}>
                            <i className="flaticon-attachment"  style={{ marginRight: "6px" }}></i>
                                Attach File
                            </Typography>
                        </label>
                        )}
                        {(udf.value && (udf.value.name && udf.value.name?.indexOf("/") === -1)) ? (
                        <Tooltip
                            title={udf.value.name
                                ? udf.value.name
                                : udf.value ? udf.value.replace(/^.*[\\\/]/, "") : ""}
                            arrow
                        >
                            <Typography
                            style={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                cursor: "pointer",
                            }}
                            >
                            {udf.value.name
                            ? udf.value.name
                            :udf.value ? udf.value.replace(/^.*[\\\/]/, "") : ""}
                            </Typography>
                        </Tooltip>
                        )
                                :
                        (udf.value && (udf.value !== '' && udf.value?.indexOf("/") > -1)) && (
                        <Tooltip title={udf.value?.replace(/^.*[\\\/]/, "")} arrow>
                        <div  onClick={() => downloadAttachmentUrl(udf.value)}>
                            <Typography className={classes.downloadLink}>
                                {udf.value?.replace(/^.*[\\\/]/, "")}
                            </Typography>
                            <Link
                                style={{ display: "none" }}
                                id="udfFileDownloader"
                                target="_blank"
                                onClick={(e) => e.stopPropagation()}
                                download
                            ></Link>
                        </div>
                        </Tooltip>
                        )}  
                    </Grid>
                ) : (
                    <TextField
                        fullWidth
                        variant="outlined"
                        margin="dense"
                        type={typeOfValueInputField}
                        value={udf.value ? udf.value : ""}
                        // disabled={showCompanyDetails}
                        placeholder="Value"
                        classes={{
                            root: classes.MuiFormControlroot,
                        
                        }}
                        inputRef={focusTextInput}
                        disabled={udf.type === 'password'}
                        onChange={(e) => onUpdateUDFValue(e, index, udf.type)}
                    />
                )}
                </Grid>
    
                <Grid item md={1}>
                    <IconButton
                        onClick={() => onRemoveCustomFieldClick(udf, index)}
                        style={{
                        color: "#555",
                        padding: "0px",
                        // marginRight: "-16px",
                        marginTop: "3px",
                        }}
                    >
                        <ClearIcon />
                    </IconButton>
                </Grid>
            </Grid>
            );
        }) : 
        <Grid item container justifyContent='center' style={{padding:'8px 16px'}}>
            <Typography variant='body1'>
                No Custom Field Found
            </Typography>
        </Grid>
        );
    };

    const onSaveClick = () => {
        
        if (userDefinedFields.length > 0) {
            const errors = isValidUserDefinedFields(userDefinedFields);
                if (errors) {
                    enqueueSnackbar(errors, {
                        variant:'error'
                    });
                    return;
                } else {
                    for (var i = 0; i < userDefinedFields.length; i++) {
                        if (userDefinedFields[i].type === udfTypes.ImageOrDocument.value && typeof(userDefinedFields[i].value) === 'object') {
                            uploadFileIfExists(i, userDefinedFields);
                            break;
                        } else if (i === userDefinedFields.length - 1) {//call company api directly as this is last iteration
                            callEditClientApi(userDefinedFields);
                        }
                    }
                }
        } 
    }

    const uploadFileIfExists = (i, userDefinedFields) => {
        const udfObj = userDefinedFields[i];
        //upload file logic
    
        //console.log('udf obj', udfObj.value, config);
        const client_id = props.clientInfo?.id;
        const file_name = udfObj.value?.name;
        const file_type = udfObj.value?.type; 
        const type = 'custom'

        setLoading(true);
        getClientUploadURLApi(client_id, file_name, type)
            .then((response) => {
            const res = response.data;
            consoleToLog('Response getClientUploadURLApi', res);
            
            var signedRequest = res.upload_signed_url;
            var url = res.url;

            // Put the fileType in the headers for the upload
                const options = {
                headers: {
                    "Content-Type": file_type,
                }
                };
                axios.defaults.headers.common = {};
                axios.put(signedRequest, udfObj.value, options)
                .then(result => {
                    consoleToLog('signed Request s3', result);
                    console.log("Response from s3");
                    setLoading(false);

                    callUploadSuccessApi(i, userDefinedFields, udfObj, url)
                })
                .catch(error => {
                    consoleToLog('error: getClientUploadURLApi', error);
                    setLoading(false);
                })
            })
            .catch((e) => {
            consoleToLog('Error getUploadURLApi', e);
            setLoading(false);

            })

    }

    const callUploadSuccessApi = (i, userDefinedFields, udfObj, url) => {
        const client_id = props.clientInfo?.id;
        
        uploadCustomFieldSuccessApi(client_id, url)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response uploadCustomFieldSuccessApi: ', res);
        
                //update state obj with new path
                const newUDFObj = {
                    ...udfObj,
                    value: url
                }

                let tempArr = userDefinedFields.filter((obj, index) => index !== i);
                consoleToLog('tempArr', tempArr);
                tempArr.splice(i,0, newUDFObj);
                callapi (i, userDefinedFields ,tempArr);
            })
            .catch((e) => {
                consoleToLog("Error uploadCustomFieldSuccessApi: ", e);
            })
        }

    const callapi = (i, userDefinedFields, tempArr) => {
        let useDefinedArr = userDefinedFields.map(a => {
            const exists = tempArr.find(b => a.name == b.name);
            return exists ? (a.value = exists.value, a) : a;
        });
    
        if (i === userDefinedFields.length - 1) {//call company api
            callEditClientApi(useDefinedArr);
        } else {//recursive call if file exists
            uploadFileIfExists(i + 1, useDefinedArr);
        }
    }

    const callEditClientApi = (userDefinedFields) => {
        const client_id = props.clientInfo?.id;
        const name = props.clientInfo?.name;
        const address = props.clientInfo?.address;
        const state = props.clientInfo?.state;
        const country = props.clientInfo?.country;
        const phone_number = props.clientInfo?.phone_number;
        const email = props.clientInfo?.email;
        const tax_id = props.clientInfo?.tax_id;
        console.log('userdefined fields', userDefinedFields);


        setLoading(true);
        editClientApi(client_id, name, address, state, country, phone_number, email, tax_id, userDefinedFields)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response editClientApi: ', res);
                enqueueSnackbar(res.message, {variant:'success'});
                setLoading(false);

                //setUserDefinedFields(userDefinedFields);
                props.setUserDefinedFieldsFromParent(userDefinedFields);

            })
            .catch((e) => {
                consoleToLog('Error editClientApi: ', e.response);
                setLoading(false);
                show401ErrorAndClearRedux(props, e, enqueueSnackbar);
            })

    }

    return (
        <div style={{padding:"0px 16px"}}>
            {/* <Grid item container justifyContent='flex-end'>
                <Button variant='contained' color='secondary' 
                    onClick={onUserDefinedFieldAdded}>
                    <AddCircleOutlineIcon fontSize='small' style={{marginRight:'6px'}}/>  
                    Add Custom Field
                </Button>
            </Grid> */}

            <Grid item md={12} style={{marginTop:"8px"}}>
                {showUserDefinedFields()}
            </Grid>

            {userDefinedFields && userDefinedFields.length > 0 &&
            <Grid item style={{marginTop:'16px'}}>
                <Button
                    onClick={onSaveClick}
                    variant='contained' 
                    color='secondary'>
                    {loading && <CircularProgress size={20} style={{marginRight:'8px', color:'white'}}/> }Save
                </Button>
            </Grid>}
            
            <CustomFieldDrawer openFilterDrawer={openDrawer}
                handleCloseFilterDrawer={handleCloseCustomFieldDrawer}
                fromCustomFieldComponent={true}
                onItemClick={onItemClick}
                onUDFNameChanged={onUDFNameChanged}
                currentIndex={currentIndex}
                setSwitchFocus={setSwitchFocus}
            />
        </div>
    );
}

const mapStateToProps = (state) => ({
    selectedAccount: state.invoiceAccounts.selectedAccount
})

export default connect(mapStateToProps)(ClientCustomFields);