import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Link, Container, CircularProgress } from '@material-ui/core';
import {connect} from 'react-redux';
import { setSelectedDrawerState, SelectedDrawerState } from '../actions/drawer';
import { AppRoutes, fetchAccessToken } from '../util/AppUtil';
import {getInvoiceAccountsListApi} from '../services/authService';
import { setSelectedInvoiceAccount } from '../actions/invoiceAccount';
import { getTdsTypeList } from '../actions/invoiceAccount';
import { setInvoiceAccountsList } from '../actions/invoiceAccount';
import { history } from '../routers/AppRouter';
import { useSnackbar } from 'notistack';
import { consoleToLog } from '../util/AppUtil';

const useStyles = makeStyles((theme) => ({
    containerStyles : {
        height:'100vh',
        background: theme.palette.primary.light,
        display:'flex',
        alignItems:"center",
        justifyContent:'center'
    }
}))

const PaymentSuccessPages = (props) => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();

    const [loading, setLoading] = useState(true);

    useEffect(() => {

        const fetchToken = async () => {
            try {
            const data = await fetchAccessToken('invoices');
            if(data) {
                consoleToLog('Response authenticateApi', data);
                const {access_token} = data;
                if(access_token) {
                    getInvoiceAccountList();
                }  else {
                    window.open(`${process.env.REACT_APP_AUTH_APP_DOMAIN_NAME}/login?service=invoices`, '_self');
                    setLoading(false);
                }

            } 
            } catch (error) {
                consoleToLog("error fetchAccessToken", error);
                setLoading(false);
            }
        };
    
        fetchToken();
    }, []);

    const getInvoiceAccountList = () => {
        getInvoiceAccountsListApi()
            .then((response) => {
                const res = response.data;
                consoleToLog("Response InvoiceAccountListApi ", res);
                if(res.length === 0 ) {
                    history.push('/', {logOut:true});
                } else {
                    props.setInvoiceAccountsList(res);
                    //enqueueSnackbar('Payment successfull', {variant:'success'});
                    setLoading(false);
                    
                    let selected_invoice_account_id = props.selectedAccount.id;
                        //if found update its data
                        const updateSelectedAccount = res.find((acc) => acc.id === selected_invoice_account_id);
                        if (updateSelectedAccount) {
                            props.setSelectedInvoiceAccount(updateSelectedAccount);
                            props.getTdsTypeList(updateSelectedAccount?.id, updateSelectedAccount?.country);
                        }
                    
                }

            })
            .catch((e) => {
                consoleToLog('Error InvoiceAccountListApi ', e.response);
                setLoading(false);
                if(e.response && e.response.status === 401) {
                    history.push('/', {logOut:true});
                }
                if (e.response.data && e.response.data.message) {
                    return false;
                }
            });
    }

    const onGoToDashboard = () => {
        props.setSelectedDrawerState(SelectedDrawerState.SHOW_DASHBOARD);
        history.push(AppRoutes.SETTINGS);
    }

    return (
        <div className={classes.containerStyles}>
            <Container maxWidth='xs'>
                <img src='/images/cc-payment.svg' style={{width:'90%', margin:"0px auto"}}/>
                {loading ?
                <Grid item container justifyContent='center'>
                    <Grid item>
                        <CircularProgress size={20} style={{color:"#000", textAlign:'center'}}/>
                    </Grid>
                </Grid>
                : 
                <Typography onClick={onGoToDashboard} style={{textAlign:'center', marginTop:'8px', fontSize:'18px'}}>
                    {"Payment Succeeded"}
                    <Link href={AppRoutes.BILLING}
                        style={{margin:'0px 0px 0px 4px', color:"#007bfb"}}>Go to dashboard</Link>
                </Typography>}
            </Container>
        </div>
    );
}

const mapStateToProps = (state) => ({
    selectedAccount: state.invoiceAccounts.selectedAccount,
    drawerState: state.drawerState.setDrawerState
});

const mapDispatchToProps = (dispatch) => ({
    setSelectedDrawerState: (drawerState) => dispatch(setSelectedDrawerState(drawerState)),
    setInvoiceAccountsList: (accountList) => dispatch(setInvoiceAccountsList(accountList)),
    setSelectedInvoiceAccount: (account) => dispatch(setSelectedInvoiceAccount(account)),
    getTdsTypeList: (invoice_account_id, country_code) => dispatch(getTdsTypeList(invoice_account_id, country_code))
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentSuccessPages);