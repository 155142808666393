import { OverlayView } from "../actions/overlay";

//manage dashboard page loading
const defaultState = {
    overlayRandomToken: "",
    showOverlayView: OverlayView.NO_VIEW,
    downloadPath: "",
    overlayMessage:"",
    apiRoute: ''
}

const overlay= (state = defaultState, action) => {
    switch (action.type) {
        case 'SET_OVERLAY_VIEW':
            return {
                ...state,
                showOverlayView: action.exportView
            };
        case 'SET_OVERLAY_TOKEN':
            return {
                ...state,
                overlayRandomToken: action.randomToken
            };
        case 'SET_OVERLAY_MESSAGE':
            return{
                ...state,
                overlayMessage : action.overlayMessage 
            } 
        case 'SET_DOWNLOAD_PATH':
            return{
                ...state,
                downloadPath : action.path 
            }
        case 'SET_API_ROUTE':
            return{
                ...state,
                apiRoute : action.route 
            }

        default:
            return state;
    }
};

export default overlay;