import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Typography, Button} from '@material-ui/core';
import {connect} from 'react-redux';

const useStyles = makeStyles((theme) => ({
    taxPresetColumn : {
        with:'100vw',
        background: '#F9F9F9',
        borderRadius: '5px',
        marginBottom: '8px',
        padding:'8px 8px',
        '&:last-child' : {
            marginBottom: '0px'
        },
        "& .deleteIcon" : {
            visibility: 'hidden'
        },
        "&:hover .deleteIcon" : {
            visibility: 'visible'
        }
    }
}))

const TaxRates = (props) => {
    const classes = useStyles();


    const onDeleteClick = (tcsObj) => {
        if(window.confirm('Are you sure you want to delete this tcs type?')) {
            props.onSaveOrDeleteClick(tcsObj);
        }
    }

    return (
        <Grid item md={12}>
            <Grid item md={12} style={{marginTop:'24px'}}>
                {props.taxArrayList && props.taxArrayList.length > 0 ? 
                    props.taxArrayList.map((tax) => {
                    return <Grid item container 
                                key={props.fromTCSTypesListComponent ? tax.name : tax.id} 
                                justifyContent="space-between"
                                alignItems='center'
                                className={classes.taxPresetColumn}>

                                <Grid item>
                                    <Grid item>
                                        <Typography className="tax_preset">
                                            {tax.name}
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid item>

                                <div style={{display:'flex', alignItems:'center', marginLeft:'auto'}}>
                                    <Typography className="tax_preset">
                                        {`${tax.rate}%`}
                                    </Typography>

                                    {props.fromTCSTypesListComponent &&
                                    <Button 
                                        className="deleteIcon"
                                        onClick={() => onDeleteClick(tax)}
                                        style={{
                                            fontSize: "16px",
                                            minWidth: '20px',
                                            paddingLeft:'8px',
                                            paddingRight:'8px',
                                            marginLeft:'8px'
                                        }}
                                        title="Delete Tax Preset">
                                        <i className="flaticon-delete-1" style={{height:'25px'}}></i>
                                    </Button> 
                                    }
                                    </div>                
                                </Grid> 
                            </Grid>
                    })
                    :
                    <Grid item container justifyContent='center'
                        className={classes.taxPresetColumn}>
                        <Typography>
                            {props.fromTaxPresetComponent ? 'No Tax Preset Found' : 'No TCS Types Found'}
                        </Typography>
                    </Grid>
                }   
            </Grid> 
        </Grid>
    );
}

const mapStateToProps = (state) => ({
    selectedAccount: state.invoiceAccounts.selectedAccount
});

export default connect(mapStateToProps)(TaxRates);