import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import { login } from '../actions/invoiceAuth';
import {CircularProgress, Grid} from '@material-ui/core';
import { getUserDetailsObjApi } from '../services/authService';
import { consoleToLog } from '../util/AppUtil';
import {setUserObj} from '../actions/invoiceAccount';
import {history} from '../routers/AppRouter';
import { fetchAccessToken } from '../util/AppUtil';
import { logout } from '../actions/invoiceAuth';
import { clearSelectedInvoiceFilters } from '../actions/invoiceFilters';
import { setSelectedDrawerState } from '../actions/drawer';
import { clearSelectedTransactionFilters } from "../actions/transactionFilters";
import { setClearInvoiceAccounts } from '../actions/invoiceAccount';
import { SelectedDrawerState } from '../actions/drawer';
import {getInvoiceAccountsList} from '../actions/invoiceAccount';
import { AppRoutes } from '../util/AppUtil';
import { setLoading } from '../actions/loading';

const RootPage = (props) => {

    useEffect(() => {
        let url_string = window.location.href;
        let url = new URL(url_string);
        let service_name = url.searchParams.get("service");
        let back_url = url.searchParams.get("back_url");
        let first_login = url.searchParams.get("first_login");

        if(props.location.state?.logOut) {
            clearAllReduxAndRedirectToAuthApp(back_url);
        } else {

            const fetchToken = async () => {
                try {
                const data = await fetchAccessToken('invoices');
                if(data) {
                    consoleToLog('Response authenticateApi', data);
                    const {access_token, redirect_url, invalid_service, scope} = data;
                    if(access_token) {
                        if(scope?.includes('invoices')) {
                            getUserDetailsObjApi()
                                .then((response) => {
                                    const res = response.data;
                                    consoleToLog('Response getUserDetailsObjApi: ', res);
                                    props.setLoading(true);
                                    
                                    let userObj = {
                                        ...res,
                                        first_login: first_login === "true",
                                    }
                                    props.setUserObj(userObj);
                                    props.getInvoiceAccountsList(props.selectedAccount)
                                    if(userObj?.first_login) {
                                        history.push(AppRoutes.SETUP);
                                    } else {
                                        history.push(AppRoutes.DASHBOARD);
                                    }
                                })
                                .catch((e) => {
                                    consoleToLog('Error getUserDetailsObjApi: ', e);
                                    if(e.response.data && e.response.data.message) {
                                        return;
                                    }
                                });
                        } else {
                            window.open(`${process.env.REACT_APP_AUTH_APP_DOMAIN_NAME}/not-registered?service=invoices`, '_self');
                        }
                    }  else {
                        window.open(`${process.env.REACT_APP_AUTH_APP_DOMAIN_NAME}/login?service=invoices`, '_self');
                    }

                } 
                } catch (error) {
                    consoleToLog("error fetchAccessToken", error);
                }
            };
        
            fetchToken();
        }
    }, []);

    const clearAllReduxAndRedirectToAuthApp = (back_url) => {
        props.logout();
        props.setClearInvoiceAccounts();
        props.clearSelectedInvoiceFilters();
        props.setSelectedDrawerState(SelectedDrawerState.SHOW_DASHBOARD);
        props.clearSelectedTransactionFilters();
        return window.open(`${process.env.REACT_APP_AUTH_APP_DOMAIN_NAME}/logout?service=invoices${back_url && back_url !== '' ? `&back_url=${back_url}` : ''}`, '_self')
    }

    return (
        <div>
            {/* <Grid item container justifyContent='center'>
                <CircularProgress size={30} style={{marginTop:'32px'}}/>
            </Grid> */}
        </div>
    );
}

const mapStateToProps = (state) => ({
    accessToken: state.invoiceAuth,
    drawerState: state.drawerState.setDrawerState,
    selectedAccount: state.invoiceAccounts.selectedAccount
})

const mapDispatchToProps = (dispatch) => ({
    setUserObj: (user_obj) => dispatch(setUserObj(user_obj)),
    getInvoiceAccountsList: (selectedAccount) => dispatch(getInvoiceAccountsList(selectedAccount)),
    setLoading: (isLoading) => dispatch(setLoading(isLoading)),

    logout: () => dispatch(logout()),
    clearSelectedInvoiceFilters: () => dispatch(clearSelectedInvoiceFilters()),
    setClearInvoiceAccounts: () => dispatch(setClearInvoiceAccounts()),
    setSelectedDrawerState: (drawerState) => dispatch(setSelectedDrawerState(drawerState)),
    clearSelectedTransactionFilters: () => dispatch(clearSelectedTransactionFilters())
})

export default connect(mapStateToProps, mapDispatchToProps)(RootPage);