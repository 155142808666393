import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {connect} from 'react-redux';
import { 
    Container, Typography, Grid, Paper, Box, Button, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, 
    Divider, InputAdornment, Link
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import {getEntityClientInvoicesReportApi} from '../services/authService';
import { consoleToLog } from '../util/AppUtil';
import { useIsMount } from './useIsMount';
import moment from 'moment';
import { formatAmount } from '../util/currencyUtil';
import printJS from 'print-js';
import { useSnackbar } from 'notistack';
import { usePropsContext } from './context';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import LabelAndEntityComponent from './LabelAndEntityComponent';
import DownloadPrintExportComponent from './DownloadPrintExportComponent';

const useStyles = makeStyles((theme) => ({
    mainContent : {
        backgroundColor: theme.palette.primary.light, 
        transition: ".5s", 
        flexGrow: 1,
        //overflow:'hidden',
        height:'calc(100vh - 48px)',
        overflow:'auto',
        overflowX:'hidden'
        },
    container: {
        marginTop: '10px',
        display:'flex',
        justifyContent:"space-between",
        padding:'0px 16px'
    },
    paper1: {
        padding: theme.spacing(3),
    },
    paper2: {
        padding: theme.spacing(2),
    },
    tableContainer: {
        marginTop: theme.spacing(2),
    },
    table: {
        minWidth: 650,
    },
    dueAmount: {
        color: 'red',
    },
    actionButtons: {
        marginTop: theme.spacing(2),
    },
    entityBox: {
        padding: theme.spacing(2),
        borderRadius: '8px',
        backgroundColor: theme.palette.background.default,
        cursor: 'pointer',
        border: `1px solid ${theme.palette.divider}`,
        overflow: 'hidden',
        '&:hover': {
            backgroundColor: theme.palette.action.hover,
        },
        '&.selected': {
            backgroundColor: theme.palette.action.selected,
            border: `2px solid green`,
        },
        position:'relative',
    },
    selectedEntity: {
        backgroundColor: theme.palette.action.selected,
    },
    boldText: {
        fontWeight: 600,
        paddingTop: '24px'
    },
    imageStyles: {
        position:'absolute',
        right:'16px',
        bottom:'16px',
        width:'25px'
    },
    listContainerStyle: {
        '& .showPaidText': {
            display:'block'
        },
        '& .client_statement_btn': {
            display:'none',
        },
        '&:hover' : {
            '& .showPaidText': {
                display:'none'
            },
            '& .client_statement_btn': {
                display:'block',
                position:"absolute",
                top:'12px',
                right:'8px',
                paddingTop:0,
                paddingBottom:0,
                color:'#4a5568',
                background:'#fff',
                border:'1px solid #ccc',
                width:'170px'
            },
        }
    }
    }
));    

const RevenueByClientReport = (props) => {
    const classes = useStyles();
    const isMount = useIsMount();
    const {enqueueSnackbar} = useSnackbar();


    const [selectedEntities, setSelectedEntities] = useState([...props.selectedAccount?.entities]);
    const [loading, setLoading] = useState(false);
    const [clientInvoiceData, setClientInvoiceData] = useState([]);
    const [searchClient, setSearchClient] = useState('');
    const [dateRange, setDateRange] = useState(undefined);
    const [dowloadStatementUrl, setDownloadStatementUrl] = useState(undefined);

    const [openDetailsModal, setOpenDetailsModal] = useState(false);

        const {handleClientSummaryDialogOpen, fullName} = usePropsContext();

    useEffect(() => {
        document.title = `Reports - ${fullName}`;
        fetchInvoicesData();
    }, []);

    useEffect(() => {
        if(!isMount) {
            setSelectedEntities([...props.selectedAccount?.entities]);
        }
    }, [props.selectedAccount.id]);

    useEffect(() => {
        if(!isMount) {
            fetchInvoicesData();
        }
    }, [selectedEntities, searchClient, dateRange]);

    useEffect(() => {
        if(!isMount) {
            if(dowloadStatementUrl) {
                printJS({printable: dowloadStatementUrl, type:'pdf', showModal:true});
                setDownloadStatementUrl(undefined);
            }
        }
    }, [dowloadStatementUrl]);

    const entities = [...props.selectedAccount?.entities];
    const invoice_account_id = props.selectedAccount.id;
    const fromdate = dateRange ? moment(dateRange?.startDate).format('YYYY-MM-DD') : undefined; 
    const todate =  dateRange ? moment(dateRange?.endDate).format('YYYY-MM-DD') : undefined;
    const accCurrencyObj = props.selectedAccount?.currencies;

    const fetchInvoicesData = async() => {

        setLoading(true);
        const entity_id = selectedEntities && selectedEntities.length > 0 ? selectedEntities.map((entity) => entity.id).join(',') : undefined;
        const fromdate = dateRange ? moment(dateRange?.startDate).format('YYYY-MM-DD') : undefined; 
        const todate =  dateRange ? moment(dateRange?.endDate).format('YYYY-MM-DD') : undefined;
        const client_name = searchClient ? searchClient : undefined;

        try {
            const response = await getEntityClientInvoicesReportApi(invoice_account_id, entity_id, client_name, fromdate, todate);
            const res = response.data;
            consoleToLog("Response getEntityClientInvoicesReportApi", res);
            setClientInvoiceData(res.items);
            setLoading(false);
        } catch(e) {
            consoleToLog("Error getEntityClientInvoicesReportApi", e.response);
            setLoading(false);
        }
    }

    const checkIfEntityAlreadySelected = (entity) => {
        const alreadySelectedEntity = selectedEntities.filter((selectedEntity) => selectedEntity.id === entity.id);
        return alreadySelectedEntity && alreadySelectedEntity.length > 0 ? true : false;
    }

    const handleEntityClick = (entity) => {
        if(checkIfEntityAlreadySelected(entity)) {
            setSelectedEntities(selectedEntities.filter((selectedEntity) => selectedEntity.id !== entity.id));
        } else {
            setSelectedEntities(oldEntities => [entity, ...oldEntities]);
        }
    };

    return (
        <div className={classes.mainContent}>
            <Container className={classes.container} maxWidth='xl' disableGutters>
                <Box width={'100%'} height={'100%'} display='flex' 
                    style={{marginBottom:'24px'}}
                    justifyContent='space-between'>
                    <Box width='73%'>
                        <Paper className={classes.paper1} variant='outlined'>
                            <LabelAndEntityComponent heading={'Revenue by Clients Report'}
                                dateRange={dateRange}
                                setDateRange={setDateRange}
                                entities={entities}
                                selectedEntities={selectedEntities}
                                handleEntityClick={handleEntityClick}/>
                            
                            <Grid item container 
                                alignItems='center'
                                spacing={1}
                                style={{marginBottom: '16px'}}>
                                <Grid item md={7}>
                                    <Typography variant='h6' 
                                        style={{margin:0}}
                                        className={'invoice_text_color'}>
                                        Detailed Report
                                    </Typography>

                                    <Typography variant='body2' className={'invoice_text_color'}>
                                        Below is a list of all your customers and their total paid and due amounts
                                    </Typography>
                                </Grid>

                                <Grid item md={5}>
                                    <TextField 
                                        fullWidth
                                        variant='outlined'
                                        placeholder='Search Client'
                                        value={searchClient}
                                        onChange={(e) => setSearchClient(e.target.value)}
                                        margin='dense'
                                        InputProps={{
                                            startAdornment: (
                                            <InputAdornment position="start">
                                                {/* <SearchIcon className={classes.searchicon}/> */}
                                                <i className="flaticon-search"></i>
                                            </InputAdornment>
                                            )
                                        }}
                                        InputLabelProps={{style: {fontSize: 14, color: "#ccc"}}}
                                    />
                                </Grid>
                            </Grid>

                            <TableContainer component={Paper} elevation={0}>
                                <Table className={classes.table} aria-label="revenue report">
                                    <TableHead style={{background:"#f7f7f7"}}>
                                        <TableRow>
                                            <TableCell className='tableheading_style'>Name</TableCell>
                                            <TableCell className='tableheading_style'>Opening Balance</TableCell>
                                            <TableCell className='tableheading_style'>Total Billing</TableCell>
                                            <TableCell className='tableheading_style'>Total paid</TableCell>
                                            <TableCell className='tableheading_style'>Amount due</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {(loading ? Array.from(new Array(clientInvoiceData.length)) : clientInvoiceData).map((clientInvoicesObj, index) => {
                                            let isLastElement = index === clientInvoiceData.length - 1;
                                            let currencyObj = clientInvoicesObj?.currency;
                                        return  clientInvoicesObj
                                            ?
                                            <TableRow key={index} hover style={{cursor:'pointer'}}
                                                className={classes.listContainerStyle}> 
                                                <TableCell>
                                                    <Typography variant='body2' 
                                                        className={isLastElement && classes.boldText}>
                                                        {clientInvoicesObj.customer_name}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant='body2'
                                                        className={isLastElement && classes.boldText}>
                                                        {!isLastElement && formatAmount(clientInvoicesObj.opening_balance, isLastElement ? accCurrencyObj : currencyObj)}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant='body2'
                                                        className={isLastElement && classes.boldText}>
                                                        {!isLastElement && formatAmount(clientInvoicesObj.total_billing, isLastElement ? accCurrencyObj : currencyObj)}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant='body2'
                                                        className={isLastElement && classes.boldText}>
                                                        {formatAmount(clientInvoicesObj.total_paid, isLastElement ? accCurrencyObj : currencyObj)}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell style={{position:'relative'}}>
                                                    <Typography variant='body2'
                                                        className={`showPaidText ${isLastElement && classes.boldText}`}>
                                                        {formatAmount(clientInvoicesObj.amount_due, isLastElement ? accCurrencyObj : currencyObj)}
                                                    </Typography>
                                                    <Button className='client_statement_btn'
                                                        onClick={() => handleClientSummaryDialogOpen(clientInvoicesObj)}>
                                                        
                                                        <DescriptionOutlinedIcon fontSize='small' style={{position:'relative', top:'4px', marginRight:'6px', color:'#4a5568', fontSize:'18px'}}/>
                                                        Client Statement
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                            :
                                            <TableRow key={index} hover>
                                                <TableCell component="th" scope="row"
                                                    className='tablebody_style'>
                                                        <Typography variant='h5'>
                                                            <Skeleton animation='wave' variant="text" />
                                                        </Typography>
                                                </TableCell>
                                                <TableCell component="th" scope="row"
                                                    className='tablebody_style'>
                                                        <Typography variant='h5'>
                                                            <Skeleton animation='wave' variant="text" />
                                                        </Typography>
                                                </TableCell>
                                                <TableCell component="th" scope="row"
                                                    className='tablebody_style'>
                                                        <Typography variant='h5'>
                                                            <Skeleton animation='wave' variant="text" />
                                                        </Typography>
                                                </TableCell>
                                                <TableCell component="th" scope="row"
                                                    className='tablebody_style'>
                                                        <Typography variant='h5'>
                                                            <Skeleton animation='wave' variant="text" />
                                                        </Typography>
                                                </TableCell>
                                                <TableCell component="th" scope="row"
                                                    className='tablebody_style'>
                                                        <Typography variant='h5'>
                                                            <Skeleton animation='wave' variant="text" />
                                                        </Typography>
                                                </TableCell>
                                            </TableRow>
                                        }
                                    
                                    )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </Box>

                    <Box width='25.5%' display='flex' flexDirection='column' gridRowGap='16px'>
                        <DownloadPrintExportComponent invoice_account_id={invoice_account_id}
                            selectedEntities={selectedEntities}
                            searchClient={searchClient}
                            searchItem={undefined}
                            fromdate={fromdate}
                            todate={todate}
                            fromRevenueByClientReports={true}
                            />
                    </Box>
                </Box>

                <iframe id="pdfFrame" style={{display:'none'}}></iframe>
            </Container>
        </div>
    );
}

const mapStateToProps = (state) => ({
    selectedAccount: state.invoiceAccounts.selectedAccount,
    drawerState: state.drawerState.setDrawerState
})

export default connect(mapStateToProps)(RevenueByClientReport);