import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Grid, Typography, Button, CircularProgress} from '@material-ui/core';
import { useIsMount } from '../useIsMount';
import { consoleToLog, currencyFormatter } from '../../util/AppUtil';
import { getClientDetailsApi } from '../../services/authService';
import {connect} from 'react-redux';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
    statisticsLabel: {
        fontSize:"16px",
        fontWeight:"500"
    },
    labelsContainer: {
        borderRight: '1px solid #eef2f7',
    },
    labels: {
        color: '#6a7685',
        fontSize: '13px',
        lineHeight: '20px',
        opacity: '.75',
        fontWeight:'500',
    },
    valuesStyles: {
        color: '#4a5568',
        lineHeight: '28px',
        fontWeight:'500',
        fontSize:'16px'
    }
}))

const ClientStatistics = (props) => {
    const classes = useStyles();
    const isMount = useIsMount();
    const {enqueueSnackbar} = useSnackbar();

    const [detailsObj, setDetailsObj] = useState(undefined);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getClientDetails();
    }, [])

    const getClientDetails = () => {
        const invoice_account_id = props.selectedAccount?.id;
        const client_id = props.clientInfo?.id;

        setLoading(true);
        getClientDetailsApi(invoice_account_id, client_id)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response getClientDetailsApi: ', res);
                setLoading(false);
                setDetailsObj(res);
            })
            .catch((e) => {
                consoleToLog('Error getClientDetailsApi: ', e.response);
                setLoading(false);
                if(e.respose.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {variant:'error'});
                    return;
                }
            })
    }



    return (
        <div style={{padding:"0px 16px"}}>
        {loading ?
            <Grid item container justifyContent='center' alignItems='center' style={{marginTop:'8px'}}>
                <CircularProgress size={25} />
            </Grid>
            :
            <>
            <Typography className={classes.statisticsLabel}>
                Statistics
            </Typography>

            <Grid item container spacing={2} style={{marginTop:'8px'}}>
                <Grid item sm={2} className={classes.labelsContainer}>
                    <Typography variant="body1" className={classes.labels}>
                        Invoiced
                    </Typography>

                    <Typography className={classes.valuesStyles}>
                        {detailsObj?.amount_invoiced ? currencyFormatter.format(detailsObj?.amount_invoiced) : currencyFormatter.format(0) }
                    </Typography>
                </Grid>
                <Grid item sm={2} className={classes.labelsContainer}> 
                    <Typography variant="body1" className={classes.labels}>
                        Collected
                    </Typography>
                    
                    <Typography className={classes.valuesStyles}>
                        {detailsObj?.amount_collected ? currencyFormatter.format(detailsObj?.amount_collected) : currencyFormatter.format(0)}
                    </Typography>
                </Grid>
                <Grid item sm={2} className={classes.labelsContainer}>
                    <Typography variant="body1" className={classes.labels}>
                        Amount Due
                    </Typography>
                    
                    <Typography className={classes.valuesStyles}>
                        {detailsObj?.amount_due ? currencyFormatter.format(detailsObj?.amount_due) : currencyFormatter.format(0)}
                    </Typography>
                </Grid>
                <Grid item sm={2} className={classes.labelsContainer}>
                    <Typography variant="body1" className={classes.labels}>
                        Past Due
                    </Typography>
                    
                    <Typography className={classes.valuesStyles}
                        style={{color: detailsObj?.amount_past_due > 0 && 'red'}}>
                        {detailsObj?.amount_past_due ? currencyFormatter.format(detailsObj?.amount_past_due) : currencyFormatter.format(0)}
                    </Typography>
                </Grid>
            </Grid>
            </>}
            
        </div>
    );
}

const mapStateToProps = (state) => ({
    selectedAccount: state.invoiceAccounts.selectedAccount
});

export default connect(mapStateToProps)(ClientStatistics);