import { useEffect, useState} from 'react';
import {connect} from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Menu, Tooltip, Popover, Badge, AppBar, Toolbar, MenuItem, Fab  } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import {setClearInvoiceAccounts, setSelectedInvoiceAccount} from '../actions/invoiceAccount';
import {setSelectedDrawerState, SelectedDrawerState} from '../actions/drawer';
import {history} from '../routers/AppRouter';
import { AppRoutes, consoleToLog, trackGAEvent } from '../util/AppUtil';
import { clearSelectedInvoiceFilters } from '../actions/invoiceFilters';
import {clearSelectedTransactionFilters} from '../actions/transactionFilters';
import {logoutApi} from '../services/authService';
import AddIcon from '@material-ui/icons/Add';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import BusinessOutlinedIcon from '@material-ui/icons/BusinessOutlined';
import {onUpgradeNowClick} from '../util/AppUtil';
import { setGoToBilling } from '../actions/invoiceAccount';
import {AccessLevel} from '../util/AppUtil';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

const useStyles = makeStyles((theme) => ({
    MuiPopoverroot : {
        '& .MuiPopover-paper' : {
        width:'550px !important',
        borderRadius: '0px !important',
        height:'calc(100vh - 51px)',
        marginTop:"16px",
        flexGrow: 1,
        scrollbarWidth: 'none',
        marginLeft:"16px",
        overflow:'auto',
        "&::-webkit-scrollbar": {
            display: "none"
        } 
        }
    },
    logo: {
        maxWidth: 120,
        marginLeft: "15px",
        cursor:'pointer'
    },
    login: {
        marginLeft: 'auto',
    },
    selectEmpty: {
        color: "white",
        padding: "0 14px",
        backgroundColor: "transparent"
    },
    icons : {
        backgroundColor: "transparent",
        color: "white",
        fontSize: "18px",
        marginTop: "8px",
        minWidth: "40px"
    },
    colorSecondary : {
        color: theme.palette.primary.main,
        background: theme.palette.secondary.contrastText
    },
    avatar : {
        minHeight: "28px",
        height: "28px",
        width: "28px",
        fontSize: '13px !important',
        color: '#fff !important',
        border: '1px solid #fff !important',
        background:'#3d3d3d !important',
        display:'flex',
        alignItems:'center'
    },
    addIconStyle: {
        [theme.breakpoints.down(1450)] : {
            position:'relative',
            top:'2px'
        }
    },
    actionIconStyle: {
        width:'20px',
        marginRight: '6px',
        filter:"invert(30%)"
    },
  }));

  const Header = (props) => {
    const classes = useStyles(props);
    const [anchorEl, setAnchorEl] = useState(null);
    const [profileEl, setProfileEl] = useState(null);
    const  name = props.selectedAccount ? props.selectedAccount.name : '';
    const [selectedText, setSelectedText] = useState(name);
    const [accChanged, setAccChanged] = useState(false);
    const [anchorEl2, setAnchorEl2] = useState(null);

    const canCreateInvoice = props.selectedAccount?.can_create_invoice;
    const entityLimitExceeded = props.selectedAccount?.entity_limit_exceeded;
    const { access_level } = props.selectedAccount || {};
    const accessLevelViewer = AccessLevel.getUserAccessLevelValue(access_level) === AccessLevel.VIEWER ? true : false;


    useEffect(() => {
        setSelectedText(props.selectedAccount?.name)
    }, [props.selectedAccount?.name])

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (event) => {
        const value = event.currentTarget.dataset.myValue;
        setSelectedText(value ? event.currentTarget.dataset.myValue : selectedText);
        setAnchorEl(null);
    };

    const handleProfileMenuClick = (event) => {
        setProfileEl(event.currentTarget);
    };

    const handleProfileMenuClose = () => {
        setProfileEl(null);
    };

    const onLogoutClick = () => {
        logoutApi()
            .then((response) => {
                const res = response.data;
                consoleToLog('Response logoutApi: ', res);
                localStorage.clear();
                props.setClearInvoiceAccounts();
                props.setSelectedDrawerState(SelectedDrawerState.SHOW_DASHBOARD);
                props.clearSelectedInvoiceFilters();
                props.clearSelectedTransactionFilters();
                window.open(`${process.env.REACT_APP_AUTH_APP_DOMAIN_NAME}/login?service=invoices`, '_self');
            })
            .catch((e) => {
                consoleToLog('Error logoutApi: ', e);
                if(e.response.data && e.response.data.message) {
                    return;
                }
            })

    };

    const clearTransactionAndInvoicesState = () => {
        history.push(AppRoutes.PROFILE);
        props.transactionsComponentState && props.setTransactionComponentState(undefined);
        props.invoicesComponentState && props.setInvoiceComponentState(undefined);
    }

    const onMyProfileClick = () => {
        trackGAEvent(props.selectedAccount.name, 'My Profile Clicked', `${props.user.firstname} ${props.user.lastname}`);
        props.setSelectedDrawerState(SelectedDrawerState.SHOW_PROFILE);
        clearTransactionAndInvoicesState();
        handleProfileMenuClose();
    }

    const onMyInvoiceAccountsClick = () => {
        trackGAEvent(props.selectedAccount.name, 'My Invoice Accounts Clicked', `${props.user.firstname} ${props.user.lastname}`);
        props.setSelectedDrawerState(SelectedDrawerState.SHOW_INVOICE_ACCOUNTS);
        clearTransactionAndInvoicesState();
        handleProfileMenuClose();
    }

    const onChangeAccountClick = (acc) => {
        trackGAEvent(props.selectedAccount.name, 'Invoice Account - Invoice Account changed from header', `${props.user.firstname} ${props.user.lastname}`);
        if (acc.id !== props.selectedAccount.id) {
            consoleToLog('Change Accounts Clicked');
            //Set selected org
            props.setSelectedInvoiceAccount(acc);
            props.clearSelectedInvoiceFilters();
            setAccChanged(true);
            setAnchorEl(null);
        }
    }

    const handleActionsMenuOpen = (event) => {
        setAnchorEl2(event.currentTarget);
    };

    const handleActionsMenuClose = () => {
        setAnchorEl2(null)
    }

    const onNewInvoiceClick = () => {
        // if(canCreateInvoice) {
        //     props.handleClickDialogOpen();
        //     handleActionsMenuClose();
        // } 
        props.invoicesComponentState && props.setInvoiceComponentState();
        props.handleClickDialogOpen();
        handleActionsMenuClose();
    }

    const onAddPaymentClick = () => {
        props.transactionsComponentState && props.setTransactionComponentState();
        props.handleOpenAddNewPaymentDrawer();
        handleActionsMenuClose();
    }

    const onNewClientClick = () => {
        props.handleClientDrawerOpen();
        handleActionsMenuClose();
    }

    const onUpgradeClick = (e) => {
        e.stopPropagation();
        onUpgradeNowClick(props);
    }

    const onNewEstimateClick = () => {
        //props.setShowEstimateScreen(true);
        props.estimateComponentState && props.setEstimateComponentState();
        props.setShowEstimateClicked(true);
        props.setOpenDialog(true);
        handleActionsMenuClose();
    }

    return (
        <AppBar position="static" elevation={0} style={{zIndex:99}}>
            <Toolbar variant="dense">
                {!props.notFoundPage &&
                    <Tooltip title="Drawer Open/Close" arrow>
                        <Button
                            onClick={props.drawerAction}
                            className={classes.icons}
                            >
                            <i className="flaticon-menu"></i>
                        </Button>
                    </Tooltip>}
                <img src="/images/eprocessify-logo-header.svg"
                    onClick={() => history.push(AppRoutes.DASHBOARD)}
                    alt="logo" 
                    className={classes.logo}/>
                {!props.notFoundPage && 
                <div className={classes.login}>
                    <Tooltip title="Organizations" arrow>
                        <Button aria-controls="simple-menu" 
                            aria-haspopup="true"
                            onClick={handleClick}
                            endIcon={<ArrowDropDownIcon />}
                            style={{
                                color: "white",
                                border: '1px solid #ffffff',
                                fontWeight: "400",
                                fontSize: "12px"
                            }}
                            variant="outlined"
                            >
                            {selectedText}
                        </Button>
                    </Tooltip>
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        >
                            {props.invoiceAccountsList && !props.notFoundPage &&
                                    props.invoiceAccountsList.length !== 0 ? (
                                        props.invoiceAccountsList.map((acc) => {
                                            return <MenuItem key={acc.id} data-my-value={acc.name} 
                                                        onClick={() => onChangeAccountClick(acc)}
                                                    >
                                                    {acc.name}
                                                    </MenuItem>
                                        })
                                    ) : <MenuItem>No Accounts Found</MenuItem>
                            }
                        {/* <MenuItem onClick={handleClose} data-my-value="CodeToArt Technology">CodeToArt Technology</MenuItem>
                        <MenuItem onClick={handleClose} data-my-value="CA Rushab JAin Org">CA Rushab JAin Org</MenuItem>
                        <MenuItem onClick={handleClose} data-my-value="Girish Associates">Girish Associates</MenuItem> */}
                        
                    </Menu>
                    {!accessLevelViewer &&
                    <>
                        <Tooltip title="Actions" arrow>
                                <Button  style={{marginLeft:'8px'}}
                                    onClick={handleActionsMenuOpen}
                                    className={classes.icons}>
                                    <i className={`flaticon-add-2 ${classes.addIconStyle}`}></i>
                                </Button>
                        </Tooltip>
                        <Menu
                            id="action-menu"
                            anchorEl={anchorEl2}
                            keepMounted
                            open={Boolean(anchorEl2)}
                            onClose={handleActionsMenuClose}
                            style={{marginTop:'24px', width:'210px'}}
                        >
                            <MenuItem onClick={onNewInvoiceClick}>
                                <DescriptionOutlinedIcon className={classes.actionIconStyle}/>
                                New Invoice
                                {!canCreateInvoice || entityLimitExceeded &&
                                    <span onClick={onUpgradeClick} 
                                        className='link_style' 
                                        style={{fontSize:'10px', marginLeft:'8px'}}>
                                        Upgrade
                                    </span>
                                }
                            </MenuItem>
                            <MenuItem onClick={onNewEstimateClick}>
                                <AddCircleOutlineIcon className={classes.actionIconStyle}/>
                                New Estimate
                            </MenuItem>
                            <MenuItem onClick={onNewClientClick}>
                                <BusinessOutlinedIcon style={{position:'relative', top:'-2px'}} 
                                    className={classes.actionIconStyle}/>
                                New Client
                            </MenuItem>
                            <MenuItem onClick={onAddPaymentClick}>
                                <MonetizationOnOutlinedIcon  style={{color:'green'}} 
                                    className={classes.actionIconStyle}/>
                                Add Payment
                            </MenuItem>
 
                        </Menu>
                    </>}
                    {/* <Tooltip title="Activity Feed" arrow>
                        <Button
                            style={{
                                backgroundColor: "transparent",
                                color: "white",
                                fontSize: "18px",
                                alignItem: "right",
                                marginTop: "8px",
                                minWidth: "40px",
                                marginLeft: "15px"
                            }}
                            >
                            <i className="flaticon-chat-1"></i>
                        </Button>
                    </Tooltip> */}
                    <Tooltip title={props.user.firstname + ' ' + props.user.lastname} arrow>
                        <Button style={{position:'relative', top:'-2.5px'}}
                            onClick={handleProfileMenuClick}
                            className={classes.icons}>
                            {/* <i className={`flaticon-user ${classes.profileIcon}`}></i> */}
                            <Fab className={classes.avatar}>
                                {props.user?.firstname?.charAt(0) + props.user?.lastname?.charAt(0)}
                            </Fab>
                        </Button>
                    </Tooltip>
                    <Menu
                        id="profile-menu"
                        anchorEl={profileEl}
                        keepMounted
                        open={Boolean(profileEl)}
                        onClose={handleProfileMenuClose}
                        style={{marginTop:'32px', width:'200px'}}
                        >
                        <MenuItem onClick={onMyProfileClick}>My Profile</MenuItem>
                        <MenuItem onClick={onMyInvoiceAccountsClick}>My Invoice Accounts</MenuItem>
                        <MenuItem onClick={onLogoutClick}>Logout</MenuItem>
                    </Menu>
                </div> }
                {/* <Button color="inherit" className={classes.login}>Login</Button> */}
            </Toolbar>
        </AppBar>
    )
}

const mapStateToProps = (state) => ({
    invoiceAccountsList: state.invoiceAccounts.invoiceAccountsList,
    selectedAccount: state.invoiceAccounts.selectedAccount,
    drawerState: state.drawerState.setDrawerState,
    user: state.invoiceAccounts.user,
});

const mapDispatchToProps = (dispatch) => ({
    setClearInvoiceAccounts: () => dispatch(setClearInvoiceAccounts()),
    setSelectedDrawerState: (drawerState) => dispatch(setSelectedDrawerState(drawerState)),
    setSelectedInvoiceAccount: (account) => dispatch(setSelectedInvoiceAccount(account)),
    clearSelectedInvoiceFilters: () => dispatch(clearSelectedInvoiceFilters()),
    clearSelectedTransactionFilters: () => dispatch(clearSelectedTransactionFilters()),
    setGoToBilling: (goToBilling) => dispatch(setGoToBilling(goToBilling))
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);