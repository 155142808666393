import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    mainContent: {
        width:'100%',
        height:'100%',
        backgroundColor: theme.palette.primary.light
    },
    errorMessageContainer : {
        marginTop:'54px',
        borderRadius:'4px',
    },
    errorMsg : {
        textAlign:'center'
    },
    unAuthorizedImage: {
        height: "26rem",
        width: "28rem",
        marginTop: "3rem",
    },
    unAuthorizedText: {
    fontSize: "21px",
    fontWeight: "600",
    textAlign:'center',
    marginLeft:'6%',
    marginTop:'-3%'
    },
}))

const NoAccessTokenPage = (props) => {
    const classes = useStyles();

    return (
        <div className={classes.mainContent}>
            <Grid container direction="row">
                <Grid
                    item
                    container
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    sm={12}
                >
                    <img
                    alt="Unauthorized Access"
                    src="/images/unauthorized.svg"
                    className={classes.unAuthorizedImage}
                    />
                </Grid>
                <Grid
                    item
                    container
                    direction="column"
                    
                >
                    <Typography className={classes.unAuthorizedText}>
                    Please login to <a href={process.env.REACT_APP_MAIN_APP_DOMAIN_NAME}>eProcessify</a> for authorized access. 
                    </Typography>
                </Grid>
            </Grid>
        </div>
    );
}

export default NoAccessTokenPage;