import React, {useState} from 'react';
import Modal from '@material-ui/core/Modal';
import ClearIcon from '@material-ui/icons/Clear';
import { Grid, Typography, Button, CircularProgress, FormControlLabel, FormGroup, Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {downloadInvoicePrintApi} from '../../services/authService';
import { consoleToLog, trackGAEvent } from '../../util/AppUtil';
import { logout } from '../../actions/invoiceAuth';
import { clearSelectedInvoiceFilters } from '../../actions/invoiceFilters';
import { SelectedDrawerState, setSelectedDrawerState } from '../../actions/drawer';
import { clearSelectedTransactionFilters } from "../../actions/transactionFilters";
import { setClearInvoiceAccounts } from '../../actions/invoiceAccount';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        position: 'absolute',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        width:'350px'
    },
    modalHeading: {
        fontSize:'18px',
        fontWeight:600,
        color:"#212121"
    },
    headingContainer: {
        background:'#f7f7f7',
        padding:'4px 8px 4px 16px'
    },
    printInvoiceText: {
        fontSize:'15px',
        color:'#212121',
        marginBottom:'8px',
        fontWeight:500
    }
}));

const PrintInvoiceModal = (props) => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();

    const {original_print, transport_print, customer_print} = props.selectedAccount.print_settings;
    const [loading, setLoading] = useState(false);
    const [originalPrintCopy, setOriginalPrintCopy] = useState(original_print);
    const [transportationPrintCopy, setTransportationPrintCopy] = useState(transport_print);
    const [customerPrintCopy, setCustomerPrintCopy] = useState(customer_print);

    const onPrintInvoiceClick = () => {
        const invoice_account_id = props.selectedAccount?.id;
        const invoice_id = props.invoiceObj?.id;

        setLoading(true);
        downloadInvoicePrintApi(invoice_account_id, invoice_id, originalPrintCopy, transportationPrintCopy, customerPrintCopy)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response downloadInvoicePrintApi: ', res);
                setLoading(false);

                const downloadableURL = res.document_signed_url;

                props.setDownloadInvoiceURL(downloadableURL);

                trackGAEvent(props.selectedAccount.name, 'Invoice Print Api', `${props.user.firstname} ${props.user.lastname}`);
            
                props.handlePrintInvoiceModalClose();
            })
            .catch((e) => {
                consoleToLog('Error downloadInvoicePrintApi: ', e);
                if(e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {variant: 'error'});
                    return;
                }
                props.handlePrintInvoiceModalClose();
            })
    }

    return (
        <Modal
            open={props.openPrintModal}
            onClose={props.handlePrintInvoiceModalClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className={classes.modal}
        >
            <div className={classes.paper}>
                <Grid item sm={12}>
                    
                    <Grid item sm={12}>
                        <Grid item container justifyContent='space-between' alignItems='center'
                            className={classes.headingContainer}>
                            <Grid item>
                                <Typography variant='body1' className={classes.modalHeading}>
                                    Invoice Print
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Button 
                                    onClick={props.handlePrintInvoiceModalClose}
                                    style={{minWidth:"20px"}}>
                                    <ClearIcon style={{color:"#212121"}}/>
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid item sm={12} style={{padding:'16px'}}>
                            <FormGroup>
                                <FormControlLabel control={
                                    <Checkbox  
                                        onChange = {(e)=>{
                                            setOriginalPrintCopy(e.target.checked);
                                            //updateInvoiceDisplaySettings(e.target.checked, paymentTerms, clientTerms);
                                        }}
                                        checked={originalPrintCopy}
                                    />
                                    } 
                                    label="Original Copy" />

                                    <FormControlLabel control={
                                        <Checkbox   
                                            onChange = {(e)=>{
                                                setTransportationPrintCopy(e.target.checked);
                                                //updateInvoiceDisplaySettings(paymentStub, e.target.checked, clientTerms );
                                            }}
                                            checked={transportationPrintCopy}
                                    />
                                    } 
                                    label="Transporter Copy" />

                                    <FormControlLabel control={
                                        <Checkbox   
                                            onChange = {(e)=>{
                                                setCustomerPrintCopy(e.target.checked);
                                                //updateInvoiceDisplaySettings(paymentStub, paymentTerms, e.target.checked);
                                            }}
                                            checked={customerPrintCopy}
                                    />
                                    } 
                                    label="Customer Copy" />
                            </FormGroup>
                            
                            <Grid item container justifyContent='center' alignItems='center' style={{marginTop:"16px"}}>
                                <Button variant='contained' 
                                    disabled={!originalPrintCopy && !transportationPrintCopy && !customerPrintCopy}
                                    onClick={onPrintInvoiceClick}
                                    color='primary'>
                                    {loading && <CircularProgress size={20} style={{marginRight:'8px', color:'#fff'}}/>}Print
                                </Button>
                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </Modal>
    );
}

const mapStateToProps = (state) => ({
    accessToken: state.invoiceAuth,
    selectedAccount: state.invoiceAccounts.selectedAccount,
    drawerState: state.drawerState.setDrawerState,
    user: state.invoiceAccounts.user,
});

const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(logout()),
    clearSelectedInvoiceFilters: () => dispatch(clearSelectedInvoiceFilters()),
    setClearInvoiceAccounts: () => dispatch(setClearInvoiceAccounts()),
    setSelectedDrawerState: (drawerState) => dispatch(setSelectedDrawerState(drawerState)),
    clearSelectedTransactionFilters: () => dispatch(clearSelectedTransactionFilters()),
});


export default connect(mapStateToProps, mapDispatchToProps)(PrintInvoiceModal);