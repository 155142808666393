import { getAxios } from "./axiosUtil";

export const getClientsListApi = (invoice_account_id, page, text) => {
    const param = {
        params : {
            page,
            text
        }
    }
    return getAxios().get(`${process.env.REACT_APP_CLIENTS_BASE_URL}/invoice_account/${invoice_account_id}/clients`, param);
}

export const getCustomFieldsForInvAccApi = (invoice_account_id) => {
    return getAxios().get(`${process.env.REACT_APP_CLIENTS_BASE_URL}/invoice_account/${invoice_account_id}/custom_fields`);
}

export const addCustomFieldApi = (invoice_account_id, name, default_value, show_invoice, type) => {
    const data = {
        name,
        default_value,
        show_invoice,
        type
    } 
    return getAxios().post(`${process.env.REACT_APP_CLIENTS_BASE_URL}/invoice_account/${invoice_account_id}/custom_field`, data);
}

export const editCustomFieldApi= (id, name, default_value, show_invoice, type) => {
    const data = {
        name,
        default_value,
        show_invoice,
        type
    } 
    return getAxios().put(`${process.env.REACT_APP_CLIENTS_BASE_URL}/invoice_account/custom_field/${id}/edit`, data);
}

export const onDeleteCustomFieldApi = (id) => {
    return getAxios().delete(`${process.env.REACT_APP_CLIENTS_BASE_URL}/invoice_account/custom_field/${id}/delete`);
}

export const editClientApi= (client_id, name, address, state, country, phone_number, email, tax_id,  user_defined_field) => {

    var data = new FormData();
    data.set('name', name);
    data.set('address', address);
    data.set('state', state ? state : '');
    data.set('country', country);
    data.set('phone_number', phone_number);
    data.set('email', email);
    data.set('tax_id', tax_id);
    data.append('user_defined_field', JSON.stringify(user_defined_field));

    const header = {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'Content-Type': 'multipart/form-data'
        }
    };

    return getAxios().put(`${process.env.REACT_APP_CLIENTS_BASE_URL}/${client_id}`, data, header);
}

export const addClientApi = (invoice_account_id, name, address, state, country, phone_number, email, tax_id, user_defined_field) => {

    var data = new FormData();
    data.set('invoice_account_id', invoice_account_id);
    data.set('name', name);
    data.set('address', address);
    if(state) data.set('state', state);
    data.set('country', country);
    data.set('phone_number', phone_number);
    data.set('email', email);
    data.set('tax_id', tax_id);
    data.append('user_defined_field', JSON.stringify(user_defined_field));
    
    const header = {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'Content-Type': 'multipart/form-data'
        }
    };

    return getAxios().post(`${process.env.REACT_APP_CLIENTS_BASE_URL}/client`, data, header);
}

export const deleteClientApi = (id) => {
    return getAxios().delete(`${process.env.REACT_APP_CLIENTS_BASE_URL}/${id}`);
}

export const getUploadURLApi = (filename, filetype) => {
    let data = {filename, filetype}
    return getAxios().post(`${process.env.REACT_APP_CLIENTS_BASE_URL}/signs3`, data);
}

export const getDownloadURLForClientAttachmentApi = (filepath) => {
    let data = {filepath}
    return getAxios().post(`${process.env.REACT_APP_CLIENTS_BASE_URL}/getsigns3url`, data);
}

export const addMultipleContactsApi = (invoice_account_id, contacts) => {
    let data = {contacts}
    return getAxios().post(`${process.env.REACT_APP_CLIENTS_BASE_URL}/invoice/${invoice_account_id}/contacts/multiple`,data);
}


export const autoCompleteClientListApi = (invoice_account_id, text) => {
    const param = {
        params: {
            text
        }
    }
    return getAxios().get(`${process.env.REACT_APP_CLIENTS_BASE_URL}/autocomplete_search/invoice/${invoice_account_id}/clients`, param);
}

export const getClientDetailsObjApi = (client_id) => {
    return getAxios().get(`${process.env.REACT_APP_CLIENTS_BASE_URL}/client/${client_id}/client_details`);
}

export const addContactApi = (invoice_account_id, first_name, last_name, title, email, phone_number, clients) => {
    const data = {
        first_name,
        last_name,
        title,
        email,
        phone_number,
        clients
    } 
    return getAxios().post(`${process.env.REACT_APP_CLIENTS_BASE_URL}/invoice/${invoice_account_id}/contact`, data);
}

export const editContactApi= (invoice_account_id, organization_id, id, first_name, last_name, title, email, phone_number, clients) => {
    const data = {
        organization_id,
        first_name,
        last_name,
        title,
        email,
        phone_number,
        clients
    } 
    return getAxios().put(`${process.env.REACT_APP_CLIENTS_BASE_URL}/invoice/${invoice_account_id}/contact/${id}/update`, data);
}

export const deleteContactApi = (id, client_id) => {
    const data={
        client_id
    }

    return getAxios().delete(`${process.env.REACT_APP_CLIENTS_BASE_URL}/contact/${id}/delete`, {
        data: {
            client_id
        }
    });
}


export const fullTextSearchApi = (organization_id, text) => {
    const param = {
        params: {
            text
        }
    }

    return getAxios().get(`${process.env.REACT_APP_CLIENTS_BASE_URL}/organization/${organization_id}/contact/fulltext`, param);
}

export const getContactListApi = (invoice_account_id) => {

    return getAxios().get(`${process.env.REACT_APP_CLIENTS_BASE_URL}/invoice/${invoice_account_id}/contacts`);
}

export const getClientListForContactApi = (id) => {
    return getAxios().get(`${process.env.REACT_APP_CLIENTS_BASE_URL}/contacts/${id}/client_list`);
}


export const directDeleteContactApi = (invoice_account_id, id) => {
    return getAxios().delete(`${process.env.REACT_APP_CLIENTS_BASE_URL}/invoice/${invoice_account_id}/contact/${id}/direct_delete`);
}

