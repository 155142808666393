import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Container, Button, Typography, Tooltip, Tabs, Tab, Paper} from '@material-ui/core';
import {connect} from 'react-redux';
import BasicInfo from './BasicInfo';
import ChangePassword from './ChangePassword';
import { SelectedDrawerState } from '../actions/drawer';
import MyInvoiceAccounts from './MyInvoiceAccounts';
import CreateInvoiceAccountDrawer from './CreateInvoiceAccountDrawer';
import {setHeightForComponent, AccessLevel} from '../util/AppUtil';
import { usePropsContext } from './context';

const useStyles = makeStyles((theme) => ({
    mainContent : {
        backgroundColor: theme.palette.primary.light, 
        transition: ".5s", 
        flexGrow: 1,
        //overflow:'hidden',
        height:'calc(100vh - 48px)',
        overflow:'auto',
        overflowX:'hidden'
        },
    container: {
        marginTop: "8px"
        },
    tab: {
        flexGrow: 1,
        borderRadius:0,
        borderBottom: "1px solid #c5b9b9",
        },
    settingsContainer: {
        width:'100%',
        background:'#fff',
        borderRadius:'4px',
        padding:'16px 24px',
        marginTop:'16px',
        marginBottom:'32px'
    },

    }
));    


const MyProfile = (props) => {
    const classes = useStyles();

    const { fullName, is_entity_email_unverified, isInvoiceAccountPlanFree, entityLimitExceeded } = usePropsContext();

    const [value, setValue] = useState(0);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [accountObj, setAccountObj] = useState(undefined);

    const handleDrawerOpen = (account) => {
        setOpenDrawer(true);
        setAccountObj(account)
    }

    const handleDrawerClose = () => {
        setOpenDrawer(false);
        setAccountObj(undefined);
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        if(props.drawerState === SelectedDrawerState.SHOW_PROFILE) {
            setValue(0);
        }

        if(props.drawerState === SelectedDrawerState.SHOW_INVOICE_ACCOUNTS) {
            setValue(2);
        }

    }, []);
    
    const { access_level } = props.selectedAccount || {};
    const accessLevelViewer = (AccessLevel.getUserAccessLevelValue(access_level) === AccessLevel.VIEWER) ? true : false;

    return (
        <div className={classes.mainContent}>
            <Container className={classes.container} maxWidth="xl">
                <Typography style={{fontSize:'20px', fontWeight:'600'}} color="primary">
                    Profile
                </Typography>

                <Grid item container direction="column" style={{marginTop:'3px'}}>
                    <Grid item container>
                        <Paper className={classes.tab} elevation={1} style={{marginTop:"10px"}}>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                indicatorColor="primary"
                                textColor="primary"
                            >
                                <Tab label="Basic Info" />
                                <Tab label="Change Password" />
                                <Tab label="Invoice Accounts" />
                            </Tabs>
                        </Paper>
                    </Grid>
                    <Grid item style={{backgroundColor:"white",marginBottom:"1rem"}}>
                        {value === 0 && <BasicInfo />}
                        {value === 1 && <ChangePassword />}
                        {value === 2 && <MyInvoiceAccounts handleDrawerOpen={handleDrawerOpen}/>}
                    </Grid>
                </Grid>
            </Container>   

            <CreateInvoiceAccountDrawer openDrawer={openDrawer}
                handleDrawerClose={handleDrawerClose}
                accountObj={accountObj}/>
        </div>
    );
}

const mapStateToProps = (state) => ({
    selectedAccount: state.invoiceAccounts.selectedAccount,
    drawerState: state.drawerState.setDrawerState
})

export default connect(mapStateToProps)(MyProfile);