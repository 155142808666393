import React, { useEffect, useState, memo } from 'react';
import { CircularProgress, makeStyles, MenuItem } from '@material-ui/core';
import {Grid, Typography, TextField, Button, FormControlLabel, Checkbox, Paper} from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import RightToLeftSideDrawer from './RightToLeftSideDrawer';
import moment from 'moment';
import {connect} from 'react-redux';
import {addPaymentTransactionApi, updatePaymentTransactionApi} from '../services/authService';
import {consoleToLog, isInvoiceAccountCountryIndia} from '../util/AppUtil';
import { useSnackbar } from 'notistack';
import { useIsMount } from './useIsMount';
import {setInvoiceItemObj} from '../actions/invoiceAccount';
import { SendEmailType } from '../actions/invoiceAccount';
import { logout } from '../actions/invoiceAuth';
import { clearSelectedInvoiceFilters } from '../actions/invoiceFilters';
import { setSelectedDrawerState } from '../actions/drawer';
import { clearSelectedTransactionFilters } from "../actions/transactionFilters";
import { setClearInvoiceAccounts } from '../actions/invoiceAccount';
import {trackGAEvent, InvoiceStatus} from '../util/AppUtil';
import { SelectedDrawerState } from '../actions/drawer';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Autocomplete from "@material-ui/lab/Autocomplete";
import { currencyFormatterForAmount, formatAmount } from '../util/currencyUtil';

const useStyles = makeStyles((theme) => ({
    drawer: {
    //    width: drawerWidth,
        flexShrink: 0
    },
    drawerPaper: {
        width: '30%',
        //height: 'calc(100vh - 51px)',
        flexGrow:1,
        top:'51px',
    },
    appBar: {
        background:'#F9F9F9',
        height:'45px',
        color:'#202020'
    },
    customMarginTop : {
        marginTop:"16px"
    },
    customMarginBottom : {
        marginBottom:'-8px'
    },
    MuiAutocompleteinputRoot : {
        '& .MuiOutlinedInput-root' : {
        padding:'0.5px 1px 0.5px 1px',
        borderRadius: '4px',
        background:'#fff',
        marginTop:'4px'
        }
    },
    createItemButton: {
        margin:'16px auto 4px 10px', 
        width:'95%', 
        color:'#1bac3b', 
        borderColor:'#d4d8de'
    },
    createItemIcon: {
        margin:'0px 6px 0px 0px', 
        fontSize:'16px'
    }
}))

const AddEditPaymentDrawer = memo((props) => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();
    const isMount = useIsMount();

    const [fullAmt,setFullAmount]=useState("");
    const [date, setDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
    const [paymentMethod, setPaymentMethod] = useState(-1);
    const [bankAccount,setBankAccount]=useState({});
    const [paymentNotes, setPaymentNotes] = useState('');
    const [sendEmail, setSendEmail] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [convertToTax, setConvertToTax] = useState(true);
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);

    const invoiceObj = props.transactionObj ? props.transactionObj?.invoice : props.invoiceObj;
    const total_amount = props.transactionObj ? props.transactionObj?.invoice.total : props.invoiceObj?.total;
    const due_amount = props.transactionObj ? props.transactionObj?.invoice.due : props.invoiceObj?.due;
    const client_name = props.transactionObj ? props.transactionObj?.invoice?.client_name: props.fromPreviewScreen ? props.invoiceObj?.client.name : props.invoiceObj?.client_name;
    const amount_paid = props.transactionObj?.amount ? props.transactionObj?.amount : 0;
    const date_paid = props.transactionObj?.payment_date;
    const notes = props.transactionObj?.remarks;
    const method = props.transactionObj?.payment_method;
    const bank_acc=props.transactionObj?.bank_account;
    const tds_amount = props.transactionObj ? props.transactionObj?.invoice.tds_amount : props.invoiceObj?.tds_amount;
    const entity_id = props.fromPreviewScreen ?  props.invoiceObj?.entity.id : props.invoiceObj?.entity_id;


    const selectedEntity = props.selectedAccount.entities.find((entity) => Number(entity.id) === entity_id);
    const bankList = invoiceObj && entity_id ? selectedEntity?.bank_accounts && selectedEntity?.bank_accounts.length > 0 ? selectedEntity?.bank_accounts : [] : [];
    const currencyObj = props.transactionObj || props.fromPreviewScreen ? invoiceObj?.currencies : invoiceObj?.currency;

    const invoiceAccountCountry = props.selectedAccount?.country;
 
     // handles when user changes input in date inputfield
     const handleChangeDate = e => {
        setDate(e.target.value);
     };

     const onSendEMailClicked = (e) => {
        setSendEmail(!sendEmail);
    }

    const onConvertToTax = (e) => {
        setConvertToTax(!convertToTax);
    }
    
  
    useEffect(() => {
            if(props.transactionObj) {
                setFullAmount(amount_paid);
                setDate(date_paid);
                setPaymentMethod(method);
                setPaymentNotes(notes);
                setBankAccount(bank_acc);
            }
    }, [props.transactionObj]);

    useEffect(() => {
        if(!isMount) {
            if(fullAmt > total_amount - due_amount) {
                setErrorMessage(`The amount field cannot be greater than ${formatAmount(total_amount - due_amount, currencyObj)}.`)
            } 

            if(tds_amount > 0 && fullAmt > total_amount - tds_amount) {
                setErrorMessage(`The amount field cannot be greater than ${formatAmount(total_amount - tds_amount, currencyObj)}.`)
            } 

            if(!props.transactionObj && fullAmt > due_amount) {
                setErrorMessage(`The amount field cannot be greater than ${due_amount}.`)
            }

            if(!props.transactionObj && tds_amount > 0 && fullAmt > total_amount - tds_amount) {
                setErrorMessage(`The amount field cannot be greater than ${formatAmount(total_amount - tds_amount, currencyObj)}.`)
            }
    
            // if(!props.transactionObj && invoiceObj?.status === InvoiceStatus.PARTIAL && tds_amount > 0 && fullAmt > due_amount - tds_amount) {
            //     setErrorMessage(`The amount field cannot be greater than ${currencyFormatterForAmount(due_amount - tds_amount)}.`)
            // }
        }
    }, [fullAmt])

    useEffect(() => {
        if(!isMount) {
            if(props.bankAccObj) {
                setBankAccount(props.bankAccObj);
                props.setBankAccObj(undefined);

            }
            
        } 

    }, [props.bankAccObj]);


    const onAddUpdatePaymentClick = () => {
        const invoice_account_id = props.selectedAccount?.id
        const invoice_id = props.invoiceObj?.id;
        const transaction_id = props.transactionObj?.id;

        const amount = parseFloat(fullAmt);
        if(!amount) {
            enqueueSnackbar('Amount cannot be empty!', {variant:'error'});
            return;
        }

        const payment_method = paymentMethod;
        if(payment_method === -1 || payment_method === '-1') {
            enqueueSnackbar('Please Select Payment Method', {variant:'error'});
            return;
        }

        const remarks = paymentNotes;

        const payment_date = date;
        if(!payment_date) {
            enqueueSnackbar('Please enter todays date!', {variant:'error'});
            return;
        }

        
        const send_receipt = sendEmail;

        const bank_account = bankAccount ? bankAccount?.id : undefined;

        setLoading(true);
        if(props.transactionObj) {
            callUpdateTransactionApi(invoice_account_id, transaction_id, amount, payment_method, bank_account, remarks, payment_date);
        } else {

        addPaymentTransactionApi(invoice_account_id, invoice_id, amount, payment_method, bank_account,
            remarks, payment_date, send_receipt, convertToTax)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response addPaymentTransactionApi: ', res);
                setLoading(false);

                enqueueSnackbar('Payment added successfully', {variant:'success'});
                if(props.drawerState === SelectedDrawerState.SHOW_INVOICES || props.drawerState === SelectedDrawerState.SHOW_CLIENTS) {
                    props.updateInvoiceItemInInvoiceList && props.updateInvoiceItemInInvoiceList(res);
                    if(props.setUpdatedInvoiceObj) {
                        let invoiceObj = res.invoice;
                        let obj = {
                            ...props.invoiceObj,
                            paid: invoiceObj.paid,
                            status: invoiceObj.status,
                            due: invoiceObj.due
                        }
                        props.setUpdatedInvoiceObj(obj);
                    }
                }

                if(props.drawerState === SelectedDrawerState.SHOW_TRANSACTIONS || props.drawerState === SelectedDrawerState.SHOW_CLIENTS) {
                    props.setAddTransaction && props.setAddTransaction(true);
                    props.updateTranscItemInTranscList && props.updateTranscItemInTranscList(res);
                }
                props.openAddNewPaymentDrawer && props.handleCloseAddNewPaymentDrawer();
                if(props.fromPreviewScreen){ 
                    let invoiceObj = res.invoice;
                    let obj = {
                        ...props.invoiceObj,
                        paid: invoiceObj.paid,
                        status: invoiceObj.status,
                        due: invoiceObj.due
                    }
                    props.setInvoiceItemObj(obj);
                }

                onCloseAddEditPaymentDrawer();

                trackGAEvent(props.selectedAccount.name, 'Payment added', `${props.user.firstname} ${props.user.lastname}`);
                
            })
            .catch((e) => {
                consoleToLog('Error addPaymentTransactionApi: ', e);
                setLoading(false);
                if(e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {variant: 'error'});
                    return;
                }
            });

        }    
     }

    const callUpdateTransactionApi = (invoice_account_id, transaction_id, amount, payment_method,bank_account,
        remarks, payment_date) => {
            updatePaymentTransactionApi(invoice_account_id, transaction_id, amount, payment_method, bank_account,
                remarks, payment_date)  
                .then((response) => {
                    const res = response.data;
                    consoleToLog('Response updatePaymentTransactionApi: ', res);
                    setLoading(false);

                    enqueueSnackbar('Payment updated successfully', {variant:'success'});

                    if(props.drawerState === SelectedDrawerState.SHOW_TRANSACTIONS) props.updateTranscItemInTranscList(res);
                    onCloseAddEditPaymentDrawer();
                    trackGAEvent(props.selectedAccount.name, 'Payment updated', `${props.user.firstname} ${props.user.lastname}`);
                })
                .catch((e) => {
                    consoleToLog('Error updatePaymentTransactionApi: ', e.response);
                    setLoading(false);
                    if(e.response.data && e.response.data.message) {
                        enqueueSnackbar(e.response.data.message, {variant: 'error'});
                        return;
                    }
                });
    }

    const onPaidInFullClick = () => {
        if(invoiceObj?.status === InvoiceStatus.PARTIAL) {
            setFullAmount(due_amount);
        } else {
            setFullAmount(total_amount - tds_amount);
        }
    }
    
    const onCloseAddEditPaymentDrawer = () => {
        setFullAmount('');
        setDate(moment(new Date()).format("YYYY-MM-DD"));
        setPaymentMethod(-1);
        setPaymentNotes('');
        setBankAccount(-1);
        props.transactionObj && props.setTransactionObj(undefined);
        props.handleCloseAddPaymentDrawer();
    }

    const onAddBankAccountClick = (e) => {
        props.handleBankDrawerOpen();
        if(props.fromPreviewScreen) {
            props.setEntityObjForBankAcc(invoiceObj.entity);
        } else {
            let obj = {
                id: invoiceObj.entity_id
            }
            props.setEntityObjForBankAcc(obj);
        }
    }

    const CreateItemButton = ({ children, ...other }) => (
        <Paper {...other} 
            style={{marginTop:'6px',
                    boxShadow: 'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px' 
                    }}>
        <Button fullWidth
        className={classes.createItemButton}
        variant="outlined"
        color="primary"
        onMouseDown={onAddBankAccountClick}
        >
        <AddCircleOutlineIcon fontSize='small' className={classes.createItemIcon}/>  
            Add Bank Account
        </Button>
        {children}
    </Paper>
    );

    return (
            <RightToLeftSideDrawer title={props.transactionObj ? "Update Payment" :"Add Payment" }
                openDrawer={props.openAddPaymentDrawer}
                closeDrawer={onCloseAddEditPaymentDrawer}
                fromPreviewScreen={props.fromPreviewScreen ? true : false}>

                <div className='modal__main'>

                    <Grid item container spacing={1}
                        justifyContent='space-between'
                        style={{borderBottom:'1px solid rgba(0,0,0, .1)'}}> 
                        <Grid item md={4} lg={4}>
                            <Typography variant="subtitle1" 
                                className={classes.customMarginBottom}
                                style={{color: "#9198a6"}}>
                                Invoice Total
                            </Typography>
                            <Typography variant="subtitle1" style={{fontSize: "20px"}}>
                            {formatAmount(total_amount, currencyObj)}
                            </Typography>
                            
                        </Grid> 

                        {isInvoiceAccountCountryIndia(invoiceAccountCountry) &&
                        <Grid item md={4} lg={4} style={{textAlign:'center'}}>
                            <Typography variant="subtitle1" 
                                className={classes.customMarginBottom}
                                style={{color: "#9198a6"}}>
                                TDS Amount
                            </Typography>
                            <Typography variant="subtitle1" style={{fontSize: "20px"}}>
                            {formatAmount(tds_amount, currencyObj)}
                            </Typography>
                            
                        </Grid>}   


                        <Grid item md={4} lg={4} style={{textAlign:'right'}}>
                            <Typography variant="subtitle1" 
                                className={classes.customMarginBottom}
                                style={{color: "#9198a6"}}>
                                Amount Due
                            </Typography>   
                            <Typography variant="subtitle1" style={{fontSize: "20px"}}>
                            {formatAmount(due_amount, currencyObj)}
                            </Typography> 
                            
                        </Grid>
                    </Grid>


                    <Grid item container spacing={1} style={{marginTop: "15px"}}> 
                        <Grid item md={6} lg={6}>
                            <div style={{ display: "flex" }}>
                                <Typography variant="subtitle1" 
                                    className={classes.customMarginBottom} 
                                    >
                                    Payment *
                                </Typography>
                                <Typography variant="subtitle1" 
                                    className={classes.customMarginBottom}
                                    onClick={onPaidInFullClick} 
                                    style={{marginLeft: "5px", paddingRight: "10px", cursor: "pointer", fontWeight: "bold", color: "#4c51bf"}}>
                                        Paid in full
                                </Typography>
                            </div>
                            <TextField fullWidth 
                                id="payment" 
                                variant="outlined"
                                type="number"
                                margin="dense"
                                autoComplete="off"
                                value={fullAmt}
                                onChange={(e)=>{
                                    let input = e.target.value;
                                    // Regular expression to allow only numbers with up to 2 decimal places
                                    if (input === "" || /^\d+(\.\d{0,2})?$/.test(input)) {
                                        setFullAmount(input);
                                    }
                                }}
                                InputLabelProps={{style: {fontSize: 13, color: "#ccc"}}}
                                error={(fullAmt > total_amount - tds_amount) || (!props.transactionObj && fullAmt > due_amount)}
                                helperText={(fullAmt > total_amount - tds_amount) || (!props.transactionObj && fullAmt > due_amount) ? errorMessage : ''}
                                InputProps={{
                                    inputProps: { min: 0 }
                                }}
                                onKeyPress={(event) => {
                                    if (event?.key === '-' || event?.key === '+') {
                                        event.preventDefault();
                                    }
                                }}
                            />
                        </Grid>    


                        <Grid item md={6} lg={6}>
                            <Typography variant="subtitle1" 
                                className={classes.customMarginBottom}>
                               Payment Date *
                            </Typography>   

                            <TextField fullWidth 
                                id="payment_date"
                                variant="outlined"
                                type="date"
                                margin="dense"
                                autoComplete="off"
                                value={date}
                                onChange={handleChangeDate}
                                InputLabelProps={{style: {fontSize: 13, color: "#ccc"}}}
                            />
                        </Grid>
                    </Grid> 

                    <Grid item md={12} lg={12} 
                        className={classes.customMarginTop}>
                        <Typography variant="subtitle1" 
                            className={classes.customMarginBottom}>
                            Payment Method *
                        </Typography>   

                        <TextField fullWidth 
                            id="payment_method"
                            variant="outlined"
                            margin="dense"
                            autoComplete="off"
                            select
                            defaultValue={-1}
                            value={paymentMethod}
                            //label='Select Payment Method'
                            InputLabelProps={{style: {fontSize: 13, color: "#ccc"}}}
                            onChange={(e) => setPaymentMethod(e.target.value)}
                        >
                            <MenuItem value="-1">Select Payment Method</MenuItem>
                            <MenuItem value="bank">Bank</MenuItem>
                            <MenuItem value="cheque">Cheque</MenuItem>
                            <MenuItem value="cash">Cash</MenuItem>    
                            <MenuItem value="upi">UPI</MenuItem>
                            <MenuItem value="credit_card">Credit Card</MenuItem>
                            <MenuItem value="external">External</MenuItem>
                        </TextField>    
                    </Grid>

                    <Grid item md={12} lg={12} 
                        className={classes.customMarginTop}>
                        <Typography variant="subtitle1" style={{marginBottom:'-4px'}}>
                            Bank Account
                        </Typography>

                        <Autocomplete
                            id="combo-box-demo"
                            options={bankList}
                            classes={{
                                root: classes.MuiAutocompleteinputRoot
                            }}
                            value={bankAccount}
                            getOptionLabel={(option) => option.bank_name}
                            onChange={(e, newValue) => {
                                setBankAccount(newValue);
                            }}
                            renderInput={params => (
                                <TextField {...params} variant="outlined" 
                                    placeholder='Select Bank Account' 
                                    fullWidth
                                    classes={{
                                        root: classes.customTextField
                                    }}
                                    />
                            )}
                            PaperComponent={CreateItemButton}
                        />
                    
                        {/* <TextField fullWidth 
                            id="bank_account"
                            variant="outlined"
                            margin="dense"
                            autoComplete="off"
                            select
                            defaultValue={bankAccount}     
                            
                            disabled={paymentMethod==="cash"}
                            value={bankAccount}
                            onChange={(e) =>    { 
                                if(bankList && bankList.length > 0) {
                                    setBankAccount(e.target.value);
                                } else {
                                    setBankAccount('-1');
                                }
                            }}
                        >
                        {<MenuItem value="-1">Select Bank Account</MenuItem>}
                        {bankList && bankList.length === 0  && 
                            <MenuItem value={0}>
                                <Button 
                                    onClick={onAddBankAccountClick}
                                    color='secondary'
                                    variant='contained'>
                                        <AddCircleOutlineIcon fontSize='small' style={{marginRight:'4px'}}/>
                                        Add Bank Account
                                    </Button>
                            </MenuItem>}
                            {
                        bankList && bankList.length > 0  &&
                        bankList.map((account) => {
                            return <MenuItem key={account.id} value={account.id}>{account.bank_name}({account.account_number})</MenuItem>
                        
                        })
                    }
                        </TextField>     */}
                    
                    
                    </Grid>

                    <Grid item md={12} lg={12} 
                        className={classes.customMarginTop}>
                        <Typography variant="subtitle1" 
                            className={classes.customMarginBottom}>
                            Payment Notes
                        </Typography>   

                        <TextField fullWidth 
                            id="payment_notes"
                            variant="outlined"
                            margin="dense"
                            multiline
                            rows={4}
                            placeholder='Enter payment note. (note will only be seen by you)'
                            value={paymentNotes}
                            onChange={(e) => setPaymentNotes(e.target.value)}
                        />
                    </Grid>

                    {!props.transactionObj &&
                    <Grid item md={12}
                         className={classes.customMarginTop}>
                        <FormControlLabel
                            classes={{
                                root: classes.MuiFormControlLabelroot
                            }}
                            control={
                            <Checkbox
                                name="checkedB"
                                color="primary"
                                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                onClick={(e) => e.stopPropagation()}
                                onChange={(e) => onSendEMailClicked(e)}
                                checked={sendEmail}
                            />
                            }
                            label={`Send ${client_name} a payment receipt email`}
                        />
                    </Grid>}
                    
                    {!props.transactionObj && (props.invoiceObj?.invoice_type === SendEmailType.PROFORMA && fullAmt > 0 || 
                    props.invoiceObj?.invoice_type === SendEmailType.PROFORMA && props.invoiceObj?.status !== InvoiceStatus.DRAFT
                    && props.invoiceObj?.status !== InvoiceStatus.PAID && props.invoiceObj?.status !== InvoiceStatus.CANCELLED) &&
                    <Grid item md={12}
                        className={classes.customMarginTop}>
                        <FormControlLabel
                            classes={{
                                root: classes.MuiFormControlLabelroot
                            }}
                            control={
                            <Checkbox
                                name="checkedB"
                                color="primary"
                                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                onClick={(e) => e.stopPropagation()}
                                onChange={(e) => onConvertToTax(e)}
                                checked={convertToTax}
                                disabled={Number(fullAmt) === props.invoiceObj.total || Number(fullAmt) === props.invoiceObj.due}
                            />
                            }
                            label={`Convert to Tax Invoice`}
                        />
                    </Grid>}

                    <div className="modal__footer">
                        <Button variant="contained" color="primary" 
                            onClick={onAddUpdatePaymentClick}
                            disabled={(fullAmt > total_amount ) || (!props.transactionObj && fullAmt > due_amount) || loading || props.accessLevelViewer}
                            style={{opacity: (fullAmt > total_amount ) || (!props.transactionObj && fullAmt > due_amount) ? 0.7 : 1}}
                            className="modal__button">
                            {loading && <CircularProgress size={15} className='loading__style'/>}
                            {props.transactionObj ? 'Update' : 'Save' }    
                        </Button>
                        <Button variant="outlined" style={{
                            minWidth: '130px',
                        }} onClick={onCloseAddEditPaymentDrawer}>
                            Cancel
                        </Button>
                    </div> 
                </div>    
            </RightToLeftSideDrawer>
    );
})

const mapStateToProps = (state) => ({
    selectedAccount: state.invoiceAccounts.selectedAccount,
    user: state.invoiceAccounts.user,
    drawerState: state.drawerState.setDrawerState
});

const mapDispatchToProps = (dispatch) => ({
    setInvoiceItemObj: (invoice) => dispatch(setInvoiceItemObj(invoice)),
    logout: () => dispatch(logout()),
    clearSelectedInvoiceFilters: () => dispatch(clearSelectedInvoiceFilters()),
    setClearInvoiceAccounts: () => dispatch(setClearInvoiceAccounts()),
    setSelectedDrawerState: (drawerState) => dispatch(setSelectedDrawerState(drawerState)),
    clearSelectedTransactionFilters: () => dispatch(clearSelectedTransactionFilters())
})

export default connect(mapStateToProps, mapDispatchToProps)(memo(AddEditPaymentDrawer));