import React, {useState} from 'react';
import RightToLeftSideDrawer from './RightToLeftSideDrawer';
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Button, Link, Divider, CircularProgress } from "@material-ui/core";
import ClearIcon from '@material-ui/icons/Clear';
import { useSnackbar } from 'notistack';
import {connect} from 'react-redux'
import {consoleToLog, trackGAEvent, OverlayMessage} from '../util/AppUtil'; 
import { v4 as uuidv4 } from 'uuid';
import {downloadTemplateApi, importCompanyListApi} from '../services/authService';
import { setOvelayMessage, setOverlayToken, setOverlayView, OverlayView, setApiRoute } from '../actions/overlay';

const useStyles = makeStyles((theme) => ({
    fileUploaderContainer: {
        display: 'flex !important',
        width:'100%',
        height: '6rem !important',
        justifyContent:'center',
        alignItems: 'center',
        cursor: 'pointer',
        borderRadius: '4px !important',
    },
    attachIcon : {
        fontSize : '16px',
        marginRight: '8px',
        marginTop: '2px'
    }, 
    linkText:{
        color: "#20a8e0",
        fontSize: "14px",
        fontWeight: "600",
        marginLeft: "2px",
        cursor: "pointer",
        textDecoration:'none'
    },
    clearIcon: {
        minWidth:"20px", 
        padding:"0", 
        marginLeft:'8px'
    },
    circularProgress: {
        marginLeft: 0,
        marginRight: theme.spacing(2),
        color: 'white'
    },
    errorHeading : {
        fontWeight: "600", 
        fontSize:'24px', 
        color:'#ccc', 
        marginTop:'16px', 
        marginBottom:'4px'
    }  
}))

const SetupCompanyDrawer = (props) => {
    const [attachment, setAttachment] = useState([]);
    const [attachment1, setAttachment1] = useState([]);
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();

    const [errorReport, setErrorReport] = useState([]);
    const [companySetupLoading, setCompanySetupLoading] = useState(false);
    const [invoiceSetupLoading, setInvoiceSetupLoading] = useState(false);

    const dragOver = (e) => {
        e.preventDefault();
    }
    
    const dragEnter = (e) => {
        e.preventDefault();
    }
    
    const dragLeave = (e) => {
        e.preventDefault();
    }
    
    const fileDrop = (e) => {
        e.preventDefault();
        const files = e.dataTransfer.files[0];
        consoleToLog(files);
        setAttachment([...attachment, files]);
    }

    const fileDrop1 = (e) => {
        e.preventDefault();
        const files = e.dataTransfer.files[0];
        consoleToLog(files);
        setAttachment1([...attachment1, files]);
    }

    const onAttachmentSelected = (e) => {
        const { target: { files } } = e;
        setAttachment([...attachment, e.target.files[0]]);
    }

    const onAttachmentSelected1 = (e) => {
        const { target: { files } } = e;
        setAttachment1([...attachment1, e.target.files[0]]);
    }

    const onRemoveAttachment = (e, isInvoice) => {
        e.stopPropagation();
        if(isInvoice) setAttachment1([]);
        else setAttachment([]);
    }

    const showAttachment = (file, index, isInvoice) => {
        return (
            <Grid item container>
                <Typography key={index} variant="body2" color="primary">
                    {file.name}
                </Typography>
                <Button onClick={(e)=>onRemoveAttachment(e, isInvoice)} 
                    className={classes.clearIcon}>
                    <ClearIcon fontSize="small"/>
                </Button>
            </Grid>
        );
    }

    const onImportCompanyClick = (e) => {
        e.preventDefault();

        const file = attachment[0];
        if (!file) {
            enqueueSnackbar('Please attach a file', {
                variant:'error'
            });
            return;
        }
        const invoice_account_id = props.selectedAccount.id;

        setCompanySetupLoading(true);
        props.setApiRoute('setup_import');
        importCompanyListApi(invoice_account_id, file)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response importCompanyListApi: ", res);
                setCompanySetupLoading(false);

                //show msg
                if (res.error_report && res.error_report.length > 0) {
                    props.setOverlayView(OverlayView.NO_VIEW);
                    props.setOverlayToken("");
                    enqueueSnackbar(res.message, {
                        variant:'error'
                    })
                }
                else {
                    props.setOvelayMessage(OverlayMessage.IMPORT_MSG);
                    props.setOverlayView(OverlayView.SHOW_OVERLAY_LOADING);
                    props.setOverlayToken(res.task_id);
                    props.handleCloseCompanySetupDrawer();

                    trackGAEvent(props.selectedAccount.name, 'Import Setup Template', `${props.user.firstname} ${props.user.lastname}`);
                }

                setAttachment([]);
            })
            .catch((e) => {
                consoleToLog("importCompanyListApi error", e.response);
                setCompanySetupLoading(false);
                props.setOverlayView(OverlayView.NO_VIEW);
                props.setOverlayToken("");
                if (e.response.data && e.response.data.message) {
                    setErrorReport(e.response.data.message);
                    // enqueueSnackbar(e.response.data.message, {
                    //     variant:'error'
                    // });
                    return false;
                }
            });
            
    }

    const downloadTemplateFile = () =>{
        const invoice_account_id = props.selectedAccount.id;

        props.setApiRoute('setup_export');

        downloadTemplateApi(invoice_account_id) 
            .then((response) => {
                const res = response.data;
                consoleToLog('Response downloadTemplateApi ', res);

                props.setOverlayView(OverlayView.SHOW_OVERLAY_LOADING);
                props.setOverlayToken(res.task_id);
                props.handleCloseCompanySetupDrawer();
                props.setOvelayMessage(OverlayMessage.EXPORT_MSG);
            })
            .catch((e) => {
                consoleToLog("downloadTemplateApi error", e.response);
                props.setOverlayView(OverlayView.NO_VIEW);
                props.setOverlayToken("");

                if (e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {
                        variant:'error'
                    });
                    return false;
                }
            });
    }

    const closeDrawer = () => {
        setErrorReport([]);
        setAttachment([]);
        setAttachment1([]);
        props.handleCloseCompanySetupDrawer()
    }

    return (
        <RightToLeftSideDrawer title="Import Client Data"
            openDrawer={props.openCompanySetupDrawer}
            closeDrawer={closeDrawer}
            fromOnboardingComponent={props.fromOnboardingComponent}
        >
            <div className="modal__main">
                <Grid item>
                    <div onDragOver={dragOver}
                        onDragEnter={dragEnter}
                        onDragLeave={dragLeave}
                        onDrop={fileDrop}
                        className={classes.fileUploaderContainer}
                        style={{border: (attachment && attachment.length > 0) ? 'none' : '2px dashed #c5bebe'}}>

                    {attachment && attachment.length > 0 ?
                    <div>
                            {(attachment && attachment.length > 0) &&
                                    attachment.map((file, index) => {
                                        return showAttachment(file, index, false);
                            })}
                    </div>
                    :
                    <label>   
                        <input style={{display:'none'}}
                            type="file"
                            id="companyListFile"
                            accept=".xlsx"
                            onChange={onAttachmentSelected}
                        />

                        <Typography variant="body2" style={{cursor:'pointer'}}>
                            <i className={`flaticon-attachment ${classes.attachIcon}`}></i>
                            Attach File
                        </Typography>
                    </label>  
                    }  
                    </div>    

                    <Grid item style={{marginTop:'8px'}}>
                        <Typography variant="body2">
                            Use  <Link className={classes.linkText} 
                                    onClick={()=>{
                                        if(props.overlay && props.overlay.showOverlayView === OverlayView.SHOW_OVERLAY_LOADING){
                                            enqueueSnackbar("Another download request is already in progress. Please be patient.", ({
                                                variant: 'error'
                                            }));
                                        }
                                        else downloadTemplateFile();

                                    }}
                                    >this template </Link> 
                                to import your client data
                        </Typography> 
                    </Grid>

                    <Grid item style={{marginTop:'24px'}}>
                        <Button onClick={onImportCompanyClick} 
                            variant="contained" color="primary" 
                            style={{width:'130px'}}>
                            {companySetupLoading && <CircularProgress size={20} className={classes.circularProgress}/>}
                            Import
                        </Button> 
                    </Grid>
                </Grid>
                                    
                <Grid item>
                    {errorReport && errorReport.length > 0 &&
                        <div>
                            <Typography className={classes.errorHeading}
                            >Error Report</Typography>
                            <Divider light style={{padding:'0', margin:'0'}}/>
                        </div>
                    }
                    {errorReport && errorReport.length > 0 &&
                        errorReport.map((error, index) => {
                            return <div key={index}>
                                    <Typography style={{color:'red', padding:'8px 0px'}}>{index + 1}. {error}</Typography>
                                    <Divider light/>
                                    </div>
                        })
                    }
                </Grid>
            </div>    
            
        </RightToLeftSideDrawer>
    );
}

const mapStateToProps = (state) => ({
    selectedAccount: state.invoiceAccounts.selectedAccount,
    user: state.invoiceAccounts.user
});

const mapDispatchToProps = (dispatch) => ({
    setOverlayView: (exportView) => dispatch(setOverlayView(exportView)),
    setOverlayToken: (randomToken) => dispatch(setOverlayToken(randomToken)),
    setOvelayMessage: (overlayMessage) => dispatch(setOvelayMessage(overlayMessage)),
    setApiRoute: (route) => dispatch(setApiRoute(route))
})

export default connect(mapStateToProps, mapDispatchToProps)(SetupCompanyDrawer);