import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {Grid,Typography,Button,CircularProgress,ClickAwayListener,Tooltip} from "@material-ui/core";
import { connect } from "react-redux";
import { setSelectedDrawerState } from "../actions/drawer";
import {setInvoiceAccountsList} from '../actions/invoiceAccount';
import {setSelectedInvoiceAccount} from '../actions/invoiceAccount';
import { useIsMount } from "./useIsMount";
import AddEditBankDrawer from "./AddEditBankDrawer";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import { consoleToLog, AccessLevel } from "../util/AppUtil";
import { logout } from "../actions/invoiceAuth";
import {trackGAEvent,currencyFormatter} from "../util/AppUtil";
import { useSnackbar } from "notistack";
import { deleteBankApi } from "../services/authService";

const useStyles = makeStyles((theme) => ({
    settingsContainer: {
        width: "100%",
        background: "#fff",
        borderRadius: "4px",
        padding: "8px 0px",
        marginBottom: "32px",
    },
    heading: {
        fontSize: "20px",
        fontWeight: "600",
    },
    btn: {
        background: "#383838",
        width: "135px",
        paddingLeft: "4px",
        paddingRight: "4px",
        color: "white",
    },
    loading: {
        position: "relative",
        left: "50%",
        right: "50%",
        marginTop: "3%",
    },
    itemTitleList: {
        background:'#f9fafb',
        borderBottom: '1px solid #edf2f7',
    },
    itemListContainer : {
        background:'white', 
        minWidth:"100%", 
        padding:'10px 16px', 
        color:'#6b7280',
        position:'relative',
        borderBottom: '1px solid #edf2f7',
        '& .action__icon' : {
            padding:'2px 4px 0px 4px',
            border:'1px solid white',
            borderRadius:'4px',
            cursor:'pointer'
        },
        '&:hover .action__icon' : {
            border:'1px solid #ccc'
        },
        '&:hover':{
            backgroundColor:'#f7fafc',
        },
        '& .more_actionButton' : {
            visibility:'hidden',
            position:'absolute',
            right:'90px',
            width:'160px',
            background:'white',
            border:'1px solid #ccc',
            borderRadius:'5px',
            display:'flex',
            justifyContent:'center',
            aligItems:'center',
            fontSize:'14px',
            padding:'4px 0px 4px 0px',
            [theme.breakpoints.down(1500)]: {
                right:'80px',
                padding:'3px 0px 3px 0px',
            },

        },
        '&:hover .more_actionButton' : {
            visibility:'visible',
        },
    },
    actionsIcons: {
        color: '#b5bbc2',
        fontSize: '18px',
        marginRight: '8px' 
    },
   
  }));

const BanksComponent = (props) => {
    const classes = useStyles();
    const isMount = useIsMount();
    const { enqueueSnackbar } = useSnackbar();
   
    const [openBankDrawer, setOpenBankDrawer] = useState(false);
    const [loading, setLoading] = useState(false);
    const [animationActive, setAnimationActive] = useState(false);
    const [bankAccountObj, setBankAccountObj] = useState({});
    const [bankAccountId, setBankAccountId] = useState('');
    const [isActive, setIsActive] = useState(false);
    
    const { access_level } = props.selectedAccount || {};
    const accessLevelViewer =
        AccessLevel.getUserAccessLevelValue(access_level) === AccessLevel.VIEWER
        ? true
        : false;
    const bankList = props.selectedAccount.entities?.map((item) => {
                return item.bank_accounts.map((bankItem) => {
                    return {
                        ...bankItem,
                        entity: {
                            id: item.id,
                            name: item.name
                        }
                    }
                })
        }).flat();

    useEffect(() => {
        if(!isMount) {
            if(props.openBankDrawerFromParent) {
                handleBankDrawerOpen();
            }
        }
    }, [props.openBankDrawerFromParent])

    const handleBankDrawerOpen = () => {
       setOpenBankDrawer(true);
    };

    const handleBankDrawerClose = () => {
        setOpenBankDrawer(false);
        bankAccountObj && setBankAccountObj({});
        props.handleBankDrawerCloseFromParent();
      
    };

    const onEditBankClick = (e, item) => {
        trackGAEvent(props.selectedAccount.name, 'Bank Account Settings - Edit Bank clicked', `${props.user.firstname} ${props.user.lastname}`);
        e.stopPropagation();
        setBankAccountObj(item);
        handleBankDrawerOpen();
    }
      
    const closeMoreOptionsMenu = () => {
        setIsActive(false);
        setBankAccountId('');
    }

    const onDeleteBankClick = (e, bankAccount) => {
        e.stopPropagation();
        closeMoreOptionsMenu();

        setAnimationActive(true);
        if(window.confirm('Are you sure you want to delete this item!')) {
            const invoice_account_id = props.selectedAccount?.id;
            const bank_id = bankAccount.id;

            deleteBankApi(invoice_account_id, bank_id)
                .then((response) => {
                    const res = response.data;
                    consoleToLog('Response deleteBankApi: ', res);

                    
                    let account = {
                        ...props.selectedAccount,
                        entities: props.selectedAccount?.entities.map((item) => {
                            if(item.id === bankAccount?.entity.id) {
                                item.bank_accounts = item.bank_accounts.filter((bankObj) => bankObj.id !== bank_id)
                            }
                            return item
                        })
                    };
    
                    let accountList = props.invoiceAccountsList.map((ia) => {
                    if (ia.id === account.id) return account;
                        else return ia;
                    });
    
                    props.setInvoiceAccountsList(accountList);
                    props.setSelectedInvoiceAccount(account);
    
                
                    setAnimationActive(false);

                    trackGAEvent(props.selectedAccount.name,'Bank Settings - Bank Account deleted',`${props.user.firstname} ${props.user.lastname}`);
                })
                .catch((e) => {
                    setAnimationActive(false);
                    consoleToLog('Error deleteBankApi', e);
                    if(e.response.data && e.response.data.message) {
                        enqueueSnackbar(e.response.data.message, {variant: 'error'});
                        return;
                    }
                })
        }
    }


    return (
        <Grid item xs={12} className={classes.settingsContainer}>
            <Grid
                item
                container
                spacing={2}
                alignItems="center"
                style={{ marginTop: "16px", padding: "0 16px" }}
            >
        </Grid>

            <Grid item xs={12} style={{ marginTop: "24px" }}>
                <Grid item sm={12}>
                    <Grid item sm={12} className={classes.itemTitleList}>
                    <Grid item container style={{ padding: "10px 16px" }}>
                        <Grid item sm={3}>
                            <Typography variant="body1" className={classes.headings}>
                            Bank Name
                            </Typography>
                        </Grid>

                        <Grid item sm={2}>
                            <Typography variant="body1" className={classes.headings}>
                            Account Holder Name
                            </Typography>
                        </Grid>
                        <Grid item sm={3}>
                            <Typography variant="body1" className={classes.headings}>
                            Account No
                            </Typography>
                        </Grid>
                        <Grid item sm={3}>
                            <Typography variant="body1" className={classes.headings}>
                            Entity Name
                            </Typography>
                        </Grid>
                    </Grid>
                    </Grid>
                </Grid>
            </Grid>


                {loading ?
                    <CircularProgress size={30} className={classes.loading}/> 
                    :
                    <Grid item xs={12}>
                    <TransitionGroup mode="out-in">
                    {
                        bankList && bankList.length > 0  &&
                        bankList.map((account) => {
                            return  <CSSTransition
                                        key={account.id}
                                        timeout={1000}
                                        exit={animationActive}
                                        classNames="item">
                                        <Grid item container 
                                            justifyContent="flex-start"
                                            className={classes.itemListContainer}
                                            alignItems="center">
                                        
                                            <Grid item md={3} lg={3}>
                                                <Typography variant="body1">
                                                    {account.bank_name}
                                                </Typography>
                                            </Grid>

                                            <Grid item md={2} lg={2}>
                                                <Typography variant="body1">
                                                    {account.account_holder_name}
                                                </Typography>
                                            </Grid>

                                            <Grid item md={3} lg={3}>
                                                <Typography variant="body1">
                                                    {account.account_number}
                                                </Typography>
                                            </Grid>

                                            <Grid item md={3} lg={3}>
                                                <Typography variant="body1">
                                                    {account.entity.name}
                                                </Typography>
                                            </Grid>

                                            {!accessLevelViewer &&
                                            <Grid item md={1} lg={1}
                                                    style={{display:'flex', aligItems:'center', justifyContent:"end", flexBasis:'6%', maxWidth:'6%'}}>
                                                    <Tooltip title="More Actions" arrow>  
                                                        <i className="flaticon-more action__icon"  
                                                        onClick={(e) => {
                                                                e.stopPropagation();
                                                                setIsActive(!isActive);
                                                                setBankAccountId(account.id);
                                                            }}
                                                        style={{fontSize:'1.1rem'}}></i>
                                                    </Tooltip>
                                                <>    
                                                {account.id === bankAccountId &&
                                                <ClickAwayListener onClickAway={closeMoreOptionsMenu}>
                                                <Grid item style={{color:'#6b7280'}}
                                                    className={`menu menu1_margin ${isActive ? "active" : "inactive"}`}>
                                                    <ul>
                                                        <li onClick={(e) => onEditBankClick(e, account)}>
                                                            <a  style={{display:'flex', alignItems:'center'}}>
                                                                <EditOutlinedIcon fontSize='small'  
                                                                style={{marginTop:'4px'}}
                                                                className={classes.actionsIcons}/>
                                                                Edit
                                                            </a>
                                                        </li>

                                                        <li onClick={(e) => onDeleteBankClick(e, account)}>
                                                            <a style={{display:'flex', alignItems:'center'}}>
                                                                <DeleteOutlinedIcon fontSize='small'  className={classes.actionsIcons}/>
                                                                Delete
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </Grid>
                                                </ClickAwayListener>
                                                }
                                                </>
                                            </Grid> }   

                                        </Grid>
                                    </CSSTransition>
                        })
                        }         
                        </TransitionGroup>
                        

                        {bankList && bankList.length === 0 &&
                        <Grid item container justifyContent="center" style={{marginTop:'16px'}}>
                            <Typography variant="body1">
                                No Bank Accounts Found
                            </Typography>
                        </Grid>}
                        </Grid>}

                    <AddEditBankDrawer
                        openBankDrawer={openBankDrawer}
                        handleBankDrawerClose={handleBankDrawerClose}
                        bankAccountObj={bankAccountObj}
                        setBankAccountObj={setBankAccountObj}
                    />
    </Grid>
    );
};

const mapStateToProps = (state) => ({
    selectedAccount: state.invoiceAccounts.selectedAccount,
    invoiceAccountsList: state.invoiceAccounts.invoiceAccountsList,
    user: state.invoiceAccounts.user,
});

const mapDispatchToProps = (dispatch) => ({
    setInvoiceAccountsList: (accountList) => (dispatch(setInvoiceAccountsList(accountList))),
    setSelectedInvoiceAccount: (account) => (dispatch(setSelectedInvoiceAccount(account))),
})

export default connect(mapStateToProps, mapDispatchToProps)(BanksComponent);



