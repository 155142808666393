import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles'; 
import moment from "moment";
import { consoleToLog } from "../util/AppUtil";
import { registerLocale, setDefaultLocale } from  "react-datepicker";

const useStyles = makeStyles((theme) => ({
    btn: {
        width: "242.06px", 
        fontWeight: 400, 
        boxShadow: "none !important", 
        //borderLeft:  "1px solid #ccc", 
        //borderRight:  "1px solid #ccc", 
        color: "#000",  
        borderRadius: "0", 
        justifyContent: "left", 
        backgroundColor: "#FFF",
        padding: "6px 12px !important"
    },
    calenderStyle: {
        border:'none !important', 
        borderTop:'1px solid #aeaeae !important'
    }
}));

const Calender = (props) => {

    const classes = useStyles();

    const onChange = (dates) => {
        //console.log('date*********',dates);
        props.setDueDate(dates);
        props.handleDueDatePopoverClose();
    };

    console.log('********dueDate', props.dueDate);

  return (
    <div>
        <div>
        <Button className={classes.btn}
            style={{fontSize: "13px"}}
            variant="contained"
            onClick={()=>{
                const immediateDate = moment(props.issueDate).add(0,'days').toDate();
                props.setDueDate(immediateDate);
                props.handleDueDatePopoverClose();
            }}
        > 
            <img alt="edit" src="/images/tomorrow.svg" style={{marginRight:"6px"}}/>  
            Immediate
            {/* <span style={{ marginLeft:'auto', color: "gray", fontSize: "13px"}}>{todayDay}</span> */}
        </Button>
        </div>
        <div>
        <Button className={classes.btn}
            variant="contained"
            style={{fontSize: "13px"}}
            onClick={()=>{
                const after7Days = moment(props.issueDate).add(7,'days').toDate();
                props.setDueDate(after7Days);
                props.handleDueDatePopoverClose();
            }}
        >
            <img alt="edit" src="/images/today.svg" style={{marginRight:"6px"}}/>
            After 7 Days
            {/* <span style={{ marginLeft:'auto', color: "gray", fontSize: "13px"}}>{tomorrowDay}</span> */}
        </Button>
        </div>
        <div>
        <Button className={classes.btn}
            variant="contained"
            style={{fontSize: "13px"}}
            onClick={()=>{
                const after14Days = moment(props.issueDate).add(14,'days').toDate();
                props.setDueDate(after14Days);
                props.handleDueDatePopoverClose();
            }}
        >
            <img alt="edit" src="/images/today.svg" style={{marginRight:"6px"}}/>
            After 14 Days
            {/* <span style={{ marginLeft:'auto', color: "gray", fontSize: "13px"}}>{nextMonday}</span> */}
        </Button>
        </div>
        <div style={{borderBottom:'1px solid #aeaeae'}}>
        <Button className={classes.btn}
            variant="contained"
            style={{fontSize: "13px"}}
            onClick={()=>{
                const after30Days = moment(props.issueDate).add(30,'days').toDate();
                props.setDueDate(after30Days);
                props.handleDueDatePopoverClose();
            }}
        >
            <img alt="edit" src="/images/nextweek.svg" style={{marginRight:"6px"}}/>
            After 30 Days
        </Button>
        </div>
        <DatePicker
            selected={moment(props.dueDate).toDate()}
            onChange={onChange}
            //selectsRange
            inline
            className={classes.calenderStyle}
        />
    </div>
  );
}

export default Calender;