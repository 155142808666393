import React, {useEffect, useState} from 'react';
import {Grid, Typography, Dialog, Button, Container, Box, Paper, TableContainer, Table, TableHead, TableBody,
    TableCell, Divider, TableRow, Popover,
    CircularProgress
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import Slide from "@material-ui/core/Slide";
import { connect } from 'react-redux';
import {SendEmailType} from '../../actions/invoiceAccount';
import { history } from '../../routers/AppRouter';
import { getUrlAccordingToSelectedComponent } from '../../util/AppUtil';
import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined';
import { Skeleton } from '@material-ui/lab';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import DateRangeIcon from '@material-ui/icons/DateRange';
import DatePicker from "react-datepicker";
import moment from 'moment';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {getClientInvoiceSummaryApi} from '../../services/authService';
import { consoleToLog, AccessLevel } from '../../util/AppUtil';
import SendIcon from '@material-ui/icons/Send';
import { formatAmount } from '../../util/currencyUtil';
import SendEmailComponent from '../SendEmailComponent';
import { useIsMount } from '../useIsMount';
import { downloadClientSummaryApi } from '../../services/authService';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import Link from '@material-ui/core/Link';
import { useSnackbar } from 'notistack';
import printJS from 'print-js';
import { sendClientStamentApi, downloadCsvForClientSummaryApi } from '../../services/authService';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: theme.spacing(4),
        display:'flex',
        width:'100%',
        justifyContent:"space-between"
    },
    paper1: {
        padding: theme.spacing(3),
    },
    paper2: {
        padding: theme.spacing(2),
    },
    tableContainer: {
        marginTop: theme.spacing(2),
    },
    table: {
        minWidth: 650,
    },
    backIcon: {
        width:'40px',
        height:'40px',
        border:'1px solid rgba(0,0,0, .1)',
        borderRadius:'5px',
        cursor:'pointer',
        display:'flex',
        justifyContent:'center',
        alignItems:'center'
    },
    summaryStyle: {
        padding:"4px 8px",
        background:"#eef1ff",
        color:"#8049df",
        fontSize:'15px',
        fontWeight:500,
        borderRadius:'5px'
    },
    summaryPaddingStyle: {
        padding:"8px 0px",
    },
    summaryTextStyle: {
        color: '#8d95a6',
        fontSize: '14px',
        fontWeight: 500
    },
    boldText: {
        fontWeight: 600,
        fontSize: '14px',
        color: '#333',
    },
    summaryPaddingStyle1: {
        padding:"16px",
    },
}))

const ClientSummaryDialog = (props) => {
    const classes = useStyles();
    const isMount = useIsMount();
    const {enqueueSnackbar} = useSnackbar();

    const [loading, setLoading] = useState(false);
    const [invoicesTransactionList, setInvoicesTransactionList] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [toDateFilter, setToDateFilter] = useState(moment());
    const [showSendEmailUi, setShowSendEmailUi] = useState(false);
    const [summary, setSummary] = useState([]);
    const [emailLoading, setEmailLoading] = useState(false);
    //const [clientObj, setClientObj] = useState(undefined);
    const [apiLoading, setApiLoading] = useState(true);
    const [downloadStatementUrl, setDownloadStatementUrl] = useState('');

    const [downloadCsvLoading, setDowloadCsvLoading] = useState(false);
    const [downloadPdfLoading, setDowloadPdfLoading] = useState(false);
    const [printPdfLoading, setPrintPdfLoading] = useState(false);

    //const {client_id} = useParams();
    const invoice_account_id = props.selectedAccount.id;

    useEffect(() => {
        if(props.clientStatementObj?.client.id) {
            getClientReportSummary();
        }
    }, [props.clientStatementObj?.client.id]);


    useEffect(() => {
        if(!isMount) {
            getClientReportSummary();
        }
    }, [toDateFilter]);


    useEffect(() => {
        if(!isMount) {
            if(downloadStatementUrl) {
                printJS({printable: downloadStatementUrl, type:'pdf', showModal:true});
                setDownloadStatementUrl(undefined);
            }
        }
    }, [downloadStatementUrl]);


    const {access_level} = props.selectedAccount;
    const accessLevelViewer = (AccessLevel.getUserAccessLevelValue(access_level) === AccessLevel.VIEWER) ? true : false;
    const clientObj = props.clientStatementObj?.client;
    const accCurrencyObj = props.selectedAccount?.currencies;

    const getClientReportSummary = async() => {
        let todate = moment(toDateFilter).format('YYYY-MM-DD');
        let client_id = clientObj?.id;
        setLoading(true);

        try {
            const response = await getClientInvoiceSummaryApi(invoice_account_id, client_id, todate);
            const res = response.data;
            consoleToLog("Response getClientInvoiceSummaryApi ", res);
            setLoading(false);
            apiLoading && setApiLoading(false);

            //setClientObj({...res.client});
                let array = [{
                    label: 'Invoiced',
                    amount: res.summary.invoiced
                },
                {
                    label: 'Payments',
                    amount: res.summary.payment
                },
                {
                    label: 'Account Balance',
                    amount: res.summary.invoiced - res.summary.payment
                }
            ]
            setSummary([...array]);
            setInvoicesTransactionList([...res.statement]);
        } catch(e) {
            consoleToLog("Error getEntityClientInvoicesReportApi", e.response);
            setLoading(false);
            apiLoading && setApiLoading(false);
        }
    }

    const handleSelectDatePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleSelectDatePopoverClose = () => {
            setAnchorEl(null);
    }

    const onCustomDateChange = (date) => {
        setToDateFilter(date);
        setAnchorEl(null);
    }

    const showSelectDatePopOver = () => {
        return (<Popover
            id={'select-date-popover'}
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleSelectDatePopoverClose}
            className='date_popover_style'
            anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
            }}
            transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
            }}
        >
            <DatePicker
                selected={moment(toDateFilter).toDate()}
                onChange={(date) => onCustomDateChange(date)}
                //selectsRange
                inline
                maxDate={new Date()}
            />
        </Popover>)
    }

    const onSendStatementClick = () => {
        setShowSendEmailUi(true);
    }

    const onBackButtonClick = () => {
        setShowSendEmailUi(false);
    }

    const showButtonsUi = () => {
        setShowSendEmailUi(false);
    }

    const onSendEmailClickFromClientStatement = async(email_array, subject, body) => {
        setEmailLoading(true);
        let client_id = clientObj?.id;

        const todate = moment(toDateFilter).format('YYYY-MM-DD');

        try {
            const response = await sendClientStamentApi(invoice_account_id, client_id, email_array, subject, body, todate);
            const res = response.data;
            consoleToLog('Response sendClientStamentApi: ', res);
            setEmailLoading(false);
            enqueueSnackbar('Email sent successfully!', {variant:"success"});
        } catch(e) {
            consoleToLog('Response sendClientStamentApi: ', e);
            setEmailLoading(false);
            if(e.response.data && e.response.data.message) {
                enqueueSnackbar(e.response.data.message, {variant:"error"});
            }
        }
    }

    const onDownloadOrPrintClientStatementClick = async(fromPrint) => {
        let todate = moment(toDateFilter).format('YYYY-MM-DD');
        fromPrint ? setPrintPdfLoading(true) : setDowloadPdfLoading(true);
    
        try {
            const response = await downloadClientSummaryApi(invoice_account_id, clientObj?.id, todate);
            const res = response.data;
            consoleToLog("Response downloadClientSummaryApi", res);
            fromPrint ? setPrintPdfLoading(false) : setDowloadPdfLoading(false);

            const downloadableURL = res.document_signed_url;
            if(fromPrint) {
                setDownloadStatementUrl(downloadableURL);
            } else {

                // //initiate download
                document.getElementById('statement_download').href = downloadableURL;
                document.getElementById('statement_download').click();
            }

        } catch (e) {
            consoleToLog("Error downloadClientSummaryApi", e.response);
            fromPrint ? setPrintPdfLoading(false) : setDowloadPdfLoading(false);
            if(e.response.data && e.response.data.message) {
                enqueueSnackbar(e.response.data.message, {variant: 'error'});
                return;
            }
        }
    }

    const onDownloadCsvClick = async() => {
        let todate = moment(toDateFilter).format('YYYY-MM-DD');
        setDowloadCsvLoading(true);
        
        try {
            const response = await downloadCsvForClientSummaryApi(invoice_account_id, clientObj?.id, todate);
            const res = response.data;
            consoleToLog("Response downloadClientSummaryApi", res);
            setDowloadCsvLoading(false);

            const downloadableURL = res.document_signed_url;

            // //initiate download
            document.getElementById('csv_download').href = downloadableURL;
            document.getElementById('csv_download').click();

        } catch (e) {
            consoleToLog("Error downloadClientSummaryApi", e.response);
            setDowloadCsvLoading(false);
            if(e.response.data && e.response.data.message) {
                enqueueSnackbar(e.response.data.message, {variant: 'error'});
                return;
            }
        }
    }

    return (
        <Dialog
            open={props.openClientSummaryModal}
            TransitionComponent={Transition}
            scroll="paper"
            fullScreen
            style={{scrollbarWidth:'none !important', zIndex:'100'}}
            onClose={props.handleClientSummaryDialogClose}>

                {apiLoading ?
                <Grid item container justifyContent='center'>
                    <CircularProgress size={20} color='#222' style={{marginTop:'8px'}}/>
                </Grid> 
                :
                <Grid item md={12} style={{background:'#f7f7f7'}}>
                    <Container className={classes.container} maxWidth='xl'>
                        <Box width='100%' style={{marginBottom:'32px'}}>
                            <Box height={'100%'} display='flex' justifyContent='space-between'>
                                <Box width={showSendEmailUi ? '60%' : '73%'}>
                                    <Paper variant='outlined'>
                                        <Grid item container alignItems='center' justifyContent='space-between' style={{padding:'16px'}}>
                                            <Grid item>
                                                <div className={classes.backIcon}
                                                    onClick={() => props.handleClientSummaryDialogClose()}>
                                                    <KeyboardArrowLeftIcon fontSize='large' style={{color:'#555'}}/>
                                                </div>
                                            </Grid>

                                            <Grid item>
                                            <Button variant="outlined" color="primary" 
                                                style={{display:'flex', alignItems:'center', color:'#6a7685', padding:'5px'}}
                                                onClick={handleSelectDatePopoverOpen}>
                                                <DateRangeIcon fontSize='small' style={{marginRight:'6px', marginTop:'-2px',}}/> 
                                                    To: {moment(toDateFilter).format('DD/MM/YYYY')}
                                                    <ExpandMoreIcon fontSize='small' style={{marginLeft:"4px"}}/>
                                            </Button>
                                            {showSelectDatePopOver()}
                                            </Grid>
                                        </Grid>
                                        <Divider variant='dark' style={{margin:'0px 0px 16px 0px'}}/>
                                        <Grid item xs={12} className={classes.paper2}>
                                            
                                            <Grid container alignItems='flex-start' justifyContent='space-beween'> 
                                                <Grid item xs={8}>
                                                    <Typography variant='h5' className='invoice_text_color'>
                                                        Account Statement
                                                    </Typography>
                                                        <Typography className='bill_label'>
                                                            Bill To:
                                                        </Typography>
                                                        <Typography className='client_owner_name'>{clientObj?.name || 'CLient Name'}</Typography>
                                                        <Typography variant="body2">{clientObj?.address}</Typography>
                                                        <Typography variant="body2">{clientObj?.country}, {clientObj?.state}</Typography>
                                                        <Typography variant="body2">{clientObj?.email}</Typography>
                                                        <Typography variant="body2">{clientObj?.phone_number}</Typography>

                                                </Grid>

                                                <Grid item xs={4}>
                                                    <Box style={{position:'relative'}}>
                                                        <Typography className={classes.summaryStyle}>
                                                            Summary as of {moment(toDateFilter).format('DD/MM/YYYY')}
                                                        </Typography>
                                                        <Box>
                                                            {
                                                                summary && summary.length > 0 &&
                                                                summary.map((obj, index) => {
                                                                    let isLastElement = index === summary.length - 1;
                                                                    return  <>
                                                                            <Grid item container alignItems='center' justifyContent='space-between'
                                                                                className={classes.summaryPaddingStyle}>
                                                                                <Grid item>
                                                                                    <Typography className={`${isLastElement ? classes.boldText : classes.summaryTextStyle}`}>
                                                                                        {obj?.label}
                                                                                    </Typography>
                                                                                </Grid>
                                                                                <Grid item>
                                                                                    <Typography className={`${isLastElement ? classes.boldText : classes.summaryTextStyle}`}>
                                                                                        {obj?.label === 'Payments' && '-' }{formatAmount(obj.amount, accCurrencyObj)}
                                                                                    </Typography>
                                                                                </Grid>
                                                                            </Grid>
                                                                            {!isLastElement && <Divider />}
                                                                            </>
                                                                })
                                                            }
                                                        </Box>
                                                    </Box>
                                                </Grid>
                                        </Grid>

                                            <Divider style={{margin:'16px 0px'}}/>

                                            <Grid item container 
                                                alignItems='center'
                                                spacing={1}
                                                style={{marginBottom: '16px'}}>
                                                <Grid item md={7}>
                                                    <Typography variant='h6' 
                                                        style={{margin:0}}
                                                        className={classes.colorText}>
                                                        Detailed Summary
                                                    </Typography>

                                                    <Typography variant='body2' className={classes.colorText}>
                                                        Below is a timeline of all invoices and payment transactions 
                                                    </Typography>
                                                </Grid>

                                            </Grid>

                                            <TableContainer component={Paper} elevation={0}>
                                                <Table className={classes.table} aria-label="revenue report">
                                                    <TableHead style={{background:"#f0f5f9"}}>
                                                        <TableRow>
                                                            <TableCell className='tableheading_style1'>Date</TableCell>
                                                            <TableCell className='tableheading_style1' align="left" colSpan={2}>Invoice</TableCell>
                                                            <TableCell className='tableheading_style1' align="right" colSpan={2}>Invoice Amount</TableCell>
                                                            <TableCell className='tableheading_style1' align="right">Paid Amount</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {(loading ? Array.from(new Array(invoicesTransactionList.length)) : invoicesTransactionList).map((statementObj, index) => {
                                                            let isLastElement = index === invoicesTransactionList.length - 1;
                                                            let currencyObj = statementObj?.currency;
                                                        return  statementObj 
                                                            ?
                                                            (statementObj.type === 'invoice' ||  statementObj.type === 'transaction') &&
                                                            <TableRow key={index} hover style={{cursor:'pointer'}}> 
                                                                <TableCell>
                                                                    <Typography variant='body2' className={classes.summaryTextStyle}>
                                                                            {moment(statementObj?.date).format('DD-MM-YYYY')}
                                                                    </Typography>
                                                                </TableCell>
                                                                <TableCell align="left" colSpan={2}>
                                                                    <Typography variant='body2' className={`purple_text ${classes.summaryTextStyle}`}>
                                                                            {`${statementObj?.invoice_description ? `${statementObj?.invoice_description} -` : ''} 
                                                                            #${statementObj?.invoice_number > 0 ? statementObj?.invoice_number : statementObj?.proforma_invoice_number}`}
                                                                    </Typography>
                                                                </TableCell>
                                                                <TableCell align="right" colSpan={2}>
                                                                    <Typography variant='body2' className={classes.summaryTextStyle} >
                                                                            {statementObj?.type === 'invoice' && formatAmount(statementObj?.invoice_amount, currencyObj)}
                                                                    </Typography>
                                                                </TableCell>
                                                                <TableCell align="right">
                                                                    <Typography variant='body2' className={classes.summaryTextStyle}>
                                                                            {statementObj?.type === 'transaction' && `-${formatAmount(statementObj?.paid, currencyObj)}`}
                                                                    </Typography>
                                                                </TableCell>
                                                            </TableRow>
                                                            :
                                                            <TableRow key={index} hover>
                                                                <TableCell component="th" scope="row"
                                                                    className='tablebody_style'>
                                                                        <Typography variant='h5'>
                                                                            <Skeleton animation='wave' variant="text" />
                                                                        </Typography>
                                                                </TableCell>
                                                                <TableCell component="th" scope="row"
                                                                    className='tablebody_style' colSpan={2} align='left'>
                                                                        <Typography variant='h5'>
                                                                            <Skeleton animation='wave' variant="text" />
                                                                        </Typography>
                                                                </TableCell>
                                                                <TableCell component="th" scope="row"
                                                                    className='tablebody_style' colspan={2} align={'right'} >
                                                                        <Typography variant='h5'>
                                                                            <Skeleton animation='wave' variant="text" />
                                                                        </Typography>
                                                                </TableCell>
                                                                <TableCell component="th" scope="row"
                                                                    className='tablebody_style'>
                                                                        <Typography variant='h5'>
                                                                            <Skeleton animation='wave' variant="text" />
                                                                        </Typography>
                                                                </TableCell>
                                                            </TableRow>
                                                        }
                                                    
                                                    )}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>

                                            {   invoicesTransactionList && invoicesTransactionList.filter((obj) => obj.type !== 'invoice' || obj.type !== 'transaction').length > 0 &&
                                                summary && summary.length > 0 ?
                                                summary.map((obj, index) => {
                                                    let isLastElement = index === summary.length - 1;
                                                    return  <>
                                                            <Grid item container alignItems='center' justifyContent='space-between'
                                                                className={classes.summaryPaddingStyle1}>
                                                                <Grid item>
                                                                    <Typography className={`${isLastElement ? classes.boldText : classes.summaryTextStyle}`}>
                                                                        {obj.label}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Typography className={`${isLastElement ? classes.boldText : classes.summaryTextStyle}`}>
                                                                        {obj.label === 'Payments' && '-' }{formatAmount(obj.amount, accCurrencyObj)}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                            {!isLastElement && <Divider />}
                                                            </>
                                                })
                                                :
                                                <Grid item container justifyContent='center' alignItems='center' 
                                                    style={{padding:'16px', background:"#f7f7f7", borderRadius:'4px'}}>
                                                    <Typography >
                                                        No Summary found
                                                    </Typography>
                                                </Grid>
                                            }
                                        </Grid>
                                    </Paper>
                                </Box>

                                <Box width={showSendEmailUi ? '38.3%' : '25%'} display='flex' flexDirection='column' gridRowGap='16px'>

                                    {showSendEmailUi ?
                                    <SendEmailComponent fromReportsComponent={true}
                                        accessLevelViewer={accessLevelViewer}
                                        onBackButtonClick={onBackButtonClick}
                                        loading={emailLoading}
                                        onSendEmailClickFromClientStatement={onSendEmailClickFromClientStatement}
                                        toDateFilter={toDateFilter}
                                        />
                                    :
                                    <Paper className={classes.paper2} variant='outlined'
                                        style={{display:'flex', flexDirection:'column', gap:'16px'}}>
                                        
                                        <Button fullWidth 
                                            onClick={() => {
                                                onSendStatementClick()
                                            }}
                                            variant="contained"
                                            disabled={accessLevelViewer}
                                            className='button_purple'>
                                            <SendIcon fontSize='small'
                                            style={{marginRight:'5px', fontSize:'16px'}} />
                                                Send Statement
                                        </Button>

                                        <Button fullWidth className={classes.buttonStyles}
                                            onClick={() => onDownloadOrPrintClientStatementClick(false)}
                                            variant="contained" style={{background:'#4a5568', color:'#fff'}} 
                                            disabled={downloadPdfLoading}>
                                            {downloadPdfLoading ? <CircularProgress size={16} style={{marginRight:'8px', color:"#fff"}}/>  : 
                                            <PictureAsPdfIcon fontSize='small'
                                                style={{marginRight:'5px', fontSize:'16px'}} />}
                                                Download
                                                <Link
                                                    style={{ display: "none" }}
                                                    id="statement_download"
                                                    target="_blank"
                                                    onClick={(e) => e.stopPropagation()}
                                                    download
                                                ></Link>
                                        </Button>

                                        <Button variant="outlined" fullWidth color="primary"
                                            onClick={onDownloadCsvClick}
                                            disabled={downloadCsvLoading}>
                                            {downloadCsvLoading && <CircularProgress size={16} style={{marginRight:'8px'}}/>}Export CSV
                                            <Link
                                                style={{ display: "none" }}
                                                id="csv_download"
                                                target="_blank"
                                                onClick={(e) => e.stopPropagation()}
                                                download
                                            ></Link>
                                        </Button>

                                        <Button variant="outlined" fullWidth color="primary"
                                            onClick={() => onDownloadOrPrintClientStatementClick(true)}
                                            disabled={printPdfLoading}>
                                                
                                            {printPdfLoading ? <CircularProgress size={16} style={{marginRight:'8px'}}/>  : 
                                                <PrintOutlinedIcon fontSize='small'
                                                style={{marginRight:'5px', color:'green', fontSize:'16px'}} />}
                                            Print
                                        </Button>
                                    </Paper>}
                                </Box>
                            </Box>
                        </Box>

                    </Container>

                </Grid>}
            
        </Dialog>
    );
}

const mapStateToProps = (state) => ({
    selectedAccount: state.invoiceAccounts.selectedAccount
});

export default connect(mapStateToProps)(ClientSummaryDialog);