import React, {useState} from 'react';
import {Grid, Button, Popover} from '@material-ui/core';
import { DateRangePicker} from "materialui-daterange-picker";
import moment from 'moment';
import {connect} from 'react-redux';
import { consoleToLog ,trackGAEvent} from '../../util/AppUtil';
import {setPaymentStartDateFilter, setPaymentEndDateFilter} from '../../actions/transactionFilters';

const PaymentDateRangeFilterComponent = (props) => {
    const [anchorEl, setAnchorEl] = useState(false);
    const [dateRange, setDateRange] = useState({});

    //Last Month
    var prevMonthFirstDay = new moment().subtract(1, 'months').date(1)
    
    //for next month last day
    var thisMonthFirstDay = moment().startOf('month').format('YYYY-MM-DD');
    var thisMonthLastDay = moment().format('YYYY-MM-DD');
    
    //for last day of previous month
    var lastdayLastMonth = moment(lastdayLastMonth).subtract(1,'months').endOf('month').format('YYYY-MM-DD');

    let currentFinancialYearStart;
    let currentFinancialYearEnd;

    currentFinancialYearStart = (moment().month() < 3) ? moment().subtract(1, 'year').month('April').startOf('month').format('YYYY-MM-DD')
    : moment().month('April').startOf('month').format('YYYY-MM-DD');

    currentFinancialYearEnd =  (moment().month() < 3) ? moment().month('March').endOf('month').format('YYYY-MM-DD')
    : moment().add(1, 'year').month('March').endOf('month').format('YYYY-MM-DD');
    
    let lastFinancialYearStart = moment(currentFinancialYearStart).subtract(1, 'year').format('YYYY-MM-DD');
    let lastFinancialYearEnd = moment(currentFinancialYearEnd).subtract(1, 'year').format('YYYY-MM-DD');


    const openDateRangePicker = (event) => {
        consoleToLog("transaction date filter");
        setAnchorEl(event.currentTarget);
    };

    const open = Boolean(anchorEl);

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <span style={{fontSize:'14px'}}>
                Payment Date Range
            </span>
            <Grid item container lg={12} style={{marginTop:'3px'}}>
                <Grid item lg={6}>
                    <Button variant='outlined' 
                        color='primary' 
                        style={{fontWeight:"300 !important"}}
                        onClick={openDateRangePicker}>
                        {`${moment(props.paymentStartDateFilter).format('DD-MM-YYYY')} - ${moment(props.paymentEndDateFilter).format('DD-MM-YYYY')}`}
                    </Button>
                    <Popover
                        id='issue_date_range-popover'
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center"
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "center"
                        }}
                        classes={{
                            paper:'filter_date_popover'
                        }}
                    >
                        <DateRangePicker
                            open={true}
                            toggle={handleClose}
                            onChange={(range) => {
                            consoleToLog("range", range, dateRange);
                                setDateRange(range);
                                trackGAEvent(props.selectedAccount.name, 'Transaction filter - date range filter changed');
                                props.setPaymentStartDateFilter(moment(range.startDate).format('YYYY-MM-DD'));
                                props.setPaymentEndDateFilter(moment(range.endDate).format('YYYY-MM-DD'));

                                if(range.startDate !== props.paymentStartDateFilter && range.endDate !== props.paymentEndDateFilter) {
                                    props.pageReset();
                                }
                            }}
                            style={{
                            width:'50%'
                            }}
                            initialDateRange={{
                                startDate:  props.paymentStartDateFilter,
                                endDate: props.paymentEndDateFilter
                            }}
                            definedRanges={[
                            {
                                label: `${moment(lastFinancialYearStart, 'YYYY-MM-DD').format('MMM-YYYY')}-${moment(lastFinancialYearEnd, 'YYYY-MM-DD').format('MMM-YYYY')}`,
                                startDate: lastFinancialYearStart,
                                endDate: lastFinancialYearEnd
                            },
                            {
                                label: `${moment(currentFinancialYearStart, 'YYYY-MM-DD').format('MMM-YYYY')}-${moment(currentFinancialYearEnd, 'YYYY-MM-DD').format('MMM-YYYY')}`,
                                startDate: currentFinancialYearStart,
                                endDate: currentFinancialYearEnd
                            },
                            {
                                label: 'Last Month',
                                startDate: prevMonthFirstDay,
                                endDate: lastdayLastMonth
                            },
                            {
                                label: 'This Month',
                                startDate: thisMonthFirstDay,
                                endDate: thisMonthLastDay
                            }
                            ]}
                        />
                    </Popover>    
                </Grid>
            </Grid>
        </div>
    );
}

const mapStateToProps = (state) => ({
    paymentStartDateFilter:  state.transactionFilters.paymentStartDateFilter,
    paymentEndDateFilter: state.transactionFilters.paymentEndDateFilter,
    selectedAccount: state.invoiceAccounts.selectedAccount
})

const mapDispatchToProps = (dispatch) => ({
    setPaymentStartDateFilter: (start_date) => dispatch(setPaymentStartDateFilter(start_date)),
    setPaymentEndDateFilter: (end_date) => dispatch(setPaymentEndDateFilter(end_date))
})

export default connect(mapStateToProps, mapDispatchToProps)(PaymentDateRangeFilterComponent);