import React, {useState, useEffect} from 'react';
import {Grid, Typography, Button, Avatar, Popover, MenuItem} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { useIsMount } from '../useIsMount';
import { getClientsListApi } from '../../services/clientService';
import { connect } from 'react-redux';
import AddEditClientDrawer from '../AddEditClientDrawer';
import { consoleToLog, AccessLevel } from '../../util/AppUtil';

const useStyles = makeStyles((theme) => ({
    connectIcons: {
        width:'20px',
        marginLeft:'auto'
    },
    avatarStyle: {
        width:"38px !important",
        height:"38px !important",
        fontSize:'16px !important',
        fontWeight:'600 !important',
        transform: 'translateY(3px)'
    },
    clientText: {
        transform: 'translateY(1.5px)'
    },
    clientsContainerStyle: {
        height:'170px', 
        overflowY:'auto', 
        overflowX:'hidden',
        "&::-webkit-scrollbar": {
            display: "none"
        },
        [theme.breakpoints.down(1430)] : {
            height:'70px', 
        }
    },
    clientColumn : {
        background: '#F9F9F9',
        borderRadius: '5px',
        marginBottom: '8px',
        padding:'8px 16px 8px 8px',
        '&:last-child' : {
            marginBottom: '0px'
        },
        "& .deleteIcon" : {
            visibility: 'hidden'
        },
        "&:hover .deleteIcon" : {
            visibility: 'visible'
        }
    },
    iconAttach: {
        marginLeft:'6px', 
        fontSize:'0.8rem', 
        height:'20px', 
        color:'#222'
    },
}));

const OnboardingClients = (props) => {
    const classes = useStyles();
    const isMount = useIsMount();

    const [clientList, setClientList] = useState([]);
    const [page, setPage] = useState(0);
    const [nextPage, setNextPage] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isMoreLoading, setIsMoreLoading] = useState(false);

    const [openClientDrawer, setOpenClientDrawerDrawer] = useState(false);

    const { access_level } = props.selectedAccount || {};
    const accessLevelViewer = (AccessLevel.getUserAccessLevelValue(access_level) === AccessLevel.VIEWER) ? true : false;

    useEffect(() => {
        fetchClientList();
    }, []);


    const fetchClientList = (query) => {
        const invoice_account_id = props.selectedAccount.id;

        if(!isMoreLoading)  setLoading(true);
        getClientsListApi(invoice_account_id, page, query)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response getClientsListApi: ', res);
                setLoading(false);

                if(isMoreLoading) {
                    const newItems = clientList.concat(res.companies);
                    setClientList(newItems);
                    setPage(page + 1);
                    setNextPage(res.next_page);
                    setIsMoreLoading(!isMoreLoading);
                } else {
                    setNextPage(res.next_page);
                    if (res.next_page) setPage(page + 1);
                    setClientList(res.companies);
                }
            })
            .catch((e) => {
                setLoading(false);
                consoleToLog('Error getClientsListApi: ', e.response);
                //show401ErrorAndClearRedux(props, e, enqueueSnackbar);
            })
    }

    const showClientsList = () => {
        return (
                <Grid item container className={classes.clientsContainerStyle}>
                    <Grid item container direction="column">
                        {clientList.map((client) => {
                            const compArr = client.name.split(" ");
                            let compStr1 = compArr[0];
                            let compStr2 = compArr[1] ? compArr[1] : '';
                            const finalStr = compStr1?.charAt(0).toUpperCase() + compStr2?.charAt(0).toUpperCase();
                            const clientEmails = client.email && client.email.length > 0 ? client.email.split(',') : [];
                            return <Grid item container 
                                        key={client.id} 
                                        justifyContent='space-between'
                                        className={classes.clientColumn}>

                                        <Grid item md={12} lg={12}>
                                            <Grid item container alignItems="center">
                                                <Grid item>
                                                    <Avatar className={classes.avatarStyle}>
                                                        {finalStr}   
                                                    </Avatar>
                                                </Grid>
                                                <Grid item className={classes.clientText}>
                                                    <div style={{marginLeft: "16px"}}>
                                                        <Typography variant='body1'>
                                                            {client.name} 
                                                        </Typography>
                                                        <Typography variant="subtitle1" style={{color:'#555555'}}>
                                                            {clientEmails}
                                                        </Typography>
                                                    </div>
                                                </Grid>
                                            </Grid>                                                
                                        </Grid>
                                        {/* {member.user.id === memberId && showMoreActionsPopover(member, fullName)} */}
                                    </Grid>
                            })
                        }   
                    </Grid>
                </Grid>
        )
    }

    const handleClientDrawerOpen = () => {
        setOpenClientDrawerDrawer(true);
    }

    const handleClientDrawerClose=()=>{
        setOpenClientDrawerDrawer(false);
    }

    const addClient = (clientObj) => {
        setClientList(oldClients => [clientObj, ...oldClients]);
    }

    return (
        <Grid item md={12}>
            {clientList && clientList.length > 0 && showClientsList()}
            <Grid item container justifyContent='center' alignItems='center'
                style={{marginTop:"16px"}}>
                <Grid item md={6}>
                    <Button variant='outlined' color='primary' fullWidth
                        onClick={handleClientDrawerOpen}>
                        Add Client
                        <AddCircleOutlineIcon className={classes.connectIcons} />
                    </Button>
                </Grid>
            </Grid>
            {openClientDrawer && <AddEditClientDrawer openClientDrawer={openClientDrawer}
                                    handleClientDrawerClose={handleClientDrawerClose}
                                    addClient={addClient}
                                    accessLevelViewer={accessLevelViewer}
                                    fromOnboardingComponent={true}
                                />}
        </Grid>
    );
}

const mapStateToProps = (state) => ({
    selectedAccount: state.invoiceAccounts.selectedAccount,
    user: state.invoiceAccounts.user,
    invoiceAccountsList: state.invoiceAccounts.invoiceAccountsList
});

export default connect(mapStateToProps)(OnboardingClients);