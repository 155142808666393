//mentions used in:
//email Settings

//subject
export const getMentionOptionsForSubject = () => {
    //var { name, job_description, due_date, issue_date, total_amount, due_amount } = emailSubjectMentionData ? emailSubjectMentionData : {};

    var mentionOptionsForSubject = [
        { id: `{{invoice_account.name}}`, display: `{{invoice_account.name}}`},
        { id: `{{client.name}}`, display: `{{client.name}}` },
        { id: `{{total_due}}`, display: `{{total_due}}`},
        { id: `{{total_invoice_count}}`, display: `{{total_invoice_count}}`}
       // { id: `{{invoice.invoice_description}}`, display: `{{invoice.invoice_description}}` },
       // { id: `{{invoice.due_date}}`, display: `{{invoice.due_date}}` },
       // { id: `{{invoice.issue_date}}`, display: `{{invoice.issue_date}}` },
        //{ id: `{{invoice.total}}`, display: `{{invoice.total}}` },
        //{ id: `{{invoice.due}}`, display: `{{invoice.due}}` },
    ];
    return mentionOptionsForSubject;
}

//body
export const getMentionOptionsForBody = (emailBodyMentionData) => {
    //var { name, job_description, due_date, issue_date, total_amount, due_amount } = emailBodyMentionData ? emailBodyMentionData : {};

    var mentionOptionsForBody = [
        {
            text: `{{invoice_account.name}}`,
            value: `{{invoice_account.name}}`,
            url: `{{invoice_account.name}}`
        },
        {
            text: `{{client.name}}`,
            value: `{{client.name}}`,
            url: `{{client.name}}`
        },
        { 
            text: `{{total_due}}`, 
            value: `{{total_due}}`, 
            url: `{{total_due}}`
        },
        { 
            text: `{{total_invoice_count}}`, 
            value: `{{total_invoice_count}}`, 
            url: `{{total_invoice_count}}`
        },
        // {
        //     text: `{{invoice.invoice_description}}`,
        //     value: `{{invoice.invoice_description}}`,
        //     url: `{{invoice.invoice_description}}`
        // },
        // {
        //     text: `{{invoice.due_date}}`,
        //     value: `{{invoice.due_date}}`,
        //     url: `{{invoice.due_date}}`,
        // },
        // {
        //     text: `{{invoice.issue_date}}`,
        //     value: `{{invoice.issue_date}}`,
        //     url: `{{invoice.issue_date}}`,
        // },
        // {
        //     text: `{{invoice.total}}`,
        //     value: `{{invoice.total}}`,
        //     url: `{{invoice.total}}`,
        // },
        // {
        //     text: `{{invoice.due}}`,
        //     value: `{{invoice.due}}`,
        //     url: `{{invoice.due}}`,
        // }
    ];

    return mentionOptionsForBody;
}