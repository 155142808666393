import React, {Fragment} from 'react';
import { Autocomplete } from '@material-ui/lab';
import ClearIcon from '@material-ui/icons/Clear';
import {Grid, Typography, Button, Chip, TextField, Box, Divider} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    chip : {
        borderRadius: '4px !important',
        backgroundColor: '#f9f9f9 !important',
        border: '1px solid #ececec !important',
    },
    MuiAutocompleteinputRoot : {
        '& .MuiOutlinedInput-root' : {
        padding:'1px',
        borderRadius: '5px',
        }
    },
    customTextField: {
        "& input::placeholder": {
            fontSize: "13px",
        }
    }
}))

const AutocompleteTaxRates = (props) => {
    const classes = useStyles();

    const renderOptions = (option) => {
        return (
                <Grid item container alignItems='center' 
                    justifyContent='space-between' key={option?.tax_preset_id}>
                    <Grid item>
                        {option.name}
                    </Grid>

                    <Grid item>
                        {option.rate}%
                    </Grid>
                </Grid>
            // props.fromOtherCountry ?
            // <div style={{display: "flex", width: "100%"}}>
            //     {`${option.name} ${option.rate}`}
            // </div>
            // :
            // <div style={{display: "flex", width: "100%"}}>
            //     {option.name}
            // </div>
            // );
        )
    }

    const onDeleteTaxPreset = (option) => {
        console.log('option', option);

        if(props.fromOtherCountry) {
            props.setSelectedOtherCountryTaxes(props.selectedOtherCountryTaxes.filter(entry => entry !== option));
        } else {
            if(props.fromInterState) {
                props.setSelectedInterStateTaxes(props.selectedInterStateTaxes.filter(entry => entry !== option)) 
            } else {
                props.setSelectedIntraStateTaxes(props.selectedIntraStateTaxes.filter(entry => entry !== option));
            }
        }
        
    }

    const renderTags = (options) => {
        return (
            options.map(option =>
                <Fragment key={option.id}>
                <Chip
                    classes={{
                        root: classes.chip
                    }}
                    label={`${option.name} (${option.rate}%)`}
                    onDelete={() => onDeleteTaxPreset(option)}
                    variant="outlined"
                    style={{margin: "2px 2px"}}
                    deleteIcon={<ClearIcon  style={{width: "18px"}}/>}
                />
                </Fragment>))
    }

    const taxOptions = () => {
        if(props.fromOtherCountry) {
            return props.otherCountryTaxes.filter(option => !props.selectedOtherCountryTaxes.some(selected => selected.tax_preset_id === option.id));
        } else {
            if(props.fromInterState) {
                return props.interStateTaxes.filter((option) => !props.selectedInterStateTaxes.some(selected => selected.tax_preset_id === option.id)); 
            } else {
                return props.intraStateTaxes.filter((option) => !props.selectedIntraStateTaxes.some(selected => selected.tax_preset_id === option.id));
            }
        }
    }

    const selectedTaxes = () => {
        if(props.fromOtherCountry) {
            return props.selectedOtherCountryTaxes;
        } else {
            if(props.fromInterState) {
                return props.selectedInterStateTaxes; 
            } else {
                return props.selectedIntraStateTaxes;
            }
        }
    }

    const onTaxPresetsChange = (e, newValue) => {

        if(props.fromOtherCountry) {
            props.setSelectedOtherCountryTaxes(newValue);
        } else {
            if(props.fromInterState) {
                props.setSelectedInterStateTaxes(newValue); 
            } else {
                props.setSelectedIntraStateTaxes(newValue);
            }
        }
    }

    const showPlaceholder = () => {
        if(props.fromOtherCountry) {
            return "Type here";
        } else {
            if(props.fromInterState) {
                return "Type IGST18, CGST28. You can select multiple taxes."; 
            } else {
                return "Type SGST9, CGST9. You can select multiple taxes.";
            }
        }
    }

    const getTaxLabel = (option) => {
        // if(props.fromOtherCountry) {
        //     return `${option.name} ${option.rate}`
        // } else {
        //     return option.name
        // }
        return `${option.name} (${option.rate}%)`;
    }

    return (
        <div>
            <Autocomplete
                multiple
                id="size-small-outlined-multi"
                size="small"
                options={taxOptions()}
                getOptionLabel={(option) => getTaxLabel(option)}
                disableClearable
                renderOption={renderOptions}
                value={selectedTaxes()}
                onChange={(e, newValue) => onTaxPresetsChange(e, newValue)}
                classes={{
                    root: classes.MuiAutocompleteinputRoot
                }}
                filterSelectedOptions
                renderTags={(options) => renderTags(options)}
                renderInput={(params) => (
                    <TextField {...params} variant="outlined" 
                        placeholder={showPlaceholder()} 
                        fullWidth
                        classes={{
                            root: classes.customTextField
                        }}
                    />
                )}
            />
        </div>
    );
}

export default AutocompleteTaxRates;