import React, {useState, useEffect} from 'react';
import BaseComponent from './Base/BaseComponent';
import { makeStyles } from '@material-ui/core/styles';
import {useIsMount} from '../components/useIsMount';
import LeftMenuComponent from './LeftMenuComponent';
import DashboardComponent from './DashboardComponent';
import InvoicesComponent from './InvoicesComponent';
import TransactionsComponent from './TransactionsComponent';
import SettingsComponent from './SettingsComponent';
import { connect } from 'react-redux';
import { SelectedDrawerState, setSelectedDrawerState } from '../actions/drawer';
import {getInvoiceAccountsList} from '../actions/invoiceAccount';
import NoAccessTokenPage from './NoAccessTokenPage';
import { CircularProgress, Grid } from '@material-ui/core';
import {clearSelectedInvoiceFilters} from '../actions/invoiceFilters';
import { clearSelectedTransactionFilters } from '../actions/transactionFilters';
import SnackbarComponent from './SnackbarComponent';
import ClientsComponent from './ClientsComponent';
import MyProfile from './MyProfile';
import AccountMembers from './AccountMembers';
import {AccessLevel} from '../util/AppUtil';
import { history } from '../routers/AppRouter';
import { useLocation } from 'react-router-dom';
import AddEditPaymentDrawer from './AddEditPaymentDrawer';
import {consoleToLog, trackGAEvent} from '../util/AppUtil';
import InvoiceModalComponent from './modals/InvoiceModalComponent';
import AddNewPaymentDrawer from './AddNewPaymentDrawer';
import AddEditClientDrawer from './AddEditClientDrawer';
import ClientContactModal from './modals/ClientContactModal';
import InvoiceListComponent from './InvoiceListComponent';
import AddEditBankDrawer from './AddEditBankDrawer';
import CreateEditPreviewInvoiceModal from './modals/CreateEditPreviewInvoiceModal';
import ShowInvoicesDrawer from './ShowInvoicesDrawer';
import OutsideOnboardingComponent from './OutsideOnboardingComponent';
import { fetchAccessToken, InvoicePlans } from '../util/AppUtil';
import ShowInfoDialog from './ShowInfoDialog';

const useStyles = makeStyles((theme) => ({
    displayflex : {
        display: "flex", 
        height: "100%"
    },
    loading: {
        position:'absolute',
        left:'50%',
        right:'50%',
        top:'10%'
    }

}));

const DashboardPage = (props) => {
    const classes = useStyles();
    const isMount = useIsMount();
    const [loading, setLoading] = useState(true);

    const [openAddPaymentDrawer, setOpenAddPamentDrawer] = useState(false);
    const [invoiceObj, setInvoiceObj] = useState(undefined);
    const [paymentInvoiceObj, setPaymentInvoiceObj] = useState(undefined);

    const [openDialog, setOpenDialog] = useState(false);

    const [openAddNewPaymentDrawer, setOpenAddNewPaymentDrawer] = useState(false);
    const [updateTranscItemFromParent, setUpdateTranscItemFromParent] = useState(undefined);
    const [transactionObj, setTransactionObj] = useState(undefined);
    const [addTransaction, setAddTransaction] = useState(undefined);

    const [openClientDrawer, setOpenClientDrawerDrawer] = useState(false);
    const [openClientContactDrawer, setOpenClientContactDrawer] = useState(false);
    const [newClientObj, setNewClientObj] = useState(undefined);
    const [clientObjFromParent, setClientObjFromParent] = useState(undefined);
    const [editClient, setEditClient] = useState(undefined);
    const [isAddClient, setIsAddClient] = useState(false);

    const [openBankDrawer, setOpenBankDrawer] = useState(false);
    const [bankAccObj, setBankAccObj] = useState(undefined);
    const [entityObjForBankAcc, setEntityObjForBankAcc] = useState(false);
    const [updatedInvoiceObj, setUpdatedInvoiceObj] = useState(undefined);

    const [openInvoiceDialog, setOpenInvoiceDialog] = useState(false);
    const [openInvoicesDrawer, setOpenInvoicesDrawer] = useState(false);
    const [dueInvoiceObj, setDueInvoiceObj] = useState(undefined);
    const [clientInvoiceObj, setClientInvoiceObj] = useState(undefined);

    const [openInfoDialog, setOpenInfoDialog] = useState(false);
    const [newInvoiceClicked, setNewInvoiceClicked] = useState(false);
    const [callgetInvoiceListApi, setCallGetInvoiceListApi] = useState(false);

    const location = useLocation();
    const fullName = props.user.firstname + ' ' + props.user.lastname;

    //const yourhandle= require('countrycitystatejson');

    //console.log('getStates', yourhandle.getStatesByShort('IN'))

    useEffect(() => {
            const fetchToken = async () => {
                try {
                const data = await fetchAccessToken('invoices');
                if(data) {
                    consoleToLog('Response authenticateApi', data);
                    const {access_token} = data;
                    if(access_token) {
                        props.getInvoiceAccountsList(props.selectedAccount);
                        setLoading(false);
                    } else {
                        //window.open(`${process.env.REACT_APP_AUTH_APP_DOMAIN_NAME}/login?service=invoices`, '_self');
                    }
                } 
                } catch (error) {
                    consoleToLog("error fetchAccessToken", error);
                }
            };
        
            fetchToken();
        
    }, []);

    useEffect(() => {
        if(Object.keys(props.editInvoiceObj).length > 0 || Object.keys(props.invoiceObj).length > 0 || props.showCreateInvoice) {
            setOpenInvoiceDialog(true);
        }
    }, []);

    const[showDrawer, setShowDrawer] = useState(true);
    const is_email_verified = props.selectedAccount?.is_email_verified;

    const { access_level } = props.selectedAccount || {};
    const accessLevelViewer = (AccessLevel.getUserAccessLevelValue(access_level) === AccessLevel.VIEWER) ? true : false;

    const entityList = props.selectedAccount.entities;
    const unVerifiedEntityEmails = entityList && entityList.length > 0 ? entityList.filter((entityItem) => !entityItem.is_email_verified) : [];
    const is_entity_email_unverified = unVerifiedEntityEmails && unVerifiedEntityEmails.length > 0;
    const isInvoiceAccountPlanFree = props.selectedAccount?.plan?.toLowerCase() === InvoicePlans.FREE;
    const entityLimitExceeded = props.selectedAccount?.entity_limit_exceeded;
    const canCreateInvoice = props.selectedAccount?.can_create_invoice;

    const drawerAction = () => {
        //consoleToLog("*****drawerAction"); 
        setShowDrawer(!showDrawer);
    }

    const handleOpenAddPaymentDrawer = (e, invoice) => {
        trackGAEvent(props.selectedAccount.name, 'Invoice list item - Add Payment button clicked', `${props.user.firstname} ${props.user.lastname}`);
        e.stopPropagation();
        setInvoiceObj(invoice);
        setOpenAddPamentDrawer(true);
    };

    const handleCloseAddPaymentDrawer = () => {
        setOpenAddPamentDrawer(false);
        setInvoiceObj(undefined);
    };

    const updateInvoiceItemInInvoiceList = (obj) => {
        setPaymentInvoiceObj(obj);
    }

    const handleClickDialogOpen = () => {
        if(canCreateInvoice) {
            if(!entityLimitExceeded) {
                trackGAEvent(props.selectedAccount.name, 'Invoice list item - New Invoice button clicked', `${props.user.firstname} ${props.user.lastname}`);
                setOpenDialog(true);
            } else {
                handleInfoDialogOpen();
            }
        } else {
            handleInfoDialogOpen();
            setNewInvoiceClicked(true);
        }
    };
    
    const handleClickDialogClose = () => {
        setOpenDialog(false);
    };

    const handleOpenAddNewPaymentDrawer = () => {
        setOpenAddNewPaymentDrawer(true);
    };

    const handleCloseAddNewPaymentDrawer = () => {
        setOpenAddNewPaymentDrawer(false);
    };

    const updateTranscItemInTranscList = (obj) => {
        setUpdateTranscItemFromParent(obj);
    }

    const handleClientDrawerOpen = () => {
        //trackGAEvent(props.selectedOrganization.organization.name, 'Add Contact Clicked', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
        setOpenClientDrawerDrawer(true);
    }

    const handleClientDrawerClose=()=>{
        setOpenClientDrawerDrawer(false);
    }

    const handleOpenClientContactDrawer = () => {
        setOpenClientContactDrawer(true);
    }

    const handleCloseClientContactDrawer = () => { 
        setOpenClientContactDrawer(false);
    }

    const addClient = (clientObj) => {
        if((props.drawerState === SelectedDrawerState.SHOW_CLIENTS) || (props.drawerState === SelectedDrawerState.SHOW_ONBOARDING)) {
                setIsAddClient(true);
                setClientObjFromParent(clientObj);
        }
        setNewClientObj(clientObj);
        props.drawerState !== SelectedDrawerState.SHOW_CLIENTS && props.drawerState !== SelectedDrawerState.SHOW_ONBOARDING && handleOpenClientContactDrawer();
    }

    const clientEdited = (clientObj) => {
        if((props.drawerState === SelectedDrawerState.SHOW_CLIENTS) || (props.drawerState === SelectedDrawerState.SHOW_ONBOARDING)) {
            setClientObjFromParent(clientObj);
        }
    }

    const handleBankDrawerOpen = () => {
        setOpenBankDrawer(true);
    };

    const handleBankDrawerClose = () => {
        setOpenBankDrawer(false);
    };

    const handleInvoiceDialogOpen = () => {
        setOpenInvoiceDialog(true);
    }

    const handleInvoiceDialogClose = () => {
        setOpenInvoiceDialog(false);
    }

    const handleInvoicesDrawerOpen = (invoice, selectedEntity) => {
        const obj = {
            ...invoice,
            entity_id: selectedEntity === 'all' ? undefined : selectedEntity
        }
        setDueInvoiceObj(obj);
        setOpenInvoicesDrawer(true);
    }

    const handleInvoicesDrawerClose = () => {
        setOpenInvoicesDrawer(false);
        setDueInvoiceObj(undefined);
    }

    const updateInvoiceItemListInClients = (invoiceObj) => {
        setClientInvoiceObj(invoiceObj)
    }

    const handleInfoDialogOpen = () => {
        setOpenInfoDialog(true);
    }
    
    const handleInfoDialogClose = () => {
        setOpenInfoDialog(false);
    }

    const displaySelectedComponent = () => {
        if(props.drawerState === SelectedDrawerState.SHOW_INVOICES) {
            return  <InvoiceListComponent showDrawer={showDrawer} 
                                    fullName={fullName}
                                    is_entity_email_unverified={is_entity_email_unverified}
                                    handleOpenAddPaymentDrawer={handleOpenAddPaymentDrawer}
                                    paymentInvoiceObj={paymentInvoiceObj}
                                    setPaymentInvoiceObj={setPaymentInvoiceObj}
                                    handleInvoiceDialogOpen={handleInvoiceDialogOpen}
                                    openInvoiceDialog={openInvoiceDialog}
                                    updatedInvoiceObj={updatedInvoiceObj}
                                    setUpdatedInvoiceObj={setUpdatedInvoiceObj}
                                    isInvoiceAccountPlanFree={isInvoiceAccountPlanFree}
                                    entityLimitExceeded={entityLimitExceeded}
                                    callgetInvoiceListApi={callgetInvoiceListApi}
                                    setCallGetInvoiceListApi={setCallGetInvoiceListApi}
                                    />
        }
        else if(props.drawerState === SelectedDrawerState.SHOW_TRANSACTIONS) {
            return  <TransactionsComponent showDrawer={showDrawer} 
                                    fullName={fullName}
                                    is_entity_email_unverified={is_entity_email_unverified}
                                    updateTranscItemFromParent={updateTranscItemFromParent}
                                    setUpdateTranscItemFromParent={setUpdateTranscItemFromParent}
                                    setTransactionObj={setTransactionObj}
                                    setOpenAddPamentDrawer={setOpenAddPamentDrawer}
                                    setAddTransaction={setAddTransaction}
                                    addTransaction={addTransaction}
                                    handleInvoiceDialogOpen={handleInvoiceDialogOpen}
                                    isInvoiceAccountPlanFree={isInvoiceAccountPlanFree}
                                    entityLimitExceeded={entityLimitExceeded}/>
        }
        else if(props.drawerState === SelectedDrawerState.SHOW_SETTINGS) {
            return  <SettingsComponent showDrawer={showDrawer} 
                                    fullName={fullName}
                                    is_entity_email_unverified={is_entity_email_unverified}
                                    isInvoiceAccountPlanFree={isInvoiceAccountPlanFree}
                                    entityLimitExceeded={entityLimitExceeded}
                                    handleInfoDialogOpen={handleInfoDialogOpen}/>
        }
        else if(props.drawerState === SelectedDrawerState.SHOW_CLIENTS) {
            return <ClientsComponent showDrawer={showDrawer} 
                                    fullName={fullName}
                                    is_entity_email_unverified={is_entity_email_unverified}
                                    handleClientDrawerOpen={handleClientDrawerOpen}
                                    clientObjFromParent={clientObjFromParent}
                                    setClientObjFromParent={setClientObjFromParent}
                                    handleOpenClientContactDrawer={handleOpenClientContactDrawer}
                                    setEditClient={setEditClient}
                                    isAddClient={isAddClient}
                                    setIsAddClient={setIsAddClient}
                                    handleInvoiceDialogOpen={handleInvoiceDialogOpen}
                                    clientInvoiceObj={clientInvoiceObj}
                                    setClientInvoiceObj={setClientInvoiceObj}
                                    updateTranscItemFromParent={updateTranscItemFromParent}
                                    setUpdateTranscItemFromParent={setUpdateTranscItemFromParent}
                                    setAddTransaction={setAddTransaction}
                                    addTransaction={addTransaction}
                                    updatedInvoiceObj={updatedInvoiceObj}
                                    setUpdatedInvoiceObj={setUpdatedInvoiceObj}
                                    isInvoiceAccountPlanFree={isInvoiceAccountPlanFree}
                                    entityLimitExceeded={entityLimitExceeded}
                                    callgetInvoiceListApi={callgetInvoiceListApi}
                                    setCallGetInvoiceListApi={setCallGetInvoiceListApi}
                                    />
        }
        else if(props.drawerState === SelectedDrawerState.SHOW_PROFILE || 
            props.drawerState === SelectedDrawerState.SHOW_INVOICE_ACCOUNTS) {
            return <MyProfile showDrawer={showDrawer} 
                                    fullName={fullName}
                                    is_entity_email_unverified={is_entity_email_unverified}
                                    isInvoiceAccountPlanFree={isInvoiceAccountPlanFree}
                                    entityLimitExceeded={entityLimitExceeded}/>
        }
        else if(props.drawerState === SelectedDrawerState.SHOW_MEMBERS) {
            return <AccountMembers showDrawer={showDrawer} 
                                    fullName={fullName}
                                    is_entity_email_unverified={is_entity_email_unverified}
                                    isInvoiceAccountPlanFree={isInvoiceAccountPlanFree}
                                    entityLimitExceeded={entityLimitExceeded}/>
        }
        else if(props.drawerState === SelectedDrawerState.SHOW_ONBOARDING) {
            return <OutsideOnboardingComponent showDrawer={showDrawer} 
                                    fullName={fullName}
                                    is_entity_email_unverified={is_entity_email_unverified}
                                    handleClickDialogOpen={handleClickDialogOpen}
                                    isInvoiceAccountPlanFree={isInvoiceAccountPlanFree}
                                    entityLimitExceeded={entityLimitExceeded}
                                    handleClientDrawerOpen={handleClientDrawerOpen}
                                    clientObjFromParent={clientObjFromParent}
                                    setClientObjFromParent={setClientObjFromParent}
                                    handleOpenClientContactDrawer={handleOpenClientContactDrawer}
                                    isAddClient={isAddClient}
                                    setIsAddClient={setIsAddClient}
                                    setEditClient={setEditClient}/>
        }
        else {
            return <DashboardComponent showDrawer={showDrawer} 
                                    fullName={fullName}
                                    is_entity_email_unverified={is_entity_email_unverified}
                                    handleInvoicesDrawerOpen={handleInvoicesDrawerOpen}
                                    isInvoiceAccountPlanFree={isInvoiceAccountPlanFree}
                                    entityLimitExceeded={entityLimitExceeded}
                    />
        }
    }

    return (
        <BaseComponent drawerAction={drawerAction}
            handleClickDialogOpen={handleClickDialogOpen}
            handleOpenAddNewPaymentDrawer={handleOpenAddNewPaymentDrawer}
            handleClientDrawerOpen={handleClientDrawerOpen}>
            {
                Object.keys(props.selectedAccount).length === 0 ? <CircularProgress size={30} className={classes.loading} /> :
                Object.keys(props.selectedAccount).length !== 0 &&
                <div className={classes.displayflex}>
                    <LeftMenuComponent showDrawer={showDrawer}/>
                    <Grid item container flexDirection="column">
                        {Object.keys(props.selectedAccount).length !== 0 &&
                        unVerifiedEntityEmails && unVerifiedEntityEmails.length > 0 && !accessLevelViewer &&
                        <SnackbarComponent showDrawer={showDrawer}
                            unVerifiedEntityEmails={unVerifiedEntityEmails}/>}
                        {Object.keys(props.selectedAccount).length !== 0 &&
                            displaySelectedComponent()
                        }
                    </Grid>
                </div>
            }

            {openAddPaymentDrawer && <AddEditPaymentDrawer openAddPaymentDrawer={openAddPaymentDrawer}
                handleCloseAddPaymentDrawer={handleCloseAddPaymentDrawer}
                invoiceObj={invoiceObj}
                updateInvoiceItemInInvoiceList={updateInvoiceItemInInvoiceList}
                updateTranscItemInTranscList={updateTranscItemInTranscList}
                transactionObj={transactionObj}
                setTransactionObj={setTransactionObj}
                accessLevelViewer={accessLevelViewer}
                openAddNewPaymentDrawer={openAddNewPaymentDrawer}
                handleCloseAddNewPaymentDrawer={handleCloseAddNewPaymentDrawer}
                setAddTransaction={setAddTransaction}
                handleBankDrawerOpen={handleBankDrawerOpen}
                handleBankDrawerClose={handleBankDrawerClose}
                bankAccObj={bankAccObj}
                setBankAccObj={setBankAccObj}
                setEntityObjForBankAcc={setEntityObjForBankAcc}
            />} 

            { openDialog && 
                <InvoiceModalComponent openDialog={openDialog}
                    handleClickDialogClose={handleClickDialogClose}
                    handleInvoiceDialogOpen={handleInvoiceDialogOpen}/>
            }

            {openAddNewPaymentDrawer &&
                <AddNewPaymentDrawer openAddNewPaymentDrawer={openAddNewPaymentDrawer} 
                    handleCloseAddNewPaymentDrawer={handleCloseAddNewPaymentDrawer}
                    handleOpenAddPaymentDrawer={handleOpenAddPaymentDrawer}
                />
            }

            {openClientDrawer && 
            <AddEditClientDrawer openClientDrawer={openClientDrawer} 
                    handleClientDrawerClose={handleClientDrawerClose}
                    accessLevelViewer={accessLevelViewer}
                    addClient={addClient}
                    editClient={editClient}
                    setEditClient={setEditClient}
                    clientEdited={clientEdited}
                />}

            {openClientContactDrawer &&
            <ClientContactModal openDrawer={openClientContactDrawer}
                    setOpenClientContactDrawer={setOpenClientContactDrawer}
                    newClientObj={newClientObj}
                    setNewClientObj={setNewClientObj}
                    handleCloseClientContactDrawer={handleCloseClientContactDrawer}
                    clientEdited={clientEdited}
                    //clientsList={clientsList}
                    //setClientsList={setClientsList}
                    
                />}  

            {openBankDrawer &&
                <AddEditBankDrawer openBankDrawer={openBankDrawer}
                    handleBankDrawerClose={handleBankDrawerClose}
                    bankAccountObj={{}}
                    fromDashboardOrAddPayment={true}
                    setBankAccObj={setBankAccObj}
                    entityObjForBankAcc={entityObjForBankAcc}
                    setEntityObjForBankAcc={setEntityObjForBankAcc}
                />
            }

            {
                openInvoiceDialog && 
                <CreateEditPreviewInvoiceModal openInvoiceDialog={openInvoiceDialog}
                    handleInvoiceDialogClose={handleInvoiceDialogClose}
                    setUpdatedInvoiceObj={setUpdatedInvoiceObj}
                    setAddTransaction={setAddTransaction}
                    updateTranscItemInTranscList={updateTranscItemInTranscList}
                    updateInvoiceItemListInClients={updateInvoiceItemListInClients}
                    setCallGetInvoiceListApi={setCallGetInvoiceListApi}/>
            }

            {
                openInvoicesDrawer && 
                <ShowInvoicesDrawer openInvoicesDrawer={openInvoicesDrawer}
                    handleInvoicesDrawerClose={handleInvoicesDrawerClose}
                    dueInvoiceObj={dueInvoiceObj}
                    handleOpenAddPaymentDrawer={handleOpenAddPaymentDrawer}
                    handleInvoiceDialogOpen={handleInvoiceDialogOpen}/>            
            }

            {openInfoDialog && 
            <ShowInfoDialog openInfoDialog={openInfoDialog}
                handleInfoDialogClose={handleInfoDialogClose}
                newInvoiceClicked={newInvoiceClicked}
                setNewInvoiceClicked={setNewInvoiceClicked}/>
            }
        </BaseComponent>
    );
}

const mapStateToProps = (state) => ({
    accessToken: state.invoiceAuth,
    drawerState: state.drawerState.setDrawerState,
    selectedAccount: state.invoiceAccounts.selectedAccount,
    user: state.invoiceAccounts.user,
    showCreateInvoice: state.invoiceAccounts.showCreateInvoice,
    invoiceObj: state.invoiceAccounts.invoiceObj,
    showPreviewInvoiceScreen: state.invoiceAccounts.showPreviewInvoiceScreen,
    showSendInvoiceScreenType: state.invoiceAccounts.showSendInvoiceScreenType,
    editInvoiceObj: state.invoiceAccounts.editInvoiceObj
});

const mapDispatchToProps = (dispatch) => ({
    getInvoiceAccountsList: (selectedAccount) => dispatch(getInvoiceAccountsList(selectedAccount)),
    setSelectedDrawerState: (drawerState) => dispatch(setSelectedDrawerState(drawerState)),
    clearSelectedInvoiceFilters: () => dispatch(clearSelectedInvoiceFilters()),
    clearSelectedTransactionFilters: () => dispatch(clearSelectedTransactionFilters())
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage);