import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {Grid, Typography,  Avatar, Button, Paper, Tooltip} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {AccessLevel} from '../util/AppUtil';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';

const useStyles = makeStyles((theme) => ({
    tabPanelContainer: {
        flexGrow: 1,
        padding:'15px',
        background: 'white',
        //height:'100vh',
        marginBottom:'25px',
    },      
    circularProgress: {
        marginLeft: 0,
        marginRight: theme.spacing(2),
        color: 'white'
      }, 
    companyListContainer : {
        background:'#F9F9F9', 
        minWidth:"100%", 
        borderRadius:'4px', 
        padding:'10px', 
        marginBottom:'6px',
        '& .action__icons' : {
            visibility: 'hidden',
        },
        '&:hover .action__icons' : {
            visibility: 'visible',
        },
        '&:last-child' : {
            marginBottom:'0px'
        }
    },
    avatarStyle: {
        width:"40px !important",
        height:"40px !important",
        fontSize:'16px !important',
        fontWeight:'600 !important',
        marginLeft:'12px !important'
    },    
}
))

const MyInvoiceAccounts = (props) => {
    const classes = useStyles();

    const { access_level } = props.selectedAccount || {};
    const accessLevelOwner = (AccessLevel.getUserAccessLevelValue(access_level) === AccessLevel.OWNER) ? true : false;
    console.log('accesslevelOwner', accessLevelOwner, access_level); 

    return (
        <Grid item container>
            <Paper className={classes.tabPanelContainer} elevation={0}>
                <Grid container direction="column" spacing={2}  style={{marginTop:'8px'}}>
                    {/* <Grid item>
                        <Button variant="contained" color="primary" 
                            onClick={() => props.handleDrawerOpen()}
                            style={{width:'185px'}}
                            disabled={accessLevelViewer}>
                            Create Invoice Account
                        </Button>
                    </Grid>  */}

                    <Grid item md={12} lg={12}>
                        {
                            props.invoiceAccountsList && props.invoiceAccountsList.length > 0 ?
                            props.invoiceAccountsList.map((account) => {
                                const accArr = account.name.split(" ");
                                let accStr1 = accArr[0];
                                let accStr2 = ((accArr[1] && accArr[1] !== '-') && accArr[1]) || ((accArr[1] && accArr[1] === '-') && accArr[2]) || '';
                                const finalStr = accStr1.charAt(0).toUpperCase() + accStr2.charAt(0).toUpperCase();
                                return <Grid item container key={account.id} 
                                            className={classes.companyListContainer}
                                            alignItems="center"
                                        >
                                            <Grid item md={1} lg={1}>
                                                <Avatar className={classes.avatarStyle}>
                                                    {finalStr}    
                                                </Avatar>
                                            </Grid>  

                                            <Grid item md={9} lg={9}>
                                                    <Typography variant="body1" color="primary" 
                                                        style={{fontWeight:'600'}}> 
                                                        {account.name}
                                                    </Typography>

                                                    <Typography variant="subtitle1" 
                                                        style={{color:'#555', marginBottom:"0px"}}> 
                                                        {account.email}
                                                    </Typography>
                                            </Grid> 

                                            <Grid item md={1} lg={1} style={{flexBasis:'10%', maxWidth:'10%'}}>
                                                <Typography variant="body1" color="primary" 
                                                        style={{fontWeight:'600'}}>
                                                    {account.access_level?.toUpperCase()}    
                                                </Typography>
                                            </Grid>

                                            {accessLevelOwner &&
                                            <Grid item md={1} lg={1} className='action__icons' style={{flexBasis:'6%', maxWidth:'6%'}}>
                                                <Tooltip title="Update Invoice Account Name" arrow>
                                                    <Button onClick={() => props.handleDrawerOpen(account)}>
                                                        <EditOutlinedIcon fontSize='small'/>
                                                    </Button>
                                                </Tooltip>
                                            </Grid>}

                                            {/* <Grid item md={1} lg={1} className="action__icons">
                                                <div style={{display:'flex', justifyContent:'flex-end'}}>
                                                    <Tooltip title="Archive Organization" arrow>    
                                                        <Button // onClick={() => onDeleteCompanyClick(company.id) }
                                                            style={{
                                                            minWidth:'10px'
                                                        }}>
                                                            <i className="flaticon-delete-1" style={{fontSize:'1.1rem'}}></i>
                                                        </Button>
                                                    </Tooltip>
                                                </div>
                                            </Grid>  */}
                                    </Grid>
                            }) : <Grid item container justifyContent="center">
                                    <Typography variant="body2" >
                                        No Invoice Accounts
                                    </Typography>
                                </Grid>  
                        }
                    </Grid>      
                </Grid> 
            </Paper>    
        </Grid>
    );
}

const mapStateToProps = (state) => ({
    invoiceAccountsList: state.invoiceAccounts.invoiceAccountsList,
    selectedAccount: state.invoiceAccounts.selectedAccount
});

export default connect(mapStateToProps)(MyInvoiceAccounts);