import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Grid, Typography, Button, Box, Tabs, Tab, Paper, CircularProgress} from '@material-ui/core';
import { connect } from 'react-redux';
import { history } from '../../routers/AppRouter';
import { consoleToLog, shouldDisable, AccessLevel } from '../../util/AppUtil';
import { login } from '../../actions/invoiceAuth';
import { useIsMount } from '../useIsMount';
import {updateUserLoginApi} from '../../services/authService';
import { getInvoiceAccountsList, setUserObj } from '../../actions/invoiceAccount';
import { setSelectedDrawerState, SelectedDrawerState } from '../../actions/drawer';
import OnboardingEntityUpdate from './OnboardingEntityUpdate';
import OnboardingTaxPresets from './OnboardingTaxPresets';
import OnboardingClients from './OnboardingClients';
import BasicInfo from '../BasicInfo';
import UpdateInvoiceAccount from './UpdateInvoiceAccount';

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        width:'100vw',
        height:'100vh',
        overflow:'auto'
    },
    onBoardingDesignStyle : {
        background: '#3D3D3D',
        height:'100vh',
        padding:'40px 48px'
    },
    logoStyle: {
        width: '220px',
        height: '49.18px',
        [theme.breakpoints.down(1400)] : {
            width: '190px',
            height: '40px',
        }
    },
    headingContainer: {
        marginTop:'70px'
    },
    headingTextStyle: {
        fontSize:'25px',
        fontWeight: '600',
        color:'#fff',
        lineHeight:'31px',
        [theme.breakpoints.down(1400)] : {
            fontSize:'21px'
        }
    },
    paragraphTextStyle: {
        marginTop:'16px',
        color:'#fff',
        fontSize:'13px',
    },
    imageContainer : {
        marginTop:'72px',
        [theme.breakpoints.down(1400)] : {
            marginTop:'52px'
        }
    },
    rightPanelStyle: {
        background:  theme.palette.primary.light,
        height:'100vh',
        display:'flex',
        justifyContent:'center',
        alignItems:'center'
    },
    greetingHeadingStyle: {
        fontSize:'20px',
        fontWeight:'600'
    },
    greetingTextStyle: {
        fontSize:'16px',
        marginTop:'8px',
        color:'#333'
    },
    innerbg: {
        border: "1px solid #dbdbdb",
        borderRadius: "10px",
        padding: "16px 8px",
        background: "#ffffff",
        height:'auto',
        maxHeight:'88vh',
        overflow:'auto'
        // [theme.breakpoints.down("md")]: {
        //     padding: "45px 20px",
        //     }
    },
    onboardHeading: {
        padding:'0px 16px 0px 16px',
        fontSize : "19px",
        fontWeight: "600",
        margin:'0px auto 0px auto',
    },
    tab: {
        minWidth:'15px',
        height: '8px !important',
        marginLeft:'4px',
        background:'#cbcbcb',
        borderRadius:"10px",
        cursor:'pointer',
        '&:first-child': {
            marginLeft:'0px',
        },
    },
    tabSelected: {
        width:'50px',
        backgroundColor: '#555555 !important',
    },
    imageStyle: {
        marginLeft:'16px',
        [theme.breakpoints.down(1400)] : {
            width:'75%'
        }
    },
    buttonStyles: {
        padding:"0px 16px"
    }
}))

const OnboardingComponent = (props) => {
    const classes = useStyles();
    const isMount = useIsMount();

    const [tabValue, setTabValue] = useState(0);
    const [callApi, setCallApi] = useState(false);
    const [loading, setLoading] = useState(true);

    const { access_level } = props.selectedAccount || {};
    const accessLevelOwner = shouldDisable(AccessLevel.getUserAccessLevelValue(access_level), AccessLevel.OWNER);
    const entityObj = props.selectedAccount?.entities && props.selectedAccount?.entities.length > 0 ? props.selectedAccount?.entities[0] : [];

    useEffect(() => {
        props.getInvoiceAccountsList(props.selectedAccount);
        if(props.user.first_login) {
            updateUserLogin();
        }
    }, []);

    useEffect(() => {
        if(!isMount) {
            if(!accessLevelOwner) {
                setLoading(false);
            } else {
                props.getInvoiceAccountsList(props.selectedAccount);
                history.push('/onboarding');
            }
        }
    }, [props.selectedAccount]);

    const showHeadingTitle = () => {
        switch (tabValue) {
            case 0:
                return 'Update Invoice Account'

            case 1:
                return 'Tax Presets'

            case 2:
                return 'Add Clients'

            default:
                break;
        }
    }

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    }

    const onNextClick = () => {
        setCallApi(true);
        // if(tabValue < 1) {
        //     if(tabValue === 0) {
        //         setCallApi(true);
        //     } else {
        //         setTabValue(tabValue => tabValue + 1);
        //     }
        // } else {
        //     props.setSelectedDrawerState(SelectedDrawerState.SHOW_ONBOARDING);
        //     history.push('/dashboard');
        // }
    }

    const onSkipClick = () => {
        props.getInvoiceAccountsList(props.selectedAccount);
        props.setSelectedDrawerState(SelectedDrawerState.SHOW_ONBOARDING);
        history.push('/onboarding');
    }

    const updateUserLogin = () => {

        updateUserLoginApi()
            .then((response) => {
                const res = response.data;
                consoleToLog('Response updateUserLoginApi: ', res);

                props.setUserObj(res);
            })
            .catch((e) => {
                consoleToLog('Error updateUserLoginApi: ', e.reponse);
                if(e.response.data && e.response.data.message) {
                    return;
                }
            })
    }

    return (
        <div className={classes.mainContainer}>
            {loading ?
            <Grid item container justifyContent='center' alignItems='center' style={{marginTop:'18px'}}>
                <CircularProgress size={28}/>
            </Grid>
            :
            <Grid item container>
                <Grid item md={4} className={classes.onBoardingDesignStyle}>
                        <Grid item container direction='column'>
                            <Grid item md={12}>
                                <img src='/images/eProcessify_logo_name_white.png' className={classes.logoStyle}/>
                            </Grid>

                            <Grid item md={12} className={classes.headingContainer}>
                                <Typography className={classes.headingTextStyle}>
                                    Create professional-looking invoices with greater ease than ever before.
                                </Typography>

                                <Typography className={classes.paragraphTextStyle}>
                                    Generate invoices quickly, Track invoices, Automate payment reminders, Stay updated and know when your bill is viewed and downloaded
                                </Typography>
                            </Grid>

                            <Grid item md={12} className={classes.imageContainer}>
                                <img src='/images/on_borboarding_image.svg' className={classes.imageStyle}/>
                            </Grid>
                        </Grid>
                </Grid>

                <Grid item md={8} className={classes.rightPanelStyle}>
                    <Grid item md={tabValue === 1 ? 9 : 7}>
                        <Grid item md={12}>
                            <Typography className={classes.greetingHeadingStyle}>
                                Greetings {props.user.firstname}
                            </Typography>

                            {/* <Typography className={classes.greetingTextStyle}>
                                We appreciate your decision to be part of <span style={{fontWeight:500, color:'#555'}}>eProcessify.</span> To initiate you journey, we suggest you complete these steps.
                            </Typography> */}
                        </Grid>

                        <Grid item md={12} style={{marginTop:'8px'}}>
                        
                            <Grid item md={12} className={classes.innerbg}>
                                {/* {tabValue !== 1 &&
                                <Typography className={classes.onboardHeading}>
                                    {showHeadingTitle()}
                                </Typography>} */}

                                    {tabValue === 0 && <UpdateInvoiceAccount fromOnboardingComponent={true}
                                                            callApi={callApi}
                                                            setCallApi={setCallApi}
                                                            onSkipClick={onSkipClick}
                                                            />}


                                <Grid item container direction='column' className={classes.buttonStyles}>
                                    <Grid item md={12} style={{margin: '24px 0px 16px 0px'}}>  
                                        <Button variant="contained" color="primary" 
                                            className="modal__button"
                                            fullWidth
                                            onClick={onNextClick}
                                            >
                                            {tabValue === 0 ? 'Save' : 'Next'}
                                        </Button>
                                    </Grid>

                                    <Grid item  md={12}>    
                                        <Button 
                                            className="modal__button"
                                            fullWidth
                                            style={{background:'#fff'}}
                                            onClick={onSkipClick}
                                            >
                                            Skip
                                        </Button>
                                    </Grid>
                            
                                </Grid>
                            </Grid>

                            {/* <Grid item container style={{marginTop:"8px"}}>
                                <div tabIndex={0} 
                                    onClick={(e) => {
                                        setTabValue(0);
                                    }}
                                    className={tabValue === 0 ? `${classes.tab} ${classes.tabSelected}` :  classes.tab}/>
                                <div tabIndex={1} 
                                    onClick={(e) => {
                                        setTabValue(1);
                                    }}
                                    className={tabValue === 1 ? `${classes.tab} ${classes.tabSelected}` :  classes.tab}/>
                                {/* <div tabIndex={2} 
                                    onClick={(e) => {
                                        setTabValue(2);
                                    }}
                                    className={tabValue === 2 ? `${classes.tab} ${classes.tabSelected}` :  classes.tab}/>}
                            </Grid> */}

                        </Grid>
                    </Grid>
                </Grid>
            </Grid>}
        </div>
    );
}

const mapStateToProps = (state) => ({
    invoiceAccountsList: state.invoiceAccounts.invoiceAccountsList,
    selectedAccount: state.invoiceAccounts.selectedAccount,
    drawerState: state.drawerState.setDrawerState,
    user: state.invoiceAccounts.user
});

const mapDispatchToProps = (dispatch) => ({
    getInvoiceAccountsList: (selectedAccount) => dispatch(getInvoiceAccountsList(selectedAccount)),
    setSelectedDrawerState: (drawerState) => dispatch(setSelectedDrawerState(drawerState)),
    setUserObj: (user_obj) => dispatch(setUserObj(user_obj))
});


export default connect(mapStateToProps, mapDispatchToProps)(OnboardingComponent);