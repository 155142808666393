import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Grid, Typography, Button, Container, Paper} from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import {InvoicePlans, isInvoiceAccountCountryIndia, isInvoiceAccountOnFreePlan} from '../util/AppUtil';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import {createCheckoutSessionApi, createBillingPortalApi} from '../services/authService';
import {connect} from 'react-redux';
import { consoleToLog } from '../util/AppUtil';
import { useSnackbar } from 'notistack';
import { useIsMount } from './useIsMount';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles((theme) => ({
    settingsContainer: {
        width: "100%",
        background: "#fff",
        borderRadius: "4px",
        padding: "16px",
        marginBottom: "32px",
    },
    unSelectedBtn: {
        background: '#fff',
        width:'150px',
        fontSize:'16px',
        borderRadius:'100px',
        color:"#000",
        border:'2px solid #D3D3DA',
        boxShadow:'none',
        height:"50px",
        '&:hover': {
            background: '#fff'
        }
    },
    selectedBtn : {
        background: '#181818',
        width:'150px',
        fontSize:'16px',
        borderRadius:'100px',
        color:"#fff",
        boxShadow:'none',
        height:"50px",
        '&:hover': {
            background: '#181818'
        }
    },
    contentContainer: {
        display:'flex',
        alignItems:'flex-end',
        gap:'16px'
    },
    cardStyle: {
        //boxShadow: 'rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px',
        height:'470px'
    },
    cardHeaderStyle: {
        padding:'8px 16px'
    },
    freePlanListContainer: {
        marginTop:'32px',
        listStyle:'none',
        padding:0
    },
    listItemStyle: {
        fontSize:'19px',
        margin:'6px 0px',
    },
    checkIconStyle: {
        fontSize:'19px',
        color: '#004aad',
        marginRIght:'4px',
        position:'relative',
        top:'4px'
    },
    cancelIconStyle: {
        fontSize:'21px',
        color: '#ff4962',
        marginRIght:'4px',
        position:'relative',
        top:'4px'
    },
    media: {
        width:'220px',
        position:'absolute',
        right:'-16px',
        bottom:0
    },
    priceText: {
        fontSize:'32px',
        color: '#004aae'
    },
    planText: {
        fontSize:'15px',
        fontWeight:500
    },
    headingStyle: {
        fontSize:'20px',
        color: '#007bfb',
        fontWeight: 600
    },
    currencySymbol: {
        fontSize:'14px',
        marginTop:'16px',
        position:'relative',
        top:'-10px',
        fontWeight:600,
        marginRight:'4px'
    },
    amountText: {
        fontSize:'25px',
        color:'#212121',
    },
    incrementDecrementStyle: {
        minWidth: "30px",
        height:'35px',
        border:'1px solid #888',
        borderRadius:'190px',
        color:"#333"
    },
    quantityText: {
        border:'1px solid #ccc',
        lineHeight:'32px',
        width:'60px',
        height:'35px',
        textAlign:'center',
        fontSize:'16px',
        borderRadius:'5px'
    },
    purchaseButton: {
        background: '#004aad',
        color:"#fff",
        height:'30px',
        borderRadius:'100px',
        '&:hover': {
            background: '#17498d'
        }
    },
    manageBillingBtn: {
        background: '#004aad',
        width:'140px',
        color:"#fff",
        height:'40px',
        position:'relative',
        right:'30px',
        borderRadius:'100px',
        '&:hover': {
            background: '#17498d'
        }
    }
}));

const BillingComponent = (props) => {
    const classes = useStyles();
    const isMount = useIsMount();
    const {enqueueSnackbar} = useSnackbar();

    const plan = props.selectedAccount?.plan?.toLowerCase();
    const purchaseEntityCount = props.selectedAccount?.entity_count;
    const accountCountry = props.selectedAccount?.country;

    const [selectedPlan, setSelectedPlan] = useState(!isInvoiceAccountOnFreePlan(plan) ? plan : InvoicePlans.YEARLY);
    const [rate, setRate] = useState(0);
    const [pricing, setPricing] = useState(0);
    const [pricingText, setPricingText] = useState('');
    const [quantity, setQuantity] = useState(1);

    const onPlanClick = (val) => {
        setSelectedPlan(val);
    }

    useEffect(() => {
        setQuantity(1);
        if(selectedPlan === InvoicePlans.YEARLY) {
            setRate(isInvoiceAccountCountryIndia(accountCountry) ? 6000 : 6);
            let price = isInvoiceAccountCountryIndia(accountCountry) ? 6000/12 : 6;
            setPricing(price.toFixed(2));
            setPricingText('month billed annually');
        } else {
            setRate(isInvoiceAccountCountryIndia(accountCountry) ? 600 : 7.5);
            setPricing(isInvoiceAccountCountryIndia(accountCountry) ? 600 : 7.5);
            setPricingText('month');
        }
    }, [selectedPlan]);

    useEffect(() => {
        if(!isInvoiceAccountOnFreePlan(plan)) {
            let entityCount = purchaseEntityCount - 1;
            if(plan === InvoicePlans.YEARLY) {
                if(purchaseEntityCount > 1) {
                    let basePrice = isInvoiceAccountCountryIndia(accountCountry) ? 5000 : 5;
                    let incrementPrice = isInvoiceAccountCountryIndia(accountCountry) ? 1000 : 1;

                    
                    let total = basePrice + (entityCount * incrementPrice);
                    let finalPrice = isInvoiceAccountCountryIndia(accountCountry) ? total/12 : total;
                    setPricing(finalPrice.toFixed(2));
                }
            } else {
                if(purchaseEntityCount > 1) {
                    let basePrice = isInvoiceAccountCountryIndia(accountCountry) ? 500 : 6.5;
                    let incrementPrice = isInvoiceAccountCountryIndia(accountCountry) ? 100 : 0.5;
                    
                    let total = basePrice + (entityCount * incrementPrice);
                    setPricing(total.toFixed(2));
                }
            }
        } else {
            setSelectedPlan(InvoicePlans.YEARLY);
            setRate(isInvoiceAccountCountryIndia(accountCountry) ? 6000 : 6);
            let price = isInvoiceAccountCountryIndia(accountCountry) ? 6000/12 : 6;
            setPricing(price.toFixed(2));
            setPricingText('month billed annually');
        }

    }, [plan, props.selectedAccount?.id]);
    
    const increment = (e) => {
        let count = quantity+1;
        calculateAmount(count, true);
        setQuantity(count);
    }
    
    const decrement = (e) => {
        if(quantity!==1){
            let count = quantity-1;
            calculateAmount(count, false);
            setQuantity(count);
        }
    }

    const calculateAmount = (count, increment) => {
        let total;
        if(increment) {
            if(selectedPlan === InvoicePlans.MONTHLY) {
                total = isInvoiceAccountCountryIndia(accountCountry) ? rate + 100 : rate + 1.5;
                setRate(total);
                setPricing(total);
            } else {
                total = isInvoiceAccountCountryIndia(accountCountry) ? rate + 1000 : rate + 1;
                setRate(total);
                let finalPrice = isInvoiceAccountCountryIndia(accountCountry) ? total/12 : total;
                setPricing((finalPrice).toFixed(2));
            }
        } else {
            if(selectedPlan === InvoicePlans.MONTHLY) {
                total = isInvoiceAccountCountryIndia(accountCountry) ? rate - 100 : rate - 1.5;
                setRate(total);
                setPricing(total);
            } else {
                total = isInvoiceAccountCountryIndia(accountCountry) ? rate - 1000 : rate - 1;
                setRate(total);
                setPricing((total/12).toFixed(2));
            }
        }
    }

    const onPurchaseClick = () => {
        const invoice_account_id = props.selectedAccount?.id;
        const lookup_key = selectedPlan;
        const currency = isInvoiceAccountCountryIndia(accountCountry) ? 'inr' : 'usd';
        
        createCheckoutSessionApi(invoice_account_id, lookup_key, quantity, currency)
        .then((response) => {
            const res = response.data;
            consoleToLog("Response createCheckoutSessionApi: ", res);
            window.location.href = res.url
        })
        .catch((e) => {
            if (e.response.data && e.response.data.message) {
                enqueueSnackbar(e.response.data.message, {
                    variant:'error'
                });
                return false;
            }
        });
    }

    const onManageBillingClick = () => {
        const invoice_account_id = props.selectedAccount?.id;
        const customer_id = props.selectedAccount?.customer_id;

        createBillingPortalApi(invoice_account_id, customer_id)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response createBillingPortalApi: ", res);
                window.location.href = res.url
            })
            .catch((e) => {
                if (e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {
                        variant:'error'
                    });
                    return false;
                }
            });
    }

    return (
        <Grid item xs={12} className={classes.settingsContainer}>
            <Container maxWidth='xl' disableGutters>
                {!props.selectedAccount?.customer_id &&
                    <Grid item container 
                        justifyContent='center'
                        alignItems='center' 
                        spacing={1}
                        style={{marginTop:'16px'}}>
                        <Grid item>
                            <Button variant='contained' onClick={() => onPlanClick(process.env.REACT_APP_YEARLY_PLAN)}
                                className={selectedPlan === InvoicePlans.YEARLY ? classes.selectedBtn : classes.unSelectedBtn}
                                size='large'>
                                Yearly
                            </Button>
                        </Grid>

                        <Grid item>
                            <Button variant='contained' onClick={() => onPlanClick(process.env.REACT_APP_MONTHLY_PLAN)}
                                className={selectedPlan === InvoicePlans.MONTHLY ? classes.selectedBtn : classes.unSelectedBtn}
                                size='large'>
                                Monthly
                            </Button>
                        </Grid>
                    </Grid>
                }

                <Grid item container 
                    justifyContent='center'
                    alignItems='center' 
                    spacing={6}
                    style={{marginTop:'16px'}}>
                    
                    <Grid item>
                        <Card variant='outlined' elevation={0} className={classes.cardStyle}>
                            <div className={classes.cardHeaderStyle}>
                                <Grid item container alignItems='center'>
                                    <Grid item md={9}>
                                        <Typography variant='body1' style={{marginBottom:'-4px', fontWeight:500}}>
                                            Premium
                                        </Typography>
                                        <Typography variant='h5'>
                                            <span className={classes.priceText}>
                                                {isInvoiceAccountCountryIndia(accountCountry) ? '₹' : '$'}
                                                {pricing}
                                            </span>
                                            <span className={classes.planText}>/{`${pricingText}`}</span>
                                        </Typography>
                                    </Grid>
                                    <Grid item md={3}>
                                        {props.selectedAccount?.customer_id &&
                                        <Button 
                                            onClick={onManageBillingClick}
                                            className={classes.manageBillingBtn}>
                                            Manage Billing
                                        </Button>
                                        }

                                        {/* <Button 
                                            onClick={onPurchaseClick}
                                            className={classes.purchaseButton}>
                                            Purchase
                                        </Button> */}
                                    </Grid>
                                </Grid>
                                
                            </div>
                            <CardContent className={classes.contentContainer}>
                                <div>
                                    <ul className={classes.freePlanListContainer}>
                                        <li className={classes.listItemStyle}> 
                                            <Typography variant='body1'>
                                                <CheckCircleIcon className={classes.checkIconStyle}/> Unlimited Invoices
                                            </Typography>
                                        </li>
                                        <li className={classes.listItemStyle}> 
                                            <Typography variant='body1'>
                                                <CheckCircleIcon className={classes.checkIconStyle}/> Unlimited customers
                                            </Typography>
                                        </li>
                                        <li className={classes.listItemStyle}> 
                                            <Typography variant='body1'>
                                                <CheckCircleIcon className={classes.checkIconStyle}/> Unlimited users
                                            </Typography>
                                        </li>
                                        <li className={classes.listItemStyle}> 
                                            <Typography variant='body1'>
                                                <CheckCircleIcon className={classes.checkIconStyle}/> Send Invoices
                                            </Typography>
                                        </li>
                                        <li className={classes.listItemStyle}>
                                            <Typography variant='body1'>
                                                <CheckCircleIcon className={classes.checkIconStyle}/> Payment Tracking
                                            </Typography>
                                        </li>
                                        <li className={classes.listItemStyle}>
                                            <Typography variant='body1'>
                                                <CheckCircleIcon className={classes.checkIconStyle}/> Payment Reminders
                                            </Typography>
                                        </li>
                                        <li className={classes.listItemStyle}>
                                            <Typography variant='body1'>
                                                <CheckCircleIcon className={classes.checkIconStyle}/> One Entity only
                                            </Typography>
                                        </li>
                                        <li className={classes.listItemStyle}>
                                            <Typography variant='body1'>
                                                <CheckCircleIcon className={classes.checkIconStyle}/> Recurring Invoices
                                            </Typography>
                                        </li>
                                        <li className={classes.listItemStyle}>
                                            <Typography variant='body1'>
                                                <CheckCircleIcon className={classes.checkIconStyle}/> eSignature
                                            </Typography>
                                        </li>
                                        <li className={classes.listItemStyle}>
                                            <Typography variant='body1'>
                                                <CheckCircleIcon className={classes.checkIconStyle}/> Multi Currency
                                            </Typography>
                                        </li>

                                    </ul>
                                </div>
                                <div style={{position:'relative', width:'210px'}}>
                                    <img 
                                        className={classes.media}
                                        style={{width:'240px'}}
                                        src={'/images/premium_image.svg'} />

                                </div>
                            </CardContent>
                        </Card>
                    </Grid>

                    {!props.selectedAccount?.customer_id &&
                    <Grid item>
                        <Paper variant='outlined' style={{padding:"24px"}}>
                            <Grid item md={12}>
                                <Grid item>
                                    <Typography className={classes.headingStyle}>
                                        Premium Plan
                                    </Typography>
                                </Grid>

                                <Grid item style={{marginTop:'8px'}}>
                                    <Typography variant='body1' style={{marginBottom:'4px'}}>
                                        How many business entities
                                    </Typography>
                                    <Grid item container spacing={2} 
                                        alignItems='center'
                                        style={{marginTop:'4px'}}
                                        >
                                            
                                        <Grid item>
                                            <Button 
                                                onClick={decrement}
                                                className={classes.incrementDecrementStyle}>
                                                <RemoveIcon fontSize='small'/>
                                            </Button>
                                        </Grid>
                                        
                                        <Grid item>
                                            <Typography className={classes.quantityText}>
                                                {quantity}
                                            </Typography>
                                        </Grid>

                                        <Grid item>
                                            <Button 
                                                disabled={quantity > 9}
                                                onClick={increment}
                                                className={classes.incrementDecrementStyle}>
                                                <AddIcon fontSize='small'/>
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item md={12}>
                                    <Button fullWidth
                                        style={{marginTop:'24px'}}
                                        onClick={onPurchaseClick}
                                        className={classes.purchaseButton}>
                                        Purchase
                                    </Button>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>}
                </Grid>
            
            </Container>
        </Grid>
    );
}

const mapStateToProps = (state) => ({
    selectedAccount: state.invoiceAccounts.selectedAccount,
    user: state.invoiceAccounts.user
});


export default connect(mapStateToProps)(BillingComponent);