import React from 'react';
import {Grid, Typography, Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Tooltip, Paper} from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { displayInvoiceTypeLabel, InvoiceStatus, statusColor } from '../util/AppUtil';
import { formatAmount } from '../util/currencyUtil';
import moment from 'moment';

const EstimateInvoicesComponent = ({invoiceObj}) => {
    const invoiceList = invoiceObj.invoices;

    return (
        <Grid item sm={9} style={{background:'#fff'}}>
                <Paper variant='outlined'>
                    <TableContainer style={{overflow:'visible', height:'100%'}}>
                        <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell className='tableheading_style'>#</TableCell>
                                <TableCell className='tableheading_style'>Status</TableCell>
                                <TableCell className='tableheading_style'>Issue Date</TableCell>
                                <TableCell className='tableheading_style'>Amount</TableCell>
                                <TableCell className='tableheading_style'>Due Amount</TableCell>
                            </TableRow>
                        </TableHead>
                        
                        <TableBody>
                            {invoiceList && invoiceList.length > 0 && invoiceList.map((invoice) => {
                                const over_due = (moment(invoice?.due_date).toDate().getTime() < Date.now());
                                const recurr_invoice_status = invoice.recurring_invoice?.status === 'active';
                                const currencyObj = invoice?.currencies;

                                return (
                                    <TableRow hover  key={invoice.id}>
                                        <TableCell component="th" scope="row" className={`tablebody_style`}>
                                            {<span className={'invoice_no_style'}>
                                                {displayInvoiceTypeLabel(invoice.invoice_type)}
                                            </span>} 
                                            <span style={{fontSize:'13px', position:'relative', top:'1px'}}>
                                                {invoice.invoice_number}
                                            </span>
                                        </TableCell>

                                        <TableCell className={`tablebody_style1`}>
                                            <Grid item container alignItems='center'>
                                                <div
                                                    style={{width: '4px', 
                                                        height: '20px', 
                                                        marginRight: '4px',
                                                        borderTopLeftRadius: '2px',
                                                        borderBottomLeftRadius: '2px',
                                                        background: statusColor(invoice.status.toLowerCase())
                                                        }}>
                                                </div>
                                                <Typography style={{
                                                    color:statusColor(invoice.status.toLowerCase()), 
                                                    fontSize: "12px"
                                                }}>
                                                    {invoice.status.charAt(0).toUpperCase() + invoice.status.slice(1).toLowerCase()}
                                                    {recurr_invoice_status &&
                                                        <Typography style={{
                                                            color:'#007bfb', 
                                                            fontSize: "8px"
                                                        }}>
                                                            Recurring
                                                        </Typography>
                                                    } 
                                                </Typography>
                                            </Grid>
                                        </TableCell>

                                        <TableCell className='tablebody_style'>
                                            <Tooltip title={moment(invoice.issue_date, 'YYYY-MM-DD').format('MMM Do, YY')} arrow>
                                                <Typography variant='body1' className='issue_date_text_ellipsis'>
                                                    {/* {showListItemDate(invoice.issue_date)} */}
                                                    {moment(invoice.issue_date, 'YYYY-MM-DD').format('MMM Do, YY')}
                                                </Typography>
                                            </Tooltip>
                                        </TableCell>

                                        <TableCell className='tablebody_style'>
                                            {formatAmount(invoice.total, currencyObj)}
                                        </TableCell>

                                        <TableCell className='tablebody_style'>
                                            <Typography variant='subtitle1'
                                                style={{color: (parseInt(invoice.due) > 0 && over_due && 
                                                invoice?.status !== InvoiceStatus.DRAFT &&
                                                invoice.status !==  InvoiceStatus.PAID) && 'red'}}>
                                                {formatAmount(invoice.due, currencyObj)}
                                            </Typography>
                                        </TableCell>
                                        
                                    </TableRow>
                                    
                                );
                            })

                            }
                            
                        </TableBody>
                    </Table>
                    </TableContainer>
                </Paper>
        
        </Grid>
    );
}

export default EstimateInvoicesComponent;