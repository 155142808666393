import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Grid, CircularProgress, Typography} from '@material-ui/core';
import BaseComponent from '../components/Base/BaseComponent';
import { verifyEmailApi } from '../services/authService';
import {connect} from 'react-redux';
import { logout } from '../actions/invoiceAuth';
import { getQueryParams } from '../util/urlUtil';
import { useSnackbar } from 'notistack';
import { consoleToLog } from '../util/AppUtil';
import EmailRoundedIcon from '@material-ui/icons/EmailRounded';
import { history } from '../routers/AppRouter';

const useStyles = makeStyles((theme) => ({
    mainContent : {
        width: '100%', 
        backgroundColor: theme.palette.primary.light, 
        transition: ".5s", 
        flexGrow: 1,
        //overflow:'hidden',
        height:'calc(100vh - 48px)',
        overflow:'auto',
        overflowX:'hidden'
    },
    verifyEmailContainer : {
        background:'#FFFFFF',
        borderRadius:'4px',
        padding:'8px',
        height:'250px',
        position:'relative'
    },
    emailIcon : {
        fontSize: '60px'
    },
    loginStyle : {
        color:"#20a8e0",
        fontWeight:'600',
        marginLeft:'4px',
        cursor:'pointer'
    },
    itemsContainer : {
        position:'relative', 
        top:'100%', 
        bottom:'100%',
        transform:'translateY(-200%)'
    },
    loading: {
        position:'relative',
        left:'50%',
        right:'50%',
    },

    }
));    


const VerifyEmailPage = (props) => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        //get url parameter
        let queryParams = getQueryParams(document.location.search);

        //api call
            setLoading(true);
        verifyEmailApi(queryParams)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response verifyEmailApi: ", res);
                enqueueSnackbar(res.message, {
                    variant:'success'
                })
                    setLoading(false);
                    history.push('/settings');
            })
            .catch((e) => {
                consoleToLog("verifyEmailApi error", e.response);
                    setLoading(false);
                if (e.response.data && e.response.data.msg) {
                    enqueueSnackbar(e.response.data.msg, {
                        variant:'error'
                    })
                    return false;
                }
            });
    }, []);

    const onLoginClick = () => {
        window.open(`${process.env.REACT_APP_AUTH_APP_DOMAIN_NAME}/login?service=invoices`, '_self');
    }

    return (
        <BaseComponent verifyEmailPage={true}>
            <div className={classes.mainContent}>
                <Grid item container justifyContent='center'
                    style={{marginTop:'32px'}}
                    alignItems='center'>
                    <Grid item sm={7} className={classes.verifyEmailContainer}>

                    {loading ? <CircularProgress className={classes.loading}/>
                    :   
                        <Grid item container direction="column" 
                            alignItems='center'
                            className={classes.itemsContainer}>
                                <EmailRoundedIcon className={classes.emailIcon}/>
                                <Typography style={{fontSize:'1.2rem'}}>
                                    You email id is verified! Continue to 
                                    <span className={classes.loginStyle}
                                        onClick={onLoginClick}
                                    >
                                        Login
                                    </span>
                                </Typography>
                        </Grid>
                    }

                    </Grid>

                </Grid>
            </div>
        </BaseComponent>
    );
}

const mapStateToProps = (state) => ({
    accessToken: state.invoiceAuth
});

const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(logout())
});

export default connect(mapStateToProps, mapDispatchToProps)(VerifyEmailPage);