import React, {useState} from 'react';
import {connect} from 'react-redux';
import {Grid, Typography, TextField, Button, CircularProgress, Paper} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {useSnackbar} from 'notistack';
import { passwordValidator } from '../util/validator';
import {consoleToLog, trackGAEvent} from '../util/AppUtil';
import {changePasswordApi} from '../services/authService';

const useStyles = makeStyles((theme) => ({
    tabPanelContainer: {
        flexGrow: 1,
        padding:'15px',
        background: 'white',
        //height:'100vh',
        marginBottom:'25px',
        height:'70vh'
    },      
    circularProgress: {
        marginLeft: 0,
        marginRight: theme.spacing(2),
        color: 'white'
      }, 

}))

const ChangePassword = (props) => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();

    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [oldPassInvalid, setOldPassInvalid] = useState(false);
    const [oldPassErrMessage, setOldPassErrMessage] = useState('')
    const [newPassInvalid, setNewPassInvalid] = useState(false);
    const [newPassErrMessage, setNewPassErrMessage] = useState('');
    const [confirmPassIvalid, setConfirmPassInvalid] = useState(false)  
    const [loading, setLoading] = useState(false);

    const onUpdatePasswordClick = (e) => {
        e.preventDefault();

        const old_password = oldPassword
        const new_password = newPassword;

        if (!old_password) {//check empty
            setOldPassInvalid(true);
            setOldPassErrMessage('* Old password can not be empty!');
            return;
        } else {
            setOldPassInvalid(false);
        }

        const isOldPasswordValid = passwordValidator(old_password);
        if (!isOldPasswordValid) {
            setOldPassInvalid(true);
            setOldPassErrMessage('* Password length should be 5 or more!')
            return;
        } else {
            setOldPassInvalid(false);
        }

        if (!new_password) {//check empty
            setNewPassInvalid(true);
            setNewPassErrMessage('* New password can not be empty!');
            return;
        }else {
            setNewPassInvalid(false);
        }

        const isNewPasswordValid = passwordValidator(new_password);
        if (!isNewPasswordValid) {
            setNewPassInvalid(true);
            setNewPassErrMessage('* Password length should be 5 or more!');
            return;
        }else {
            setNewPassInvalid(false);
        }

        if(newPassword !== confirmPassword) {
            setConfirmPassInvalid(true);
            return;
        }else {
            setConfirmPassInvalid(false);
        }


        setLoading(true);
        //call change password api
        changePasswordApi(old_password, new_password)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response changePasswordApi: ", res);
                enqueueSnackbar(res.message, {
                    variant:'success'
                });
                setLoading(false);
                //trackGAEvent(props.selectedOrganization.organization.name, 'Change Password', `${props.auth.user.firstname} ${props.auth.user.lastname} changed password`);
            })
            .catch((e) => {
                consoleToLog("changePasswordApi error", e);
                setLoading(false);
                if (e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {
                        variant:'error'
                    });
                    return false;
                }
            });
    } 

    return (
        <Grid item container>
            <Paper className={classes.tabPanelContainer} elevation={0}>
                <Grid item container direction="column" spacing={2}  style={{marginTop:'16px'}}>
                    <Grid item md={5} lg={5}>
                        <Typography variant="body2">
                            Old Password
                        </Typography>

                        <TextField 
                            fullWidth
                            variant="outlined"
                            margin="dense"
                            type="password"
                            placeholder="Enter Old Password"
                            InputLabelProps={{style: {fontSize: 13, marginTop:'3px'}}}
                            onChange={(e) => setOldPassword(e.target.value)}
                            error={oldPassInvalid}
                            helperText={oldPassInvalid ? oldPassErrMessage : ''}
                        />
                    </Grid> 

                    <Grid item md={5} lg={5}>
                        <Typography variant="body2">
                            New Password
                        </Typography>

                        <TextField 
                            fullWidth
                            variant="outlined"
                            margin="dense"
                            type="password"
                            placeholder="Enter New Password"
                            InputLabelProps={{style: {fontSize: 13, marginTop:'3px'}}}
                            onChange={(e) => setNewPassword(e.target.value)}  
                            error={newPassInvalid}
                            helperText={newPassInvalid ? newPassErrMessage : ''}
                        />
                    </Grid>  

                    <Grid item md={5} lg={5}>
                        <Typography variant="body2">
                            Confirm New Password
                        </Typography>

                        <TextField 
                            fullWidth
                            variant="outlined"
                            margin="dense"
                            type="password"
                            placeholder="Re-enter New Password"
                            InputLabelProps={{style: {fontSize: 13, marginTop:'3px'}}}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            error={confirmPassIvalid}
                            helperText={confirmPassIvalid ? '* Passwords are not matching!' : ''}
                        />
                    </Grid>  

                    <Grid item md={5} lg={5} style={{marginTop:'8px'}}>
                        <Grid item container>
                            <Button variant="contained" 
                                color="primary" 
                                //style={{minWidth:'107px'}}
                                onClick={onUpdatePasswordClick}
                                >
                                {loading && <CircularProgress size={24} className={classes.circularProgress}/>}
                                Change Password
                            </Button>    

                            {/* <Button variant="outlined" color="primary" style={{marginLeft:'8px', minWidth:'107px'}}>
                                Reset
                            </Button> */}
                        </Grid>   
                    </Grid>
                </Grid>        
            </Paper>        
        </Grid>
    );
}

const mapStateToProps = (state) => ({
    selectedAccount: state.invoiceAccounts.selectedAccount
});

export default connect(mapStateToProps)(ChangePassword);