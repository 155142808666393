export const OverlayView  = {
    SHOW_OVERLAY_LOADING  : 'overlay_loading',
    SHOW_OVERLAY_DOWNLOAD : 'overlay_download',
    NO_VIEW : 'no_view',
    ERROR_VIEW : 'error_view'
};

export const setOverlayView = (exportView) => ({
    type: 'SET_OVERLAY_VIEW',
    exportView
})

export const setOverlayToken = (randomToken) => ({
    type: 'SET_OVERLAY_TOKEN',
    randomToken
})

export const setOvelayMessage = (overlayMessage) => ({
    type :'SET_OVERLAY_MESSAGE',
    overlayMessage
})

export const setDownloadPath = (path) => ({
    type :'SET_DOWNLOAD_PATH',
    path
})

export const setApiRoute = (route) => ({
    type :'SET_API_ROUTE',
    route
})