import React, {useEffect, useState} from 'react';
import {Grid, Typography, Button, TextField, CircularProgress} from '@material-ui/core';
import TaxRates from './TaxRates';
import {connect} from 'react-redux';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import RightToLeftSideDrawer from './RightToLeftSideDrawer';
import {makeStyles} from '@material-ui/core/styles';
import {updateTcsSettingsApi} from '../services/authService';
import { consoleToLog, trackGAEvent } from '../util/AppUtil';
import { logout } from '../actions/invoiceAuth';
import { clearSelectedInvoiceFilters } from '../actions/invoiceFilters';
import { setSelectedDrawerState } from '../actions/drawer';
import { clearSelectedTransactionFilters } from "../actions/transactionFilters";
import { setInvoiceAccountsList } from '../actions/invoiceAccount';
import {setSelectedInvoiceAccount, setClearInvoiceAccounts} from '../actions/invoiceAccount';
import { useIsMount } from './useIsMount';

import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
    customMarginTop : {
        marginTop:"16px"
    },
    customMarginBottom : {
        marginBottom:"-8px"
    }
}))

const TCSTypesList = (props) => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();
    const isMount = useIsMount();

    const [openDrawer, setOpenDrawer] = useState(false);

    const [tcsTypeName, setTcsTypeName] = useState('');
    const [tcsTypeRate, setTcsTypeRate] = useState(0);
    const [errorNo, setErrorNo] = useState(undefined);
    const [errorMsg, setErrorMsg] = useState('');
    const [loading, setLoading] = useState(false);

    const tcsList = props.selectedAccount?.tcs && props.selectedAccount?.tcs.length > 0 ? props.selectedAccount?.tcs : [];

    useEffect(() => {
        if(!isMount) {
            if(props.openAddTCSTypeDrawer) {
                handleDrawerOpen();
            }
        }
    }, [props.openAddTCSTypeDrawer]);

    const handleDrawerOpen = () => {
        setOpenDrawer(true);
    }

    const handleDrawerClose = () => {
        setOpenDrawer(false);
        props.handleAddTCSTypeDrawerClose();
    }

    const onSaveOrDeleteClick = (tcsObj) => {
        const name = tcsTypeName;
            if(name === '' && !tcsObj) {
                setErrorNo(0);
                setErrorMsg('* Please enter tcs type name');
                return;
            }

        const rate = Number(tcsTypeRate);
            if(rate === 0 && !tcsObj) {
                setErrorNo(1);
                setErrorMsg('* Please enter tcs type rate');
                return;
            }

        const invoice_account_id = props.selectedAccount?.id;
        const newTcsObj = !tcsObj && {name, rate};
        const tcs = tcsObj ? tcsList.filter((tcs) => tcs.name !== tcsObj?.name) : [newTcsObj, ...tcsList];

        setLoading(true);
        updateTcsSettingsApi(invoice_account_id, tcs)
        .then((response) => {
            const res = response.data;
            consoleToLog('Response updateTcsSettingsApi: ', res);
            setLoading(false);
            enqueueSnackbar('TCS settings updated successfully', { variant: 'success' });
            setErrorMsg('');
            setErrorNo(undefined);

            let accountList = props.invoiceAccountsList.map((ia)=>{
                if(ia.id === res.id) return res;
                else return ia;
            });

            props.setInvoiceAccountsList(accountList);
            props.setSelectedInvoiceAccount(res);

            onCancelClick();

            trackGAEvent(props.selectedAccount.name, 'TCS Type created successfully', `${props.user.firstname} ${props.user.lastname}`)

        })
        .catch((e) => {
            consoleToLog('Error addTaxPresetApi: ', e);
            setLoading(false);
            if(e.response.data && e.response.data.message) {
                enqueueSnackbar(e.response.data.message, {variant: 'error'});
                return;
            }
        });
    }

    const onCancelClick = () => {
        setTcsTypeName('');
        setTcsTypeRate(0);
        setErrorNo(undefined);
        setErrorMsg('');
        handleDrawerClose();
    }

    return (
        <RightToLeftSideDrawer openDrawer={openDrawer} 
                closeDrawer={handleDrawerClose}
                title='Add TCS Type'
            >
                <Grid item container style={{padding:'16px'}}>

                    <Grid item md={12} lg={12}>
                        <Typography variant="subtitle1" 
                            className={classes.customMarginBottom}>
                            TCS Type Name
                        </Typography>

                        <TextField fullWidth 
                            id="tax_name" 
                            variant="outlined"
                            type="text"
                            margin="dense"
                            value={tcsTypeName}
                            autoComplete="off"
                            placeholder="Enter tax preset name"
                            InputLabelProps={{style: {fontSize: 13, color: "#ccc"}}}
                            onChange={(e) => {
                                setTcsTypeName(e.target.value)
                                setErrorNo(undefined);
                                setErrorMsg('');
                            }}
                            error={errorNo === 0}
                            helperText={errorNo === 0 ? errorMsg : ''}
                        />
                    </Grid>    


                    <Grid item md={12} lg={12} 
                        className={classes.customMarginTop}>
                        <Typography variant="subtitle1" 
                            className={classes.customMarginBottom}>
                            Rate
                        </Typography>   

                        <TextField fullWidth 
                            id="tax-rate" 
                            variant="outlined"
                            type="number"
                            margin="dense"
                            autoComplete="off"
                            value={tcsTypeRate}
                            //placeholder="Mobile Number"
                            InputLabelProps={{style: {fontSize: 13, color: "#ccc"}}}
                            onChange={(e) => {
                                setTcsTypeRate(e.target.value);
                                setErrorNo(undefined);
                                setErrorMsg('');
                            }}
                            error={errorNo === 1}
                            helperText={errorNo === 1 ? errorMsg : ''}
                        />
                    </Grid>

                    <div className="modal__footer">
                        <Button variant="contained" color="primary" 
                            onClick={() => onSaveOrDeleteClick()}
                            className="modal__button">
                            {loading && <CircularProgress size={15} className='loading__style'/>}
                            Save    
                        </Button>
                        <Button variant="outlined" style={{
                            minWidth: '130px',
                        }} onClick={handleDrawerClose}>
                            Cancel
                        </Button>
                    </div> 
                </Grid>

        </RightToLeftSideDrawer>
    );
}

const mapStateToProps = (state) => ({
    selectedAccount: state.invoiceAccounts.selectedAccount,
    user: state.invoiceAccounts.user,
    invoiceAccountsList: state.invoiceAccounts.invoiceAccountsList
});

const mapDispatchToProps = (dispatch) => ({
    setSelectedInvoiceAccount: (account) => dispatch(setSelectedInvoiceAccount(account)),
    logout: () => dispatch(logout()),
    clearSelectedInvoiceFilters: () => dispatch(clearSelectedInvoiceFilters()),
    setClearInvoiceAccounts: () => dispatch(setClearInvoiceAccounts()),
    setSelectedDrawerState: (drawerState) => dispatch(setSelectedDrawerState(drawerState)),
    clearSelectedTransactionFilters: () => dispatch(clearSelectedTransactionFilters()),
    setInvoiceAccountsList: (accountList) => dispatch(setInvoiceAccountsList(accountList))
});


export default connect(mapStateToProps, mapDispatchToProps)(TCSTypesList);