import React from 'react';
import {Grid, Typography} from '@material-ui/core';
import {connect} from 'react-redux'; 
import {addSelectedStatusToInvoiceFilter, removeSelectedStatusFromInvoiceFilter} from '../../actions/invoiceFilters';
import {trackGAEvent} from '../../util/AppUtil';
import {addSelectedStatusToEstimateFilter, removeSelectedStatusFromEstimateFilter} from '../../actions/invoiceFilters';

const InvoiceStatusFilterComponent = (props) => {
    const invoiceStatusNameList = ["Draft", "Sent", "Viewed", "Downloaded", "Partial", "Paid", "Cancelled"];
    const invoiceStatusValueList = ["draft", "sent", "viewed", "downloaded", "partial", "paid", "cancelled"];

    const estimateStatusNameList = ["Draft", "Sent", "Viewed", "Downloaded", "Approved", "Cancelled", "Rejected", "Closed"];
    const estimateStatusValueList = ["draft", "sent", "viewed", "downloaded", "approved", "cancelled", "rejected", "closed"];

    const invoiceStatusClassNames = ["draft__status", "sent__status", "viewed__status", "downloaded__status", "partial__status", "paid__status", "cancelled_status", "approved__status", "rejected__status"];
    const invoiceStatusClassNames1 = ["draft__background", "sent__background", "viewed__background", "downloaded__background", "partial__background", "paid__background", "cancelled__background", "approved__background", "rejected__background"]

    const estimateStatusClassNames = ["draft__status", "sent__status", "viewed__status", "downloaded__status", "est_approved__status", "est_cancelled__status", "est_rejected__status", "est_closed__status"];
    const estimateStatusClassNames1 = ["draft__background", "sent__background", "viewed__background", "downloaded__background", "est_approved__background", "est_cancelled__background", "est_rejected__background", "est_closed__background"]


    const statusNameList = props.fromEstimatesComponent ? estimateStatusNameList : invoiceStatusNameList;
    const statusValueList = props.fromEstimatesComponent ? estimateStatusValueList : invoiceStatusValueList;
    const statusClassNames = props.fromEstimatesComponent ? estimateStatusClassNames : invoiceStatusClassNames;
    const statusClassNames1 = props.fromEstimatesComponent ? estimateStatusClassNames1 : invoiceStatusClassNames1;
    
    const statusClassName = (index) => {
        const STATUS_CLASS = `status__filterList ${statusClassNames1[index]}`;
        if(props.fromEstimatesComponent) {
            return props.selectedStatusEstimateFilter.includes(statusValueList[index]) ? `${STATUS_CLASS} active` : STATUS_CLASS;
        } else {
            return props.selectedStatusInvoiceFilter.includes(statusValueList[index]) ? `${STATUS_CLASS} active` : STATUS_CLASS;
        }
    }

    const onStatusClick = (selectedValue) => {
        trackGAEvent(props.selectedAccount.name, 'Invoice filter - status filter changed');
        const selectedStatus = props.fromEstimatesComponent ? props.selectedStatusEstimateFilter.filter((status) => status === selectedValue) : props.selectedStatusInvoiceFilter.filter((status) => status === selectedValue);
        if(selectedStatus && selectedStatus.length > 0) {
            props.fromEstimatesComponent ? props.removeSelectedStatusFromEstimateFilter(selectedValue) : props.removeSelectedStatusFromInvoiceFilter(selectedValue);
        } else {
            props.fromEstimatesComponent ? props.addSelectedStatusToEstimateFilter(selectedValue) : props.addSelectedStatusToInvoiceFilter(selectedValue);
        }
    }

    return (
        <div style={{marginTop:'18px'}}>
            <span style={{fontSize:'14px'}}>
                Status            
            </span> 
            <Grid container lg={12} style={{marginTop:'8px'}} >
                {statusNameList.map((status, index) => {
                        return <Grid item
                                    className={statusClassName(index)}
                                    style={{display:'flex', alignItems:'center'}}
                                    key={status}
                                    onClick={() => {
                                        onStatusClick(statusValueList[index])
                                        props.pageReset();
                                    }}
                                >
                            <span className={`circle ${statusClassNames[index]}`}></span> 
                            <Typography  style={{fontSize:'12px'}}>{status}</Typography>
                        </Grid>
                    })
                }
            </Grid> 
        </div>
    );
}

const mapStateToProps = (state) => ({
    accessToken: state.invoiceAuth,
    selectedStatusInvoiceFilter: state.invoiceFilters.selectedStatusInvoiceFilter,
    selectedStatusEstimateFilter: state.invoiceFilters.selectedStatusEstimateFilter,
    selectedAccount: state.invoiceAccounts.selectedAccount
});

const mapDispatchToProps = (dispatch) => ({
    addSelectedStatusToInvoiceFilter: (item) => dispatch(addSelectedStatusToInvoiceFilter(item)),
    removeSelectedStatusFromInvoiceFilter: (item) => dispatch(removeSelectedStatusFromInvoiceFilter(item)),

    addSelectedStatusToEstimateFilter: (item) => dispatch(addSelectedStatusToEstimateFilter(item)),
    removeSelectedStatusFromEstimateFilter: (item) => dispatch(removeSelectedStatusFromEstimateFilter(item))
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceStatusFilterComponent);