import { useEffect, useState } from 'react';

const useRestoreComponentState = (componentState, setComponentState) => {
    const [scrollContainer, setScrollContainer] = useState(null);

    useEffect(() => {
        if (scrollContainer && componentState) {
            scrollContainer.scrollTop = componentState.scrollPosition;
        }
    }, [scrollContainer, componentState?.scrollPosition]);

    const saveState = (stateObj) => {
        if (scrollContainer) {
            setComponentState({
                ...stateObj,
                scrollPosition: scrollContainer.scrollTop,
            });
        }
    };

    const restoreState = () => {
        if (componentState) {
            // Set the state from the saved component state
            return componentState;
        }
        return {};
    };

    return { setScrollContainer, saveState, restoreState };
};

export default useRestoreComponentState;