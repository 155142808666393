import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Grid, Typography, Button, Avatar} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    clientName: {
        fontSize:'16px',
        fontWeight:'500'
    },
    labelHeading: {
        fontWeight:'600 !important',
        fontSize:'14px !important'
    },
    companyInfoStyle: {
        marginBottom:'8px'
    },
    addressStyle: {
        width:'250px'
    },
}))


const ClientInfo = (props) => {
    const classes = useStyles();

    return (
        <div style={{padding:"0px 16px"}}>

            {props.clientInfo?.address &&
            <Grid item className={classes.companyInfoStyle}>
                <Typography className={classes.labelHeading}> 
                    Address
                </Typography>
                <Typography variant='body2' className={classes.addressStyle}>
                    {props.clientInfo?.address}
                </Typography>
            </Grid>}

            {props.clientInfo?.state &&
            <Grid item className={classes.companyInfoStyle}>
                <Typography className={classes.labelHeading}>
                    State
                </Typography>
                <Typography variant='body2'>
                    {props.clientInfo?.state}
                </Typography>
            </Grid>}

            {props.clientInfo?.country && 
            <Grid item className={classes.companyInfoStyle}>
                <Typography className={classes.labelHeading}>
                    Country
                </Typography>
                <Typography variant='body2'>
                    {props.clientInfo?.country}
                </Typography>
            </Grid>}
            
        </div>
    );
}

export default ClientInfo;