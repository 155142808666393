import { Button } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useEffect ,useState} from 'react';
import { connect } from 'react-redux';
import { OverlayView, setOverlayView, setOverlayToken, setOvelayMessage} from '../actions/overlay';
import { checkBackgroundTaskCompleted } from '../services/authService';
import { consoleToLog, trackGAEvent } from '../util/AppUtil';
import { useIsMount } from './useIsMount';
import { useSnackbar } from 'notistack'; 
import { OverlayMessage } from '../util/AppUtil';
import { setDownloadPath } from '../actions/overlay';
import { downloadAttachmentApi } from '../services/authService';
import { setApiRoute } from '../actions/overlay';

const OverlayComponent = (props) => {
    var interval;
    const isMount = useIsMount();
    //const [shouldShowLoading, setShouldShowLoading] = [false];
    //const [shouldShowDownload, setShouldShowDownload] = [false];
    const[errorMessage, setErrorMessage] = useState("");
    const[setUpMessage,setSetupMessage] = useState("");
    const[errorMessageArr, setErrorMessageArr] = useState([]);

    const {enqueueSnackbar} = useSnackbar();

    useEffect(()=>{
        if(!isMount && props.overlay && props.overlay.showOverlayView === OverlayView.SHOW_OVERLAY_LOADING && props.overlay.overlayRandomToken){
            consoleToLog("useEffect 2: ")
            //loadData();
            interval = setInterval(loadData, 15000);
        }

    }, [props.overlay && (props.overlay.showOverlayView === OverlayView.SHOW_OVERLAY_LOADING), props.overlay.overlayRandomToken])

    useEffect(() => {
        if(!isMount){
            console.log("useEffect1")
            if(props.overlay.overlayRandomToken === ""){
                //console.log("errorMessage", errorMessage)
                if (errorMessageArr && errorMessageArr.length === 0){
                    props.setOverlayView(OverlayView.NO_VIEW);
                    // consoleToLog("NO view");
                }else{
                    //props.setOverlayView(OverlayView.ERROR_VIEW);
                     //consoleToLog("error view1");
                }    
            }
        }
        return () => clearInterval(interval);
    }, [props.overlay.overlayRandomToken, errorMessage])
    

    const loadData = ()=>{
        const invoice_account_id = props.selectedAccount.id;
        const apiRoute = props.overlay.apiRoute;
        const randomToken = props.overlay.overlayRandomToken;

        consoleToLog("loadData OverlayComponent interval called");

        if(props.overlay.overlayRandomToken !== ""){
            checkBackgroundTaskCompleted(invoice_account_id, apiRoute, randomToken)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response checkBackgroundTaskComplted: ", res); 

                    if(res.type === "file_uri"){
                        props.setOverlayView(OverlayView.SHOW_OVERLAY_DOWNLOAD);
                        props.setDownloadPath(res.response);
                        clearInterval(interval);
                        setSetupMessage("");
                    } else if(res.type === "setup"){
                        props.setOverlayView(OverlayView.SHOW_OVERLAY_DOWNLOAD);
                        setSetupMessage("Setup completed.");
                        clearInterval(interval);
                    }

                    // if(res.message && res.response){
                    //     props.setOverlayView(OverlayView.SHOW_OVERLAY_DOWNLOAD);
                    //     props.setDownloadPath(res.response);
                    //     clearInterval(interval);
                    //     setSetupMessage("");
                    // } else if(res.type === "setup"){
                    //     props.setOverlayView(OverlayView.SHOW_OVERLAY_DOWNLOAD);
                    //     setSetupMessage("Setup completed.");
                    //     clearInterval(interval);
                    // }
            })
            .catch((e) => {
                consoleToLog("checkBackgroundTaskComplted error", e.response);
                props.setOverlayView(OverlayView.ERROR_VIEW);
                props.setOverlayToken("");
                props.setOvelayMessage("");
                props.setApiRoute("");
                clearInterval(interval);
                setSetupMessage("");
                // if (e.response.data && e.response.data.message) {
                //     // enqueueSnackbar(e.response.data.message, {variant: "error"});
                //     // props.setOverlayView(OverlayView.ERROR_VIEW);
                //     setErrorMessage(e.response.data.message);
                // }

                if (e.response.data.response && e.response.data.response.errors && e.response.data.response.errors.length > 0) {
                    // enqueueSnackbar(e.response.data.message, {variant: "error"});
                    // props.setOverlayView(OverlayView.ERROR_VIEW);
                    setErrorMessageArr(e.response.data.response.errors);
                }
 
            }); 
        } 

    }

    const clearReduxState = () => {
        props.setOverlayView(OverlayView.NO_VIEW);
        props.setOverlayToken("");
        props.setOvelayMessage("");
        props.setApiRoute("");
        clearInterval(interval);
        setSetupMessage("");
    }
    

    const downloadLink = () => {
        const invoice_account_id = props.selectedAccount.id;
        const path = props.overlay.downloadPath;

        downloadAttachmentApi(invoice_account_id, path)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response downloadAttachmentApi: ", res);    
                consoleToLog("Response downloadAttachmentApi dosnload url: : ", res.document_signed_url);    
                
                trackGAEvent(props.selectedAccount.name, 'Download Exported File', `${props.user.firstname} ${props.user.lastname}`);
                
                document.getElementById('initiateDownloadAttachment').href = res.document_signed_url;
                document.getElementById('initiateDownloadAttachment').click();
                
                clearReduxState();

            })
            .catch((e) => {
                consoleToLog("downloadExportedFile error", e);
                if (e.response.data && e.response.data.message) {
                    return false;
                }
            });
    }

    return(
        <div style={{
            position: 'absolute',
            zIndex: 2000,
        }}>
            {props.overlay && (props.overlay.showOverlayView === OverlayView.SHOW_OVERLAY_LOADING && props.overlay.overlayMessage !== "")  
                && <div style={{borderRadius: "8px", color: 'white', background: "#0071ff",  position: "fixed", bottom: "20px", right: "50px", padding: "13px 15px 15px 10px"}}>
                <div>
                    <CircularProgress color="white" style={{width: "25px", height: "25px", marginBottom: "-10px"}}/>
                    <span style={{marginBottom: "50px", marginLeft: "8px"}}>{props.overlay.overlayMessage}</span>
                </div>
                <Button variant="outlined"  
                    style={{color: "white", border: "1px solid white", float: "right", marginTop: "10px"}}
                    onClick={clearReduxState}>
                        Close
                </Button>
            </div>}

            {props.overlay && (props.overlay.showOverlayView === OverlayView.ERROR_VIEW && errorMessageArr && errorMessageArr.length > 0)  
                && <div style={{borderRadius: "8px", color: 'white', background: "red",  position: "fixed", bottom: "20px", right: "50px", padding: "13px 15px 15px 10px"}}>
                <ul style={{margin:'0px', paddingLeft:'22px'}}>
                    {   
                        errorMessageArr && errorMessageArr.length > 0 &&
                        errorMessageArr.map((error) => {
                            return <li key={error} style={{marginBottom: "8px", padding:"0px"}}>{error}</li> 
                        })                    }
                    {/* <span style={{marginBottom: "50px", marginLeft: "8px"}}>{errorMessage}</span> */}
                </ul>
                <Button variant="outlined"  
                    style={{color: "white", border: "1px solid white", float: "right", marginTop: "10px"}}
                    onClick={clearReduxState}>
                        Close
                </Button>
            </div>}

            {props.overlay && (props.overlay.showOverlayView === OverlayView.SHOW_OVERLAY_DOWNLOAD && setUpMessage === "") && <div style={{borderRadius: "8px", color: 'white', background: "#0071ff",  position: "fixed", bottom: "20px", right: "50px", padding: "13px 15px 15px 10px"}}>
                <span style={{marginBottom: "50px", marginLeft: "8px"}}>
                    {props.overlay.overlayMessage === OverlayMessage.DOWNLOAD_MESSAGE ? 'Zip file is ready for download' : 'Spreadsheet is ready for download.'}
                </span>
                <div style={{textAlign: "center", margin: "0 auto", marginTop: "20px"}}>
                    <Button variant="outlined" 
                        style={{color: "white", border: "1px solid white"}}
                        onClick={()=>{
                            downloadLink();
                        }}>
                        Download
                    </Button>
                    <a style={{visibility: "hidden"}}
                        id="initiateDownloadAttachment"
                        target="_blank"
                        onClick={(e) => e.stopPropagation()}
                        download
                    ></a>
                    <Button variant="outlined"  
                        style={{color: "white", border: "1px solid white", marginLeft: "8px"}}
                        onClick={clearReduxState}>
                        Close
                    </Button>
                </div>
            </div>}

            {props.overlay && (props.overlay.showOverlayView === OverlayView.SHOW_OVERLAY_DOWNLOAD && setUpMessage !=="") && 
            <div style={{borderRadius: "8px", color: 'white',background: "#0071ff",  position: "fixed", bottom: "20px", right: "50px", padding: "13px 15px 15px 10px"}}>
                <div>
                    <span style={{marginBottom: "50px", marginLeft: "8px"}}>{setUpMessage}</span>
                </div>
                <Button variant="outlined"  
                    style={{color: "white", border: "1px solid white", float: "right", marginTop: "10px"}}
                    onClick={clearReduxState}>
                        Close
                </Button>
            </div>
        }
        </div>
    )
}



const mapStateToProps = (state) => ({
    accessToken: state.invoiceAuth,
    selectedAccount: state.invoiceAccounts.selectedAccount,
    overlay: state.overlay,
    user: state.invoiceAccounts.user
});

const mapDispatchToProps = (dispatch) => ({
    setOverlayView: (exportView) => dispatch(setOverlayView(exportView)),
    setOverlayToken: (randomToken) => dispatch(setOverlayToken(randomToken)),
    setOvelayMessage: (overlayMessage) => dispatch(setOvelayMessage(overlayMessage)),
    setDownloadPath: (path) => dispatch(setDownloadPath(path)),
    setApiRoute: (route) => dispatch(setApiRoute(route))
})

export default connect(mapStateToProps, mapDispatchToProps)(OverlayComponent)