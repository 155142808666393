import React, {useState, useEffect, memo} from 'react';
import {Paper, Box, Grid, Link, Button, CircularProgress} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { consoleToLog } from '../util/AppUtil';
import { printClientInvoiceReportApi } from '../services/authService';
import printJS from 'print-js';
import { useIsMount } from './useIsMount';
import { useSnackbar } from 'notistack';
import { downloadCsvForRevenueByClientAndItemApi, downloadRevenueByItemsReportApi } from '../services/authService';

const useStyles = makeStyles((theme) => ({
    paper2: {
        padding: theme.spacing(2),
    },
}))

const DownloadPrintExportComponent = memo(({invoice_account_id, selectedEntities, searchClient, searchItem, fromdate, todate, fromRevenueByClientReports}) => {
    const classes = useStyles();
    const isMount = useIsMount();
    const {enqueueSnackbar} = useSnackbar();

    const [dowloadStatementUrl, setDownloadStatementUrl] = useState(undefined);
    const [downloadCsvLoading, setDowloadCsvLoading] = useState(false);
    const [downloadPdfLoading, setDowloadPdfLoading] = useState(false);
    const [printPdfLoading, setPrintPdfLoading] = useState(false);

    useEffect(() => {
        if(!isMount) {
            if(dowloadStatementUrl) {
                printJS({printable: dowloadStatementUrl, type:'pdf', showModal:true});
                setDownloadStatementUrl(undefined);
            }
        }
    }, [dowloadStatementUrl]);

    const onDownloadOrPrintStatementClick = async(fromPrint) => {
        const entity_id = selectedEntities && selectedEntities.length > 0 ? selectedEntities.map((entity) => entity.id).join(',') : undefined;
        const client_name = searchClient ? searchClient : undefined; 
        const item_name = searchItem ? searchItem : undefined; 
        
        fromPrint ? setPrintPdfLoading(true) : setDowloadPdfLoading(true);

        try {
            const response = fromRevenueByClientReports ? await printClientInvoiceReportApi(invoice_account_id, entity_id, client_name, fromdate, todate) : 
            await downloadRevenueByItemsReportApi(invoice_account_id, entity_id, item_name, fromdate, todate);

            const res = response.data;
            consoleToLog(`Response ${fromRevenueByClientReports ? 'printClientInvoiceReportApi' : 'downloadRevenueByItemsReportApi'}`, response);
            fromPrint ? setPrintPdfLoading(false) : setDowloadPdfLoading(false);

            const downloadableURL = res.document_signed_url;
            if(fromPrint) {
                setDownloadStatementUrl(downloadableURL);
            } else {

                // //initiate download
                document.getElementById('statement_download').href = downloadableURL;
                document.getElementById('statement_download').click();
            }

        } catch (e) {
            consoleToLog(`Error ${fromRevenueByClientReports ? 'printClientInvoiceReportApi' : 'downloadRevenueByItemsReportApi'}`, e.response);
            fromPrint ? setPrintPdfLoading(false) : setDowloadPdfLoading(false);
            if(e.response.data && e.response.data.message) {
                enqueueSnackbar(e.response.data.message, {variant: 'error'});
                return;
            }
        }
    }

    const onDownloadCsvClick = async() => {
        const apiStr = fromRevenueByClientReports ? 'revenue_by_clients' : 'items/revenue_by_items';
        const client_name = searchClient ? searchClient : undefined;
        const item_name = searchItem ? searchItem : undefined;

        setDowloadCsvLoading(true);

        try {
            const response = await downloadCsvForRevenueByClientAndItemApi(invoice_account_id, apiStr, fromdate, todate, client_name, item_name);
            const res = response.data;
            consoleToLog("Response downloadCsvForRevenueByClientAndItemApi", res);

            setDowloadCsvLoading(false);

            const downloadableURL = res.document_signed_url;
            // //initiate download
            document.getElementById('csv_download').href = downloadableURL;
            document.getElementById('csv_download').click();

        } catch (e) {
            setDowloadCsvLoading(false);
            if(e.response.data && e.response.data.message) {
                enqueueSnackbar(e.response.data.message, {variant: 'error'});
                return;
            }
        }
    }

    return (
        <div>
            <Paper className={classes.paper2} variant='outlined'
                style={{display:'flex', flexDirection:'column', gap:'16px'}}>
                <Button variant="outlined" fullWidth color="primary"
                    disabled={downloadCsvLoading}
                    onClick={onDownloadCsvClick}>
                    {downloadCsvLoading && <CircularProgress size={16} style={{marginRight:'8px'}}/>} Export CSV
                    <Link
                        style={{ display: "none" }}
                        id="csv_download"
                        target="_blank"
                        onClick={(e) => e.stopPropagation()}
                        download
                    ></Link>
                </Button>

                <Button fullWidth
                    onClick={() => onDownloadOrPrintStatementClick(false)}
                    variant="contained" style={{background:'#4a5568', color:'#fff'}}
                    disabled={downloadPdfLoading} >
                    {downloadPdfLoading ? <CircularProgress size={16} style={{marginRight:'8px', color:"#fff"}}/>  :
                        <PictureAsPdfIcon fontSize='small'
                        style={{marginRight:'5px', fontSize:'16px'}} />}
                        Download
                        <Link
                            style={{ display: "none" }}
                            id="statement_download"
                            target="_blank"
                            onClick={(e) => e.stopPropagation()}
                            download
                        ></Link>
                </Button>

                <Button variant="outlined" fullWidth color="primary"
                    onClick={() => onDownloadOrPrintStatementClick(true)}
                    disabled={printPdfLoading}>
                        
                    {printPdfLoading ? <CircularProgress size={16} style={{marginRight:'8px'}}/>  :
                        <PrintOutlinedIcon fontSize='small'
                        style={{marginRight:'5px', color:'green', fontSize:'16px'}} />}
                    Print
                </Button>
            </Paper>
        </div>
    );
})

export default memo(DownloadPrintExportComponent);