import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles'; 
import {Grid, FormControlLabel, Checkbox, TextField, Typography, Button, CircularProgress, FormGroup, Link, Drawer, MenuItem} from '@material-ui/core';
import {connect} from 'react-redux';
import { useSnackbar } from 'notistack';
import { setSelectedInvoiceAccount, setInvoiceAccountsList, setClearInvoiceAccounts } from '../actions/invoiceAccount';
import { consoleToLog } from '../util/AppUtil';
import { updateGeneralSettingsApi, updateInvoiceDisplaySettingsApi, updateTDSSettingsApi } from '../services/authService';
import { useIsMount } from './useIsMount';
import { logout } from '../actions/invoiceAuth';
import { clearSelectedInvoiceFilters } from '../actions/invoiceFilters';
import { setSelectedDrawerState } from '../actions/drawer';
import { clearSelectedTransactionFilters } from "../actions/transactionFilters";
import {trackGAEvent} from '../util/AppUtil';
import { AccessLevel, shouldDisable } from '../util/AppUtil';
import RightToLeftSideDrawer from './RightToLeftSideDrawer';
import {invoiceAccountUpdateApi} from '../services/authService';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import TCSTypesList from './TCSTypesList';
import TaxRates from './TaxRates';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import {updateTcsSettingsApi} from '../services/authService';
import {invoiceAccountOnboardingUpdateApi} from '../services/authService';
import {updateInvoicePrintSettingsApi} from '../services/authService';
import InvoiceEntityPrefixAndNumber from './InvoiceEntityPrefixAndNumber';
import { updateEntitiesDetailsApi } from '../services/authService';
import {getInvoiceAccountsEntityListApi} from '../services/authService';
import Select from '@material-ui/core/Select';
import { isInvoiceAccountCountryIndia, invoicePrefixOptions, startMonthOptions, showPrefixExampleValue } from '../util/AppUtil';

const useStyles = makeStyles((theme) => ({
    settingsContainer: {
        width:'100%',
        background:'#fff',
        borderRadius:'4px',
        marginBottom:'32px'
    },
    preferenceSettingsContainer: {
        borderRight: '1px solid rgba(0,0,0, 0.1)',
    },
    drawer: {
        width: '50%',
        flexShrink: 0,
    },
    drawerPaper: {
        width: '50%',
        height:'calc(100vh - 190px)',
        overflow:"auto",
    },
    rowContainerStyle: {
        padding:'4px 16px 0px 16px',
        borderBottom: '1px solid rgba(0,0,0,.1)',
        '&:hover' : {
            background:'#f7f7f7'
        }
    },
    rowTextStyle: {
        fontSize:"15px",
        '&:hover': {
            background: 'transparent'
        }
    },
    customMarginButton: {
        marginBottom:'-8px'
    },
    arrowIconStyle: {
        fontSize:'16px',
        marginLeft:'12px',
        color:'#555'
    }
})) 

const GeneralSettingsComponent = (props) => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();
    const isMount = useIsMount();
    
    const proforma_enabled = props.selectedAccount.general_settings?.proforma_enabled;
    const hsn_code_4_digit = props.selectedAccount.general_settings?.hsn_code_4_digit;
    const [enableProformaInvoice, setEnableProformaInvoice] = useState(proforma_enabled);
    const [HSNCode, SetHSNCode] = useState(hsn_code_4_digit);
    const [loading, setLoading] = useState(false);

    const {show_payment_stub, show_payment_terms, show_client_notes} = props.selectedAccount.display_settings;
    const prefix_value = props.selectedAccount?.prefix;
    const format_value = props.selectedAccount?.sequence_format;
    const start_month = props.selectedAccount?.financial_year_start_month;
    const enable_tds = props.selectedAccount?.enable_tds;
    const onboarding = props.selectedAccount?.onboarding;
    const {original_print, transport_print, customer_print} = props.selectedAccount.print_settings || {};

    const [paymentStub, setPaymentStub] = useState(show_payment_stub);
    const [paymentTerms, setPaymentTerms] = useState(show_payment_terms);
    const [clientTerms, setClientTerms] = useState(show_client_notes);
    const [prefixValue, setPrefixValue] = useState(prefix_value);
    const [formatValue, setFormatValue] = useState(format_value);
    const [exampleValue, setExampleValue] = useState(format_value);
    const [monthValue, setMonthValue] = useState(start_month);
    //const [showTCSList, setShowTCSList] = useState(false); 
    const [originalPrintCopy, setOriginalPrintCopy] = useState(true);
    const [transportationPrintCopy, setTransportationPrintCopy] = useState(false);
    const [customerPrintCopy, setCustomerPrintCopy] = useState(false);
    //const [showUpiQrCode, setShowUpiQrCode] = useState(false);

    const [enableOnboarding, setShowEnableOnboarding] = useState(onboarding); 

    const [enableTDSPayment, setEnableTDSPayment] = useState(enable_tds);
    const [callupdateEntityDetailsApi, setCallupdateEntityDetailsApi] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);

    const { access_level } = props.selectedAccount || {};
    const disabled = shouldDisable(AccessLevel.getUserAccessLevelValue(access_level), AccessLevel.OWNER);

    const [value, setValue] = useState(''); 

    const [openDrawer, setOpenDrawer] = useState(false);

    // const startMonthOptions = [{name: "January", value:1}, {name: "February", value: 2}, {name: "March", value:3}, 
    //         {name: "April", value: 4}, {name: "May", value: 5}, {name: "June", value: 6},
    //         {name: "July", value: 7}, {name: "August", value:8}, {name: "September", value: '9'}, 
    //         {name: "October", value: 10}, {name: "November", value: 11}, {name: "December", value: 12}
    //     ];
    
    const [openAddTCSTypeDrawer, setAddOpenTCSTypeDrawer] = useState(false);

    const tcsList = props.selectedAccount?.tcs && props.selectedAccount?.tcs.length > 0 ? props.selectedAccount?.tcs : [];
    const invoiceAccountCountry = props.selectedAccount?.country;
    
    // const options = [
    //     {name: `{{prefix}}-{{number}}`, value: `{{prefix}}-{{number}}`},
    //     {name: `{{prefix}}-{{FY}}-{{number}}`, value: `{{prefix}}-{{FY}}-{{number}}`},
    //     {name: `{{prefix}}-{{MMM-YY}}-{{number}}`, value: `{{prefix}}-{{MMM-YY}}-{{number}}`}
    // ]

    const handleDrawerOpen = (val) => {
        setOpenDrawer(true);
        setValue(val);
    }


    const handleDrawerClose = () => {
        setOpenDrawer(false);
    }

    const setDefaulValues = () => {
            setEnableProformaInvoice(proforma_enabled);
            SetHSNCode(hsn_code_4_digit);
            setPaymentStub(show_payment_stub);
            setPaymentTerms(show_payment_terms);
            setClientTerms(show_client_notes);
            setPrefixValue(prefix_value);
            if(format_value === `{{prefix}}-{{MMM_YY}}-{{number}}`) {
                setFormatValue( `{{prefix}}-{{MMM-YY}}-{{number}}`);
            } else {
                setFormatValue(format_value);
            }
            setMonthValue(start_month);
            setExampleValue(format_value);
            setEnableTDSPayment(enable_tds);
            setOriginalPrintCopy(original_print ? original_print : true);
            setTransportationPrintCopy(transport_print ? transport_print : false);
            setCustomerPrintCopy(customer_print ? customer_print : false);

    }

    useEffect(() => {
        if(!isMount) {
            setDefaulValues();
        }
    }, [openDrawer]);

    const updateGeneralSettings = () => {
        const invoice_account_id = props.selectedAccount?.id;
        const proforma_enabled = enableProformaInvoice;
        const hsn_code = HSNCode;

        setLoading(true);
        updateGeneralSettingsApi(invoice_account_id, proforma_enabled, hsn_code)
        .then((response) => {
            const res = response.data;
            consoleToLog('Response updateGeneralSettingsApi: ', res);
            setLoading(false);

            let account = {
                ...props.selectedAccount,
                general_settings: res.general_settings
            };

            let accountList = props.invoiceAccountsList.map((ia)=>{
                if(ia.id === account.id) return account;
                else return ia;
            });

            props.setInvoiceAccountsList(accountList);
            props.setSelectedInvoiceAccount(account)
            enqueueSnackbar("General Setting updated", {variant: 'success'});
            handleDrawerClose();
            trackGAEvent(props.selectedAccount.name, 'General Settings Updated', `${props.user.firstname} ${props.user.lastname}`);

        })
        .catch((e) => {
            consoleToLog('Error updateGeneralSettingsApi: ', e.response);
            setLoading(false);
            if(e.response.data && e.response.data.message) {
                enqueueSnackbar(e.response.data.message, {variant: 'error'});
                return;
            }
        })
    }

    const updateInvoiceDisplaySettings = () => {
        //console.log("checkboxes: ", paramPaymentStub, paramPaymentTerms, paramClientTerms);
        const invoice_account_id = props.selectedAccount?.id;

        updateInvoiceDisplaySettingsApi(invoice_account_id, paymentStub, paymentTerms, clientTerms)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response updateInvoiceDisplaySettingsApi: ', res);
                let account = {
                    ...props.selectedAccount,
                    display_settings: res.display_settings
                };

                let accountList = props.invoiceAccountsList.map((ia)=>{
                    if(ia.id === account.id) return account;
                    else return ia;
                });

                props.setInvoiceAccountsList(accountList);
                props.setSelectedInvoiceAccount(account)
                enqueueSnackbar("Setting updated", {variant: 'success'});

                trackGAEvent(props.selectedAccount.name, 'Invoice display settings updated', `${props.user.firstname} ${props.user.lastname}`);
                handleDrawerClose();
            })
            .catch((e) => {
                consoleToLog('Error updateInvoiceDisplaySettingsApi: ', e.response);
                if(e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {variant: 'error'});
                    return;
                }
            })
    }

    const onHSNCodeChange = (e) => {
            trackGAEvent(props.selectedAccount.name, 'General Settings - HSN Code Changed', `${props.user.firstname} ${props.user.lastname}`);
            SetHSNCode(e.target.value);
    }

    const displayTitle = () => {
        switch(value) {

            case 1:
                return 'Invoice Display Settings';

            case 2: 
                return 'Invoice Numbering Format';

            case 3: 
                return 'TDS Payment';

            case 4: 
                return 'TCS Payment';

            case 5: 
                return 'Onboarding';

            case 6:
                return 'Print Copy';

            case 7: 
                return 'Invoice Prefix and Number';

            case 8:
                return 'Estimate Prefix and Number';
        }
    }

    const onSaveClick = () => {

        value === 1 && 
        updateInvoiceDisplaySettings();

        value === 2 && 
        updateAccountSettings();

        value === 3 &&
        updateTDSSettings();

        value === 5 &&
        updateInvoiceAccountOnboarding();

        value === 6 &&
        updateInvoicePrintSettings();

        (value === 7 || value === 8) && 
        updateEntitiesDetails();
    }

    const handleFormatChangeValue = (e) => {
        let value = e.target.value;
        setFormatValue(value);
        setExampleValue(value);
    }

    
    
    const handleMonthChange = (e) => {
        setMonthValue(e.target.value);
    }

    const updateAccountSettings = () => {
        const name = props.selectedAccount?.name;
        const address = props.selectedAccount?.address;
        const selectedCountry = props.selectedAccount?.country;
        const selectedState = props.selectedAccount?.state;
        const email = props.selectedAccount.email ? props.selectedAccount.email : '';
        const mobile = props.selectedAccount.mobile ? props.selectedAccount.mobile : '';
        const tax_id = props.selectedAccount.tax_id ? props.selectedAccount.tax_id : '';
        const default_client_notes = props.selectedAccount.default_client_notes ? props.selectedAccount.default_client_notes : '';
        const default_payment_terms = props.selectedAccount.default_payment_terms ? props.selectedAccount.default_payment_terms : '';
        const image = '';
        const prefix = prefixValue;
        if(!prefix) {
            enqueueSnackbar('Prefix cannot be empty!', {variant:'error'});
            return;
        }

        const format = compareAndShowValues();
        if(format === '-1') {
            enqueueSnackbar('Please select format', {variant:'error'});
            return
        }

        const financial_year_start_month = monthValue;
        if(financial_year_start_month === -1) {
            enqueueSnackbar('Please select ffinancial year start month', {variant:'error'});
            return
        }

        const invoice_account_id = props.selectedAccount?.id;
        const currency = props.selectedAccount.currencies?.id;
        const onboarding = false;

        
        setLoading(true);
        //update organization api call
        invoiceAccountUpdateApi(invoice_account_id, name, address, selectedCountry, 
        selectedState, email, mobile, tax_id, default_client_notes, default_payment_terms, image, prefix, format, 
        financial_year_start_month, currency, onboarding)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response invoiceAccountUpdateApi: ", res);

                let account = {
                    ...props.selectedAccount,
                    prefix: res.prefix,
                    sequence_format: res.sequence_format,
                    financial_year_start_month: res.financial_year_start_month
                }

                let accountList = props.invoiceAccountsList.map((ia)=>{
                    if(ia.id === account.id) return account;
                    else return ia;
                });

                props.setInvoiceAccountsList(accountList);
                props.setSelectedInvoiceAccount(account)
                enqueueSnackbar("Invoice numbering settings updated", {variant: 'success'});
                handleDrawerClose();
                trackGAEvent(props.selectedAccount.name, 'Invoice Account Setting Updated', `${props.user.firstname} ${props.user.lastname}`);
            })
            .catch((e) => {
                consoleToLog("invoiceAccountUpdateApi error", e);
                setLoading(false);
                if (e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {
                        variant:'error'
                    });
                    return false;
                }
            });
    }
    

    const updateTDSSettings = () => {
        const invoice_account_id = props.selectedAccount?.id;
        const enableTDS = enableTDSPayment;

        updateTDSSettingsApi(invoice_account_id, enableTDS)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response updateTDSSettingsApi: ", res);

                let account = {
                    ...props.selectedAccount,
                    enable_tds: res.enable_tds
                };

                let accountList = props.invoiceAccountsList.map((ia)=>{
                    if(ia.id === account.id) return account;
                    else return ia;
                });

                props.setInvoiceAccountsList(accountList);
                props.setSelectedInvoiceAccount(account);
                enqueueSnackbar("TDS settings updated", {variant: 'success'});
                handleDrawerClose();
                trackGAEvent(props.selectedAccount.name, 'TDS Setting Updated', `${props.user.firstname} ${props.user.lastname}`);
            })
            .catch((e) => {
                consoleToLog("updateTDSSettingsApi error", e);
                if (e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {
                        variant:'error'
                    });
                    return false;
                }
            });
        

    }

    const onSaveOrDeleteClick = (tcsObj) => {
        const invoice_account_id = props.selectedAccount?.id;
        const tcs = tcsObj && tcsList.filter((tcs) => tcs.name !== tcsObj?.name);

        setLoading(true);
        updateTcsSettingsApi(invoice_account_id, tcs)
        .then((response) => {
            const res = response.data;
            consoleToLog('Response updateTcsSettingsApi: ', res);
            setLoading(false);
            enqueueSnackbar('TCS settings updated successfully', { variant: 'success' });

            let accountList = props.invoiceAccountsList.map((ia)=>{
                if(ia.id === res.id) return res;
                else return ia;
            });

            props.setInvoiceAccountsList(accountList);
            props.setSelectedInvoiceAccount(res);


            trackGAEvent(props.selectedAccount.name, 'TCS Type updated successfully', `${props.user.firstname} ${props.user.lastname}`)

        })
        .catch((e) => {
            consoleToLog('Error addTaxPresetApi: ', e);
            setLoading(false);
            if(e.response.data && e.response.data.message) {
                enqueueSnackbar(e.response.data.message, {variant: 'error'});
                return;
            }
        });
    }

    const updateInvoicePrintSettings = () => {
        const invoice_account_id = props.selectedAccount?.id;

        const print_settings = {
            original_print: originalPrintCopy,
            transport_print: transportationPrintCopy,
            customer_print: customerPrintCopy
        }

        updateInvoicePrintSettingsApi(invoice_account_id, print_settings)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response updateInvoicePrintSettingsApi: ', res);
                let account = {
                    ...props.selectedAccount,
                    print_settings: res.print_settings
                };

                let accountList = props.invoiceAccountsList.map((ia)=>{
                    if(ia.id === account.id) return account;
                    else return ia;
                });

                props.setInvoiceAccountsList(accountList);
                props.setSelectedInvoiceAccount(account)
                enqueueSnackbar("Print Settings updated", {variant: 'success'});

                trackGAEvent(props.selectedAccount.name, 'Invoice print settings updated', `${props.user.firstname} ${props.user.lastname}`);
                handleDrawerClose();
            })
            .catch((e) => {
                consoleToLog('Error updateInvoicePrintSettingsApi: ', e.response);
                if(e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {variant: 'error'});
                    return;
                }
            })
    }

    const updateEntitiesDetails = () => {
        setCallupdateEntityDetailsApi(true);
    }

    const handleAddTCSTypeDrawerOpen = (val) => {
        setAddOpenTCSTypeDrawer(true);
    }

    const handleAddTCSTypeDrawerClose = (val) => {
        setAddOpenTCSTypeDrawer(false);
    }

    const updateInvoiceAccountOnboarding = () => {
        const invoice_account_id = props.selectedAccount?.id;
        const onboardingVal = enableOnboarding;

        setLoading(true);
        invoiceAccountOnboardingUpdateApi(invoice_account_id, onboardingVal)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response invoiceAccountOnboardingUpdateApi: ", res);
                enqueueSnackbar('Onboarding settings updated', {variant:'success'});
                setLoading(false);

                let account = {
                    ...props.selectedAccount,
                    onboarding: res.onboarding
                };

                let accountList = props.invoiceAccountsList.map((ia)=>{
                    if(ia.id === account.id) return account;
                    else return ia;
                });

                props.setInvoiceAccountsList(accountList);
                props.setSelectedInvoiceAccount(account);
                handleDrawerClose();
            })
            .catch((e) => {
                consoleToLog("invoiceAccountOnboardingUpdateApi error", e.response);
                setLoading(false);
                if (e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {
                        variant:'error'
                    });
                    return false;
                }
            });
    }
    
    const compareAndShowValues = () => {
        if(formatValue === `{{prefix}}-{{MMM-YY}}-{{number}}`) {
            return `{{prefix}}-{{MMM_YY}}-{{number}}`
        } else {
            return formatValue;
        }
    }

    return (
        <Grid item xs={12}  className={classes.settingsContainer}>
            {[
                { id: 1, label: 'Invoice Display Settings' },
                { id: 2, label: 'Invoice Numbering Format' },
                ...(isInvoiceAccountCountryIndia(invoiceAccountCountry) ? [
                    { id: 3, label: 'TDS Payment' },
                    { id: 4, label: 'TCS Payment' }
                ] : []),
                { id: 5, label: 'Show Onboarding' },
                { id: 6, label: 'Print Copy' },
                { id: 7, label: 'Invoice Prefix and Number' },
                { id: 8, label: 'Estimate Prefix and Number' }
            ].map(item => (
                <Grid item sm={12} key={item.id} className={classes.rowContainerStyle}>
                    <Button
                        disableRipple
                        className={classes.rowTextStyle}
                        onClick={() => handleDrawerOpen(item.id)}
                    >
                        {item.label}
                        <ArrowForwardIcon fontSize='small' className={classes.arrowIconStyle} />
                    </Button>
                </Grid>
            ))}

            <RightToLeftSideDrawer openDrawer={openDrawer} 
                closeDrawer={handleDrawerClose}
                className={classes.drawer}
                classes={{
                    paper: classes.drawerPaper,
                }}
                title={displayTitle()}
            >
                <Grid item md={12} className='modal__main1'>

                    {/* {value === 0 &&
                        <Grid item sm={12}>
                            <FormControlLabel control={
                            <Checkbox  
                                onChange = {(e)=>{
                                    trackGAEvent(props.selectedAccount.name, 'General Settings - Proforma Invoice Changed', `${props.user.firstname} ${props.user.lastname}`);
                                    setEnableProformaInvoice(e.target.checked)
                                
                                }}
                                disabled={disabled}
                                checked={enableProformaInvoice}
                            />
                            } 
                            label="Proforma Invoice" />
                        </Grid>
                    } */}

                    {/* {
                        value === 1 &&
                        <Grid item sm={12}>
                            <Typography variant='subtitle1'
                                className={classes.customMarginButton}>
                                HSN/SAC Code
                            </Typography>
                            <TextField
                                variant='outlined'
                                margin='dense'
                                value={HSNCode}
                                onChange={onHSNCodeChange}
                                placeholder="HSN/SAC Code"
                                inputProps={{maxLength :4}}  
                                InputLabelProps={{style: {fontSize: 13.5}}}
                                disabled={disabled}
                            />
                        </Grid>
                    } */}

                    {
                        value === 1 &&
                        <Grid item sm={12}>
                            <FormGroup>
                                <FormControlLabel control={
                                    <Checkbox  
                                        onChange = {(e)=>{
                                            setPaymentStub(e.target.checked);
                                            //updateInvoiceDisplaySettings(e.target.checked, paymentTerms, clientTerms);
                                        }}
                                        disabled={disabled}
                                        checked={paymentStub}
                                    />
                                    } 
                                    label="Show Payment stub" />

                                    <FormControlLabel control={
                                        <Checkbox   
                                            onChange = {(e)=>{
                                                setPaymentTerms(e.target.checked);
                                                //updateInvoiceDisplaySettings(paymentStub, e.target.checked, clientTerms );
                                            }}
                                            disabled={disabled}
                                            checked={paymentTerms}/>
                                        } 
                                        label="Show Payment Terms" />

                                    <FormControlLabel control={
                                        <Checkbox   
                                            onChange = {(e)=>{
                                                setClientTerms(e.target.checked);
                                                //updateInvoiceDisplaySettings(paymentStub, paymentTerms, e.target.checked);
                                            }}
                                            disabled={disabled}
                                            checked={clientTerms}/>
                                        } 
                                        label="Show Client Notes" />

                                    {/* <FormControlLabel control={
                                        <Checkbox   
                                            onChange = {(e)=>{
                                                setShowUpiQrCode(e.target.checked);
                                                //updateInvoiceDisplaySettings(paymentStub, paymentTerms, e.target.checked);
                                            }}
                                            disabled={disabled}
                                            checked={showUpiQrCode}/>
                                        } 
                                        label="Show UPI QR Code" /> */}
                            </FormGroup>
                        </Grid>
                    }

                    {
                        value === 2 &&
                        <Grid item sm={12}>
                            <Grid item container direction='column'>
                                {/* <Grid item sm={12}>
                                    <Typography variant='subtitle1'
                                        className={classes.customMarginButton}>
                                        Prefix
                                    </Typography>
                                    <TextField 
                                        placeholder='Enter prefix'
                                        variant='outlined'
                                        margin='dense'
                                        inputProps={{maxLength :3}}
                                        value={prefixValue}
                                        onChange={(e) => setPrefixValue(e.target.value)}
                                        style={{width:'30%'}}
                                    />
                                </Grid> */}

                                <Grid item sm={12}>
                                    <Typography variant='subtitle1'>
                                        Format
                                    </Typography>
                                    <Select variant='outlined'
                                        margin='dense'
                                        value={formatValue}
                                        onChange={handleFormatChangeValue}
                                        renderValue={(value) => (invoicePrefixOptions.includes(value) ? value : `${value}`)}
                                        style={{width:'67%'}}
                                    >
                                        <MenuItem value='-1'>Please Select Format</MenuItem>

                                        {invoicePrefixOptions.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Grid>
                                {exampleValue &&
                                <Typography variant='body1'>
                                    {showPrefixExampleValue(exampleValue, props.selectedAccount.prefix)}
                                </Typography>
                                }

                            </Grid>

                            <Grid item sm={12} style={{marginTop:'12px'}}>
                                <Typography variant='subtitle1'
                                    className={classes.customMarginButton}>
                                    Financial Year Start Month
                                </Typography>
                                <TextField 
                                    placeholder='prefix'
                                    variant='outlined'
                                    margin='dense'
                                    inputProps={{maxLength :3}}
                                    value={monthValue}
                                    onChange={handleMonthChange}
                                    select
                                    style={{width:'67%'}}
                                >
                                    {
                                        <MenuItem value={-1}>Please Select Start Month</MenuItem>
                                    }
                                    {
                                        startMonthOptions.map((val) => {
                                            return <MenuItem key={val.name} value={val.value}>
                                                        {val.name}
                                                    </MenuItem> 
                                        })
                                    }
                                    
                                </TextField>
                            </Grid>

                            
                        </Grid>
                    }

                    {value === 3 &&
                        <Grid item sm={12}>
                            <FormControlLabel control={
                            <Checkbox  
                                onChange = {(e)=>{
                                    trackGAEvent(props.selectedAccount.name, 'General Settings - TDS Payment Settings Changed', `${props.user.firstname} ${props.user.lastname}`);
                                    setEnableTDSPayment(e.target.checked)
                                
                                }}
                                disabled={disabled}
                                checked={enableTDSPayment}
                            />
                            } 
                            label="TDS Payment" />
                        </Grid>
                    }

                    {value === 4 &&
                        <Grid item sm={12}>
                            <Grid item container justifyContent='space-between' alignItems='center'>
                                <Grid item>
                                    <Typography style={{fontSize:'15px', fontWeight:500}}>
                                        TCS Types
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Button variant='contained' color='primary'
                                        onClick={handleAddTCSTypeDrawerOpen}>
                                        Add TCS Type
                                        <AddCircleOutlineIcon ontSize='small' 
                                            style={{fontSize:'15px', marginLeft:'4px'}}/>
                                    </Button>
                                </Grid>
                            </Grid>
                            <TaxRates taxArrayList={tcsList}
                                fromTCSTypesListComponent={true}
                                onSaveOrDeleteClick={(tcsObj) => onSaveOrDeleteClick(tcsObj)}/>
                        </Grid>
                    }

                    {value === 5 &&
                        <Grid item sm={12}>
                            <FormControlLabel control={
                            <Checkbox  
                                onChange = {(e)=>{
                                    trackGAEvent(props.selectedAccount.name, 'General Settings - Onboarding updated', `${props.user.firstname} ${props.user.lastname}`);
                                    setShowEnableOnboarding(e.target.checked)
                                
                                }}
                                disabled={disabled}
                                checked={enableOnboarding}
                            />
                            } 
                            label="Show Onboarding" />
                        </Grid>
                    }

                    {
                        value === 6 &&
                        <Grid item sm={12}>
                            <FormGroup>
                                <FormControlLabel control={
                                    <Checkbox  
                                        onChange = {(e)=>{
                                            setOriginalPrintCopy(e.target.checked);
                                            //updateInvoiceDisplaySettings(e.target.checked, paymentTerms, clientTerms);
                                        }}
                                        disabled={true}
                                        checked={originalPrintCopy}
                                    />
                                    } 
                                    label="Original Copy" />

                                    <FormControlLabel control={
                                        <Checkbox   
                                            onChange = {(e)=>{
                                                setTransportationPrintCopy(e.target.checked);
                                                //updateInvoiceDisplaySettings(paymentStub, e.target.checked, clientTerms );
                                            }}
                                            disabled={disabled}
                                            checked={transportationPrintCopy}
                                    />
                                    } 
                                    label="Transporter Copy" />

                                    <FormControlLabel control={
                                        <Checkbox   
                                            onChange = {(e)=>{
                                                setCustomerPrintCopy(e.target.checked);
                                                //updateInvoiceDisplaySettings(paymentStub, paymentTerms, e.target.checked);
                                            }}
                                            disabled={disabled}
                                            checked={customerPrintCopy}
                                    />
                                    } 
                                    label="Customer Copy" />
                            </FormGroup>
                        </Grid>
                    }

                    {
                        (value === 7 || value === 8) && <InvoiceEntityPrefixAndNumber callupdateEntityDetailsApi={callupdateEntityDetailsApi}
                                        setCallupdateEntityDetailsApi={setCallupdateEntityDetailsApi}
                                        handleDrawerClose={handleDrawerClose}
                                        setButtonLoading={setButtonLoading}
                                        value={value}
                                        />
                    }

                    {value !== 4 &&
                    <div className="modal__footer">
                        <Button variant="contained" color="primary" 
                            onClick={onSaveClick}
                            className="modal__button">
                            {buttonLoading && <CircularProgress size={18} style={{marginRight:"8px", color:'#fff'}}/>}
                            Save    
                        </Button>
                        <Button variant="outlined" style={{
                            minWidth: '130px',
                        }} onClick={handleDrawerClose}>
                            Cancel
                        </Button>
                    </div>
                    } 
                </Grid>

            </RightToLeftSideDrawer>

            {value === 4 && 
                <TCSTypesList openAddTCSTypeDrawer={openAddTCSTypeDrawer}
                    handleAddTCSTypeDrawerClose={handleAddTCSTypeDrawerClose}/> }
        </Grid>
    );
}

const mapStateToProps = (state) => ({
    selectedAccount: state.invoiceAccounts.selectedAccount,
    invoiceAccountsList: state.invoiceAccounts.invoiceAccountsList,
    user: state.invoiceAccounts.user
});

const mapDispatchToProps = (dispatch) => ({
    setSelectedInvoiceAccount: (account) => dispatch(setSelectedInvoiceAccount(account)),
    setInvoiceAccountsList: (list) => dispatch(setInvoiceAccountsList(list)),
    logout: () => dispatch(logout()),
    clearSelectedInvoiceFilters: () => dispatch(clearSelectedInvoiceFilters()),
    setClearInvoiceAccounts: () => dispatch(setClearInvoiceAccounts()),
    setSelectedDrawerState: (drawerState) => dispatch(setSelectedDrawerState(drawerState)),
    clearSelectedTransactionFilters: () => dispatch(clearSelectedTransactionFilters())
});

export default connect(mapStateToProps, mapDispatchToProps)(GeneralSettingsComponent);