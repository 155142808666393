import React from 'react';
import {Grid, Typography} from '@material-ui/core';
import {connect} from 'react-redux'; 
import {addSelectedTypeToInvoiceFilter, removeSelectedTypeFromInvoiceFilter} from '../../actions/invoiceFilters';
import { trackGAEvent } from '../../util/AppUtil';
import {addSelectedBillTypeToEstimateFilter, removeSelectedBillTypeFromEstimateFilter} from '../../actions/invoiceFilters';

const InvoiceEstimateTypeFilterComponent = (props) => {
    const invoiceTypeNameList = ["Proforma", "Tax", "None"];
    const invoiceTypeValueList = ["proforma", "tax", "none"];

    const estimateBillTypeNameList = ["Not Invoiced", "Partially Invoiced", "Invoiced"];
    const estimateBillTypeValueList = ["none", "partial", "invoiced"];

    const invoiceTypeClassNames = ["proforma__invoice", "tax__invoice", "none__invoice"];
    const invoiceTypeClassNames1 = ["proforma__background", "tax__background", "none__background"];

    const estimateBillTypeClassNames = ["none_bill_type", "partial_bill_type", "invoiced_bill_type"];
    const estimateBillTypeClassNames1 = ["none_bill_type_background", "partial_bill_type_background", "invoiced_bill_type_background"];

    const typeNamesList = props.fromEstimatesComponent ? estimateBillTypeNameList : invoiceTypeNameList;
    const typeValueList = props.fromEstimatesComponent ? estimateBillTypeValueList : invoiceTypeValueList;
    const typesClassNames = props.fromEstimatesComponent ? estimateBillTypeClassNames : invoiceTypeClassNames;
    const typesClassNames1 = props.fromEstimatesComponent ? estimateBillTypeClassNames1 : invoiceTypeClassNames1;
    
    const invoiceTypeClassName = (index) => {
        const TYPE_CLASS = `status__filterList ${typesClassNames1[index]}`;
        if(props.fromEstimatesComponent) {
            return props.billTypeEstimateFilter.includes(typeValueList[index]) ? `${TYPE_CLASS} active` : TYPE_CLASS;
        } else {
            return props.selectedTypeInvoiceFilter.includes(typeValueList[index]) ? `${TYPE_CLASS} active` : TYPE_CLASS;
        }
    }


    const onInvoiceTypeClick = (selectedValue) => {
        trackGAEvent(props.selectedAccount.name, props.fromEstimatesComponent ? 'Invoice filter - Invoice Type filter changed' : 'Estimate filter - Bill Type filter changed');
        const selectedStatus = props.fromEstimatesComponent ? props.billTypeEstimateFilter.filter((status) => status === selectedValue) : props.selectedTypeInvoiceFilter.filter((status) => status === selectedValue);
        if(selectedStatus && selectedStatus.length > 0) {
            props.fromEstimatesComponent ? props.removeSelectedBillTypeFromEstimateFilter(selectedValue) : props.removeSelectedTypeFromInvoiceFilter(selectedValue);
        } else {
            props.fromEstimatesComponent ? props.addSelectedBillTypeToEstimateFilter(selectedValue) : props.addSelectedTypeToInvoiceFilter(selectedValue);
        }
    }

    return (
        <div style={{marginTop:'16px'}}>
            <span style={{fontSize:'14px'}}>
                {props.fromEstimatesComponent ? 'Bill Type' : 'Invoice Type'}            
            </span> 
            <Grid container lg={12} style={{marginTop:'8px'}} >
                {typeNamesList.map((type, index) => {
                        return <Grid item
                                    className={invoiceTypeClassName(index)}
                                    style={{display:'flex', alignItems:'center'}}
                                    key={type}
                                    onClick={() => {
                                        onInvoiceTypeClick(typeValueList[index])
                                        props.pageReset();
                                    }}
                                >
                            <span className={`circle ${typesClassNames[index]}`}></span> 
                            <Typography  style={{fontSize:'12px'}}>{type}</Typography>
                        </Grid>
                    })
                }
            </Grid> 
        </div>
    );
}

const mapStateToProps = (state) => ({
    accessToken: state.invoiceAuth,
    selectedTypeInvoiceFilter: state.invoiceFilters.selectedTypeInvoiceFilter,
    selectedAccount: state.invoiceAccounts.selectedAccount,
    billTypeEstimateFilter: state.invoiceFilters.billTypeEstimateFilter
});

const mapDispatchToProps = (dispatch) => ({
    addSelectedTypeToInvoiceFilter: (item) => dispatch(addSelectedTypeToInvoiceFilter(item)),
    removeSelectedTypeFromInvoiceFilter: (item) => dispatch(removeSelectedTypeFromInvoiceFilter(item)),

    addSelectedBillTypeToEstimateFilter: (billType) => dispatch(addSelectedBillTypeToEstimateFilter(billType)),
    removeSelectedBillTypeFromEstimateFilter: (billType) => dispatch(removeSelectedBillTypeFromEstimateFilter(billType))

});

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceEstimateTypeFilterComponent);