import React,{useState, useEffect, Fragment} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {TextField, Grid, Avatar, Chip, Tooltip} from '@material-ui/core';
import { consoleToLog, trackGAEvent } from '../util/AppUtil';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ClearIcon from '@material-ui/icons/Clear';
import { useIsMount } from './useIsMount';
import {getClientsListApi} from '../services/clientService';


const useStyles = makeStyles((theme) => ({
    avatar : {
        width: '15px !important',
        height: '15px !important',
        fontSize: '12px !important',
        background: 'none !important',
        color: 'rgba(0, 0, 0, 0.87) !important',
        border: '1px solid rgba(0, 0, 0, 0.26) !important'
        },
    
    chip : {
        borderRadius: '4px !important',
        backgroundColor: '#f9f9f9 !important',
        border: '1px solid #ececec !important',
        },
        
    avatarStyle: {
        width: 20,
        height: 20,
        fontSize: 12
    },
       
    autoStyle: {
        "&::-webkit-scrollbar": {
            display: "none"
        }
            
    }
    }
));

const AutocompleteClientsComponent = (props) => {
    const classes = useStyles();
    const [companies, setCompanies] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState([]);
    const [inputText, setInputText] = useState('')
    const [open, setOpen] = useState(false);
    const isMount = useIsMount(); 

    const fetchCompanyList = (text) => {
        const invoice_account_id = props.selectedAccount?.id;
        getClientsListApi(invoice_account_id, 0, text)
        .then((response) => {
            const res = response.data;
            consoleToLog('Response getClientsListApi: ', res);
                if(res.companies && res.companies.length > 0) {
                    setCompanies(res.companies);
                } else {
                    const noCF = { id: -1, name: 'No companies found' }
                    setCompanies([noCF])
                }
        })
        .catch((e) => {
            consoleToLog('Error getClientsListApi: ', e.response);
        })
    }

    useEffect(() => {
        if(props.isFromContactDrawer) {
            if(props.selectedCompanies && props.selectedCompanies.length > 0 && props.isFromContactDrawer) setSelectedCompany(props.selectedCompanies);
        }
    }, [props.selectedCompanies]);


    useEffect(() => {
        if(!isMount) {
            consoleToLog("Autocomplete companies- on company change: in useeffect", selectedCompany);
            props.isFromContactDrawer && props.onSelectedCompanies(selectedCompany);
        }
    }, [selectedCompany])

    useEffect(() => {
        if(!isMount) {
            consoleToLog("Autocomplete Companies- On Clear click: in useeffect");
            props.clearFilter && setSelectedCompany([]);
        }
    }, [props.clearFilter])

    const onAddCompany = (option) => {
    }

    const onDeleteCompany = (option) => {
        const clientList = props.contactObj.clients;
        const clientPresentInContact = clientList.filter((client) => client.id === option.id);
        if(props.isFromContactDrawer && Object.keys(props.contactObj).length > 0 && clientPresentInContact && clientPresentInContact.length > 0) {
            if(window.confirm(`Are you sure you want to remove contact from ${option.name}`)) {
                props.onDeleteClientFromContactDrawer(props.contactObj?.id, option);
                setSelectedCompany(selectedCompany.filter(entry => entry !== option));
            }
        } else {
            setSelectedCompany(selectedCompany.filter(entry => entry !== option));
        }
    }

    const renderOptions = (option) => {
        return (
            <div style={{display: "flex", width:"100%"}} 
                className={classes.autoStyle}
                onClick={() =>{
                    onAddCompany(option)
                    props.isFromMobileComponent && props.pageReset();    
                }}     
            >
                <Avatar style={{marginRight: "6px", marginTop: "4px"}}  
                    classes={{
                        root: classes.avatar
                    }}
                >
                    {option.name.charAt(0)}
                </Avatar>
                {option.name}
            </div>
        );
    }

    const renderTags = (options) => {
        return (
            options.map(option =>
                <Fragment key={option.name}>
                    <Tooltip title={option.name} arrow>
                        <Chip
                            classes={{
                                root: classes.chip
                            }}
                            avatar={<Avatar classes={{
                                root: classes.avatar
                            }}>{option.name.charAt(0)}</Avatar>}
                            label={option.name}
                            onDelete={() => onDeleteCompany(option)}
                            variant="outlined"
                            style={{margin: "2px 2px", overflow:'hidden'}}
                            deleteIcon={<ClearIcon  style={{width: "18px"}}/>}
                        />
                    </Tooltip>
                </Fragment>))
    }

    const handleInputChange = (e, newValue) => {
        fetchCompanyList(newValue);
            
        setInputText(newValue);
            if (!newValue) {
                setOpen(false);
            }
    }

    return (
        <div style={{marginTop:props.isFromContactDrawer ? '0px' : '16px'}}>
            
            <span style={{fontSize:'14px', color: props.isFromReport && '#888888'}}>
                Companies            
            </span>
            <Grid item style={{marginTop:'8px'}}>
                <Autocomplete
                    multiple={true}
                    style={{width: "100%"}}
                    id="size-small-outlined-multi"
                    size="small"
                    open={open}
                    onOpen={() =>  {
                        if(inputText) {
                            setOpen(true)
                        }
                    }}
                    onClose={() => setOpen(false)}
                    options={companies}
                    getOptionSelected={(option, value) => option.id === value.id}
                    getOptionLabel={(option) => option.name}
                    disableClearable
                    renderOption={(option) => renderOptions(option)}
                    value={selectedCompany}
                    onChange={(e, newValue) => {
                        setSelectedCompany(newValue)
                    }}
                    onInputChange={(e, newValue) => handleInputChange(e, newValue)}
                    renderTags={(options) => renderTags(options)}
                    renderInput={(params) => (
                        <TextField {...params} variant="outlined" placeholder="Search Company" />
                    )}
                />
            </Grid>        
        </div>
    );
}

const mapStateToProps = (state) => ({
    accessToken: state.invoiceAuth,
    selectedAccount: state.invoiceAccounts.selectedAccount,
    user: state.invoiceAccounts.user
});

export default connect(mapStateToProps)(AutocompleteClientsComponent);