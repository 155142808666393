import React, {useState, Fragment, useEffect} from 'react';
import {TextField, Grid, Chip ,Paper, Button} from '@material-ui/core'; 
import {connect} from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ClearIcon from '@material-ui/icons/Clear';
import { useIsMount } from './useIsMount';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';



const useStyles = makeStyles((theme) => ({
    avatar : {
        width: '15px !important',
        height: '15px !important',
        fontSize: '12px !important',
        background: 'none !important',
        color: 'rgba(0, 0, 0, 0.87) !important',
        border: '1px solid rgba(0, 0, 0, 0.26) !important'
        },

    chip : {
        borderRadius: '4px !important',
        backgroundColor: '#f9f9f9 !important',
        border: '1px solid #ececec !important',
        },

    avatarStyle: {
        width: 20,
        height: 20,
        fontSize: 12
        },
        autoStyle: {
        "&::-webkit-scrollbar": {
            display: "none"
        }
    },
    MuiAutocompleteinputRoot : {
        '& .MuiOutlinedInput-root' : {
        padding:'1px',
        borderRadius: '5px',
        }
    },
    customTextField: {
        "& input::placeholder": {
          fontSize: "13px",
        }
      },
      createTaxPresetButton: {
        margin:'16px auto 4px 6px', 
        width:'95%', 
        color:'#1bac3b', 
        borderColor:'#d4d8de'
    },
    createTaxPresetIcon: {
        margin:'0px 6px 0px 0px', 
        fontSize:'16px'
    },

    }
));

const AutoCompleteTaxPreset = (props) => {
    const classes = useStyles();
    const isMount = useIsMount(); 
   
    const taxPresets = props.selectedAccount.tax_presets && props.selectedAccount.tax_presets.length > 0 ? 
    props.selectedAccount.tax_presets : [];

    
    useEffect(() => {
        if(!isMount) {
            // props.setTaxes(selectedTaxPresets);
            if(props.taxPresetObj && Object.keys(props.taxPresetObj).length !== 0)
            {
              props.setTaxes([...props.taxes,props.taxPresetObj]);
              props.setTaxPresetObj(undefined);
            }

        }

    }, [props.taxPresetObj]);

    const renderOptions = (option) => {
        return (
            <div style={{display: "flex", width: "100%"}} 
                className={classes.autoStyle}>
                {option.name}
            </div>
            );
    }

    const onDeleteTaxPreset = (option) => {
        
        props.setTaxes(props.taxes.filter(entry => entry !== option));
    }
    
    const handleAddEditTaxPresetDrawer = () => {
      
        props.handleTaxPresetDrawerOpen();
       
    }

    const renderTags = (options) => {
        return (
            options.map(option =>
                <Fragment key={option.id}>
                <Chip
                    classes={{
                        root: classes.chip
                    }}
                    label={option.name}
                    onDelete={() => onDeleteTaxPreset(option)}
                    variant="outlined"
                    style={{margin: "2px 2px"}}
                    deleteIcon={<ClearIcon  style={{width: "18px"}}/>}
                />
                </Fragment>))
        }

       

        const CreateTaxPresetButton = ({ children, ...other }) => (
            <Paper {...other} 
                style={{marginTop:'6px',
                        boxShadow: 'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px' 
                        }}>
              <Button fullWidth
                className={classes.createTaxPresetButton}
                variant="outlined"
                color="primary"
                onMouseDown={handleAddEditTaxPresetDrawer}
               
                // onMouseDown={handleAddEditItemDrawerOpen}
              >
                <AddCircleOutlineIcon fontSize='small' className={classes.createTaxPresetIcon}/>  
                Create Tax Preset
              </Button>
              {children}
            </Paper>
          );

      
    

    return (
        <div>
            <Autocomplete
                multiple
                style={{width: "100%"}}
                id="size-small-outlined-multi"
                size="small"
                options={taxPresets}
                getOptionLabel={(option) => option.name}
                disableClearable
                renderOption={(option) => renderOptions(option)}
                value={props.taxes}
                onChange={(e, newValue) => {                    
                    props.setTaxes(newValue);                   
                }}
                classes={{
                    root: classes.MuiAutocompleteinputRoot
                }}
                renderTags={(options) => renderTags(options)}
                renderInput={(params) => (
                    <TextField {...params} variant="outlined" 
                        placeholder={'Select Tax/Taxes'} 
                        fullWidth
                        classes={{
                            root: classes.customTextField
                        }}
                    />
                )}
                PaperComponent={CreateTaxPresetButton}
               
            />
        
        </div>
    );
}

const mapStateToProps = (state) => ({
    selectedAccount: state.invoiceAccounts.selectedAccount,
})

export default connect(mapStateToProps)(AutoCompleteTaxPreset);