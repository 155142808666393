import React, {useState} from 'react';
import {Grid, Typography, Button} from '@material-ui/core';
import TaxRates from '../TaxRates';
import {makeStyles} from '@material-ui/core/styles';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CreateTaxPresetDrawer from '../CreateTaxPresetDrawer';
import {connect} from 'react-redux';
import TaxPresets from '../TaxPresets';

const useStyles = makeStyles((theme) => ({
    taxListContainerStyle: {
        height:'100%', 
        overflowY:'auto', 
        overflowX:'hidden',
        "&::-webkit-scrollbar": {
            display: "none"
        },
    },
    connectIcons: {
        width:'20px',
        marginLeft:'auto'
    }
}))

const OnboardingTaxPresets = (props) => {
    const classes = useStyles();

    const [openTaxPresetDrawerFromParent, setOpenTaxPresetDrawerFromParent]=useState(false);
    const taxPresets = props.selectedAccount.tax_presets;

    const handleTaxPresetDrawerOpenFromParent= () => {
        setOpenTaxPresetDrawerFromParent(true);
    }
    
    const handleTaxPresetDrawerCloseFromParent = () => {
        setOpenTaxPresetDrawerFromParent(false);
    }

    return (
        <div>
            <Grid item container className={classes.taxListContainerStyle}>
                <Grid item md={12}>
                <TaxPresets 
                    openTaxPresetDrawerFromParent={openTaxPresetDrawerFromParent}
                    handleTaxPresetDrawerCloseFromParent={handleTaxPresetDrawerCloseFromParent}
                    fromOnboardingComponent={true}
                    callApi={props.callApi}
                    setCallApi={props.setCallApi}
                    setTabValue={props.setTabValue}
                />
                </Grid>
            </Grid>
            {/* <Grid item container justifyContent='center'>
                <Grid item md={4} style={{marginTop:'16px'}}>
                    <Button variant='outlined' color='primary' 
                        fullWidth
                        onClick={handleTaxPresetDrawerOpenFromParent}>
                        Add Tax Prest
                        <AddCircleOutlineIcon className={classes.connectIcons} />
                    </Button>
                </Grid> 
            </Grid> */}
        </div>
    );
}

const mapStateToProps = (state) => ({
    selectedAccount: state.invoiceAccounts.selectedAccount,
})

export default connect(mapStateToProps)(OnboardingTaxPresets);