import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles'; 
import {Grid, Typography, TextField, Button, Avatar} from '@material-ui/core';
import {v4 as uuidv4} from 'uuid';

const useStyles = makeStyles((theme) => ({
    mainContent : {
        width: (showDrawer) => {
            return showDrawer ? "83%" : "100%"; 
        },
        backgroundColor: theme.palette.primary.light, 
        transition: ".5s", 
        flexGrow: 1,
        //overflow:'hidden',
        height:'calc(100vh - 48px)',
        overflow:'hidden',
        overflowX:'hidden', 
    },
    container: {
        marginTop: "8px"
    },
    settingsContainer: {
        width:'100%',
        background:'#fff',
        borderRadius:'4px',
        padding:'16px 24px',
        marginTop:'16px',
        marginBottom:'32px'
    },
    usersColumn : {
        background: '#F9F9F9',
        borderRadius: '5px',
        marginBottom: '8px',
        padding:'8px 8px',
    },
    avatarStyle: {
        width:"38px !important",
        height:"38px !important",
        fontSize:'16px !important',
        fontWeight:'600 !important',
        transform: 'translateY(3px)'
    },
    userText: {
        transform: 'translateY(1.5px)'
    },
}))

const InvoiceUsers = (props) => {
    const classes = useStyles();

    const [invoiceUsers, setInvoiceUsers] = useState([
        {
            id: uuidv4(),
            firstname:'Vikas',
            lastname:'Hiran',
            email:'vikas@codetoart.com'
        },
        {
            id: uuidv4(),
            firstname:'Mahavir',
            lastname:'Jain',
            email:'mahavir@codetoart.com'
        },
        {
            id: uuidv4(),
            firstname:'Kunal',
            lastname:'Thakur',
            email:'kunak@codetoart.com'
        },
        {
            id: uuidv4(),
            firstname:'Mustansir',
            lastname:'Rampurawala',
            email:'mustan@codetoart.com'
        }
        
    ])

    return (
        <Grid item xs={12} className={classes.settingsContainer}>
            <Typography style={{fontSize:'20px', fontWeight:'600'}} color="primary">
                Invite Users
            </Typography>

            <Grid item container spacing={1} alignItems="center">
                <Grid item lg={4}>
                    <TextField fullWidth
                        margin="dense"
                        variant="outlined"
                        label="Enter Email Address"
                        type="text"
                        style={{background: '#fff'}}
                        InputLabelProps={{style: {fontSize: 14, paddingRight:'31px !important'}}}
                    />
                </Grid>
                <Grid item lg={1}>
                    <Button  variant="contained" 
                        style={{background: "#383838",
                                width: "107px",
                                padding: "7px 0",
                                marginTop: "4px",
                                color: "white"
                            }}
                    >
                        Invite
                    </Button>
                </Grid>          
            </Grid>

            <Grid item xs={12} style={{marginTop:'24px'}}>
                {invoiceUsers && invoiceUsers.length > 0 && 
                 invoiceUsers.map((user) => {
                    let fullName = user.firstname + " " + user.lastname;
                    return <Grid item container 
                                key={user.id}
                                alignItems="center" 
                                className={classes.usersColumn}>

                                <Grid item md={8} lg={8}>
                                    <Grid item container alignItems="center">
                                        <Grid item>
                                            <Avatar className={classes.avatarStyle}>
                                            {user.firstname.charAt(0).toUpperCase() +
                                                user.lastname.charAt(0).toUpperCase()}    
                                            </Avatar>
                                        </Grid>
                                        <Grid item className={classes.userText}>
                                            <div style={{marginLeft: "16px"}}>
                                                <Typography style={{fontSize:'16px', fontWeight:'600'}}>
                                                    {fullName}
                                                </Typography>
                                                <Typography variant="subtitle1" style={{color:'#555555'}}>
                                                    {user.email}
                                                </Typography>
                                            </div>
                                        </Grid>
                                    </Grid>                                                
                                </Grid>

                            </Grid>
                    })
                }   
            </Grid>
        </Grid>
    );
}

export default InvoiceUsers;