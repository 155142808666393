import React, {useEffect, useState} from 'react';
import {Grid, Typography, Dialog, Button, CircularProgress, Box} from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import Slide from "@material-ui/core/Slide";
import { connect } from 'react-redux';
import CreateUpdateInvoiceComponent from '../CreateUpdateInvoiceComponent';
import PreviewAndSendInvoiceComponent from '../PreviewAndSendInvoiceComponent';
import {SendEmailType} from '../../actions/invoiceAccount';
import {setCreateInvoiceAccount, setInvoiceItemObj, setCustomerObject, 
    setShowPreviewInvoiceScreen, setShowSendInvoiceScreenType, setEditInvoiceObj} from '../../actions/invoiceAccount';
import { history } from '../../routers/AppRouter';
import { getUrlAccordingToSelectedComponent } from '../../util/AppUtil';
import { useIsMount } from '../useIsMount';
import { useLocation } from 'react-router-dom';
import { setEntityObj } from '../../actions/invoiceAccount';
import {getEstimateInvoiceDetailsApi} from '../../services/authService';
import { consoleToLog } from '../../util/AppUtil';
import { extractBasePath } from '../../util/AppUtil';
import {getUndeliveredEstimateItemsApi} from '../../services/authService';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const CreateEditPreviewInvoiceModal = (props) => {

    const [loading, setLoading] = useState(true);
    const [showError, setShowError] = useState(false);
    const [apiLoading, setApiLoading] = useState(false);
    const [estimateObjForCreateInvoice, setEstimateObjForCreateInvoice] = useState({});

    const location = useLocation();
    const isMount = useIsMount();

    const urlParts = window.location.pathname?.split('/');
    const pathname = urlParts[1];
    const showEstimateScreen = pathname === 'estimate' ? true : false;
    const slug = urlParts[2];
    const type = urlParts.length > 3 ? urlParts[3] : '';
    const estimateObj =  location.state?.estimateObj;
    const invoice_account_id = props.selectedAccount.id;

    // useEffect(() => {
    //     if (props.showCreateInvoice && !props.estimateObjForCreateInvoice?.id) {
    //         setLoading(false);
    //         return;
    //     } else {
    //         getEstimateOrInvoiceDetails(false);
    //     }
    // }, []);

    useEffect(() => {
        if(props.showCreateInvoice && !estimateObj) {
                setLoading(false);
                return;
        } else {
            if(slug) {
                if(estimateObj?.id) {
                    getUndeliveredEstimateItems(false, estimateObj?.slug);
                } else {
                    getEstimateOrInvoiceDetails(false);
                }
            }
        }
    }, [])


    const getEstimateOrInvoiceDetails = async (tryAgain) => {
            tryAgain && setApiLoading(true);
            try {
                const obj = Object.keys(props.invoiceObj).length > 0 ? props.invoiceObj : estimateObj && estimateObj?.id ? estimateObj : props.editInvoiceObj;
                const apiPath = showEstimateScreen || estimateObj ? `estimate/${estimateObj ? estimateObj?.slug : slug}` : `invoice/${slug}`;

                const response = await getEstimateInvoiceDetailsApi(invoice_account_id, apiPath);
                const res = response.data;
                consoleToLog('Response getEstimateInvoiceDetailsApi: ', res);

                if (type === 'preview') {
                    props.setInvoiceItemObj(res);
                } else {
                    props.setEditInvoiceObj(res);
                }

                // Save data in the store based on which object is being fetched
                // if(estimateObj?.id) {
                //     setEstimateObjForCreateInvoice(res);
                //     props.setCustomerObject(res.client);
                //     props.setEntityObj(res.entity);
                // } else {
                //     if (type === 'preview') {
                //         props.setInvoiceItemObj(res);
                //     } else {
                //         props.setEditInvoiceObj(res);
                //     }
                // }
                showError && setShowError(false);
                tryAgain && setApiLoading(false);
            } catch (error) {
                consoleToLog("Error getEstimateInvoiceDetailsApi", error);
                setShowError(true);
                tryAgain && setApiLoading(false);
            } finally {
                setLoading(false);
            }
    }

    const getUndeliveredEstimateItems = async(tryAgain, slug) => {

        try {

            const response = await getUndeliveredEstimateItemsApi(invoice_account_id, slug);
            const res = response.data;
            consoleToLog('Response getUndeliveredEstimateItemsApi: ', res);
            // Save data in the store based on which object is being fetched
            setEstimateObjForCreateInvoice(res);
            props.setCustomerObject(res.client);
            props.setEntityObj(res.entity);
            showError && setShowError(false);
            tryAgain && setApiLoading(false);
        } catch (error) {
            consoleToLog("Error getUndeliveredEstimateItemsApi", error);
            setShowError(true);
            tryAgain && setApiLoading(false);
        } finally {
            setLoading(false);
        }
    }


    const closeDialog = () => {
        history.push(getUrlAccordingToSelectedComponent(props.drawerState));
        props.showCreateInvoice && props.setCreateInvoiceAccount(false);
        Object.keys(props.invoiceObj).length > 0 && props.setInvoiceItemObj({});
        Object.keys(props.editInvoiceObj).length > 0 && props.setEditInvoiceObj({});
        props.setCustomerObject({});
        if(props.showPreviewInvoiceScreen) props.setShowPreviewInvoiceScreen(false);
        if(props.showSendInvoiceScreenType !== SendEmailType.NONE) props.setShowSendInvoiceScreenType(SendEmailType.NONE);
        estimateObjForCreateInvoice && Object.keys(estimateObjForCreateInvoice).length > 0 && setEstimateObjForCreateInvoice({});
        props.entitObj !== -1 && props.setEntityObj(-1);
        //props.showEstimateScreen && props.setShowEstimateScreen(false);
        props.handleInvoiceDialogClose();
    }

    return (
        <Dialog
            open={props.openInvoiceDialog}
            TransitionComponent={Transition}
            scroll="paper"
            fullScreen
            style={{scrollbarWidth:'none !important', zIndex:'100'}}
            onClose={closeDialog}>

                <Grid item md={12} style={{background:'#f7f7f7'}}>

                    
                    {loading ? 
                    <Grid item container justifyContent="center">
                        <CircularProgress size={25} style={{marginTop:'32px'}}/>
                    </Grid> 
                    :
                    !showError
                    ?
                    <>
                        {
                            Object.keys(props.invoiceObj).length > 0 
                            ?
                            <PreviewAndSendInvoiceComponent handleInvoiceDialogClose={props.handleInvoiceDialogClose}
                                setUpdatedInvoiceObj={props.setUpdatedInvoiceObj}
                                setAddTransaction={props.setAddTransaction}
                                updateTranscItemInTranscList={props.updateTranscItemInTranscList}
                                closeDialog={closeDialog}
                                showEstimateScreen={showEstimateScreen}
                                setEstimateObjForCreateInvoice={setEstimateObjForCreateInvoice}
                                setLoading={setLoading}
                                getUndeliveredEstimateItems={getUndeliveredEstimateItems}
                                /> 
                            :
                            (Object.keys(props.editInvoiceObj).length > 0 || props.showCreateInvoice) &&
                            <CreateUpdateInvoiceComponent handleInvoiceDialogClose={props.handleInvoiceDialogClose}
                                setUpdatedInvoiceObj={props.setUpdatedInvoiceObj}
                                updateInvoiceItemListInClients={props.updateInvoiceItemListInClients}
                                setCallGetInvoiceListApi={props.setCallGetInvoiceListApi}
                                closeDialog={closeDialog}
                                showEstimateScreen={showEstimateScreen}
                                estimateObjForCreateInvoice={estimateObjForCreateInvoice}/>
                        }
                    </>
                    :
                    <Box display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
                            <img src={'/images/details_error.png'} width={'50%'} style={{marginTop:'70px'}} />

                            <Typography variant='h5' style={{textAlign:'center'}}>
                                Something went wrong!
                            </Typography>

                            <Box display='flex' justifyContent='center' marginTop={2}>
                                <Button variant='contained' 
                                    onClick={() => getEstimateOrInvoiceDetails(true)}
                                    style={{ width: '120px' }} // Set width for the button
                                    color='secondary'>
                                    Try again
                                    {apiLoading && <CircularProgress size={18} style={{marginLeft:'8px', color:'white'}}/>}
                                </Button>
                                <Button variant='outlined'
                                    onClick={closeDialog}
                                    style={{ width: '120px', marginLeft: '8px' }} // Set width and margin for the button
                                    color='secondary'>
                                    Cancel
                                </Button>
                            </Box>
                    </Box>
                    }

                </Grid>
            
        </Dialog>
    );
}

const mapStateToProps = (state) => ({
    accessToken: state.invoiceAuth,
    selectedAccount: state.invoiceAccounts.selectedAccount,
    user: state.invoiceAccounts.user,
    showCreateInvoice: state.invoiceAccounts.showCreateInvoice,
    invoiceObj: state.invoiceAccounts.invoiceObj,
    showPreviewInvoiceScreen: state.invoiceAccounts.showPreviewInvoiceScreen,
    showSendInvoiceScreenType: state.invoiceAccounts.showSendInvoiceScreenType,
    editInvoiceObj: state.invoiceAccounts.editInvoiceObj,
    drawerState: state.drawerState.setDrawerState,
    showEstimateScreen: state.invoiceAccounts.showEstimateScreen,
    estimateObjForCreateInvoice: state.invoiceAccounts.estimateObjForCreateInvoice
});

const mapDispatchToProps = (dispatch) => ({
    setCreateInvoiceAccount: (createInvoice) => dispatch(setCreateInvoiceAccount(createInvoice)),
    setInvoiceItemObj: (invoice) => dispatch(setInvoiceItemObj(invoice)),
    setCustomerObject: (customerObj) => dispatch(setCustomerObject(customerObj)),
    setShowPreviewInvoiceScreen: (previewInvoiceScreen) => dispatch(setShowPreviewInvoiceScreen(previewInvoiceScreen)),
    setShowSendInvoiceScreenType: (sendInvoiceScreenType) => dispatch(setShowSendInvoiceScreenType(sendInvoiceScreenType)),
    setEditInvoiceObj: (obj) => dispatch(setEditInvoiceObj(obj)),

    setEntityObj: (entity) => dispatch(setEntityObj(entity))
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateEditPreviewInvoiceModal);